import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.NODE_ENV === 'production'
        ? '/'
        : process.env.NODE_ENV === 'stage'
            ? process.env.VUE_APP_STAGE_FOLDER
            : '/',
    routes: [
        {
            path: '/',
            redirect: '/home'
        },
        {
            path: '/home',
            component: resolve => require(['./views/Home.vue'], resolve)
        },

        {
            path: '/AllDrawingResult',
            name: 'AllDrawingResult',
            component: resolve => require(['./components/template-3/AllDrawingResult'], resolve)
        },
        {
            path: '/GameList',
            name: 'GameList',
            component: resolve => require(['./components/common/GameList'], resolve)
        },
        {
            path: '/GameList3',
            name: 'GameListTemplate3',
            component: resolve => require(['./components/template-3/GameList'], resolve)
        },
        {
            path: '/ThirdpartyList',
            name: 'ThirdpartyList',
            component: resolve => require(['./components/common/ThirdpartyList'], resolve)
        },
        {
            path: '/PromotionKickbackList',
            name: 'PromotionKickbackList',
            component: resolve => require(['./components/common/PromotionKickbackList'], resolve)
        },
        {
            path: '/UserGuide',
            name: 'UserGuide',
            component: resolve => require(['./components/UI_2/UserGuide'], resolve)
        },
        {
            path: '/beijing28/room_list',
            name: 'room_list',
            component: resolve => require(['./views/room_list.vue'], resolve) //房间列表
        },
        {
            path: '/discount',
            component: resolve => require(['./views/discount.vue'], resolve),
        },
        {
            path: '/login',
            name: 'login_landing_registration',
            component: resolve => require(['./views/landing_registration.vue'], resolve) //登陆和注册界面
        },
        {
            path: '/action/luck_draw',
            name: 'luck_draw',
            component: resolve => require(['./views/action/luck_draw'], resolve) //积分抽奖
        },
        {
            path: '/action/discountCon/:id',
            name: 'discountCon',
            component: resolve => require(['./views/action/discountCon'], resolve) //优惠活动详情
        },
        {
            path: '/beijing28/TrendsToChart',
            name: 'Trend',
            component: resolve => require(['./components/pc28/TrendsToChart'], resolve) //开奖走势
        },

        {
            path: '/action/lotteryRules',
            name: 'lotteryRules',
            component: resolve => require(['./views/action/lotteryRules'], resolve) //摇奖规则
        },
        {
            path: '/action/withdrawalBonus',
            name: 'withdrawalBonus',
            component: resolve => require(['./views/action/withdrawalBonus'], resolve) //我的奖金
        },
        {
            path: '/action/singIn',
            name: 'singIn',
            component: resolve => require(['./views/action/signIn'], resolve) //签到页面
        },


        {
            path: '/t',
            name: 'TradingFloor',
            component: resolve => require(['./views/TradingFloors.vue'], resolve) //所有种类大厅
        },
        {
            path: '/game/trendsByLuzhu',
            component: resolve => require(['./components/pc28/TrendsByLuZhu'], resolve) //一分28 露珠/长龙
        },
        {
            path: '/beijing28/Analysis',
            name: 'Analysis',
            component: resolve => require(['./components/pc28/Analysis.vue'], resolve) // headerMenu 数据分析
        },
        {
            path: '/pc28/TradeHistory',
            name: 'TradeHistory',
            component: resolve => require(['./components/pc28/TradeHistory.vue'], resolve)
        },
        {
            path: '/pc28/DrawingHistory',
            name: 'DrawingHistory',
            component: resolve => require(['./components/pc28/DrawingHistory.vue'], resolve)
        },
        {
            path: '/pc28/TrendsByLuZhu',
            name: 'TrendsByLuZhu',
            component: resolve => require(['./components/pc28/TrendsByLuZhu.vue'], resolve)
        },
        {
            path: '/action/query_record',
            name: 'query_record',
            component: resolve => require(['./views/action/query_record'], resolve)
        },
        {
            path: '/action/query_luzhu',
            name: 'query_luzhu',
            component: resolve => require(['./components/pc28/TrendsByLuZhu'], resolve) //六合彩露珠
        },
        {
            path: '/ChatRoom',
            name: 'ChatRoom',
            component: resolve => require(['./components/pc28/ChatRoom'], resolve)
        },
        {
            path: '/pc28/GameRule',
            name: 'GameRule',
            component: resolve => require(['./components/template-3/GameRule'], resolve)
        },
        {
            path: '/action/down',
            name: 'androidDown',
            component: resolve => require(['./views/action/down'], resolve)
        },
        {
            path: '/downloadApp/IosQrCode',
            name: 'DownloadIosQrCode',
            component: resolve => require(['./views/action/DownloadIosByQrCode'], resolve)
        },
        {
            path: '/downloadApp/IosManual',
            name: 'DownloadIosManual',
            component: resolve => require(['./views/action/DownloadManual'], resolve)
        },
        {
            path: '/customer',
            component: resolve => require(['./views/my/customerList/customer.vue'], resolve),
        },
        {
            path: '/customerList/wechat',
            component: resolve => require(['./views/my/customerList/wechat.vue'], resolve),
        },
        {
            path: '/customerList/qqList',
            component: resolve => require(['./views/my/customerList/qqCustomer'], resolve)
        },
        {
            path: '/customerList/weCharList',
            component: resolve => require(['./views/my/customerList/weCharCustomer'], resolve)
        },
        {
            path: '/customerList/onLineList',
            component: resolve => require(['./views/my/customerList/onLineCustomer'], resolve)
        },
        {
            path: '/money',
            component: resolve => require(['./views/my/money/money.vue'], resolve)
        },
        {
            path: '/money/finish',
            component: resolve => require(['./views/my/money/payment_page.vue'], resolve)
        },
        {
            path: '/money/payment_page/:num',
            component: resolve => require(['./views/my/money/payment_page'], resolve)
        },
        {
            path: '/money/wechat_money/:id/:title/:min/:max/:need_params',
            component: resolve => require(['./views/my/money/wechat_money.vue'], resolve)
        },
        {
            path: '/money/wechat_money_finsh',
            component: resolve => require(['./views/my/money/wechat_money_finsh.vue'], resolve),
        },
        {
            path: '/money/wechat_money_under',
            component: resolve => require(['./views/my/money/wechat_money_under.vue'], resolve),
        },
        {
            path: '/money/zfu_monye_under',
            component: resolve => require(['./views/my/money/zfu_monye_under.vue'], resolve),
        },
        {
            path: '/money/yl_monye_under',
            component: resolve => require(['./views/my/money/yl_monye_under.vue'], resolve),
        },
        {
            path: '/p',
            component: resolve => require(['./views/my/my.vue'], resolve) //我的
        },
        {
            path: '/my/about/about',
            name: 'my_about',
            component: resolve => require(['./views/my/about/about.vue'], resolve) //关于
        },
        {
            path: '/my/YuLiBao',
            name: 'YuLiBao',
            component: resolve => require(['./views/my/yulibao'], resolve)
        },
        {
            path: '/my/YuLiBaoDetail',
            name: 'YuLiBaoDetail',
            component: resolve => require(['./views/my/yulibao/detail'], resolve)
        },
        {
            path: '/my/YuLiBaoTransfer',
            name: 'YuLiBaoTransfer',
            component: resolve => require(['./views/my/yulibao/transfer'], resolve)
        },
        {
            path: '/my/registration_money',
            component: resolve => require(['./views/my/registration_money/registration_money.vue'], resolve) //注册佣金
        },
        {
            path: '/my/registration_money/list',
            component: resolve => require(['./views/my/registration_money/registration_list.vue'], resolve) //注册佣金明细
        },
        {
            path: '/my/registration_money/yes',
            component: resolve => require(['./views/my/registration_money/registration_yes.vue'], resolve) //注册佣金完成
        },
        {
            path: '/my/promote_money_making/promote_money_making',
            component: resolve => require(['./views/my/promote_money_making/promote_money_making.vue'], resolve) //推广赚钱
        },
        {
            path: '/my/cash_withdrawal/no_bank',
            component: resolve => require(['./views/my/cash_withdrawal/no_bank'], resolve) //没有绑定银行卡
        },
        {
            path: '/my/cash_withdrawal/get_bank_message',
            component: resolve => require(['./views/my/cash_withdrawal/get_bank_message'], resolve) //提现
        },
        {
            path: '/my/cash_withdrawal/payment_information',
            component: resolve => require(['./views/my/cash_withdrawal/payment_information'], resolve) //出款信息
        },
        {
            path: '/my/cash_withdrawal/presentation_copy',
            component: resolve => require(['./views/my/cash_withdrawal/presentation_copy'], resolve) //提现拷贝
        },
        {
            path: '/my/set_up/set_up',
            component: resolve => require(['./views/my/set_up/set_up'], resolve) //设置
        },
        {
            path: '/my/set_up/modifying_data',
            component: resolve => require(['./views/my/set_up/modifying_data'], resolve) //修改资料
        },
        {
            path: '/my/news/news',
            component: resolve => require(['./views/my/news/news'], resolve) //消息公告
        },
        {
            path: '/my/news/newDetail',
            name: 'newDetail',
            component: resolve => require(['./views/my/news/newDetail'], resolve) //公告详情
        },
        {
            path: '/my/agent_registration/agent_registration',
            component: resolve => require(['./views/my/agent_registration/agent_registration'], resolve) //代理注册
        },
        {
            path: '/my/historical_betting/historical_betting',
            component: resolve => require(['./views/my/historical_betting/historical_betting'], resolve) //历史投注
        },
        {
            path: '/my/capital_transaction/capital_transaction',
            component: resolve => require(['./views/my/capital_transaction/capital_transaction'], resolve) //资金交易
        },
        {
            path: '/my/return_of_members/return_of_members',
            component: resolve => require(['./views/my/return_of_members/return_of_members'], resolve) //返水
        },
        {
            path: '/my/set_up/login_password',
            component: resolve => require(['./views/my/set_up/login_password'], resolve) //设置登陆密码
        },
        {
            path: '/my/set_up/presentation_password',
            component: resolve => require(['./views/my/set_up/presentation_password'], resolve) //设置提现密码
        },
        {
            path: '/my/my_bank/add_bank',
            component: resolve => require(['./views/my/my_bank/add_bank'], resolve) //我的-添加银行卡
        },
        {
            path: '/my/my_bank/no_bank',
            component: resolve => require(['./views/my/my_bank/no_bank'], resolve) //我的--没有绑定银行卡
        },
        {
            path: '/my/cash_withdrawal/now_cash',
            component: resolve => require(['./views/my/cash_withdrawal/now_cash'], resolve) //本次提现弹窗
        },
        {
            path: '/my/about/myAbout',
            name: 'myAbout',
            component: resolve => require(['./views/my/about/myAbout'], resolve) //关于
        },
        {
            path: '/my/about/listDetail',
            name: 'listDetail',
            component: resolve => require(['./views/my/about/listDetail'], resolve) //关于详情
        },
        {
            path: '/my/capitalConversion/capitalConversion',
            name: 'capitalConversion',
            component: resolve => require(['./views/my/capitalConversion/capitalConversion'], resolve) //资金转换
        },
        {
            path: '/my/redEnvelope',
            name: 'RedEnvelopeHistory',
            component: resolve => require(['./views/my/red_envelope/index'], resolve)
        },
    ]
})
import { service } from '../CONF.js'

//个人信息
export function getInfo() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/UserInfo/getInfo')
        }))
    })
}

//注册佣金明细
export function popularizeList() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/account/popularizeList')
        }))
    })
}

//注册佣金提现
export function popularizeOutMoney() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/account/popularizeOutMoney')
        }))
    })
}

//注册佣金显示数据
export function popularizeInfo() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/account/popularizeInfo')
        }))
    })
}

//会员银行卡列表
export function bank_list() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/Main/UserBankcard/UserBankCardList')
        }))
    })
}

//会员添加银行卡
export function add_bank(bank_num, subbranch, pay_name, bank_id, password) {
    const data = {
        bank_num: bank_num,
        subbranch: subbranch,
        pay_name: pay_name,
        bank_name: bank_id,
        password: password,
    }
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/Main/UserBankcard/addBankCard', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//稽核接口
export function audit() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/Main/Account/audit')
        }))
    })
}

//账户余额
export function account_balance(Authorization) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/account/money',{
                params: {
                    'Authorization': Authorization,
                },
            })
        }))
    })
}

//提现申请
export function cash_withdrawal_application(money, password, id) {
    const data = {
        money: money,
        id: id,
        password: password,
    }
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/Main/Account/withdraw', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//投注记录（按日期分）
export function betting_record(type, startTime, endTime, isProfit) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/lottery/history',{
                params: {
                    'type': type,
                    'startTime': startTime,
                    'endTime': endTime,
                    'isProfit': isProfit,
                },
            })
        }))
    })
}

//三方投注记录
export function thirdpartyTradingHistory(type, startTime, endTime, isProfit) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/thirdPlat/pageList',{
                params: {
                    'thirdType': type,
                    'startTime': startTime,
                    'endTime': endTime,
                },
            })
        }))
    })
}

//彩种列表信息
export function game_list() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/home/lottery')
        }))
    })
}

//获取开户银行
export function kf_bank() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/Main/Bank/search',{
                params: {
                    authFree: true
                },
            })
        }))
    })
}


//推广链接
export function kglj() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/PromotionLinks/linkList')
        }))
    })
}


//推广红利
export function bonus_extension(grandpa_id, parent_id, page, pageSize) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/PromotionLinks/dividend',{
                params: {
                    'grandpa_id': grandpa_id,
                    'parent_id': parent_id,
                    'page': page,
                    'pageSize': pageSize,
                },
            })
        }))
    })
}


//会员返水
export function men_return(startTime, endTime) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/Main/Account/backwater',{
                params: {
                    'startTime': startTime,
                    'endTime': endTime,
                },
            })
        }))
    })
}


//会员个人全部账户资产
export function capital() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/account/capital')
        }))
    })
}


//关于列表
export function aboutList(type) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/About/adoutList',{
                params: {
                    'type': type,
                },
            })
        }))
    })
}

//关于列表详情
export function aboutListDetail(id) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('main/About/aboutDetails',{
                params: {
                    'id': id,
                },
            })
        }))
    })
}

//修改会员登录密码
export function changePwd(old_password, new_password, new_password1) {
    const data = {
        old_password: old_password,
        new_password: new_password,
        new_password1: new_password1,
    }
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/Main/Account/changePwd', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//修改提款密码
export function BankChangePwd(old_password, new_password, new_password1) {
    const data = {
        old_password: old_password,
        new_password: new_password,
        new_password1: new_password1,
    }
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/Main/UserBankcard/changePwd', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//额度信息
export function getWmUser() {
    const data = {}
   return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/Main/WmCasino/getWmInfo', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}


//额度转换
export function moneyTransfer(from_account, to_account, change_money) {
    const data = {
        from_account: from_account,
        to_account: to_account,
        change_money: change_money,
    }
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/Main/WmCasino/moneyTransfer', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

export function sexyTransfer(from_account, change_money, to_account) {
    const data = {
        from_account: from_account,
        change_money: change_money,
        to_account: to_account,
    }
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/Main/SexyCasino/moneyTransfer', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

export function asTransfer(type, money) {
    const data = {
        type: type,
        money: money,
    }
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/Main/AsCasino/moneyTransfer', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

export function dgTransfer(type, money) {
    const data = {
        type: type,
        money: money,
    }
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/Main/DgCasino/moneyTransfer', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

export function thirdPartyBalanceTransferV1(casino_type, type, money) {
    const data = {
        casino_type,
        type,
        money,
    }
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/v1.0/Casino/transfer', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

export function thirdPartyBalanceTransfer(type, money, apiAddr) {
    const data = {
        type: type,
        money: money,
    }
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                apiAddr, 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

export function thirdPartyBalanceFetchV1(casino_type) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get(
                'v1.0/Casino/money', 
                {
                    params: {
                        casino_type,
                    },
                }
            )
        }))
    })
}

export function thirdPartyBalanceFetch(apiAddr) {
    const data = {
    }
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                apiAddr, 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//我的公告
export function announcement(page, pageSize) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('Main/userInfo/announcement',{
                params: {
                    'page': page,
                    'pageSize': pageSize,
                },
            })
        }))
    })
}

//我的公告详情
export function announcementDetails(id) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('main/userInfo/announcementDetails',{
                params: {
                    'id': id,
                },
            })
        }))
    })
}

//我的消息
export function userMessageList(page, pageSize) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/UserMessage/pageList',{
                params: {
                    'page': page,
                    'pageSize': pageSize,
                },
            })
        }))
    })
}

//我的消息详情
export function userMessageDetail(id) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/UserMessage/getDetail',{
                params: {
                    'id': id,
                },
            })
        }))
    })
}

//获取账户余额
export function getBalance() {
    const data = {}
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/home/getBalance')
        }))
    })
}

//DG
export function getDgBalance() {
    const data = {}
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/DgCasino/getBalance')
        }))
    })
}

export function getThirdPartyBalance(name) {
    const data = {}
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/'+name+'/getBalance')
        }))
    })
}

//AS
export function getASBalance() {
    const data = {}
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/main/AsCasino/getBalance', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//返水信息
export function returnwater(startTime,endTime) {
    const data = {
        startTime,
        endTime,
    }
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get(
                '/main/ReturnWater/returnWater', {
                    params: {
                        startTime,
                        endTime,
                    },
                }
            )
        }))
    })
}

//未读信息
export function getUnreadCount() {
    const data = {}
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/UserMessage/count')
        }))
    })
}

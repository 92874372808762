import { service } from '../CONF.js'

//获取首页红包
export function getAppRedPack(data) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('v1.0/user/redpack', {
                params: {
                    ...data
                }
            })
        }))
    })
}
//打开首页红包
export function openAppRedPack(data) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                'v1.0/user/redpack', 
                data,
            )
        }))
    })
}
//首页红包历史列表
export function appRedPackHistory(data) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                'v1.0/user/redpack/list', 
                data,
            )
        }))
    })
}
//首页红包提款
export function appRedPackWithdraw(data) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                'v1.0/user/redpack/active', 
                data,
            )
        }))
    })
}
//首页红包提款(多个)
export function appRedPackWithdraws(data) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                'v1.0/user/redpack/activeBatch', 
                data,
            )
        }))
    })
}
//获取等级列表
export function getLevelList(data) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('v1.0/userGrades', {
                params: {
                    ...data
                }
            })
        }))
    })
}
//获取奖金历史列表
export function getLuckyDrawHistory(data) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('main/active/drawLog', {
                params: {
                    ...data
                }
            })
        }))
    })
}

//（1.0）确认赔率
export function confirmOdds(type,issue,room_num,lotteries) {
    const data = {
        type,
        issue,
        room_num,
        lotteries,
    }
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/v1.0/confirmOdds', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//聊天室设置
export function getRoomConfig() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('Main/Home/getRoomConfig', {
                params: {
                }
            })
        }))
    })
}

//聊天室关键词
export function getTalkRoomSetting() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('main/home/getTalkRoomSetting', {
                params: {
                }
            })
        }))
    })
}

//长龙统计
export function longLine(type, data, startTime, endTime) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('main/lottery/longLine', {
                params: {
                    type: type,
                    data: data,
                    startTime: startTime,
                    endTime: endTime
                }
            })
        }))
    })
}

//获取当前期号已经封盘时间信息
export function issue(type) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/lottery/issue',{
                params: {
                    type: type,
                    authFree: true,
                    timeStamp: new Date().getTime()
                },
            })
        }))
    })
}


//获取上一期期号与开奖信息
export function prev_issue(type) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/lottery/prev_issue',{
                params: {
                    type: type
                },
            })
        }))
    })
}



//时间节点系统消息列表
export function system_message(type, room_num) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/lottery/message',{
                params: { 
                    type: type, 
                    room_num: room_num 
                },
            })
        }))
    })
}



//开奖历史记录
export function lottery_record(type, page, pageSize, startTime, endTime) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/lottery/openHistory',{
                params: {
                    type: type,
                    page: page,
                    pageSize: pageSize,
                    startTime,
                    endTime,
                    authFree: true
                },  
            })
        }))
    })
}


//北京28的投注记录
export function tz_record(type, roomId, startTime, endTime, isProfit, page, pageSize) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/lottery/originalHistory',{
                params: {
                    type: type,
                    roomId: roomId,
                    startTime: startTime,
                    endTime: endTime,
                    isProfit: isProfit,
                    page: page,
                    pageSize: pageSize,
                },
            })
        }))
    })
}





//账号余额
export function account_balance() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/account/money',{
                headers: {
                    Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('user')).token
                }
            })
        }))
    })
}



//数据分析
export function data_analysis(type) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('Main/Lottery/analysisData',{
                params: {
                    type: type,
                    authFree: true
                }
            })
        }))
    })
}

//聊天
export function chat(room_num, type, message) {
    const data = {
        room_num: room_num,
        type: type,
        message: message
    }
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/main/Message/say', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}



//聊天投注接口
export function chat_bet(issue, room_num, type, message) {
    const data = {
        issue: issue,
        room_num: room_num,
        type: type,
        message: message
    }
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/main/lottery/messageBet', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//经典彩类型——聊天投注接口
export function dtMessageBet(issue, room_num, type, message) {
    const data = {
        issue: issue,
        room_num: room_num,
        type: type,
        message: message
    }
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/main/Lottery/dtMessageBet', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//WS开房
export function roomLogin(data) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/Main/Account/roomLogin', 
                data,
            )
        }))
    })
}

//WS退房
export function roomLogout(game_room,game_type) {
    const data = {
        game_room,
        game_type,
    }
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/Main/Account/roomLogout', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//聊天室昵称
export function POST_chatRoomNickname(talkroom_nick_name) {
    const data = {
        talkroom_nick_name,
    }
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/v1.0/enternotice', 
                data,
            )
        }))
    })
}

//聊天室抢红包
export function POST_openHongbao(id, password) {
    const data = {
        id,
        password,
    }
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/v1.0/Reward', 
                data,
            )
        }))
    })
}

//聊天室红包列表
export function GET_hongbaos(start_date=Math.round(new Date().getTime()/1000)-60*60*24,end_date=Math.round(new Date().getTime()/1000),page=1,page_size=20) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get(
                '/v1.0/giftmoneys', 
                {
                    params:{
                        start_date,
                        end_date,
                    },
                },
            )
        }))
    })
}

//聊天室人数
export function GET_onlineUserCount() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get(
                '/v1.0/onlineuser/count',
                {
                    params:{

                    },
                },
            )
        }))
    })
}

//前端用户转入转出余利宝
export function POST_userrecords(money, type) {
    const data = {
        money,
        type,
    }
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/v1.0/UserInsterest/userrecords',
                data,
            )
        }))
    })
}

//用户获取余利宝收益列表
export function GET_userrecords(page_index, page_size, start_date, end_date, type) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get(
                '/v1.0/UserInsterest/userrecords',
                {
                    params: {
                        page_index,
                        page_size,
                        start_date,
                        end_date,
                        type,
                    }
                },
            )
        }))
    })
}

//前端用户获取余利宝配置
export function simpleconfig() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get(
                '/v1.0/UserInsterest/simpleconfig',
                {
                    params: {
                    }
                },
            )
        }))
    })
}

//用户余利宝汇总
export function count() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get(
                '/v1.0/UserInsterest/count',
                {
                    params: {
                    }
                },
            )
        }))
    })
}

//客服列表
export function getServiceList() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get(
                'v1.0/sites/customerList',
                {
                    params: {
                    }
                },
            )
        }))
    })
}
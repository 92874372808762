import Vue from 'vue'
Vue.config.productionTip = false

import router from './router'

import store from './store'

import axios from 'axios'

import $sock from '@/components/WebSocket'

import VueCookie from 'vue-cookie';
Vue.use(VueCookie);

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper)

import VueQriously from 'vue-qriously'
Vue.use(VueQriously)

import CustomScroll from './lib/safari-custom-scrollbar/export.js'
Vue.use(CustomScroll)

import 'css-ripple-effect/dist/ripple.css'

import App from './App.vue'

import {
  getClientTime
} from '@/CONF.js'

import DataTime from '@/components/dataTime';
import {
  cloudImageBaseUrl
} from "./CONF";

var developer = location.hostname === 'localhost' || location.hostname.indexOf('192.168') === 0
var apiAddrPrefix = null;
var currentDevDomain = 'bw3368.xyz' //current
var languageDev = 'en'
var VUE__lastStay = ''; //vue variable mounte in window use VUE__ prefix
var templateName = null;
var trueDomainAPI = null;

window.wsConnection = null;
window.roomLogout = true;
window.tokenCheckTimer = null;
window.urlType = null;
window.wsPing = 0;
window.wsPong = 0;
window.wsHeartBeatTimeout = null;
window.wsPingPongCheckTimeout = null;
window.language = null;
window.dict = null;
window.currencySymbol = null;
window.connect = function (_options, _callback) {
  if (urlType) {
    console.log('scan detected. stop connect.')
    return false;
  }
  axios({
    url: apiAddrPrefix + '/main/account/wsAccessToken',
    method: 'post',
    params: {
      url: developer ? 'www.' + currentDevDomain : location.hostname
    }
  }).then(result => {
    if (result.data.code === 'SUCCESS') {
      resetStatus();
      connectWS()

      function connectWS (options) {

        var access_token = result.data.result.access_token;
        var token = JSON.parse(window.localStorage.getItem('user')) && JSON.parse(window.localStorage.getItem('user')).token;
        var wsurl = window.localStorage.getItem('wsurl');

        if (_options && _options.reconnect) {
          connecting();
        } else if (roomLogout === true && !tokenCheckTimer && token) {
          axios({
            url: window.localStorage.getItem('apiurl') + '/main/account/money',
            method: 'get',
            headers: {
              'Authorization': 'Bearer ' + token
            }
          }).then(result => {
            if (result.data.code === 'SUCCESS') {
              connecting(function () {

              });
              tokenCheckTimer = setTimeout(function () {
                window.clearTimeout(tokenCheckTimer)
                tokenCheckTimer = null;
              }, 60000)
            } else {
              token = null;
              console.log('user info removed.')
              window.localStorage.removeItem('user');
              connecting(function () {
                console.log('unexpected connection close. reconnected.')
              });
            }
          })
        } else {
          connecting();
        }

        function connecting (callback) {
          token = JSON.parse(window.localStorage.getItem('user')) && JSON.parse(window.localStorage.getItem('user')).token;
          var key = token ? 'token' : 'access_token';
          var value = token ? token : access_token;
          var params = {
            [key]: value
          }
          if (options && options.origin) {
            console.log(options.origin.toUpperCase() + ' ready to connect WS.(APP START)')
            connectWS();
          } else {
            console.log('ready to connect WS.(APP START)')
          }
          wsConnection = $sock.connect(
            wsurl,
            params,
            function () {
              console.log('ws connected.(APP START)');

              if (_options && _options.reset) {
                console.log('ready to refresh...')
                window.location.reload();
              } else {

                function wsHeartBeat () {
                  if (wsConnection.sock.readyState !== 1) {
                    console.log('ping pong stop, ready to refresh...')
                    // window.location.reload();
                    wsConnection.close();
                  }
                  wsHeartBeatTimeout = setTimeout(function () {
                    $sock.sock.send(
                      $sock.base64.encode(
                        JSON.stringify({
                          "path": "Index/ping",
                        })
                      )
                    )
                    wsPing++;
                    wsPingPongCheckTimeout = setTimeout(function () {
                      if (wsPing > wsPong) {
                        console.log('ping pong stop, ready to refresh...')
                        // window.location.reload();
                        wsConnection.close();
                      } else {
                        wsHeartBeat();
                      }
                    }, 1000 * 10)

                  }, 1000 * 10)
                }
                wsHeartBeat()

              }
              typeof callback === 'function' && callback();
              typeof _callback === 'function' && _callback();
            },
            function () {
              console.log('WS ERROR at connect.')
            }
          );

          wsConnection.sock.onclose = function (event) {
            clearTimeout(wsHeartBeatTimeout);
            clearTimeout(wsPingPongCheckTimeout);
            roomLogout = true;
            console.log("called websocket close")

            if (_options && _options.reconnect) {
              console.log('ws closed.');
              return false;
            } else {
              console.log('ws attempt to connect after 3sec.(APP START)')
              setTimeout(function () {
                if (wsConnection && wsConnection.sock.readyState === 3) {
                  console.log('wsConnection closed')
                }
                console.log('token availible, reconnect WS')
                connect();
              }, 3000)
            }
          };
          wsConnection.sock.onerror = function (event) {
            console.log('WS ERROR');
          }
        }
      }
      $sock.onRecive(function (data) {
        function _prefix (msg) {
          return msg.indexOf('特码-') === 0 ? msg : prefix + msg
        }
        switch (data.from) {
          case 'PING':
            window.wsPong++;
            break;
          case 'LAST_ISSUE':
            app.$store.commit('UPDATE_currentDrawing', data.msg)
            break;
          case 'LAST_OPENNUMBER':
            app.$store.commit('UPDATE_lastDrawing', data.msg)
            break;
          case 'USER_MONEY':
            if (data.msg.user_money === null) {
              if (window.localStorage.getItem('openid') === 'anonymous') {
                app.$router.push({
                  path: '/login',
                })
              } else {
                app.$router.push({
                  path: '/',
                })
              }
              window.localStorage.removeItem('user');
              app.Mint.Toast(getWord('sign_out_tips'));
              if (wsConnection && wsConnection.sock.readyState === 1) {
                wsConnection.close(function () {
                  console.log('ws closed.(Invalid Token)')
                });
              }
            }
            app.$store.commit('UPDATE_userBalance', data.msg)
            break;
          case 'RBOTSAY':
            var data_time = getClientTime()
            var pc28 = app.$route.query.type.indexOf('28') > -1
            var prefix = pc28 ? getWord('item_tema') + '-' : '';
            var msg = data.msg;
            var avatar = msg.headImage ? msg.headImage : msg[0].avatar;
            var drawingIndex = app.$store.state.currentDrawing && app.$store.state.currentDrawing[app.$route.query.type] && app.$store.state.currentDrawing[app.$route.query.type].issue

            app.$store.commit('UPDATE_tradingFloorMessages', {
              type: "bet-all",
              nickname: msg.nickname,
              msg: pc28 ?
                _prefix((msg.dict_name ? getWord(msg.dict_name) : getWord(msg.name)) + ' ' + window.currencySymbol + msg.money) : (msg.dict_name ? getWord(msg.dict_name) : getWord(msg.name)) + ' ' + window.currencySymbol + msg.money,
              time: data_time,
              avatar: avatar,
              drawingIndex: drawingIndex,
            })
            break;
          case 'BETSAY':
            if (data.class === 'RETURN') {
              return false;
            }
            var data_time = getClientTime()
            var pc28 = app.$route.query.type.indexOf('28') > -1
            var prefix = pc28 ? getWord('item_tema') + '-' : '';
            var msg = data.msg.message;
            var avatar = msg.headImage ? msg.headImage : msg[0].avatar;

            var combinedMsg = ''
            msg.forEach(item => {
              combinedMsg += pc28 ?
                _prefix(getWord(item.dict_keyword) + ' ' + window.currencySymbol + item.money) + '、' :
                getWord(item.dict_keyword) + (item.bet ? '-' + item.bet : '') + ' ' + window.currencySymbol + item.money + '、'
            })
            console.log(combinedMsg)
            combinedMsg = combinedMsg.slice(0, -1);
            if (window.localStorage.getItem('user')) {
              if (msg[0].nickname !== JSON.parse(window.localStorage.getItem('user')).nickname) {
                app.$store.commit('UPDATE_tradingFloorMessages', {
                  type: "bet-all",
                  nickname: msg[0].nickname,
                  msg: combinedMsg,
                  time: data_time,
                  avatar: avatar,
                  drawingIndex: msg[0].drawing_index,
                });
              }
            }
            break;
          case 'NOTICE':
            var msg = data;
            app.$store.commit('UPDATE_chatRoomNotices', {
              type: "error",
              errorcode: msg.code,
              msg: msg.msg,
              state: true,
            });
            break;
          case 'CHAT':
            if (data.class === 'RETURN') {
              return false;
            }
            var msg = data.msg;
            if (window.localStorage.getItem('user')) {
              if (msg.nickname !== JSON.parse(window.localStorage.getItem('user')).nickname && msg.nickname !== app.$store.state.userInfo.talkroom_nick_name) {
                if (msg.message.indexOf('SHORTCUT_IMAGE__') === 0) {
                  app.$store.commit('UPDATE_chatRoomMessages', {
                    type: "SHORTCUT_IMAGE",
                    nickname: msg.nickname,
                    msg: msg.message.split('SHORTCUT_IMAGE__')[1],
                    time: msg.time,
                    avatar: msg.headImage,
                    grade: msg.grade,
                    create_time: new Date().getTime(),
                  });
                } else if (msg.message.indexOf('SHORTCUT_TEXT__') === 0) {
                  app.$store.commit('UPDATE_chatRoomMessages', {
                    type: "SHORTCUT_TEXT",
                    nickname: msg.nickname,
                    msg: msg.message.split('SHORTCUT_TEXT__')[1],
                    time: msg.time,
                    avatar: msg.headImage,
                    grade: msg.grade,
                    create_time: new Date().getTime(),
                  });
                } else {
                  app.$store.commit('UPDATE_chatRoomMessages', {
                    type: "all",
                    nickname: msg.nickname,
                    msg: msg.message,
                    time: msg.time,
                    avatar: msg.headImage,
                    grade: msg.grade,
                    create_time: new Date().getTime(),
                  });
                }
              }
            }
            break;
          case 'ADMINMSG':
            var msg = data.msg;
            if (msg.action_type && msg.action_type === 'CLEAN') {
              app.$store.commit('RESET_chatRoomMessages');
            } else {
              app.$store.commit('UPDATE_chatRoomMessages', {
                type: "admin",
                nickname: msg.admin_nick_name,
                msg: msg.message,
                time: msg.time,
                avatar: cloudImageBaseUrl + msg.head_url,
                create_time: new Date().getTime(),
              });
            }
            break;
          case 'GIT_MONEY_SEND':
            var msg = data.msg;
            if (window.localStorage.getItem('user')) {
              app.$store.commit('UPDATE_chatRoomMessages', {
                type: "hongbao",
                title: msg.title,
                id: msg.id,
                time: msg.created_at,
                admin_nick_name: msg.admin_nick_name,
                repeatString: msg.password,
                avatar: msg.head_image,
                create_time: new Date().getTime(),
              });
            }
            if (window.localStorage.getItem('user') && Number(msg.type) === 2) {
              app.$store.commit('UPDATE_chatRoomRepeatHongbaos', {
                type: "hongbao",
                title: msg.title,
                id: msg.id,
                time: msg.created_at,
                admin_nick_name: msg.admin_nick_name,
                repeatString: msg.password,
                avatar: msg.head_image,
              });
            }
            break;
          case 'USER_ONLINE':
            var msg = data.msg;
            if (window.localStorage.getItem('user') && msg.talkroom_nick_name) {
              app.$store.commit('UPDATE_chatRoomMessages', {
                type: "enter",
                name: msg.talkroom_nick_name,
                avatar: msg.headimg,
                grade: msg.grade,
                id: msg.user_id,
                create_time: new Date().getTime(),
              });
              app.$store.dispatch('GET_onlineUsers');
            }
            break;
          case 'GIT_MONEY_BONUS':
            var item = data.msg;
            if (window.localStorage.getItem('user') && item.username) {
              console.log('hongbao open')
              app.$store.commit('UPDATE_hongbaoOpenList', item);
            }
            break;
          case 'PLAN_INFO':
            var msg = data.msg;
            app.$store.commit('UPDATE_chatRoomMessages', {
              type: "plan",
              nickname: msg.title,
              msg: msg.content,
              time: msg.time,
              avatar: cloudImageBaseUrl + msg.title_logo,
              gametype: msg.lottery_name,
              create_time: new Date().getTime(),
            });
            break;
          case 'ROOM_CONFIG':
            var msg = data.msg.room_config[0];
            app.$store.commit('UPDATE_chatroomSettings', msg);
            break;
          default:
            console.log('unknown type of WS message.')
            break;
        }
      });
    }
  });
}

if (window.localStorage.getItem('apiurl')) {
  apiAddrPrefix = window.localStorage.getItem('apiurl')
  init();
} else {

  if (developer) {
    selectApiServer()
  }
  if (process.env.NODE_ENV === 'stage') {
    selectApiServer()
  }
  if (process.env.NODE_ENV === 'production') {
    bindApiServer()
  }

  function selectApiServer () {
    bindApiServer('api.bw3368.xyz')
  }

  function bindApiServer (midfix) {
    var apiServer = midfix;
    apiAddrPrefix = '//' + apiServer;
    if (process.env.NODE_ENV === 'production') {
      // apiAddrPrefix = '//api.' + process.env.VUE_APP_PROD_ENQUIRE_DOMAIN;
      apiAddrPrefix = process.env.VUE_APP_PROD_ENQUIRE_DOMAIN;
    }
    init()
  }
}

function init () {
  Vue.prototype._linkTo = function (payload) {
    VUE__lastStay = {
      _pathname: window.location.pathname,
      _search: decodeURI(window.location.search.substring(1))
    }
    this.$router.push({
      path: payload.path,
      query: payload.query ? Object.assign(VUE__lastStay, payload.query) : VUE__lastStay,
    })
  }

  Vue.prototype._blured = function (e) {
    if (e.relatedTarget && e.relatedTarget.nodeName.toLowerCase() === 'input') {

    } else if (window.navigator.userAgent.toLowerCase().indexOf('iphone') > -1) {
      if (document.body.scrollTop !== 0) {
        document.body.scrollTop = 0
      }
    }
  }

  Vue.prototype._TEMPLATE = null;

  function getParmFromHash (url, parm) {
    var re = new RegExp("[?&]" + parm + "=([^&]+)(&|$)");
    var match = url.match(re);
    return (match ? match[1] : "");
  }

  var urlPromoCode = getParmFromHash(window.location.href, 'code');
  if (urlPromoCode) {
    window.sessionStorage.setItem('urlPromoCode', urlPromoCode)
  }

  axios({
    url: apiAddrPrefix + '/main/home/websiteConfig',
    method: 'post',
    params: {
      weburl: developer ? 'www.' + currentDevDomain : location.hostname
    },
    timeout: 15000,
  }).then(result => {
    if (result.data.code === 'SUCCESS') {
      var wsurl = result.data.result.ws_url;
      var languageCode = result.data.result.language;
      var languageList = result.data.result.languages;
      var config = result.data.result.config;
      var domains = result.data.result.domains;
      var trueDomainPC = domains.filter(item => {
        return item.type === 4
      });
      trueDomainPC = trueDomainPC.length > 0 ?
        trueDomainPC[0] :
        undefined
      var trueDomainWAP = domains.filter(item => {
        return item.type === 1
      })
      trueDomainWAP = trueDomainWAP.length > 0 ?
        trueDomainWAP[0] :
        undefined
      trueDomainAPI = domains.filter(item => {
        return item.type === 3
      })
      trueDomainAPI = trueDomainAPI.length > 0 ?
        trueDomainAPI[0] :
        undefined;

      // if (trueDomainWAP.header !== window.location.protocol.replace(":", '') && window.location.hostname!=='localhost') {
      //     window.location.href = trueDomainWAP.domain + '/'
      // }
      if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && window.location.hostname !== 'localhost') {
        window.location.href = trueDomainPC.domain + '/';
      }

      window.localStorage.setItem('wsurl', wsurl);

      if (developer) {
        window.localStorage.setItem('apiurl', apiAddrPrefix);
      }
      if (process.env.NODE_ENV === 'stage') {
        window.localStorage.setItem('apiurl', apiAddrPrefix);
      }
      if (process.env.NODE_ENV === 'production') {
        window.localStorage.setItem('apiurl', trueDomainAPI.domain);
      }

      config.filter(item => {
        return item.keyword === "WAP_LOGO"
      }).length > 0 && config.filter(item => {
        return item.keyword === "WAP_LOGO"
      })[0].value ?
        self.localStorage.setItem('logourl', config.filter(item => {
          return item.keyword === "WAP_LOGO"
        })[0].value) :
        self.localStorage.setItem('logourl', null);
      config.filter(item => {
        return item.keyword === "WEBSITE_TITLE"
      }).length > 0 && config.filter(item => {
        return item.keyword === "WEBSITE_TITLE"
      })[0].value ?
        window.localStorage.setItem('maintitle', config.filter(item => {
          return item.keyword === "WEBSITE_TITLE"
        })[0].value) :
        window.localStorage.setItem('maintitle', null);

      window.languageList = languageList;
      window.language = process.env.NODE_ENV !== 'development' ?
        window.sessionStorage.getItem('currentLanguage') || languageCode || 'cn' :
        languageDev || 'cn';

      [
        'QQ_REQUIRED',
        'WX_REQUIRED',
        'NAME_REQUIRED',
        'EMAIL_REQUIRED',
        'PHONE_REQUIRED',
        'RECOMMENDCODE_REQUIRED',

        'AGENT_OPEN_REQUIRED',
        'AGENT_QQ_REQUIRED',
        'AGENT_WX_REQUIRED',
        'AGENT_NAME_REQUIRED',
        'AGENT_EMAIL_REQUIRED',
        'AGENT_PHONE_REQUIRED',

        'WTPASSWORD_REQUIRED',
        'VERITYCODE_REQUIRED',
      ].forEach(_item => {
        config.filter(item => {
          return item.keyword === _item
        }).length > 0 && config.filter(item => {
          return item.keyword === _item
        })[0].value ?
          self.localStorage.setItem(_item, config.filter(item => {
            return item.keyword === _item
          })[0].value) :
          self.localStorage.setItem(_item, null);
      })

      if (process.env.NODE_ENV !== 'development') {
        sessionStorage.setItem('template', templateName);
        templateName = config.filter(item => { return item.keyword === 'H5_TEMPLATE' })[0].value || 'template-1';
      } else {
        if (location.href.indexOf('?template-') !== -1) {
          templateName = location.href.substring(location.href.indexOf('?') + 1, location.href.length);
          sessionStorage.setItem('template', templateName);
        } else {
          const t = sessionStorage.getItem('template');
          if (!t || t.indexOf('template') === -1) {
            sessionStorage.setItem('template', 'template-1');
          }
          templateName = sessionStorage.getItem('template');
        }
      }
      console.log(templateName);
      window._TEMPLATE = templateName

      import('../static/assets/' + templateName + '/iconfont/iconfont.css').then(function () {
        // console.log(templateName+' iconfont loaded')
      })
      // import('../static/assets/' + templateName + '/iconfont2/iconfont.css').then(function () {
      //     // console.log(templateName+' iconfont2 loaded')
      // })

      window.currencySymbol = result.data.result.currency;

      axios({
        url: apiAddrPrefix + '/v1.0/LanguageTypes',
        method: 'get',
        params: {
          'sign123456': window.language
        },
        timeout: 15000,
      }).then(result => {
        if (result.data.code === 'SUCCESS') {
          window.dict = result.data.result;
          start()
        } else {
          window.sessionStorage.removeItem('currentLanguage')
          var r = confirm('获取字典出错, Fetch dict failed, press OK to back in 3sec.')
          if (r) {
            setTimeout(function () {
              window.location.href = window.location.href
            }, 3000)
          }
        }
      }).catch(error => {
        // alert('获取字典失败')
        start()
      })

    } else {
      window.localStorage.removeItem('apiurl')
      // window.location.reload();
      alert(result.data.msg);
    }
  }).catch(error => {
    window.localStorage.removeItem('apiurl')
    // window.location.reload();
    console.log(error);
    alert('我在哪, 我是谁3?')
  })

  window.addEventListener('online', function () {
    connect({
      reset: true
    })
  });

  window.addEventListener('offline', resetStatus);
}

function resetStatus () {

  app.$store.commit('RESET_currentDrawing');
}

function wxLogin () {
  function forUrl (url, arr) {
    for (let y = 0; y < url.length; y++) {
      for (let x = 0; x < arr.length; x++) {
        if (url[y].indexOf(arr[x]) > -1) {
          return url[y].split('=')[1];
        }
      }
    }
  };
  var url_head = ['param', 'value', 'select', 'record', 'revert'];
  var url_tail = ['check', 'display', 'error', 'frequency', 'memory'];
  var openid_head = ['receive', 'source', 'stack', 'temporary', 'buffer'];
  var openid_tail = ['token', 'auth', 'control', 'destination', 'environment'];
  var httpY = ['h'];
  let url = unescape(window.location.href);
  let x = '';
  var count = 0;

  for (let i = 0; i < url_head.length; i++) {
    if (url.indexOf(url_head[i]) > -1) {
      count++;
    }
  }

  if (count === 4) {
    urlType = true;
  }

  if (urlType) {
    url = url.split('?')[1];
    url = url.split('&');
    url_head = forUrl(url, url_head);
    url_tail = forUrl(url, url_tail);
    openid_head = forUrl(url, openid_head);
    openid_tail = forUrl(url, openid_tail);
    httpY = forUrl(url, httpY);
    if (httpY == 'y') {
      httpY = 'https://';
    } else if (httpY == 'n') {
      httpY = 'http://';
    }
    x = httpY + url_head + '.' + url_tail;
  }
}

function start () {
  Vue.prototype.DataTime = DataTime;
  Vue.prototype._TEMPLATE = templateName;
  Vue.prototype.dict = dict;
  Vue.prototype.language = language;
  window.getWord = function (word, placeholder) {
    var pattern = /\{\$[ ]?[1-9]+\}/g;
    var placed = null;
    var result = null;
    if (word instanceof Array) {
      word.forEach(item => {
        result = (result || '') + ' ' + fetch(item)
      })
    } else {
      result = fetch(word)
    }
    if (window.language === 'cn') {
      var _ = null;
      _ = result.split('')
      var trimedWord = ''
      _.forEach(item => {
        if (item !== ' ') {
          trimedWord += item
        }
      })
      result = trimedWord
    }
    return result

    function fetch (word) {
      if (window.dict && window.dict[word]) {
        var word = window.dict[word][window.language];
        if (!word) {
          return getWord('invalid_word')
        } else {
          if (placeholder && (typeof placeholder === 'object')) {
            placed = word.match(pattern);
            placed.forEach((item, index) => {
              word = word.replace(item, placeholder[item.replace(/\s/g, '').slice(1, -1)] || 'placeholder not found')
            })
          }
          return word
        }
      } else {
        return 'word not found'
      }
    }
  };

  (async function () {
    var Mint = await require('./lib/mint-ui/export.js')
    Vue.prototype.Mint = Mint;
    var MintStyle = require('./lib/mint-ui/style.css')
  })();

  window.app = new Vue({
    router,
    store,
    render: h => h(App)
  })

  app.$store.commit('UPDATE_dict', window.dict)

  wxLogin();
  connect();

  Vue.mixin({
    methods: {
      closeBottomTab (data) {
        this.$root.$emit('closeBottomTab', data)
      },
      titleMenuSwitcher (payload) {
        this.$root.$emit('titleMenuSwitcher', payload)
      },
      headerMenuProcessor (payload) {
        this.$root.$emit('headerMenuProcessor', payload)
      },
      hardwareBackTriggered (data) {
        this.$root.$emit('hardwareBackTriggered', data)
      },
      TEMPLATE2_toggleComponentDocker (data) {
        this.$root.$emit('TEMPLATE2_toggleComponentDocker', data)
      },
      TEMPLATE2_toggleAuctioneerUI (data) {
        this.$root.$emit('TEMPLATE2_toggleAuctioneerUI', data)
      },
      TEMPLATE1_toggleComponentDocker (data) {
        this.$root.$emit('TEMPLATE1_toggleComponentDocker', data)
      },
      TEMPLATE1_toggleAuctioneerUI (data) {
        this.$root.$emit('TEMPLATE1_toggleAuctioneerUI', data)
      },
      currencySymbol () {
        return window.currencySymbol
      },
      getWord (word, placeholder) {
        var pattern = /\{\$[ ]?[1-9]+\}/g;
        var placed = null;
        var result = null;
        if (word instanceof Array) {
          word.forEach(item => {
            result = (result || '') + ' ' + fetch(item)
          })
        } else {
          result = fetch(word)
        }
        if (window.language === 'cn') {
          var _ = null;
          _ = result.split('')
          var trimedWord = ''
          _.forEach(item => {
            if (item !== ' ') {
              trimedWord += item
            }
          })
          result = trimedWord
        }
        return result

        function fetch (word) {
          if (app.dict && app.dict[word]) {
            var word = app.dict[word][app.language];
            if (!word) {
              return getWord('invalid_word')
            } else {
              if (placeholder && (typeof placeholder === 'object')) {
                placed = word.match(pattern);
                placed.forEach((item, index) => {
                  word = word.replace(item, new String(placeholder[item.replace(/\s/g, '').slice(1, -1)]) || 'placeholder not found')
                })
              }
              return word
            }
          } else {
            return 'word not found'
          }
        }
      }
    },
    computed: {
      dict () {
        return app.$store.state.dict
      },
      language () {
        return app.$store.state.language
      },
    },
    component: {

    }
  })

  window.app.$mount('#app');

  var selectionLoading = document.getElementById('api-selection-loading');
  if (selectionLoading) {
    selectionLoading.style.display = 'none';
  }

  router.beforeEach((to, from, next) => {
    if (
      (to.path.indexOf('/t') === 0 ||
        to.path.indexOf('/money') === 0 ||
        to.path.indexOf('/p') === 0) &&
      !self.localStorage.getItem('user')
    ) {
      next('/login');
    } else {
      next();
    }
  });

  router.afterEach((to, from) => {
    if (Object.keys(to.query).length === 0) {
      app.$emit('hardwareBackTriggered', to.path)
    }
  })
}
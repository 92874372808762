import { service } from '../CONF.js'


//投注接口
export function touzhu(issue, room_num, type, lotteriesAry, odds_token) {
    const data = {
        issue: issue,
        room_num: room_num,
        type: type,
        lotteries: lotteriesAry,
        odds_token,
    };
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/main/lottery/bet', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//赔率列表
export function beilv(type, room_num) {
    const data = {
        type: type,
        room_num: room_num,
    };
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/main/lottery/odds', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//获取生肖及对应的数字
export function getAnimals() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/Main/Lottery/getAnimals')
        }))
    })
}

//房间消息配置
export function getList(type, room_id) {
    const data = {
        type: type,
        room_id: room_id,
    };
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/main/RoomMessage/getList', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}
<script>
import {
  getDict,
  issue,
  bulletin,
  notice,
  registerGold,
  getGold,
  caizhong,
  userBinding,
  gamesLink,
} from "@/api/home";
import { account_balance } from "@/api/beijing28";
import { getInfo } from "@/api/my";
export default {
  name: "App",
  data() {
    var _roomHeadline = {};
    [
      "bj28",
      "jnd28",
      "twbg28",
      "mgelg28",
      "jndx28",
      "snfk28",
      "cqssc",
      "bjsc",
      "xyft",
    ].forEach((item) => {
      _roomHeadline[item] = {};
      ["room1", "room2", "room3"].forEach((_item) => {
        _roomHeadline[item][_item] = {};
        _roomHeadline[item][_item].content = [];
        _roomHeadline[item][_item].closed = false;
      });
    });

    return {
      isRouterAlive: true,
      overlay: false,
      overlayActive: false,
      popupActive: false,
      popupIndex: 0,
      tishi: "",
      usname: "",
      uspass: "",
      imgmoney: "",
      isGold: true, //红包领取状态变量
      nonex: true, //最后一条
      title: "",
      content: "",
      buillIndex: 0, //初始坐标
      buillText: [], //弹窗公告列表

      bottomTabs: [
        {
          title: getWord("auctioneer_by_ui2"),
          icon: "icon-home",
          iconActive: "icon-home-active",
          url: "/home",
          active: true,
        },
        {
          title: getWord("sale_event2"),
          icon: "icon-wallet",
          iconActive: "icon-wallet-active",
          url: "/discount",
          active: false,
        },
        {
          title: getWord("recharge"),
          icon: "icon-recharge",
          iconActive: "icon-recharge-active",
          url: "/money",
          active: false,
        },
        {
          title: getWord("online_service2"),
          icon: "icon-service",
          iconActive: "icon-service-active",
          url: "/customer",
          active: false,
        },
        {
          title: getWord("personal_centre2"),
          icon: "icon-personal",
          iconActive: "icon-personal-active",
          url: "/p",
          active: false,
        },
      ],

      roomHeadline: _roomHeadline,

      /* template-3 begin */
      drawerToggle: false,
      bottomTabs3: [
        {
          title: getWord("auctioneer_by_ui3"),
          icon: "icon-" + this._TEMPLATE + "_home",
          iconActive: null,
          url: "/home",
          active: true,
        },
        {
          title: getWord("auctioneer_by_ui"),
          icon: "icon-app",
          iconActive: null,
          url: "/GameList3",
          active: false,
        },
        {
          title: getWord("online_service"),
          icon: "icon-kefu",
          iconActive: null,
          url: "/customer",
          active: false,
        },
        {
          title: getWord("billboard"),
          icon: "icon-gift",
          iconActive: null,
          url: "/AllDrawingResult",
          active: false,
        },
        {
          title: getWord("personal_centre"),
          icon: "icon-" + this._TEMPLATE + "_personal",
          iconActive: null,
          url: "/p",
          active: false,
        },
      ],
      userData: {
        name:
          localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).username,
        balance: null,
        avatar:
          localStorage.getItem("user") &&
          JSON.parse(localStorage.getItem("user")).headImage,
      },
      /* template-3 end */
    };
  },
  components: {
    BottomTab: require("@/components/common/tabbar.vue").default,
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    switchTab(data) {
      var bottomTabs = null;
      if (this._TEMPLATE === "template-1" || this._TEMPLATE === "template-2") {
        bottomTabs = this.bottomTabs;
      }
      if (this._TEMPLATE === "template-3") {
        bottomTabs = this.bottomTabs3;
      }
      bottomTabs.forEach((item) => {
        if (item.active === true) item.active = false;
        if (item.url === data) item.active = true;
      });
    },
    overlaySwithcher(data) {
      this.overlay = data;
    },
    openPopup(index) {
      var that = this;
      this.popupIndex = index;
      that.overlay = true;
      setTimeout(function () {
        that.overlayActive = true;
      }, 300);
      setTimeout(function () {
        that.popupActive = true;
      }, 600);
    },
    closePopup(immediate) {
      var that = this;
      that.popupActive = false;
      if (immediate) {
        that.overlayActive = false;
        that.popupIndex = 0;
        that.overlay = false;
      } else {
        setTimeout(function () {
          that.overlayActive = false;
        }, 300);
        setTimeout(function () {
          that.popupIndex = 0;
          that.overlay = false;
        }, 600);
      }
    },
    closeBuillType() {
      this.closePopup();
    },
    setWXuser() {
      if (this.usname == "" || this.uspass == "") {
        this.tishi = getWord("please_fill_complete_info");
        return;
      } else if (this.usname.length < 5) {
        this.tishi = getWord("username_rules2");
        return;
      } else if (this.uspass.length < 8) {
        this.tishi = getWord("password_rules2");
        return;
      } else {
        userBinding(this.usname, this.uspass).then((result) => {
          if (result.data.code == "SUCCESS") {
            let user = JSON.parse(window.localStorage.getItem("user"));
            user.username = this.usname;
            window.localStorage.setItem("user", JSON.stringify(user));
            this.closePopup(3);
            this.notifications().get();
            app.Mint.Toast(result.data.msg);
          } else {
            this.tishi = result.data.msg;
          }
        });
      }
    },
    setNewbiePrize() {
      var that = this;
      registerGold().then((result) => {
        if (result.data.code === "SUCCESS") {
          //红包数据预备成功

          if (
            result.data.result.is_first == 1 &&
            result.data.result.status == 1
          ) {
            that.imgmoney = result.data.result.money;
            that.openPopup(2);
          } else {
            let username =
              JSON.parse(window.localStorage.getItem("user")) &&
              JSON.parse(window.localStorage.getItem("user")).username; //二维码进入后内页返回
            if (!username) {
              that.closePopup(1);
              that.openPopup(3);
            } else {
              that.closePopup(1);
              that.notifications().get();
            }
          }
        } else {
          //登陆信息失效
        }
      });
    },
    gethongbao(type) {
      var that = this;
      let username =
        JSON.parse(window.localStorage.getItem("user")) &&
        JSON.parse(window.localStorage.getItem("user")).username; //二维码进入后内页返回
      if (type && this.isGold) {
        this.isGold = false;

        if (!username) {
          this.closePopup(2);
          this.openPopup(3);
        } else {
          getGold().then((result) => {
            if (result.data.code == "SUCCESS") {
              this.closePopup(2);
            } else {
              app.Mint.Toast(result.data.msg);
              this.closePopup(2);
              return;
            }
          });
        }
      }
    },
    notifications() {
      var that = this;

      function get() {
        bulletin().then((result) => {
          if (result.data.code == "SUCCESS" && result.data.result) {
            if (result.data.result.length != 0) {
              that.buillText = result.data.result;
              if (that.buillText.length == 1) {
                that.nonex = false;
              }
              that.title = that.buillText[that.buillIndex].title;
              that.content = that.buillText[that.buillIndex].content;
              that.openPopup(4);
            } else {
              that.closePopup();
            }
          } else {
            app.Mint.Toast(result.data.msg);
          }
        });
      }

      function next() {
        that.buillIndex = that.buillIndex + 1;
        if (that.buillIndex == that.buillText.length - 1) {
          that.nonex = false;
        }
        that.title = that.buillText[that.buillIndex].title;
        that.content = that.buillText[that.buillIndex].content;
      }

      return {
        get: get,
        next: next,
      };
    },
    toggleDrawer(value) {
      if (value) {
        this.drawerToggle = value;
      } else {
        this.drawerToggle = !this.drawerToggle;
      }
    },
    checkDxds(data) {
      if (data <= 13 && data % 2 != 0) {
        return "(" + getWord("xiaodan") + ")";
      } else if (data <= 13 && data % 2 == 0) {
        return "(" + getWord("xiaoshuang") + ")";
      } else if (data >= 14 && data % 2 != 0) {
        return "(" + getWord("dadan") + ")";
      } else if (data >= 14 && data % 2 == 0) {
        return "(" + getWord("dashuang") + ")";
      } else {
        if (this._TEMPLATE === "template-3") {
          return "(??)";
        } else {
          return "";
        }
      }
    },
    toggleChatRoom() {
      console.log("template-3 placeholder");
    },
    goto(url) {
      this.$router.push({
        path: url,
      });
      this.drawerToggle = !this.drawerToggle;
      return false;
    },
    imgmoneyTransfer() {
      return this.imgmoney;
    },
    getGrade() {
      if (localStorage.getItem("user")) {
        this.$store.dispatch("GET_userInfo", {});
      }
    },
  },
  provide() {
    return {
      switchTab: this.switchTab,
      overlaySwithcher: this.overlaySwithcher,
      popupIndex: this.popupIndex,
      openPopup: this.openPopup,
      closePopup: this.closePopup,
      notifications: this.notifications,
      gethongbao: this.gethongbao,
      setNewbiePrize: this.setNewbiePrize,
      toggleDrawer: this.toggleDrawer,
      checkDxds: this.checkDxds,
      toggleChatRoom: this.toggleChatRoom,
      getGrade: this.getGrade,
      reload: this.reload,
    };
  },
  mounted() {
    var that = this;

    if (window.sessionStorage.getItem("roomHeadline")) {
    } else {
      window.sessionStorage.setItem(
        "roomHeadline",
        JSON.stringify(this.roomHeadline)
      );
    }

    document.addEventListener("gesturestart", function (event) {
      event.preventDefault();
    });

    if (localStorage.getItem("user")) {
      this.getGrade();
    }
  },
  computed: {
    userBalance() {
      return (
        this.$store.state.userBalance &&
        this.$store.state.userBalance["user_money"]
      );
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
};
</script>
<template>
  <div
    id="app"
    :class="{'drawer-open':drawerToggle}"
  >
    <template v-if="_TEMPLATE==='template-1' || _TEMPLATE==='template-2'">
      <transition name="welcome">
        <router-view v-if="isRouterAlive" />
      </transition>
      <BottomTab :bottomTabs="bottomTabs"></BottomTab>
      <div
        class="bullback"
        v-if="overlay===true"
        :class="{'active':overlayActive}"
      ></div>

      <div
        class="indexLoading"
        v-if="popupIndex===1"
      >
        <p class="indexP">{{ getWord('please_wait') }}</p>
      </div>

      <div
        v-if="popupIndex===2"
        class="popup newbie-prize"
        @click="gethongbao(true)"
      >
        <p>{{ getWord('newbie_prize_for_you') }}<span>{{ getWord('give_away') }}<b>{{ imgmoney }}</b>{{ currencySymbol() }}{{ getWord('balance2') }}</span></p>
      </div>

      <div
        v-if="popupIndex===3"
        class="popup"
      >
        <div class="setUsernameback">
          <h4><span>&FilledSmallSquare;</span>{{ getWord('sign_up2') }}<span>&FilledSmallSquare;</span></h4>
          <p class="tishiwenzi">{{ getWord('prompt_of_app_record_info') }}</p>
          <p class="inputtit">{{ getWord('username2') }}</p><input
            @blur="_blured($event)"
            v-model="usname"
            :placeholder="getWord('username_rules3')"
            maxlength="10"
          />
          <p class="inputtit">{{ getWord('password2') }}</p><input
            @blur="_blured($event)"
            v-model="uspass"
            :placeholder="getWord('password_rules3')"
            maxlength="12"
          />
          <p class="inttt">{{tishi}}</p>
          <p
            class="userbutton"
            @click="setWXuser"
          >{{ getWord('sign_up3') }}</p>
        </div>
      </div>

      <div
        class="bulletin popup"
        v-if="popupIndex===4"
        :class="{'active':popupActive}"
      >
        <div
          class="bullclose"
          @click="closeBuillType"
          v-if="nonex"
        >{{ getWord('skip') }}</div>
        <div class="bulltitle"><span>{{ title }}</span></div>
        <div
          class="bullCon"
          v-html="content"
        ></div>
        <div
          class="bullbottom"
          @click="notifications().next()"
          v-if="nonex"
        >{{ getWord('next') }}</div>
        <div
          class="bullbottom"
          v-else
          @click="closeBuillType"
        >{{ getWord('close') }}</div>
      </div>
    </template>

    <template v-if="_TEMPLATE==='template-3'">

      <section id="drawer">
        <h2>{{ getWord('personal_centre') }}</h2>
        <div class="inner">
          <div
            class="info clearfix"
            v-if="userInfo"
          >
            <img :src="userInfo.headimg" />
            <div class="text">
              <span class="name">
                {{userInfo.nickname}}
              </span>
              <span class="level">
                {{ getWord('level') }}：{{userInfo.grade_name}}
              </span>
              <span class="balance">
                {{userBalance}}
              </span>
            </div>
          </div>
          <ul>
            <li>
              <i class="iconfont icon-icon-menu"></i>
              <a>{{ getWord('common') }}</a>
            </li>
            <li>
              <i class="iconfont icon-gift"></i>
              <a
                href="#"
                @click.prevent='goto("/discount")'
              >
                {{ getWord('sale_event') }}
                <span>
                  GO
                  <i class="iconfont icon-back"></i>
                </span>
              </a>
            </li>
            <li>
              <i class="iconfont icon-info"></i>
              <a
                href="#"
                @click.prevent='goto("/my/about/myAbout")'
              >
                {{ getWord('help2') }}
                <span>
                  GO
                  <i class="iconfont icon-back"></i>
                </span>
              </a>
            </li>
            <li>
              <i class="iconfont icon-download"></i>
              <a
                href="#"
                @click.prevent='goto("/action/down")'
              >
                {{ getWord('app_download') }}
                <span>
                  GO
                  <i class="iconfont icon-back"></i>
                </span>
              </a>
            </li>
          </ul>
        </div>
      </section>

      <section id="wrap">
        <div
          class="drawer-open-cover"
          v-if="drawerToggle"
          @click="toggleDrawer(false)"
        ></div>
        <router-view />
        <BottomTab :bottomTabs="bottomTabs3"></BottomTab>
        <div
          class="bullback"
          v-if="overlay===true"
          :class="{'active':overlayActive}"
        ></div>

        <div
          class="indexLoading"
          v-if="popupIndex===1"
        >
          <p class="indexP">{{ getWord('please_wait') }}</p>
        </div>

        <div
          v-if="popupIndex===2"
          class="popup newbie-prize"
          @click="gethongbao(true)"
        >
          <h6>
            <a id="close"></a>
            <span>
              <template v-for="item in parseInt(imgmoney).toString().split('')">
                <img :src="require('@@/assets/template-3/images/home/newbie-prize/numbers/'+item+'.png')" />
              </template>
            </span>
          </h6>
        </div>

        <div
          v-if="popupIndex===3"
          class="popup"
        >
          <div
            class="setUsernameback"
            :class="{'active':overlayActive}"
          >
            <h4><span>&FilledSmallSquare;</span>{{ getWord('sign_up2') }}<span>&FilledSmallSquare;</span></h4>
            <p class="tishiwenzi">{{ getWord('prompt_of_app_record_info') }}</p>
            <p class="inputtit">{{ getWord('username2') }}</p><input
              @blur="_blured($event)"
              v-model="usname"
              :placeholder="getWord('username_rules3')"
              maxlength="10"
            />
            <p class="inputtit">{{ getWord('password2') }}</p><input
              @blur="_blured($event)"
              v-model="uspass"
              :placeholder="getWord('password_rules3')"
              maxlength="12"
            />
            <p class="inttt">{{tishi}}</p>
            <p
              class="userbutton"
              @click="setWXuser"
            >{{ getWord('sign_up3') }}</p>
          </div>
        </div>

        <div
          class="bulletin popup"
          v-if="popupIndex===4"
          :class="{'active':popupActive}"
        >
          <div
            class="bullclose"
            @click="closeBuillType"
            v-if="nonex"
          >{{ getWord('skip') }}</div>
          <div class="bulltitle"><span>{{ title }}</span></div>
          <div
            class="bullCon"
            v-html="content"
          ></div>
          <div
            class="bullbottom"
            @click="notifications().next()"
            v-if="nonex"
          >{{ getWord('next') }}</div>
          <div
            class="bullbottom"
            v-else
            @click="closeBuillType"
          >{{ getWord('close') }}</div>
        </div>
      </section>
    </template>
  </div>
</template>
<style lang='scss' scoped type="text/css">
#drawer {
  position: absolute;
  width: 65%;
  height: 100%;
  z-index: 12;
  background-color: #1c1d1f;
  color: #b0b0b0;
  transition: 0.3s ease all;
  transform: translateX(-100%);

  h2 {
    font-size: 0.36rem;
    font-weight: normal;
    height: 0.88rem;
    line-height: 0.88rem;
    text-align: center;
    background-color: #ec2829;
    color: #eeeeee;
  }

  .inner {
    font-size: 0.36rem;

    .info {
      display: flex;
      padding: 0.25rem;

      img {
        width: 1.28rem;
        height: 1.28rem;
        margin-right: 0.25rem;
      }

      .text {
        display: flex;
        flex-direction: column;
        font-size: 0.3rem;

        .level {
          font-size: 0.36rem;
        }
      }
    }

    ul {
      li {
        display: flex;
        align-items: center;

        > .iconfont {
          margin: 0 0.25rem;
        }

        a {
          flex-grow: 1;
          color: #b0b0b0;
          padding: 0.2rem 0;
          border-bottom: 1px solid #b0b0b0;
          display: flex;
          justify-content: space-between;

          span {
            font-size: 0.3rem;
            margin-right: 0.25rem;
            display: flex;
            align-items: center;

            .icon-back {
              transform: rotateZ(180deg);
              position: relative;
              top: -0.02rem;
            }
          }

          &:first-child {
            border-top: 1px solid #b0b0b0;
          }
        }
      }
    }
  }
}

#wrap {
  > section {
    height: 100%;
    flex-grow: 1;
  }

  .popup {
    .bulltitle {
      background-color: #db5c54;
    }

    &.newbie-prize {
      background-image: none;

      h6 {
        background-image: url("/static/assets/template-3/images/home/newbie-prize/underlay.png");
        width: 5.19rem;
        height: 7.32rem;
        position: relative;
        background-size: cover;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%);

        a {
          background-image: url("/static/assets/template-3/images/home/newbie-prize/close.png");
          width: 0.64rem;
          height: 0.64rem;
          position: absolute;
          background-size: cover;
          right: 0;
        }

        span {
          position: absolute;
          top: 2.2rem;
          left: 1rem;

          img {
            width: 1.4rem;
            height: 1.6rem;
            margin: 0 -0.15rem;
          }
        }
      }
    }
  }
}

.newbie-prize {
  background-image: url("/static/assets/images2/newbie-prize.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  width: 90%;
  height: 90%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;

  p {
    position: absolute;
    top: 33vh;
    width: 100%;
    text-align: center;
    font-size: 0.4rem;
    color: #ffffff;

    span {
      display: block;
      font-size: 0.5rem;

      b {
        margin: 0 0.1rem;
        font-size: 0.85rem;
        font-weight: normal;
        color: #ffd927;
      }
    }
  }
}

.borderbottom {
  border-bottom: 15px solid #efefef;
  width: 100%;
  float: left;
}

.home_dt_classic {
  width: 33%;
  float: left;

  img {
    width: 90%;
    margin: 5% 5% 0 5%;
  }

  p {
    text-align: center;
    width: 100%;
    font-size: 0.3rem;
    padding: 0.1rem 0;
  }
}

.home_kqja_imgs {
  width: 3.5rem;
  margin: 3% 0.05rem;
  float: left;
  border: 1px solid #10a0c7;
  border-radius: 0.25rem;
  background: #e3f9ff;

  .class28 {
    color: white;
    background: #10a0c7;
    text-align: center;
    height: 1.5rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    font-size: 0.3rem;
    padding-top: 0.2rem;
  }
}

.home_title {
  width: 100%;
  font-size: 0.35rem;
  line-height: 0.4rem;

  P {
    width: 0.1rem;
    height: 0.35rem;
    background: #4796fc;
    margin-right: 0.3rem;
    float: left;
  }
}

.three_play {
  background-color: #fff;
  border: none;
  margin: 0 1.5%;
  margin-top: 4%;
  float: left;

  img {
    width: 100%;
    margin-top: 0.2rem;

    &:last-child {
      margin-bottom: 1.5rem;
    }
  }
}

.home_kqjas {
  background-color: #fff;
  border: none;
  margin-left: 1.5%;
  margin-top: 4%;
  float: left;

  img {
    width: 3.5rem;
    height: 2.18rem;
    margin: 0.2rem 0;
  }
}

.mint-swipe {
  height: 3rem;
  width: 7.5rem;

  .mint-swipe-item {
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.home_kqja {
  background-color: #fff;
  border: none;
  margin-left: 0.37rem;
  margin-top: 2%;

  img {
    width: 1.5rem;
    height: 1.27rem;
  }

  .home_kqja_img {
    float: left;
    margin-right: 3%;
  }
}

h4 {
  padding-top: 0.4rem;
  font-size: 0.3rem;
  text-align: center;
  color: #4796fc;

  span {
    display: inline-block;
    transform: rotateZ(45deg);
  }
}

.setUsernameback {
  background: #fff;
  border-radius: 0.25rem;
  position: absolute;
  z-index: 2000;
  width: 6rem;
  height: 5rem;
  top: 100%;
  right: 10%;
  transition: all 0.3s ease;

  &.active {
    top: 25%;
  }

  .tishiwenzi {
    color: #999999;
    padding: 0.125rem 0.25rem 0.25rem;
    font-size: 0.2rem;
    text-align: center;
  }

  .inputtit {
    position: absolute;
    margin: 5% 0 0 0.3rem;
    font-size: 0.3rem;
  }

  .userbutton {
    background: #4796fc;
    text-align: center;
    color: #fff;
    font-size: 0.3rem;
    margin: 0 0.25rem;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0.25rem;
    height: 0.66rem;
    line-height: 0.66rem;
    border-radius: 6px;
  }

  .inttt {
    width: 4.4rem;
    height: 0.7rem;
    color: red;
    line-height: 0.7rem;
    margin-left: 0.8rem;
  }

  & > input {
    text-indent: 1.5rem;
    border-radius: 0.25rem;
    border: 1px solid #bbb;
    width: 5.5rem;
    height: 0.6rem;
    margin-left: 0.25rem;
    margin-top: 0.2rem;
  }
}

.setUsername {
  position: absolute;
  z-index: 2100;
  top: 15%;
  right: 35%;
}

.setUserType {
  width: 6rem;
  position: absolute;
  z-index: 1100;
  top: 10%;
  right: 10%;
}

.setmoney {
  position: absolute;
  color: #fde5c3;
  font-size: 0.5rem;
  z-index: 1400;
  top: 38%;
  right: 30%;
}

.box {
  padding-top: 1%;
  width: 6.5rem;
  margin: 0 auto;
  border: none;
  overflow: hidden;
}

.animate {
  padding-left: 0.2rem;
  font-size: 0.12rem;
  color: #000;
  display: inline-block;
  white-space: nowrap;
  animation: 10s wordsLoop linear infinite normal;
}

.guangb {
  box-shadow: 0px 0px 5px 2px #ccc;
  width: 7.5rem;
  height: 0.77rem;
  padding: 0 0.25rem;

  .guangb_xx {
    margin-top: 2%;
    /*margin-left: 0.3rem;*/
    float: left;
    background-size: 100%;
    width: 0.5rem;
    height: 0.43rem;
    background-image: url("/static/assets/images2/animon.png");
  }

  p {
    margin-top: 2%;
    color: red;
    margin-left: 0.3rem;
    float: left;
    font-size: 0.3rem;
  }
}

.popup {
  z-index: 12;
}

.bulletin {
  width: 0vw;
  margin: 0 auto;
  position: fixed;
  top: 25%;
  left: 0;
  right: 0;
  overflow: hidden;
  border-radius: 0.25rem;
  transition: all 0.3s ease;
  font-size: 0.3rem;
  text-align: center;

  &.active {
    width: 80vw;
  }

  .bullclose {
    position: absolute;
    color: #fff;
    left: 68vw;
    line-height: 1rem;
  }

  .bulltitle {
    height: 1rem;
    background: #4796fc;
    line-height: 1rem;
    color: #fff;

    span {
      width: 60%;
      display: inline-block;
      height: 1rem;
      overflow: hidden;
    }
  }

  .bullCon {
    text-align: left;
    height: 25vh;
    padding: 0.1rem 0.2rem;
    overflow: auto;
    background-color: #ffffff;
    img {
      max-width: 100%;
      display: none;
    }
  }
  .bullbottom {
    border-top: 1px solid #ccc;
    line-height: 1rem;
    height: 1rem;
    background-color: #ffffff;
  }
}

.bullback {
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  z-index: 11;
  opacity: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 0.3s ease;

  &.active {
    opacity: 0.8;
  }
}

.indexLoading {
  height: 100%;
  width: 100%;
  position: fixed;
  background: #000;
  z-index: 1000;
  filter: alpha(opacity=80);
  opacity: 0.8;
  top: 0;
  .indexP {
    color: #fff;
    font-size: 0.3rem;
    text-align: center;
    margin-top: 60%;
  }
}
</style>
<style lang='scss' type="text/css">
/* reset */
html,
body {
  height: 99.99%;
  width: 100%;
  position: fixed;
}

* {
  margin: 0;
  padding: 0;
}

.iconfont {
  font-size: inherit;
}

/* init */
body {
  margin: 0 auto;
  font-size: 0.22rem;
  font-family: system-ui;
}

.container {
  height: inherit;
}

/* public */

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.hidden {
  visibility: hidden;
}

#app {
  padding: 0 0 0 0;
  height: 100%;
  display: flex;
  flex-direction: column;

  > div,
  > section {
    &:first-child {
      flex-grow: 1;
    }
  }

  &.drawer-open {
    #drawer {
      transform: translateX(0%);
    }

    #wrap {
      transform: translateX(65%);
    }
  }

  #wrap {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    transition: 0.3s ease all;

    > .drawer-open-cover {
      background-color: rgba(0, 0, 0, 0.01);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 12;
    }
  }
}

a {
  color: #666;
  text-decoration: none;
}

.navi_back {
  background-color: transparent;
  color: #ffffff;
}

#app .mint-tabbar {
  position: static !important;
}
#app .bullCon img,
#app .main_footer_text3 img {
  max-width: 100%;
}
.circle {
  width: 0.52rem;
  height: 0.52rem;
  display: block;
  border-radius: 100%;

  &.circle-blue {
    box-shadow: 0px 0px 0px 2px #3985e1;
  }

  &.circle-red {
    box-shadow: 0px 0px 0px 2px #e83535;
  }
}

.mint-actionsheet {
  max-height: 100%;
  overflow: auto;
}

.mint-indicator-wrapper {
  z-index: 3;
}
.mint-indicator-mask {
  z-index: 4;
}

.dice {
  display: inline-block;
  min-height: 1em;
  padding-left: 1em;
  background-size: 1em;
  background-repeat: no-repeat;
  box-shadow: 0 0 4px -1px rgba(0, 0, 0, 0.2);
}
[class*="dice-"] {
  margin-top: 4px;
}
#announcement-detail .main img {
  max-width: 100%;
}
.dice-1 {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='400' height='400' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='2' y='2' rx='50' ry='50' width='396' height='396' stroke='lightgray' fill='white' stroke-width='4'/%3E%3Ccircle cx='200' cy='200' r='40' stroke='red' fill='%23EC2829' stroke-width='4'/%3E%3C/svg%3E");
}
.dice-2 {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='400' height='400' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='2' y='2' rx='50' ry='50' width='396' height='396' stroke='lightgray' fill='white' stroke-width='4'/%3E%3Ccircle cx='100' cy='200' r='40' stroke='black' fill='%23333333' stroke-width='4'/%3E%3Ccircle cx='300' cy='200' r='40' stroke='black' fill='%23333333' stroke-width='4'/%3E%3C/svg%3E");
}
.dice-3 {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='400' height='400' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='2' y='2' rx='50' ry='50' width='396' height='396' stroke='lightgray' fill='white' stroke-width='4'/%3E%3Ccircle cx='100' cy='100' r='40' stroke='black' fill='%23333333' stroke-width='4'/%3E%3Ccircle cx='300' cy='300' r='40' stroke='black' fill='%23333333' stroke-width='4'/%3E%3Ccircle cx='200' cy='200' r='40' stroke='black' fill='%23333333' stroke-width='4'/%3E%3C/svg%3E");
}
.dice-4 {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='400' height='400' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='2' y='2' rx='50' ry='50' width='396' height='396' stroke='lightgray' fill='white' stroke-width='4'/%3E%3Ccircle cx='100' cy='100' r='40' stroke='red' fill='%23EC2829' stroke-width='4'/%3E%3Ccircle cx='100' cy='300' r='40' stroke='red' fill='%23EC2829' stroke-width='4'/%3E%3Ccircle cx='300' cy='100' r='40' stroke='red' fill='%23EC2829' stroke-width='4'/%3E%3Ccircle cx='300' cy='300' r='40' stroke='red' fill='%23EC2829' stroke-width='4'/%3E%3C/svg%3E");
}
.dice-5 {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='400' height='400' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='2' y='2' rx='50' ry='50' width='396' height='396' stroke='lightgray' fill='white' stroke-width='4'/%3E%3Ccircle cx='100' cy='100' r='40' stroke='black' fill='%23333333' stroke-width='4'/%3E%3Ccircle cx='100' cy='300' r='40' stroke='black' fill='%23333333' stroke-width='4'/%3E%3Ccircle cx='300' cy='100' r='40' stroke='black' fill='%23333333' stroke-width='4'/%3E%3C!-- %3Ccircle cx='300' cy='200' r='40' stroke='black' fill='%23333333' stroke-width='4'/%3E --%3E%3Ccircle cx='300' cy='300' r='40' stroke='black' fill='%23333333' stroke-width='4'/%3E%3Ccircle cx='200' cy='200' r='40' stroke='black' fill='%23333333' stroke-width='4'/%3E%3C/svg%3E");
}
.dice-6 {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='400' height='400' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='2' y='2' rx='50' ry='50' width='396' height='396' stroke='lightgray' fill='white' stroke-width='4'/%3E%3Ccircle cx='100' cy='100' r='40' stroke='black' fill='%23333333' stroke-width='4'/%3E%3Ccircle cx='100' cy='200' r='40' stroke='black' fill='%23333333' stroke-width='4'/%3E%3Ccircle cx='100' cy='300' r='40' stroke='black' fill='%23333333' stroke-width='4'/%3E%3Ccircle cx='300' cy='100' r='40' stroke='black' fill='%23333333' stroke-width='4'/%3E%3Ccircle cx='300' cy='200' r='40' stroke='black' fill='%23333333' stroke-width='4'/%3E%3Ccircle cx='300' cy='300' r='40' stroke='black' fill='%23333333' stroke-width='4'/%3E%3C/svg%3E");
}

body {
  .mint-msgbox-wrapper {
    .mint-msgbox {
      width: 75%;
      border-radius: 6px;

      .mint-msgbox-header {
        display: none;
      }

      .mint-msgbox-content {
        padding: 10px 20px;

        .mint-msgbox-message {
          color: #5f646e;
          font-size: 0.36rem;
        }
      }

      .mint-msgbox-btns {
        flex-direction: row-reverse;

        .mint-msgbox-cancel {
          font-size: 0.36rem;
          color: #26a2ff;
          border-left: 1px solid #b0b0b0;
        }

        .mint-msgbox-confirm {
          font-size: 0.36rem;
        }
      }
    }
  }
}

/* Welcome Styles */
.welcome-enter {
  transform: translateX(100%);
  opacity: 0;
}
.welcome-enter-active {
  transition: all 0.075s cubic-bezier(1, 0.5, 0.8, 1);
}
.welcome-leave-active,
.welcome-leave-to {
  opacity: 0;
}

/* iphone5 portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
}
</style>
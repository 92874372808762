let DataTime = {
  getDay: (dayNum) => {
    let now = new Date();
    now.setDate(now.getDate() + dayNum);
    let cmonth = now.getMonth() + 1
    let day = now.getDate()
    if (cmonth < 10) cmonth = '0' + cmonth
    if (day < 10) day = '0' + day
    return now.getFullYear() + '-' + cmonth + '-' + day;
  },
  getCurrentWeek: () => {
    let now = new Date();
    let CWeek = now.getDay();
    now.setDate(now.getDate() - CWeek + 1);
    let cmonth = now.getMonth() + 1
    let day = now.getDate()
    if (cmonth < 10) cmonth = '0' + cmonth
    if (day < 10) day = '0' + day
    return now.getFullYear() + '-' + cmonth + '-' + day;
  },
  getLastWeek: (daynum) => {
    let now = new Date();
    let CWeek = now.getDay();
    now.setDate(now.getDate() - CWeek - daynum);
    let cmonth = now.getMonth() + 1
    let day = now.getDate()
    if (cmonth < 10) cmonth = '0' + cmonth
    if (day < 10) day = '0' + day
    return now.getFullYear() + '-' + cmonth + '-' + day;
  },
  getCurrentMonth: (daynum) => {
    let now = new Date();
    now.setDate(now.getDate());
    let cmonth = now.getMonth() + 1;
    if (cmonth < 10) cmonth = '0' + cmonth
    if (daynum < 10) daynum = '0' + daynum
    return now.getFullYear() + '-' + cmonth + '-' + daynum;
  },
  //上月起始日期
  getStartDay: () => {
    let date = new Date();
    let year = date.getFullYear();
    let mouth = date.getMonth();
    if (mouth < 10) mouth = '0' + mouth
    if (mouth == 1) {
      year = year - 1;
      mouth = 12;
    }
    return year + "-" + mouth + "-01";
  },
  //上月结束日期
  getEndDay: () => {
    let date = new Date();
    let year = date.getFullYear();
    let mouth = date.getMonth();
    if (mouth < 10) { mouth = '0' + mouth; }
    if (mouth == 1) {
      year = year - 1;
      mouth = 12;
    }
    let days;
    if (mouth == 2) {
      days = year % 4 == 0 ? 29 : 28;
    } else if (mouth == 1 || mouth == 3 || mouth == 5 || mouth == 7 || mouth == 8 || mouth == 10 || mouth == 12) {
      days = 31;
    } else {
      days = 30;
    }
    return year + "-" + mouth + "-" + days;
  }

};
export default DataTime;

import { getAnimals } from "@/api/touzhu.js";
import axios from 'axios'
import qs from 'qs'

const games=function(){
    var ossPath="//cpwap."+process.env.VUE_APP_PROD_DOMAIN+"/"
    var template=window._TEMPLATE;
    return [
        {
            type:'hot',
            color: '#FF0F0F',
            alias1:getWord('hot'),
            // alias2:'门',
            icon:ossPath+template+'/home/game-list/type-hot.png',
            list:[
                {
                    codename:'lhc',
                    name:'香港六合彩',
                    countdown:null,
                    timer:null,
                },
                {
                    codename:'bjsc',
                    name:'北京赛车',
                    countdown:null,
                    timer:null,
                },
                {
                    codename:'xyft',
                    name:'幸运飞艇',
                    countdown:null,
                    timer:null,
                },
                {
                    codename:'cqssc',
                    name:'重庆时时彩',
                    countdown:null,
                    timer:null,
                },
                {
                    codename:'scfive',
                    name:'五分赛车',
                    countdown:null,
                    timer:null,
                },
                {
                    codename:'jsfastthree',
                    name:'江苏快三',
                    countdown:null,
                    timer:null,
                },
                {
                    codename:'tencent',
                    name:'腾讯分分彩',
                    countdown:null,
                    timer:null,
                },
            ]
        },
        {
            type:'sc',
            color: '#41A441',
            alias1:getWord('saiche'),
            // alias2:'车',
            icon:ossPath+template+'/home/game-list/type-sc.png',
            list:[
                {
                    codename:'bjsc',
                    name:'北京赛车',
                    countdown:null,
                    timer:null,
                },
                {
                    codename:'xyft',
                    name:'幸运飞艇',
                    countdown:null,
                    timer:null,
                },
                {
                    codename:'scone',
                    name:'极速赛车',
                    countdown:null,
                    timer:null,
                },
                {
                    codename:'scthree',
                    name:'三分赛车',
                    countdown:null,
                    timer:null,
                },
                {
                    codename:'scfive',
                    name:'五分赛车',
                    countdown:null,
                    timer:null,
                },            
            ]
        },
        {
            type:'ssc',
            color: '#4393FF',
            alias1:getWord('shishicai'),
            // alias2:'时彩',
            icon:ossPath+template+'/home/game-list/type-ssc.png',
            list:[
                {
                    codename:'cqssc',
                    name:'重庆时时彩',
                    countdown:null,
                    timer:null,
                },
                {
                    codename:'sscone',
                    name:'一分时时彩',
                    countdown:null,
                    timer:null,
                },
                {
                    codename:'sscthree',
                    name:'三分时时彩',
                    countdown:null,
                    timer:null,
                },
                {
                    codename:'sscfive',
                    name:'五分时时彩',
                    countdown:null,
                    timer:null,
                },               
            ]
        },
        {
            type:'pc28',
            color: '#FFC927',
            alias1:'PC',
            alias2:'28',
            icon:ossPath+template+'/home/game-list/type-pc28.png',
            list:[
                {
                    codename:'pc28one',
                    name:'一分PC28',
                    countdown:null,
                    timer:null,
                },
            ]
        },
        {
            type:'lhc',
            color: '#4393FF',
            alias1:getWord('liuhecai'),
            // alias2:'合彩',
            icon:ossPath+template+'/home/game-list/type-lhc.png',
            list:[
                {
                    codename:'lhc',
                    name:'香港六合彩',
                    countdown:null,
                    timer:null,
                },
                {
                    codename:'lhcaomen',
                    name:'澳门六合彩',
                    countdown:null,
                    timer:null,
                },
                {
                    codename:'lhcone',
                    name:'极速六合彩',
                    countdown:null,
                    timer:null,
                },
                {
                    codename:'lhcthree',
                    name:'三分六合彩',
                    countdown:null,
                    timer:null,
                },
                {
                    codename:'lhcfive',
                    name:'五分六合彩',
                    countdown:null,
                    timer:null,
                },
                {
                    codename:'lhcten',
                    name:'十分六合彩',
                    countdown:null,
                    timer:null,
                },
            ]
        },
        {
            type:'fiveselect',
            color: '#F4558D',
            alias1:getWord('five_in_eleven'),
            // alias2:'选5',
            icon:ossPath+template+'/home/game-list/type-fiveselect.png',
            list:[
                {
                    codename:'gdfiveselect',
                    name:'广东十一选五',
                    countdown:null,
                    timer:null,
                },
                {
                    codename:'gdfiveselect',
                    name:'山东十一选五',
                    countdown:null,
                    timer:null,
                },
                {
                    codename:'gdfiveselect',
                    name:'江苏十一选五',
                    countdown:null,
                    timer:null,
                },
            ]
        },
        {
            type:'klsf',
            color: '#4393FF',
            alias1:getWord('kuai10'),
            // alias2:'10',
            icon:ossPath+template+'/home/game-list/type-klsf.png',
            list:[
                {
                    codename:'gxklsf',
                    name:'广西快乐十分',
                    countdown:null,
                    timer:null,
                },
                {
                    codename:'gdklten',
                    name:'广东快乐十分',
                    countdown:null,
                    timer:null,
                },
            ]
        },
        {
            type:'fastthree',
            color: '#F89951',
            alias1:getWord('kuai3'),
            // alias2:'3',
            icon:ossPath+template+'/home/game-list/type-fastthree.png',
            list:[
                {
                    codename:'fastthreeone',
                    name:'极速快三',
                    countdown:null,
                    timer:null,
                },
                {
                    codename:'fastthreethree',
                    name:'三分快三',
                    countdown:null,
                    timer:null,
                },
                {
                    codename:'fastthreefive',
                    name:'幸运快三',
                    countdown:null,
                    timer:null,
                },
                {
                    codename:'fastthreeten',
                    name:'十分快三',
                    countdown:null,
                    timer:null,
                },
                {
                    codename:'shfastthree',
                    name:'上海快三',
                    countdown:null,
                    timer:null,
                },
                {
                    codename:'jsfastthree',
                    name:'江苏快三',
                    countdown:null,
                    timer:null,
                },
                {
                    codename:'hbfastthree',
                    name:'湖北快三',
                    countdown:null,
                    timer:null,
                },
            ]
        },
        {
            type:'xync',
            color: '#41A441',
            alias1:getWord('farm'),
            // alias2:'场',
            icon:ossPath+template+'/home/game-list/type-xync.png',
            list:[
                {
                    codename:'cqxync',
                    name:'重庆幸运农场',
                    countdown:null,
                    timer:null,
                },
            ]
        },
        {
            type:'3d',
            color: '#9159F6',
            alias1:'3',
            alias2:'D',
            icon:ossPath+template+'/home/game-list/type-3d.png',
            list:[
                {
                    codename:'fc3d',
                    name:'福彩3D',
                    countdown:null,
                    timer:null,
                },
            ]
        },
        {
            type:'cp',
            color: '#9159F6',
            alias1:'4',
            alias2:'D',
            icon:ossPath+template+'/home/game-list/type-3d.png',
            list:[
                {
                    codename:'wanneng',
                    name: null,
                    countdown:null,
                    timer:null,
                },
                {
                    codename:'damacai',
                    name: getWord('type_wanneng2'),
                    countdown:null,
                    timer:null,
                },
                {
                    codename:'duoduo',
                    name: getWord('type_wanneng3'),
                    countdown:null,
                    timer:null,
                },
                {
                    codename:'yuedawan',
                    name: getWord('type_wanneng4'),
                    countdown:null,
                    timer:null,
                },
                {
                    codename:'shaba88',
                    name: getWord('type_wanneng5'),
                    countdown:null,
                    timer:null,
                },
                {
                    codename:'saimahui',
                    name: getWord('type_wanneng6'),
                    countdown:null,
                    timer:null,
                },
                {
                    codename:'xinjapowz',
                    name: getWord('type_wanneng7'),
                    countdown:null,
                    timer:null,
                },
            ]
        },
        {
            type:'kl8',
            color: '#9159F6',
            alias1:getWord('kuaile8'),
            // alias2:'乐8',
            icon:ossPath+template+'/home/game-list/type-kl8.png',
            list:[
                {
                    codename:'bjkl8',
                    name:'北京快乐8',
                    countdown:null,
                    timer:null,
                },
            ]
        },
        {
            type:'ffc',
            color: '#9159F6',
            alias1:getWord('fenfecai'),
            // alias2:'分彩',
            icon:ossPath+template+'/home/game-list/type-ffc.png',
            list:[
                {
                    codename:'tencent',
                    name:'腾讯分分彩',
                    countdown:null,
                    timer:null,
                },
            ]
        },                
        {
            type:'xy5',
            color: '#9159F6',
            alias1:getWord('xingyun5'),
            // alias2:'运5',
            icon:ossPath+template+'/home/game-list/type-xy5.png',
            list:[
                {
                    codename:'jndxy',
                    name:'加拿大幸运5',
                    countdown:null,
                    timer:null,
                },
                {
                    codename:'azxy5',
                    name:'澳洲幸运5',
                    countdown:null,
                    timer:null,
                },
                {
                    codename:'azxy10',
                    name:'澳洲幸运10',
                    countdown:null,
                    timer:null,
                },
            ]
        },
    ]
}

const verse=[
    {
        poet:'王维',
        age:'32',
        content:'迢递嵩高下，归来且闭关'
    },
    {
        poet:'王湾',
        age:'47',
        content:'渐平逢车骑，向晚睨城邑'
    },
    {
        poet:'刘基',
        age:'31',
        content:'狼曋信君子，李陵非男儿'
    },
    {
        poet:'杜甫',
        age:'48',
        content:'方春独荷锄，日暮还灌畦'
    },
]

const dict404={
    'cn':'我在哪, 我是谁?',
    'tw':'我在哪, 我是誰?',    
    'jp':'私はどこにいますか？',
    'kr':'나는 어디에, 나는 누구인가?',
    'kh':'តើខ្ញុំនៅឯណា?',//Cambodia, Khmer
    'my':'Di mana saya, siapa saya?',//Malaysia, Malay
    'la':'ຂ້ອຍຢູ່ໃສ, ຂ້ອຍແມ່ນໃຜ?',//Laos, Lao
    'th':'ฉันอยู่ที่ไหนฉันเป็นใคร',//Thailand, Thai
    'id':'Di mana aku, siapa aku?',
    'vi':'Tôi đang ở đâu, tôi là ai?',

    'en':'You got a big error.',
    'sg':'You got a big error.',//Singapore, English
    'ph':'You got a big error.',//Philippines, English
    'es':'Tienes un gran error.',
    'hi':'आपको एक बड़ी त्रुटि मिली है।',
    'pt':'Vous avez une grosse erreur.',
    'ru':'Вы получили большую ошибку.',
    
    'tr':'Büyük bir hatanız var.',    
    'fr':'Où suis-je, qui suis-je?',
    'de':'Du hast einen großen Fehler.',
}

const cloudBaseUrl = 'https://cpfiles.'+process.env.VUE_APP_PROD_DOMAIN+'/common/' //oss
const cloudImageBaseUrl = 'https://cpfiles.'+process.env.VUE_APP_PROD_DOMAIN+'/' //oss

var _service=null;

function buildInterceptors(axiosInstance) {
    return (
        axiosInstance.interceptors.request.use(
            request => {
                if (request.data != undefined) {
                    request.data=Object.assign({},request.data||{},{
                        'sign123456':window.language
                    })
                    if (typeof request.data === 'string') {
                        request.data = JSON.parse(request.data);
                    }
                    if (request.config && request.config.headers && request.config.headers['Content-Type'] && request.config.headers['Content-Type']==='multipart/form-data') {

                    }else {
                        request.data = qs.stringify(request.data);                
                    }
                } else {

                }
                request.params=Object.assign({},request.params||{},{
                    'sign123456':window.language
                })
                if (request.config && request.config.headers && request.config.headers['Content-Type'] && request.config.headers['Content-Type']==='multipart/form-data') {
                    
                }else {
                    request.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
                }
                if (localStorage.getItem('user')) {             
                    request.headers['Authorization'] = 'Bearer ' + JSON.parse(localStorage.getItem('user')).token;
                }
                if (request.params && "authFree" in request.params) {
                    delete request.headers['Authorization']
                }
                if (request.url.indexOf('main/lottery/prev_issue') < 0 && request.url.indexOf('main/lottery/issue') < 0 && request.url.indexOf('main/account/money') < 0) {
                    app.Mint.Indicator.open({
                        text: getWord('loading')+'...',
                        spinnerType: 'fading-circle'
                    });
                }
                return request
            },
            error => {
                Promise.reject(error)
            }
        ),
        axiosInstance.interceptors.response.use(
            response => {
                app.Mint.Indicator.close();
                if (response.data.code==='NOLOGIN') {
                    app.Mint.Toast(response.data.msg);
                }
                return response;
            },
            error => {
                app.Mint.Indicator.close();                
                return Promise.reject(error)
            }
        )
    )
}

function service(weixin){
    return new Promise(function(resolve, reject) {
        window.localStorage.getItem('apiurl')
            ?resolve(_service=axios.create({
                    baseURL: window.localStorage.getItem('apiurl'),
                    timeout: 15000,
                }),
                buildInterceptors(_service)
            ) 
            :alert('我在哪, 我是谁2?')
    })
}

export {
    cloudBaseUrl,
    cloudImageBaseUrl,
    service,
    games,
    verse,
    getClientTime,
    TEMPLATE3_defaultColorRule,
    pc28TypeTitle,
    pc28ColorRule,
    headerMenu,
    TEMPLATE2_headerMenu,
    currentColorRule,
    lastDrawingFormat,
    dtClassicTypeTitle,
    dtClassicTypeRule,
    dtClassicClassRule,
    tencentTypeTitle,
    tencentTypeRule,
    tencentClassRule,
    gdfiveselectColorRule,
    TEMPLATE3_gdfiveselectColorRule,
    gdfiveselectTypeTitle,
    TEMPLATE3_gdfiveselectTypeTitle,
    gdfiveselectTypeRule,
    TEMPLATE3_gdfiveselectTypeRule,
    gdfiveselectClassRule,
    TEMPLATE3_gdfiveselectClassRule,
    gdfiveselectNumberList,
    gdfiveselectZhixuanNumberList,
    lastDrawingRule,
    pc28TypeRule,

    pk10ColorRule,
    pk10TypeRule,
    pk10TypeTitle,
    pk10ClassRule,

    xyncColorRule,
    xyncTypeRule,
        TEMPLATE3_xyncTypeRule,
    xyncTypeTitle,
        TEMPLATE3_xyncTypeTitle,
    xyncClassRule,
        TEMPLATE3_xyncClassRule,

    fc3dClassRule,
        TEMPLATE3_fc3dClassRule,
    fc3dTypeTitle,
        TEMPLATE3_fc3dTypeRule,
    fc3dTypeRule,
        TEMPLATE3_fc3dTypeTitle,
    fc3dErzidingweiNumberList,
    fc3dNumberList,

    gxklsfTypeRule,
    gxklsfTypeTitle,
    gxklsfClassRule,
    TEMPLATE3_gxklsfColorRule,
    gxklsfColorRule,
    createType,
    TEMPLATE1_titleMenu,
    TEMPLATE2_titleMenu,
    lhcClassRule,
    TEMPLATE3_lhcClassRule,
    lhcTypeTitle,
    TEMPLATE3_lhcTypeTitle,
    lhcTypeRule,
    TEMPLATE3_lhcTypeRule,
    lhcBanBoRule,
    lhcWeiLianRule,
    lhcZodiacSeries,
    lhcNumberList,
    lhcColorRule,
    kuai3TypeRule,
    kuai3TypeTitle,
    gdkltenTypeTitle,
        TEMPLATE3_gdkltenTypeTitle,
    gdkltenTypeRule,
    TEMPLATE3_gdkltenTypeRule,
    gdkltenClassRule,
    TEMPLATE3_gdkltenClassRule,
    gdkltenNumberList,
    jndxyTypeTitle,
    jndxyTypeRule,
    jndxyClassRule,
    ttcTypeRule,
        TEMPLATE3_ttcTypeRule,
    ttcNumberList,
    tiantiancaiClassRule,
    	TEMPLATE3_tiantiancaiClassRule,
    tiantiancaiColorRule,
    	TEMPLATE3_tiantiancaiColorRule,
    TEMPLATE3_pc28TypeRule,
    TEMPLATE3_titleMenu,
    TEMPLATE3_headerMenu,
}

const defaultColorRule = [{
    color: '#ffffff',
    bgColor: '#EA5844',
    related: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09"]
}]
const TEMPLATE3_defaultColorRule = [{
    color: '#000000',
    bgColor: '#FFCD3D',
    related: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09"]
}]

const pc28ColorRule = [
    {
        color: '#E83535',
        related: ['RED', '01', '02', '07', '08', '12', '13', '18', '19', '23', '24']
    },
    {
        color: '#3985E1',
        related: ['BULE', '03', '04', '09', '10', '14', '15', '20', '25', '26']
    },
    {
        color: '#48BA2B',
        related: ['GREEN', '00', '05', '06', '11', '16', '17', '21', '22', '27']
    }
]
const pc28TypeTitle = ['混合', '波色/豹子/顺子/对子', '特码']
const pc28TypeRule = [
    ['DA', 'DAN', 'DADAN', 'XIAODAN', 'JIDA'],
    ['XIAO', 'SHUANG', 'DASHUANG', 'XSHUANG', 'JIXIAO'],
    ['RED', 'GREEN', 'BULE', 'SHUNZI', 'BAOZI', 'DUIZI']
]
const TEMPLATE3_pc28TypeRule = [
    [
        {
            id: 'DA',
        },
        {
            id: 'XIAO',
        },
        {
            id: 'DAN',
        },
        {
            id: 'SHUANG',
        },
        {
            id: 'DADAN',
        },
        {
            id: 'XIAODAN', 
        },
        {
            id: 'DASHUANG',
        },
        {
            id: 'XSHUANG',
        },
        {
            id: 'JIDA',
        },        
        {
            id: 'JIXIAO',
        }
    ],
    [
        {
            id: 'RED',
        },
        {
            id: 'GREEN',  
        },
        {
            id: 'BULE',
        },
        {
            id: 'BAOZI',
        },
        {
            id: 'DUIZI',
        },
        {
            id: 'SHUNZI',
        }
    ],
    [
        {
            id: '00',
        },
        {
            id: '01',
        },
        {
            id: '02',
        },
        {
            id: '03',
        },
        {
            id: '04',
        },
        {
            id: '05',
        },
        {
            id: '06',
        },
        {
            id: '07',
        },
        {
            id: '08',
        },
        {
            id: '09',
        },
        {
            id: '10',
        },
        {
            id: '11',
        },
        {
            id: '12',
        },
        {
            id: '13',
        },
        {
            id: '14',
        },
        {
            id: '15',
        },
        {
            id: '16',
        },
        {
            id: '17',
        },
        {
            id: '18',
        },
        {
            id: '19',
        },
        {
            id: '20',
        },
        {
            id: '21',
        },
        {
            id: '22',
        },
        {
            id: '23',
        },
        {
            id: '24',
        },
        {
            id: '25',
        },
        {
            id: '26',
        },
        {
            id: '27',
        },
    ]
]

const TEMPLATE3_gxklsfColorRule = [
    {
        color: '#3985E1',
        related: ['ONERED','TWORED','THREERED','FOURRED','FIVERED', '01', '04', '07', '10', '13', '16', '19','ONEBLUE','TWOBLUE','THREEBLUE','FOURBLUE','FIVEBLUE', '02', '05', '08', '11', '14', '17', '20','ONEGREEN','TWOGREEN','THREEGREEN','FOURGREEN','FIVEGREEN', '03', '06', '09', '12', '15', '18', '21']
    },
]
const gxklsfColorRule = [
    {
        color: '#E83535',
        related: ['ONERED','TWORED','THREERED','FOURRED','FIVERED', '01', '04', '07', '10', '13', '16', '19']
    },
    {
        color: '#3985E1',
        related: ['ONEBLUE','TWOBLUE','THREEBLUE','FOURBLUE','FIVEBLUE', '02', '05', '08', '11', '14', '17', '20']
    },
    {
        color: '#48BA2B',
        related: ['ONEGREEN','TWOGREEN','THREEGREEN','FOURGREEN','FIVEGREEN', '03', '06', '09', '12', '15', '18', '21']
    }
]
const gxklsfClassRule = [
    {
        class: '数字盘',
        id: 'class1'
    },
    {
        class: '两面盘',
        id: 'class2'
    },
    {
        class: '第一球',
        id: 'class3'
    },
    {
        class: '第二球',
        id: 'class4'
    },
    {
        class: '第三球',
        id: 'class5'
    },
    {
        class: '第四球',
        id: 'class6'
    },
    {
        class: '第五球',
        id: 'class7'
    },
]
const gxklsfTypeTitle = [
    {
        title:'第一球',
        class: ['class1','class3']
    },
    {
        title: '第二球',
        class: ['class1','class4']
    },
    {
        title: '第三球',
        class: ['class1','class5']
    },
    {
        title: '第四球',
        class: ['class1','class6']
    },
    {
        title: '第五球',
        class: ['class1','class7']
    },
    {
        title: '第一球',
        class: ['class2','class3']
    },
    {
        title: '第二球',
        class: ['class2','class4']
    },
    {
        title: '第三球',
        class: ['class2','class5']
    },
    {
        title: '第四球',
        class: ['class2','class6']
    },
    {
        title: '第五球',
        class: ['class2','class7']
    },
    {
        title: '总和/龙虎',
        class: ['class2']
    },
    {
        title: '第一球',
        class: ['class3']
    },
    {
        title: '第二球',
        class: ['class4']
    },
    {
        title: '第三球',
        class: ['class5']
    },
    {
        title: '第四球',
        class: ['class6']
    },
    {
        title: '第五球',
        class: ['class7']
    }
]
const gxklsfTypeRule = [
    [
        {
            id: "ONE",
            relatedClass: ['class1','class3']
        },
    ],
    [
        {
            id: "TWO",
            relatedClass: ['class1','class4']
        },
    ],
    [
        {
            id: "THREE",
            relatedClass: ['class1','class5']
        },
    ],
    [
        {
            id: "FOUR",
            relatedClass: ['class1','class6']
        },
    ],
    [
        {
            id: "FIVE",
            relatedClass: ['class1','class7']
        },
    ],
    [
        {
            id: "ONEDA",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONEXIAO",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONEDAN",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONESHUANG",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONEWEIDA",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONEWEIXIAO",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONEWEIDAN",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONEWEISHUANG",
            relatedClass: ['class2','class3']
        },
    ],
    [
        {
            id: "TWODA",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWOXIAO",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWODAN",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWOSHUANG",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWOWEIDA",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWOWEIXIAO",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWOWEIDAN",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWOWEISHUANG",
            relatedClass: ['class2','class4']
        },
    ],
    [
        {
            id: "THREEDA",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREEXIAO",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREEDAN",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREESHUANG",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREEWEIDA",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREEWEIXIAO",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREEWEIDAN",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREEWEISHUANG",
            relatedClass: ['class2','class5']
        },
    ],
    [
        {
            id: "FOURDA",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOURXIAO",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOURDAN",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOURSHUANG",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOURWEIDA",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOURWEIXIAO",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOURWEIDAN",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOURWEISHUANG",
            relatedClass: ['class2','class6']
        },
    ],
    [
        {
            id: "FIVEDA",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVEXIAO",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVEDAN",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVESHUANG",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVEWEIDA",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVEWEIXIAO",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVEWEIDAN",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVEWEISHUANG",
            relatedClass: ['class2','class7']
        },
    ],
    [
        {
            id: "ZONGDA",
            relatedClass: ['class2']
        },
        {
            id: "ZONGXIAO",
            relatedClass: ['class2']
        },
        {
            id: "ZONGDAN",
            relatedClass: ['class2']
        },
        {
            id: "ZONGSHUANG",
            relatedClass: ['class2']
        },
        {
            id: "ZONGWEIDA",
            relatedClass: ['class2']
        },
        {
            id: "ZONGWEIXIAO",
            relatedClass: ['class2']
        },
        {
            id: "LONG",
            relatedClass: ['class2']
        },
        {
            id: "HU",
            relatedClass: ['class2']
        },
    ],
    [
        {
            id: "ONERED",
            relatedClass: ['class3']
        },
        {
            id: "ONEGREEN",
            relatedClass: ['class3']
        },
        {
            id: "ONEBLUE",
            relatedClass: ['class3']
        },
    ],
    [
        {
            id: "TWORED",
            relatedClass: ['class4']
        },
        {
            id: "TWOGREEN",
            relatedClass: ['class4']
        },
        {
            id: "TWOBLUE",
            relatedClass: ['class4']
        },
    ],
    [
        {
            id: "THREERED",
            relatedClass: ['class5']
        },
        {
            id: "THREEGREEN",
            relatedClass: ['class5']
        },
        {
            id: "THREEBLUE",
            relatedClass: ['class5']
        },
    ],
    [
        {
            id: "FOURRED",
            relatedClass: ['class6']
        },
        {
            id: "FOURGREEN",
            relatedClass: ['class6']
        },
        {
            id: "FOURBLUE",
            relatedClass: ['class6']
        },
    ],
    [
        {
            id: "FIVERED",
            relatedClass: ['class7']
        },
        {
            id: "FIVEGREEN",
            relatedClass: ['class7']
        },
        {
            id: "FIVEBLUE",
            relatedClass: ['class7']
        },
    ],
]

const kuai3TypeTitle = ['三军', '大小', '点数', '围骰,全骰', '长牌', '短牌']
const kuai3TypeRule = [
    [
        {
            id: "SANJUN_1",
        },
        {
            id: "SANJUN_2",
        },
        {
            id: "SANJUN_3",
        },
        {
            id: "SANJUN_4",
        },
        {
            id: "SANJUN_5",
        },
        {
            id: "SANJUN_6",
        }
    ],
    [
        {
            id: "DA",
        },
        {
            id: "XIAO",
        },
        {
            id: "DAN",
        },
        {
            id: "SHUANG",
        },
    ],
    [
        {
            id: "HE_4",
        },
        {
            id: "HE_5",
        },
        {
            id: "HE_6",
        },
        {
            id: "HE_7",
        },
        {
            id: "HE_8",
        },
        {
            id: "HE_9",
        },
        {
            id: "HE_10",
        },
        {
            id: "HE_11",
        },
        {
            id: "HE_12",
        },
        {
            id: "HE_13",
        },
        {
            id: "HE_14",
        },
        {
            id: "HE_15",
        },
        {
            id: "HE_16",
        },
        {
            id: "HE_17",
        },
    ],
    [
        {
            id: "WEISHAI_1",
        },
        {
            id: "WEISHAI_2",
        },
        {
            id: "WEISHAI_3",
        },
        {
            id: "WEISHAI_4",
        },
        {
            id: "WEISHAI_5",
        },
        {
            id: "WEISHAI_6",
        },
        {
            id: "QUANSHAI",
        }
    ],
    [
        {
            id: "CHANG_1_2",
        },
        {
            id: "CHANG_1_3",
        },
        {
            id: "CHANG_1_4",
        },
        {
            id: "CHANG_1_5",
        },
        {
            id: "CHANG_1_6",
        },
        {
            id: "CHANG_2_3",
        },
        {
            id: "CHANG_2_4",
        },
        {
            id: "CHANG_2_5",
        },
        {
            id: "CHANG_2_6",
        },
        {
            id: "CHANG_3_4",
        },
        {
            id: "CHANG_3_5",
        },
        {
            id: "CHANG_3_6",
        },
        {
            id: "CHANG_4_5",
        },
        {
            id: "CHANG_4_6",
        },
        {
            id: "CHANG_5_6",
        },
    ],
    [
        {
            id: "DUAN_1_1",
        },
        {
            id: "DUAN_2_2",
        },
        {
            id: "DUAN_3_3",
        },
        {
            id: "DUAN_4_4",
        },
        {
            id: "DUAN_5_5",
        },
        {
            id: "DUAN_6_6",
        },
    ],
]

const gdkltenColorRule = [{
    color: '#ffffff',
    bgColor: '#EA5844',
    related: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09",'10','11','12','13','14','15','16','17','18','19','20']
}]
const TEMPLATE3_gdkltenClassRule = [
    {
        class: '两面盘',
        id: 'class1'
    },
    {
        class: '单号1-8',
        id: 'class2'
    },        
    {
        class: '第一球',
        id: 'class3'
    },
    {
        class: '第二球',
        id: 'class4'
    },
    {
        class: '第三球',
        id: 'class5'
    },
    {
        class: '第四球',
        id: 'class6'
    },
    {
        class: '第五球',
        id: 'class7'
    },
    {
        class: '第六球',
        id: 'class8'
    },
    {
        class: '第七球',
        id: 'class9'
    },
    {
        class: '第八球',
        id: 'class10'
    },
    {
        class: '正码',
        id: 'class11'
    },
    {
        class: '任选二',
        id: 'class12'
    },
    {
        class: '选二连组',
        id: 'class13'
    },
    {
        class: '任选三',
        id: 'class14'
    },
    {
        class: '选三前组',
        id: 'class15'
    },
    {
        class: '任选四',
        id: 'class16'
    },
    {
        class: '任选五',
        id: 'class17'
    },
]
const gdkltenClassRule = [
    {
        class: '单号1-8',
        id: 'class2'
    },
    {
        class: '两面盘',
        id: 'class1'
    },    
    {
        class: '第一球',
        id: 'class3'
    },
    {
        class: '第二球',
        id: 'class4'
    },
    {
        class: '第三球',
        id: 'class5'
    },
    {
        class: '第四球',
        id: 'class6'
    },
    {
        class: '第五球',
        id: 'class7'
    },
    {
        class: '第六球',
        id: 'class8'
    },
    {
        class: '第七球',
        id: 'class9'
    },
    {
        class: '第八球',
        id: 'class10'
    },
    {
        class: '正码',
        id: 'class11'
    },
    {
        class: '连码',
        id: 'class12'
    },
]
const TEMPLATE3_gdkltenTypeTitle = [
    {
        title:'第一球',
        class: ['class2','class3']
    },
    {
        title: '第二球',
        class: ['class2','class4']
    },
    {
        title: '第三球',
        class: ['class2','class5']
    },
    {
        title: '第四球',
        class: ['class2','class6']
    },
    {
        title: '第五球',
        class: ['class2','class7']
    },
    {
        title: '第六球',
        class: ['class2','class8']
    },
    {
        title: '第七球',
        class: ['class2','class9']
    },
    {
        title: '第八球',
        class: ['class2','class10']
    },

    {
        title:'总和',
        class: ['class1','class11']
    },
    {
        title:'第一球',
        class: ['class1','class3']
    },
    {
        title: '第二球',
        class: ['class1','class4']
    },
    {
        title: '第三球',
        class: ['class1','class5']
    },
    {
        title: '第四球',
        class: ['class1','class6']
    },
    {
        title: '第五球',
        class: ['class1','class7']
    },
    {
        title: '第六球',
        class: ['class1','class8']
    },
    {
        title: '第七球',
        class: ['class1','class9']
    },
    {
        title: '第八球',
        class: ['class1','class10']
    },

    {
        title:'第一球',
        class: ['class3']
    },
    {
        title: '第二球',
        class: ['class4']
    },
    {
        title: '第三球',
        class: ['class5']
    },
    {
        title: '第四球',
        class: ['class6']
    },
    {
        title: '第五球',
        class: ['class7']
    },
    {
        title: '第六球',
        class: ['class8']
    },
    {
        title: '第七球',
        class: ['class9']
    },
    {
        title: '第八球',
        class: ['class10']
    },
    {
        title: '正码',
        class: ['class11']
    },

    {
        title: '任选二',
        class: ['class12']
    },
    {
        title: '选二连组',
        class: ['class13']
    },
    {
        title: '任选三',
        class: ['class14']
    },
    {
        title: '选三前组',
        class: ['class15']
    },
    {
        title: '任选四',
        class: ['class16']
    },
    {
        title: '任选五',
        class: ['class17']
    },
]
const gdkltenTypeTitle = [
    {
        title:'第一球',
        class: ['class2','class3']
    },
    {
        title: '第二球',
        class: ['class2','class4']
    },
    {
        title: '第三球',
        class: ['class2','class5']
    },
    {
        title: '第四球',
        class: ['class2','class6']
    },
    {
        title: '第五球',
        class: ['class2','class7']
    },
    {
        title: '第六球',
        class: ['class2','class8']
    },
    {
        title: '第七球',
        class: ['class2','class9']
    },
    {
        title: '第八球',
        class: ['class2','class10']
    },

    {
        title:'总和',
        class: ['class1','class11']
    },
    {
        title:'第一球',
        class: ['class1','class3']
    },
    {
        title: '第二球',
        class: ['class1','class4']
    },
    {
        title: '第三球',
        class: ['class1','class5']
    },
    {
        title: '第四球',
        class: ['class1','class6']
    },
    {
        title: '第五球',
        class: ['class1','class7']
    },
    {
        title: '第六球',
        class: ['class1','class8']
    },
    {
        title: '第七球',
        class: ['class1','class9']
    },
    {
        title: '第八球',
        class: ['class1','class10']
    },

    {
        title:'第一球',
        class: ['class3']
    },
    {
        title: '第二球',
        class: ['class4']
    },
    {
        title: '第三球',
        class: ['class5']
    },
    {
        title: '第四球',
        class: ['class6']
    },
    {
        title: '第五球',
        class: ['class7']
    },
    {
        title: '第六球',
        class: ['class8']
    },
    {
        title: '第七球',
        class: ['class9']
    },
    {
        title: '第八球',
        class: ['class10']
    },
    {
        title: '正码',
        class: ['class11']
    },
    {
        title: '连码',
        class: ['class12']
    },
]
const TEMPLATE3_gdkltenTypeRule = [
    
    [
        {
            id: "ONE_01",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_02",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_03",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_04",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_05",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_06",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_07",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_08",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_09",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_10",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_11",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_12",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_13",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_14",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_15",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_16",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_17",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_18",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_19",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_20",
            relatedClass: ['class2','class3']
        },
    ],
    [
        {
            id: "TWO_01",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_02",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_03",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_04",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_05",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_06",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_07",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_08",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_09",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_10",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_11",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_12",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_13",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_14",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_15",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_16",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_17",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_18",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_19",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_20",
            relatedClass: ['class2','class4']
        },
    ],
    [
        {
            id: "THREE_01",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_02",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_03",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_04",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_05",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_06",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_07",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_08",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_09",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_10",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_11",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_12",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_13",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_14",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_15",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_16",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_17",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_18",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_19",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_20",
            relatedClass: ['class2','class5']
        },
    ],
    [
        {
            id: "FOUR_01",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_02",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_03",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_04",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_05",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_06",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_07",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_08",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_09",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_10",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_11",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_12",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_13",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_14",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_15",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_16",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_17",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_18",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_19",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_20",
            relatedClass: ['class2','class6']
        },
    ],
    [
        {
            id: "FIVE_01",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_02",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_03",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_04",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_05",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_06",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_07",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_08",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_09",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_10",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_11",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_12",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_13",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_14",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_15",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_16",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_17",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_18",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_19",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_20",
            relatedClass: ['class2','class7']
        },
    ],
    [
        {
            id: "SIX_01",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_02",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_03",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_04",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_05",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_06",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_07",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_08",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_09",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_10",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_11",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_12",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_13",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_14",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_15",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_16",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_17",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_18",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_19",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_20",
            relatedClass: ['class2','class8']
        },
    ],
    [
        {
            id: "SEVEN_01",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_02",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_03",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_04",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_05",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_06",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_07",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_08",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_09",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_10",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_11",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_12",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_13",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_14",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_15",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_16",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_17",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_18",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_19",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_20",
            relatedClass: ['class2','class9']
        },
    ],
    [
        {
            id: "EIGHT_01",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_02",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_03",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_04",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_05",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_06",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_07",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_08",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_09",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_10",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_11",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_12",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_13",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_14",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_15",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_16",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_17",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_18",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_19",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_20",
            relatedClass: ['class2','class10']
        },
    ],

    [
        {
            id: "TOTAL_DA",
            relatedClass: ['class1','class11']
        },
        {
            id: "TOTAL_XIAO",
            relatedClass: ['class1','class11']
        },
        {
            id: "TOTAL_DAN",
            relatedClass: ['class1','class11']
        },
        {
            id: "TOTAL_SHUANG",
            relatedClass: ['class1','class11']
        },
        {
            id: "TOTAL_WEI_DA",
            relatedClass: ['class1','class11']
        },
        {
            id: "TOTAL_WEI_XIAO",
            relatedClass: ['class1','class11']
        },
    ],
    [
        {
            id: "ONE_DA",
            relatedClass: ['class1','class3']
        },
        {
            id: "ONE_XIAO",
            relatedClass: ['class1','class3']
        },
        {
            id: "ONE_DAN",
            relatedClass: ['class1','class3']
        },
        {
            id: "ONE_SHUANG",
            relatedClass: ['class1','class3']
        },
        {
            id: "ONE_LONG",
            relatedClass: ['class1','class3']
        },
        {
            id: "ONE_HU",
            relatedClass: ['class1','class3']
        },
        {
            id: "ONE_HE_DAN",
            relatedClass: ['class1','class3']
        },
        {
            id: "ONE_HE_SHUANG",
            relatedClass: ['class1','class3']
        },
        {
            id: "ONE_WEI_DA",
            relatedClass: ['class1','class3']
        },
        {
            id: "ONE_WEI_XIAO",
            relatedClass: ['class1','class3']
        },
    ],
    [
        {
            id: "TWO_DA",
            relatedClass: ['class1','class4']
        },
        {
            id: "TWO_XIAO",
            relatedClass: ['class1','class4']
        },
        {
            id: "TWO_DAN",
            relatedClass: ['class1','class4']
        },
        {
            id: "TWO_SHUANG",
            relatedClass: ['class1','class4']
        },
        {
            id: "TWO_LONG",
            relatedClass: ['class1','class4']
        },
        {
            id: "TWO_HU",
            relatedClass: ['class1','class4']
        },
        {
            id: "TWO_HE_DAN",
            relatedClass: ['class1','class4']
        },
        {
            id: "TWO_HE_SHUANG",
            relatedClass: ['class1','class4']
        },
        {
            id: "TWO_WEI_DA",
            relatedClass: ['class1','class4']
        },
        {
            id: "TWO_WEI_XIAO",
            relatedClass: ['class1','class4']
        },
    ],
    [
        {
            id: "THREE_DA",
            relatedClass: ['class1','class5']
        },
        {
            id: "THREE_XIAO",
            relatedClass: ['class1','class5']
        },
        {
            id: "THREE_DAN",
            relatedClass: ['class1','class5']
        },
        {
            id: "THREE_SHUANG",
            relatedClass: ['class1','class5']
        },
        {
            id: "THREE_LONG",
            relatedClass: ['class1','class5']
        },
        {
            id: "THREE_HU",
            relatedClass: ['class1','class5']
        },
        {
            id: "THREE_HE_DAN",
            relatedClass: ['class1','class5']
        },
        {
            id: "THREE_HE_SHUANG",
            relatedClass: ['class1','class5']
        },
        {
            id: "THREE_WEI_DA",
            relatedClass: ['class1','class5']
        },
        {
            id: "THREE_WEI_XIAO",
            relatedClass: ['class1','class5']
        },
    ],
    [
        {
            id: "FOUR_DA",
            relatedClass: ['class1','class6']
        },
        {
            id: "FOUR_XIAO",
            relatedClass: ['class1','class6']
        },
        {
            id: "FOUR_DAN",
            relatedClass: ['class1','class6']
        },
        {
            id: "FOUR_SHUANG",
            relatedClass: ['class1','class6']
        },
        {
            id: "FOUR_LONG",
            relatedClass: ['class1','class6']
        },
        {
            id: "FOUR_HU",
            relatedClass: ['class1','class6']
        },
        {
            id: "FOUR_HE_DAN",
            relatedClass: ['class1','class6']
        },
        {
            id: "FOUR_HE_SHUANG",
            relatedClass: ['class1','class6']
        },
        {
            id: "FOUR_WEI_DA",
            relatedClass: ['class1','class6']
        },
        {
            id: "FOUR_WEI_XIAO",
            relatedClass: ['class1','class6']
        },
    ],
    [
        {
            id: "FIVE_DA",
            relatedClass: ['class1','class7']
        },
        {
            id: "FIVE_XIAO",
            relatedClass: ['class1','class7']
        },
        {
            id: "FIVE_DAN",
            relatedClass: ['class1','class7']
        },
        {
            id: "FIVE_SHUANG",
            relatedClass: ['class1','class7']
        },
        {
            id: "FIVE_LONG",
            relatedClass: ['class1','class7']
        },
        {
            id: "FIVE_HU",
            relatedClass: ['class1','class7']
        },
        {
            id: "FIVE_HE_DAN",
            relatedClass: ['class1','class7']
        },
        {
            id: "FIVE_HE_SHUANG",
            relatedClass: ['class1','class7']
        },
        {
            id: "FIVE_WEI_DA",
            relatedClass: ['class1','class7']
        },
        {
            id: "FIVE_WEI_XIAO",
            relatedClass: ['class1','class7']
        },
    ],
    [
        {
            id: "SIX_DA",
            relatedClass: ['class1','class8']
        },
        {
            id: "SIX_XIAO",
            relatedClass: ['class1','class8']
        },
        {
            id: "SIX_DAN",
            relatedClass: ['class1','class8']
        },
        {
            id: "SIX_SHUANG",
            relatedClass: ['class1','class8']
        },
        {
            id: "SIX_LONG",
            relatedClass: ['class1','class8']
        },
        {
            id: "SIX_HU",
            relatedClass: ['class1','class8']
        },
        {
            id: "SIX_HE_DAN",
            relatedClass: ['class1','class8']
        },
        {
            id: "SIX_HE_SHUANG",
            relatedClass: ['class1','class8']
        },
        {
            id: "SIX_WEI_DA",
            relatedClass: ['class1','class8']
        },
        {
            id: "SIX_WEI_XIAO",
            relatedClass: ['class1','class8']
        },
    ],
    [
        {
            id: "SEVEN_DA",
            relatedClass: ['class1','class9']
        },
        {
            id: "SEVEN_XIAO",
            relatedClass: ['class1','class9']
        },
        {
            id: "SEVEN_DAN",
            relatedClass: ['class1','class9']
        },
        {
            id: "SEVEN_SHUANG",
            relatedClass: ['class1','class9']
        },
        {
            id: "SEVEN_LONG",
            relatedClass: ['class1','class9']
        },
        {
            id: "SEVEN_HU",
            relatedClass: ['class1','class9']
        },
        {
            id: "SEVEN_HE_DAN",
            relatedClass: ['class1','class9']
        },
        {
            id: "SEVEN_HE_SHUANG",
            relatedClass: ['class1','class9']
        },
        {
            id: "SEVEN_WEI_DA",
            relatedClass: ['class1','class9']
        },
        {
            id: "SEVEN_WEI_XIAO",
            relatedClass: ['class1','class9']
        },
    ],
    [
        {
            id: "EIGHT_DA",
            relatedClass: ['class1','class10']
        },
        {
            id: "EIGHT_XIAO",
            relatedClass: ['class1','class10']
        },
        {
            id: "EIGHT_DAN",
            relatedClass: ['class1','class10']
        },
        {
            id: "EIGHT_SHUANG",
            relatedClass: ['class1','class10']
        },
        {
            id: "EIGHT_LONG",
            relatedClass: ['class1','class10']
        },
        {
            id: "EIGHT_HU",
            relatedClass: ['class1','class10']
        },
        {
            id: "EIGHT_HE_DAN",
            relatedClass: ['class1','class10']
        },
        {
            id: "EIGHT_HE_SHUANG",
            relatedClass: ['class1','class10']
        },
        {
            id: "EIGHT_WEI_DA",
            relatedClass: ['class1','class10']
        },
        {
            id: "EIGHT_WEI_XIAO",
            relatedClass: ['class1','class10']
        },
    ],

    [
        {
            id: "ONE_DONG",
            relatedClass: ['class3']
        },
        {
            id: "ONE_NAN",
            relatedClass: ['class3']
        },
        {
            id: "ONE_XI",
            relatedClass: ['class3']
        },
        {
            id: "ONE_BEI",
            relatedClass: ['class3']
        },
        {
            id: "ONE_ZHONG",
            relatedClass: ['class3']
        },
        {
            id: "ONE_FA",
            relatedClass: ['class3']
        },
        {
            id: "ONE_BAI",
            relatedClass: ['class3']
        },
    ],
    [
        {
            id: "TWO_DONG",
            relatedClass: ['class4']
        },
        {
            id: "TWO_NAN",
            relatedClass: ['class4']
        },
        {
            id: "TWO_XI",
            relatedClass: ['class4']
        },
        {
            id: "TWO_BEI",
            relatedClass: ['class4']
        },
        {
            id: "TWO_ZHONG",
            relatedClass: ['class4']
        },
        {
            id: "TWO_FA",
            relatedClass: ['class4']
        },
        {
            id: "TWO_BAI",
            relatedClass: ['class4']
        },
    ],
    [
        {
            id: "THREE_DONG",
            relatedClass: ['class5']
        },
        {
            id: "THREE_NAN",
            relatedClass: ['class5']
        },
        {
            id: "THREE_XI",
            relatedClass: ['class5']
        },
        {
            id: "THREE_BEI",
            relatedClass: ['class5']
        },
        {
            id: "THREE_ZHONG",
            relatedClass: ['class5']
        },
        {
            id: "THREE_FA",
            relatedClass: ['class5']
        },
        {
            id: "THREE_BAI",
            relatedClass: ['class5']
        },
    ],
    [
        {
            id: "FOUR_DONG",
            relatedClass: ['class6']
        },
        {
            id: "FOUR_NAN",
            relatedClass: ['class6']
        },
        {
            id: "FOUR_XI",
            relatedClass: ['class6']
        },
        {
            id: "FOUR_BEI",
            relatedClass: ['class6']
        },
        {
            id: "FOUR_ZHONG",
            relatedClass: ['class6']
        },
        {
            id: "FOUR_FA",
            relatedClass: ['class6']
        },
        {
            id: "FOUR_BAI",
            relatedClass: ['class6']
        },
    ],
    [
        {
            id: "FIVE_DONG",
            relatedClass: ['class7']
        },
        {
            id: "FIVE_NAN",
            relatedClass: ['class7']
        },
        {
            id: "FIVE_XI",
            relatedClass: ['class7']
        },
        {
            id: "FIVE_BEI",
            relatedClass: ['class7']
        },
        {
            id: "FIVE_ZHONG",
            relatedClass: ['class7']
        },
        {
            id: "FIVE_FA",
            relatedClass: ['class7']
        },
        {
            id: "FIVE_BAI",
            relatedClass: ['class7']
        },
    ],
    [
        {
            id: "SIX_DONG",
            relatedClass: ['class8']
        },
        {
            id: "SIX_NAN",
            relatedClass: ['class8']
        },
        {
            id: "SIX_XI",
            relatedClass: ['class8']
        },
        {
            id: "SIX_BEI",
            relatedClass: ['class8']
        },
        {
            id: "SIX_ZHONG",
            relatedClass: ['class8']
        },
        {
            id: "SIX_FA",
            relatedClass: ['class8']
        },
        {
            id: "SIX_BAI",
            relatedClass: ['class8']
        },
    ],
    [
        {
            id: "SEVEN_DONG",
            relatedClass: ['class9']
        },
        {
            id: "SEVEN_NAN",
            relatedClass: ['class9']
        },
        {
            id: "SEVEN_XI",
            relatedClass: ['class9']
        },
        {
            id: "SEVEN_BEI",
            relatedClass: ['class9']
        },
        {
            id: "SEVEN_ZHONG",
            relatedClass: ['class9']
        },
        {
            id: "SEVEN_FA",
            relatedClass: ['class9']
        },
        {
            id: "SEVEN_BAI",
            relatedClass: ['class9']
        },
    ],
    [
        {
            id: "EIGHT_DONG",
            relatedClass: ['class10']
        },
        {
            id: "EIGHT_NAN",
            relatedClass: ['class10']
        },
        {
            id: "EIGHT_XI",
            relatedClass: ['class10']
        },
        {
            id: "EIGHT_BEI",
            relatedClass: ['class10']
        },
        {
            id: "EIGHT_ZHONG",
            relatedClass: ['class10']
        },
        {
            id: "EIGHT_FA",
            relatedClass: ['class10']
        },
        {
            id: "EIGHT_BAI",
            relatedClass: ['class10']
        },

    ],
    [
        {
            id: "ZHENG_01",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_02",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_03",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_04",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_05",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_06",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_07",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_08",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_09",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_10",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_11",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_12",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_13",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_14",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_15",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_16",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_17",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_18",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_19",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_20",
            relatedClass: ['class11']
        },
    ],

    [
        {
            id: "LIAN_REN_2",
            relatedClass: ['class12']  
        },
    ],
    [
        {
            id: "LIAN_REN_ZU_2",
            relatedClass: ['class13']  
        },
    ],
    [
        {
            id: "LIAN_REN_3",
            relatedClass: ['class14']  
        },
    ],
    [
        {
            id: "LIAN_REN_ZU_3",
            relatedClass: ['class15']  
        },
    ],
    [
        {
            id: "LIAN_REN_4",
            relatedClass: ['class16']  
        },
    ],
    [
        {
            id: "LIAN_REN_5",
            relatedClass: ['class17']  
        },
    ],
]
const gdkltenTypeRule = [
    
    [
        {
            id: "ONE_01",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_02",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_03",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_04",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_05",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_06",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_07",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_08",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_09",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_10",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_11",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_12",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_13",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_14",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_15",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_16",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_17",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_18",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_19",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONE_20",
            relatedClass: ['class2','class3']
        },
    ],
    [
        {
            id: "TWO_01",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_02",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_03",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_04",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_05",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_06",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_07",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_08",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_09",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_10",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_11",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_12",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_13",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_14",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_15",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_16",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_17",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_18",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_19",
            relatedClass: ['class2','class4']
        },
        {
            id: "TWO_20",
            relatedClass: ['class2','class4']
        },
    ],
    [
        {
            id: "THREE_01",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_02",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_03",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_04",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_05",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_06",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_07",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_08",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_09",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_10",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_11",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_12",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_13",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_14",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_15",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_16",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_17",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_18",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_19",
            relatedClass: ['class2','class5']
        },
        {
            id: "THREE_20",
            relatedClass: ['class2','class5']
        },
    ],
    [
        {
            id: "FOUR_01",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_02",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_03",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_04",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_05",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_06",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_07",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_08",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_09",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_10",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_11",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_12",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_13",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_14",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_15",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_16",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_17",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_18",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_19",
            relatedClass: ['class2','class6']
        },
        {
            id: "FOUR_20",
            relatedClass: ['class2','class6']
        },
    ],
    [
        {
            id: "FIVE_01",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_02",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_03",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_04",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_05",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_06",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_07",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_08",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_09",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_10",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_11",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_12",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_13",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_14",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_15",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_16",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_17",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_18",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_19",
            relatedClass: ['class2','class7']
        },
        {
            id: "FIVE_20",
            relatedClass: ['class2','class7']
        },
    ],
    [
        {
            id: "SIX_01",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_02",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_03",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_04",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_05",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_06",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_07",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_08",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_09",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_10",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_11",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_12",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_13",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_14",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_15",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_16",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_17",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_18",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_19",
            relatedClass: ['class2','class8']
        },
        {
            id: "SIX_20",
            relatedClass: ['class2','class8']
        },
    ],
    [
        {
            id: "SEVEN_01",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_02",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_03",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_04",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_05",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_06",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_07",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_08",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_09",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_10",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_11",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_12",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_13",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_14",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_15",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_16",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_17",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_18",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_19",
            relatedClass: ['class2','class9']
        },
        {
            id: "SEVEN_20",
            relatedClass: ['class2','class9']
        },
    ],
    [
        {
            id: "EIGHT_01",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_02",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_03",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_04",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_05",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_06",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_07",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_08",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_09",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_10",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_11",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_12",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_13",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_14",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_15",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_16",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_17",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_18",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_19",
            relatedClass: ['class2','class10']
        },
        {
            id: "EIGHT_20",
            relatedClass: ['class2','class10']
        },
    ],

    [
        {
            id: "TOTAL_DA",
            relatedClass: ['class1','class11']
        },
        {
            id: "TOTAL_XIAO",
            relatedClass: ['class1','class11']
        },
        {
            id: "TOTAL_DAN",
            relatedClass: ['class1','class11']
        },
        {
            id: "TOTAL_SHUANG",
            relatedClass: ['class1','class11']
        },
        {
            id: "TOTAL_WEI_DA",
            relatedClass: ['class1','class11']
        },
        {
            id: "TOTAL_WEI_XIAO",
            relatedClass: ['class1','class11']
        },
    ],
    [
        {
            id: "ONE_DA",
            relatedClass: ['class1','class3']
        },
        {
            id: "ONE_XIAO",
            relatedClass: ['class1','class3']
        },
        {
            id: "ONE_DAN",
            relatedClass: ['class1','class3']
        },
        {
            id: "ONE_SHUANG",
            relatedClass: ['class1','class3']
        },
        {
            id: "ONE_LONG",
            relatedClass: ['class1','class3']
        },
        {
            id: "ONE_HU",
            relatedClass: ['class1','class3']
        },
        {
            id: "ONE_HE_DAN",
            relatedClass: ['class1','class3']
        },
        {
            id: "ONE_HE_SHUANG",
            relatedClass: ['class1','class3']
        },
        {
            id: "ONE_WEI_DA",
            relatedClass: ['class1','class3']
        },
        {
            id: "ONE_WEI_XIAO",
            relatedClass: ['class1','class3']
        },
    ],
    [
        {
            id: "TWO_DA",
            relatedClass: ['class1','class4']
        },
        {
            id: "TWO_XIAO",
            relatedClass: ['class1','class4']
        },
        {
            id: "TWO_DAN",
            relatedClass: ['class1','class4']
        },
        {
            id: "TWO_SHUANG",
            relatedClass: ['class1','class4']
        },
        {
            id: "TWO_LONG",
            relatedClass: ['class1','class4']
        },
        {
            id: "TWO_HU",
            relatedClass: ['class1','class4']
        },
        {
            id: "TWO_HE_DAN",
            relatedClass: ['class1','class4']
        },
        {
            id: "TWO_HE_SHUANG",
            relatedClass: ['class1','class4']
        },
        {
            id: "TWO_WEI_DA",
            relatedClass: ['class1','class4']
        },
        {
            id: "TWO_WEI_XIAO",
            relatedClass: ['class1','class4']
        },
    ],
    [
        {
            id: "THREE_DA",
            relatedClass: ['class1','class5']
        },
        {
            id: "THREE_XIAO",
            relatedClass: ['class1','class5']
        },
        {
            id: "THREE_DAN",
            relatedClass: ['class1','class5']
        },
        {
            id: "THREE_SHUANG",
            relatedClass: ['class1','class5']
        },
        {
            id: "THREE_LONG",
            relatedClass: ['class1','class5']
        },
        {
            id: "THREE_HU",
            relatedClass: ['class1','class5']
        },
        {
            id: "THREE_HE_DAN",
            relatedClass: ['class1','class5']
        },
        {
            id: "THREE_HE_SHUANG",
            relatedClass: ['class1','class5']
        },
        {
            id: "THREE_WEI_DA",
            relatedClass: ['class1','class5']
        },
        {
            id: "THREE_WEI_XIAO",
            relatedClass: ['class1','class5']
        },
    ],
    [
        {
            id: "FOUR_DA",
            relatedClass: ['class1','class6']
        },
        {
            id: "FOUR_XIAO",
            relatedClass: ['class1','class6']
        },
        {
            id: "FOUR_DAN",
            relatedClass: ['class1','class6']
        },
        {
            id: "FOUR_SHUANG",
            relatedClass: ['class1','class6']
        },
        {
            id: "FOUR_LONG",
            relatedClass: ['class1','class6']
        },
        {
            id: "FOUR_HU",
            relatedClass: ['class1','class6']
        },
        {
            id: "FOUR_HE_DAN",
            relatedClass: ['class1','class6']
        },
        {
            id: "FOUR_HE_SHUANG",
            relatedClass: ['class1','class6']
        },
        {
            id: "FOUR_WEI_DA",
            relatedClass: ['class1','class6']
        },
        {
            id: "FOUR_WEI_XIAO",
            relatedClass: ['class1','class6']
        },
    ],
    [
        {
            id: "FIVE_DA",
            relatedClass: ['class1','class7']
        },
        {
            id: "FIVE_XIAO",
            relatedClass: ['class1','class7']
        },
        {
            id: "FIVE_DAN",
            relatedClass: ['class1','class7']
        },
        {
            id: "FIVE_SHUANG",
            relatedClass: ['class1','class7']
        },
        {
            id: "FIVE_LONG",
            relatedClass: ['class1','class7']
        },
        {
            id: "FIVE_HU",
            relatedClass: ['class1','class7']
        },
        {
            id: "FIVE_HE_DAN",
            relatedClass: ['class1','class7']
        },
        {
            id: "FIVE_HE_SHUANG",
            relatedClass: ['class1','class7']
        },
        {
            id: "FIVE_WEI_DA",
            relatedClass: ['class1','class7']
        },
        {
            id: "FIVE_WEI_XIAO",
            relatedClass: ['class1','class7']
        },
    ],
    [
        {
            id: "SIX_DA",
            relatedClass: ['class1','class8']
        },
        {
            id: "SIX_XIAO",
            relatedClass: ['class1','class8']
        },
        {
            id: "SIX_DAN",
            relatedClass: ['class1','class8']
        },
        {
            id: "SIX_SHUANG",
            relatedClass: ['class1','class8']
        },
        {
            id: "SIX_LONG",
            relatedClass: ['class1','class8']
        },
        {
            id: "SIX_HU",
            relatedClass: ['class1','class8']
        },
        {
            id: "SIX_HE_DAN",
            relatedClass: ['class1','class8']
        },
        {
            id: "SIX_HE_SHUANG",
            relatedClass: ['class1','class8']
        },
        {
            id: "SIX_WEI_DA",
            relatedClass: ['class1','class8']
        },
        {
            id: "SIX_WEI_XIAO",
            relatedClass: ['class1','class8']
        },
    ],
    [
        {
            id: "SEVEN_DA",
            relatedClass: ['class1','class9']
        },
        {
            id: "SEVEN_XIAO",
            relatedClass: ['class1','class9']
        },
        {
            id: "SEVEN_DAN",
            relatedClass: ['class1','class9']
        },
        {
            id: "SEVEN_SHUANG",
            relatedClass: ['class1','class9']
        },
        {
            id: "SEVEN_LONG",
            relatedClass: ['class1','class9']
        },
        {
            id: "SEVEN_HU",
            relatedClass: ['class1','class9']
        },
        {
            id: "SEVEN_HE_DAN",
            relatedClass: ['class1','class9']
        },
        {
            id: "SEVEN_HE_SHUANG",
            relatedClass: ['class1','class9']
        },
        {
            id: "SEVEN_WEI_DA",
            relatedClass: ['class1','class9']
        },
        {
            id: "SEVEN_WEI_XIAO",
            relatedClass: ['class1','class9']
        },
    ],
    [
        {
            id: "EIGHT_DA",
            relatedClass: ['class1','class10']
        },
        {
            id: "EIGHT_XIAO",
            relatedClass: ['class1','class10']
        },
        {
            id: "EIGHT_DAN",
            relatedClass: ['class1','class10']
        },
        {
            id: "EIGHT_SHUANG",
            relatedClass: ['class1','class10']
        },
        {
            id: "EIGHT_LONG",
            relatedClass: ['class1','class10']
        },
        {
            id: "EIGHT_HU",
            relatedClass: ['class1','class10']
        },
        {
            id: "EIGHT_HE_DAN",
            relatedClass: ['class1','class10']
        },
        {
            id: "EIGHT_HE_SHUANG",
            relatedClass: ['class1','class10']
        },
        {
            id: "EIGHT_WEI_DA",
            relatedClass: ['class1','class10']
        },
        {
            id: "EIGHT_WEI_XIAO",
            relatedClass: ['class1','class10']
        },
    ],

    [
        {
            id: "ONE_DONG",
            relatedClass: ['class3']
        },
        {
            id: "ONE_NAN",
            relatedClass: ['class3']
        },
        {
            id: "ONE_XI",
            relatedClass: ['class3']
        },
        {
            id: "ONE_BEI",
            relatedClass: ['class3']
        },
        {
            id: "ONE_ZHONG",
            relatedClass: ['class3']
        },
        {
            id: "ONE_FA",
            relatedClass: ['class3']
        },
        {
            id: "ONE_BAI",
            relatedClass: ['class3']
        },
    ],
    [
        {
            id: "TWO_DONG",
            relatedClass: ['class4']
        },
        {
            id: "TWO_NAN",
            relatedClass: ['class4']
        },
        {
            id: "TWO_XI",
            relatedClass: ['class4']
        },
        {
            id: "TWO_BEI",
            relatedClass: ['class4']
        },
        {
            id: "TWO_ZHONG",
            relatedClass: ['class4']
        },
        {
            id: "TWO_FA",
            relatedClass: ['class4']
        },
        {
            id: "TWO_BAI",
            relatedClass: ['class4']
        },
    ],
    [
        {
            id: "THREE_DONG",
            relatedClass: ['class5']
        },
        {
            id: "THREE_NAN",
            relatedClass: ['class5']
        },
        {
            id: "THREE_XI",
            relatedClass: ['class5']
        },
        {
            id: "THREE_BEI",
            relatedClass: ['class5']
        },
        {
            id: "THREE_ZHONG",
            relatedClass: ['class5']
        },
        {
            id: "THREE_FA",
            relatedClass: ['class5']
        },
        {
            id: "THREE_BAI",
            relatedClass: ['class5']
        },
    ],
    [
        {
            id: "FOUR_DONG",
            relatedClass: ['class6']
        },
        {
            id: "FOUR_NAN",
            relatedClass: ['class6']
        },
        {
            id: "FOUR_XI",
            relatedClass: ['class6']
        },
        {
            id: "FOUR_BEI",
            relatedClass: ['class6']
        },
        {
            id: "FOUR_ZHONG",
            relatedClass: ['class6']
        },
        {
            id: "FOUR_FA",
            relatedClass: ['class6']
        },
        {
            id: "FOUR_BAI",
            relatedClass: ['class6']
        },
    ],
    [
        {
            id: "FIVE_DONG",
            relatedClass: ['class7']
        },
        {
            id: "FIVE_NAN",
            relatedClass: ['class7']
        },
        {
            id: "FIVE_XI",
            relatedClass: ['class7']
        },
        {
            id: "FIVE_BEI",
            relatedClass: ['class7']
        },
        {
            id: "FIVE_ZHONG",
            relatedClass: ['class7']
        },
        {
            id: "FIVE_FA",
            relatedClass: ['class7']
        },
        {
            id: "FIVE_BAI",
            relatedClass: ['class7']
        },
    ],
    [
        {
            id: "SIX_DONG",
            relatedClass: ['class8']
        },
        {
            id: "SIX_NAN",
            relatedClass: ['class8']
        },
        {
            id: "SIX_XI",
            relatedClass: ['class8']
        },
        {
            id: "SIX_BEI",
            relatedClass: ['class8']
        },
        {
            id: "SIX_ZHONG",
            relatedClass: ['class8']
        },
        {
            id: "SIX_FA",
            relatedClass: ['class8']
        },
        {
            id: "SIX_BAI",
            relatedClass: ['class8']
        },
    ],
    [
        {
            id: "SEVEN_DONG",
            relatedClass: ['class9']
        },
        {
            id: "SEVEN_NAN",
            relatedClass: ['class9']
        },
        {
            id: "SEVEN_XI",
            relatedClass: ['class9']
        },
        {
            id: "SEVEN_BEI",
            relatedClass: ['class9']
        },
        {
            id: "SEVEN_ZHONG",
            relatedClass: ['class9']
        },
        {
            id: "SEVEN_FA",
            relatedClass: ['class9']
        },
        {
            id: "SEVEN_BAI",
            relatedClass: ['class9']
        },
    ],
    [
        {
            id: "EIGHT_DONG",
            relatedClass: ['class10']
        },
        {
            id: "EIGHT_NAN",
            relatedClass: ['class10']
        },
        {
            id: "EIGHT_XI",
            relatedClass: ['class10']
        },
        {
            id: "EIGHT_BEI",
            relatedClass: ['class10']
        },
        {
            id: "EIGHT_ZHONG",
            relatedClass: ['class10']
        },
        {
            id: "EIGHT_FA",
            relatedClass: ['class10']
        },
        {
            id: "EIGHT_BAI",
            relatedClass: ['class10']
        },

    ],
    [
        {
            id: "ZHENG_01",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_02",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_03",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_04",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_05",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_06",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_07",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_08",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_09",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_10",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_11",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_12",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_13",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_14",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_15",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_16",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_17",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_18",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_19",
            relatedClass: ['class11']
        },
        {
            id: "ZHENG_20",
            relatedClass: ['class11']
        },
    ],

    [
        {
            id: "LIAN_REN_2",
            relatedClass: ['class12']  
        },
        {
            id: "LIAN_REN_ZU_2",
            relatedClass: ['class12']  
        },
        {
            id: "LIAN_REN_3",
            relatedClass: ['class12']  
        },
        {
            id: "LIAN_REN_ZU_3",
            relatedClass: ['class12']  
        },
        {
            id: "LIAN_REN_4",
            relatedClass: ['class12']  
        },
        {
            id: "LIAN_REN_5",
            relatedClass: ['class12']  
        },
    ],
]
const gdkltenNumberList=[]
function createGdkltenNumber(){
    for (var i=1;i<21;i++) {
        gdkltenNumberList.push({
            title:i>9?i.toString():0+i.toString(),
            select:false,
            odds:'',
        })
    }

    gdkltenColorRule.forEach(item => {
        var _color = item.bgColor;
        item.related.forEach(item => {
            var index = gdkltenNumberList.filter(data => {                  
                return data.title === item
            }).forEach(item=>{
                item.color = _color
            })
        })
    })
}
createGdkltenNumber();

const gdfiveselectColorRule = [{
    color: '#ffffff',
    bgColor: '#EA5844',
    related: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09",'10','11']
}]
const TEMPLATE3_gdfiveselectColorRule = [{
    color: '#ffffff',
    bgColor: '#03C015',
    related: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09",'10','11']
}]
const gdfiveselectClassRule = [
    {
        class: '数字盘',
        id: 'class1'
    },
    {
        class: '两面盘',
        id: 'class2'
    },
    {
        class: '连码',
        id: 'class3'
    },
    {
        class: '直选',
        id: 'class4'
    },
]
const TEMPLATE3_gdfiveselectClassRule = [
    {
        class: '数字盘',
        id: 'class1'
    },
    {
        class: '两面盘',
        id: 'class2'
    },
    {
        class: '连码一中一',
        id: 'class3'
    },
    {
        class: '连码二中二',
        id: 'class4'
    },
    {
        class: '连码三中三',
        id: 'class5'
    },
    {
        class: '连码四中四',
        id: 'class6'
    },
    {
        class: '连码五中五',
        id: 'class7'
    },
    {
        class: '连码六中五',
        id: 'class8'
    },
    {
        class: '连码七中五',
        id: 'class9'
    },
    {
        class: '连码八中五',
        id: 'class10'
    },
    {
        class: '前二组选',
        id: 'class11'
    },
    {
        class: '前三组选',
        id: 'class12'
    },
    {
        class: '前二直选',
        id: 'class13'
    },
    {
        class: '前三直选',
        id: 'class14'
    },
]
const gdfiveselectTypeTitle = ['第一球', '第二球', '第三球', '第四球', '第五球','第一球', '第二球', '第三球', '第四球', '第五球', '总和', '总尾/龙虎','连码','直选'];
const TEMPLATE3_gdfiveselectTypeTitle = ['第一球', '第二球', '第三球', '第四球', '第五球','第一球', '第二球', '第三球', '第四球', '第五球', '总和', '总尾/龙虎','连码一中一','连码二中二','连码三中三','连码四中四','连码五中五','连码六中五','连码七中五','连码八中五','前二组选','前三组选','前二直选','前三直选'];
const gdfiveselectTypeRule = [
    [
        {
            id: "ONE",
            relatedClass: ['class1']
        },
    ],
    [
        {
            id: "TWO",
            relatedClass: ['class1']
        },
    ],
    [
        {
            id: "THREE",
            relatedClass: ['class1']
        },
    ],
    [
        {
            id: "FOUR",
            relatedClass: ['class1']
        },
    ],
    [
        {
            id: "FIVE",
            relatedClass: ['class1']
        },
    ],
    [
        {
            id: "ONEDA",
            relatedClass: ['class2']
        },
        {
            id: "ONEXIAO",
            relatedClass: ['class2']
        },
        {
            id: "ONEDAN",
            relatedClass: ['class2']
        },
        {
            id: "ONESHUANG",
            relatedClass: ['class2']
        },
    ],
    [
        {
            id: "TWODA",
            relatedClass: ['class2']
        },
        {
            id: "TWOXIAO",
            relatedClass: ['class2']
        },
        {
            id: "TWODAN",
            relatedClass: ['class2']
        },
        {
            id: "TWOSHUANG",
            relatedClass: ['class2']
        },
    ],
    [
        {
            id: "THREEDA",
            relatedClass: ['class2']
        },
        {
            id: "THREEXIAO",
            relatedClass: ['class2']
        },
        {
            id: "THREEDAN",
            relatedClass: ['class2']
        },
        {
            id: "THREESHUANG",
            relatedClass: ['class2']
        },
    ],
    [
        {
            id: "FOURDA",
            relatedClass: ['class2']
        },
        {
            id: "FOURXIAO",
            relatedClass: ['class2']
        },
        {
            id: "FOURDAN",
            relatedClass: ['class2']
        },
        {
            id: "FOURSHUANG",
            relatedClass: ['class2']
        },
    ],
    [
        {
            id: "FIVEDA",
            relatedClass: ['class2']
        },
        {
            id: "FIVEXIAO",
            relatedClass: ['class2']
        },
        {
            id: "FIVEDAN",
            relatedClass: ['class2']
        },
        {
            id: "FIVESHUANG",
            relatedClass: ['class2']
        },
    ],
    [
        {
            id: "ALLDA",
            relatedClass: ['class2']
        },
        {
            id: "ALLXIAO",
            relatedClass: ['class2']
        },
        {
            id: "ALLDAN",
            relatedClass: ['class2']
        },
        {
            id: "ALLSHUANG",
            relatedClass: ['class2']
        },
    ],
    [
        {
            id: "ALLWEIDA",
            relatedClass: ['class2']
        },
        {
            id: "ALLWEIXIAO",
            relatedClass: ['class2']
        },
        {
            id: "LONG",
            relatedClass: ['class2']
        },
        {
            id: "HU",
            relatedClass: ['class2']
        },
        {
            id: "HE",
            relatedClass: ['class2']
        },
    ],
    [
        {
            id: "LIANMAONE",
            relatedClass: ['class3']
        },
        {
            id: "LIANMATWO",
            relatedClass: ['class3']
        },
        {
            id: "LIANMATHREE",
            relatedClass: ['class3']
        },
        {
            id: "LIANMAFOUR",
            relatedClass: ['class3']
        },
        {
            id: "LIANMAFIVE",
            relatedClass: ['class3']
        },
        {
            id: "LIANMASIX",
            relatedClass: ['class3']
        },
        {
            id: "LIANMASEVEN",
            relatedClass: ['class3']
        },
        {
            id: "LIANMAEIGHT",
            relatedClass: ['class3']
        },
        {
            id: "ZUER",
            relatedClass: ['class3']
        },
        {
            id: "ZUSAN",
            relatedClass: ['class3']
        },
    ],
    [
        {
            id: "QIANER",
            relatedClass: ['class4']
        },
        {
            id: "QIANSAN",
            relatedClass: ['class4']
        },
    ],
]
const TEMPLATE3_gdfiveselectTypeRule = [
    [
        {
            id: "ONE",
            relatedClass: ['class1']
        },
    ],
    [
        {
            id: "TWO",
            relatedClass: ['class1']
        },
    ],
    [
        {
            id: "THREE",
            relatedClass: ['class1']
        },
    ],
    [
        {
            id: "FOUR",
            relatedClass: ['class1']
        },
    ],
    [
        {
            id: "FIVE",
            relatedClass: ['class1']
        },
    ],
    [
        {
            id: "ONEDA",
            relatedClass: ['class2']
        },
        {
            id: "ONEXIAO",
            relatedClass: ['class2']
        },
        {
            id: "ONEDAN",
            relatedClass: ['class2']
        },
        {
            id: "ONESHUANG",
            relatedClass: ['class2']
        },
    ],
    [
        {
            id: "TWODA",
            relatedClass: ['class2']
        },
        {
            id: "TWOXIAO",
            relatedClass: ['class2']
        },
        {
            id: "TWODAN",
            relatedClass: ['class2']
        },
        {
            id: "TWOSHUANG",
            relatedClass: ['class2']
        },
    ],
    [
        {
            id: "THREEDA",
            relatedClass: ['class2']
        },
        {
            id: "THREEXIAO",
            relatedClass: ['class2']
        },
        {
            id: "THREEDAN",
            relatedClass: ['class2']
        },
        {
            id: "THREESHUANG",
            relatedClass: ['class2']
        },
    ],
    [
        {
            id: "FOURDA",
            relatedClass: ['class2']
        },
        {
            id: "FOURXIAO",
            relatedClass: ['class2']
        },
        {
            id: "FOURDAN",
            relatedClass: ['class2']
        },
        {
            id: "FOURSHUANG",
            relatedClass: ['class2']
        },
    ],
    [
        {
            id: "FIVEDA",
            relatedClass: ['class2']
        },
        {
            id: "FIVEXIAO",
            relatedClass: ['class2']
        },
        {
            id: "FIVEDAN",
            relatedClass: ['class2']
        },
        {
            id: "FIVESHUANG",
            relatedClass: ['class2']
        },
    ],
    [
        {
            id: "ALLDA",
            relatedClass: ['class2']
        },
        {
            id: "ALLXIAO",
            relatedClass: ['class2']
        },
        {
            id: "ALLDAN",
            relatedClass: ['class2']
        },
        {
            id: "ALLSHUANG",
            relatedClass: ['class2']
        },
    ],
    [
        {
            id: "ALLWEIDA",
            relatedClass: ['class2']
        },
        {
            id: "ALLWEIXIAO",
            relatedClass: ['class2']
        },
        {
            id: "LONG",
            relatedClass: ['class2']
        },
        {
            id: "HU",
            relatedClass: ['class2']
        },
        {
            id: "HE",
            relatedClass: ['class2']
        },
    ],
    [
        {
            id: "LIANMAONE",
            relatedClass: ['class3']
        },
    ],
    [
        {
            id: "LIANMATWO",
            relatedClass: ['class4']
        },
    ],
    [
        {
            id: "LIANMATHREE",
            relatedClass: ['class5']
        },
    ],
    [
        {
            id: "LIANMAFOUR",
            relatedClass: ['class6']
        },
    ],
    [
        {
            id: "LIANMAFIVE",
            relatedClass: ['class7']
        },
    ],
    [
        {
            id: "LIANMASIX",
            relatedClass: ['class8']
        },
    ],
    [
        {
            id: "LIANMASEVEN",
            relatedClass: ['class9']
        },
    ],
    [
        {
            id: "LIANMAEIGHT",
            relatedClass: ['class10']
        },
    ],
    [
        {
            id: "ZUER",
            relatedClass: ['class11']
        },
    ],
    [
        {
            id: "ZUSAN",
            relatedClass: ['class12']
        },
    ],
    [
        {
            id: "QIANER",
            relatedClass: ['class13']
        },
    ],
    [
        {
            id: "QIANSAN",
            relatedClass: ['class14']
        },
    ],
]
const gdfiveselectNumberList=[]
function createGdfiveselectNumber(){
    gdfiveselectNumberList.length=0;
    for (var i=1;i<12;i++) {
        gdfiveselectNumberList.push({
            title:i<10?'0'+i:i.toString(),
            select:false,
            odds:''
        })
    }

    var colorRule=window._TEMPLATE==='template-3'
                        ?TEMPLATE3_gdfiveselectColorRule
                        :gdfiveselectColorRule

    colorRule.forEach(item => {
        var _color = item.bgColor;
        item.related.forEach(item => {
            var index = gdfiveselectNumberList.filter(data => {                  
                return data.title === item
            }).forEach(item=>{
                item.color = _color
            })
        })
    })
}

const gdfiveselectZhixuanNumberList=[]
function createGdfiveselectZhixuanNumber(){
    for (var j=1;j<6;j++) {
        for (var i=1;i<12;i++) {
            gdfiveselectZhixuanNumberList.push({
                title:i<10?'0'+i:i.toString(),
                select:false,
                odds:'',
                index:j,
                disabled:false,
            })
        }
    }

    var colorRule=window._TEMPLATE==='template-3'
                        ?TEMPLATE3_gdfiveselectColorRule
                        :gdfiveselectColorRule

    colorRule.forEach(item => {
        var _color = item.bgColor;
        item.related.forEach(item => {
            var index = gdfiveselectZhixuanNumberList.filter(data => {                  
                return data.title === item
            }).forEach(item=>{
                item.color = _color;
            })
        })
    })
}

const TEMPLATE3_fc3dClassRule = [
    {
        class: '主势盘',
        id: 'class1'
    },
    {
        class: '一字组合',
        id: 'class2'
    },
    {
        class: '二字组合',
        id: 'class3'
    },
    {
        class: '一字定位',
        id: 'class4'
    },
    {
        class: '二字定位佰拾位',
        id: 'class5'
    },
    {
        class: '二字定位佰个位',
        id: 'class6'
    },
    {
        class: '二字定位拾个位',
        id: 'class7'
    },
    {
        class: '二字和数',
        id: 'class8'
    },
    {
        class: '组选三',
        id: 'class9'
    },
    {
        class: '组选六',
        id: 'class10'
    },
    {
        class: '跨度',
        id: 'class11'
    },
]
const fc3dClassRule = [
    {
        class: '主势盘',
        id: 'class1'
    },
    {
        class: '一字组合',
        id: 'class2'
    },
    {
        class: '二字组合',
        id: 'class3'
    },
    {
        class: '一字定位',
        id: 'class4'
    },
    {
        class: '二字定位',
        id: 'class5'
    },
    {
        class: '二字和数',
        id: 'class6'
    },
    {
        class: '组选三',
        id: 'class7'
    },
    {
        class: '组选六',
        id: 'class8'
    },
    {
        class: '跨度',
        id: 'class9'
    },
]
const TEMPLATE3_fc3dTypeTitle = ['佰位', '拾位', '个位', '佰拾', '佰个', '拾个', '佰拾和尾数', '佰个和尾数', '拾个和尾数', '佰拾个和数', '佰拾个和尾数', '一字组合', '二字组合', '佰位', '拾位', '个位', '二字定位佰拾位', '二字定位佰个位', '二字定位拾个位', '佰拾和数','佰拾和尾数','佰个和数','佰个和尾数','拾个和数','拾个和尾数','组选三','组选六','跨度']
const fc3dTypeTitle = function(){
    return [
    getWord(['bai2', 'place']), getWord(['shi2', 'place']), getWord(['ge', 'place']), getWord(['bai2', 'shi2']), getWord(['bai2', 'ge']), getWord(['shi2', 'ge']), getWord(['bai2','shi2','draw','weishu']), getWord(['bai2','ge','draw','weishu']), getWord(['shi2','ge','draw','weishu']), getWord(['bai2', 'shi2', 'ge', 'heshu']), getWord(['bai2', 'shi2', 'ge', 'draw', 'weishu']), 
    getWord(['yi', 'zi', 'combine']), 
    getWord(['er', 'zi', 'combine']), 
    getWord(['bai2', 'place']), getWord(['shi2', 'place']), getWord(['ge', 'place']),
    getWord(['er', 'zi', 'positioning', 'bai2', 'shi2', 'place']), getWord(['er', 'zi', 'positioning', 'bai2', 'ge', 'place']), getWord(['er', 'zi', 'positioning', 'shi2', 'ge', 'place']), 
    getWord(['bai2','shi2','heshu']), getWord(['bai2, shi2, draw, weishu']), getWord(['bai2, ge, heshu']), getWord(['bai2', 'ge', 'draw', 'weishu']), getWord(['shi2', 'ge', 'heshu']), getWord(['shi2', 'ge', 'draw', 'weishu']),
    getWord(['zuxuan', 'san']), getWord(['zuxuan', 'liu']), getWord('kuadu')
    ]
}
const TEMPLATE3_fc3dTypeRule = [
    [
        {
            id: "BAIDA",
            relatedClass: ['class1','class2']
        },
        {
            id: "BAIXIAO",
            relatedClass: ['class1','class2']
        },
        {
            id: "BAIDAN",
            relatedClass: ['class1','class2']
        },
        {
            id: "BAISHUANG",
            relatedClass: ['class1','class2']
        },
        {
            id: "BAIZHI",
            relatedClass: ['class1','class2']
        },
        {
            id: "BAIHE",
            relatedClass: ['class1','class2']
        },
    ],
    [
        {
            id: "SHIDA",
            relatedClass: ['class1','class2']
        },
        {
            id: "SHIXIAO",
            relatedClass: ['class1','class2']
        },
        {
            id: "SHIDAN",
            relatedClass: ['class1','class2']
        },
        {
            id: "SHISHUANG",
            relatedClass: ['class1','class2']
        },
        {
            id: "SHIZHI",
            relatedClass: ['class1','class2']
        },
        {
            id: "SHIHE",
            relatedClass: ['class1','class2']
        },
    ],
    [
        {
            id: "GEDA",
            relatedClass: ['class1','class2']
        },
        {
            id: "GEXIAO",
            relatedClass: ['class1','class2']
        },
        {
            id: "GEDAN",
            relatedClass: ['class1','class2']
        },
        {
            id: "GESHUANG",
            relatedClass: ['class1','class2']
        },
        {
            id: "GEZHI",
            relatedClass: ['class1','class2']
        },
        {
            id: "GEHE",
            relatedClass: ['class1','class2']
        },
    ],
    [
        {
            id: "BAISHIDAN",
            relatedClass: ['class1']
        },
        {
            id: "BAISHISHUANG",
            relatedClass: ['class1']
        },
    ],
    [
        {
            id: "BAIGEDAN",
            relatedClass: ['class1']
        },
        {
            id: "BAIGESHUANG",
            relatedClass: ['class1']
        },
    ],
    [
        {
            id: "SHIGEDAN",
            relatedClass: ['class1']
        },
        {
            id: "SHIGESHUANG",
            relatedClass: ['class1']
        },
    ],
    [
        {
            id: "BAISHIHEWEIDA",
            relatedClass: ['class1']
        },
        {
            id: "BAISHIHEWEIXIAO",
            relatedClass: ['class1']
        },
        {
            id: "BAISHIHEWEIZHI",
            relatedClass: ['class1']
        },
        {
            id: "BAISHIHEWEIHE",
            relatedClass: ['class1']
        },
    ],
    [
        {
            id: "BAIGEHEWEIDA",
            relatedClass: ['class1']
        },
        {
            id: "BAIGEHEWEIXIAO",
            relatedClass: ['class1']
        },
        {
            id: "BAIGEHEWEIZHI",
            relatedClass: ['class1']
        },
        {
            id: "BAIGEHEWEIHE",
            relatedClass: ['class1']
        },
    ],
    [
        {
            id: "SHIGEHEWEIDA",
            relatedClass: ['class1']
        },
        {
            id: "SHIGEHEWEIXIAO",
            relatedClass: ['class1']
        },
        {
            id: "SHIGEHEWEIZHI",
            relatedClass: ['class1']
        },
        {
            id: "SHIGEHEWEIHE",
            relatedClass: ['class1']
        },
    ],
    [
        {
            id: "BAISHIGEHEDA",
            relatedClass: ['class1']
        },
        {
            id: "BAISHIGEHEXIAO",
            relatedClass: ['class1']
        },
        {
            id: "BAISHIGEHEDAN",
            relatedClass: ['class1']
        },
        {
            id: "BAISHIGEHESHUANG",
            relatedClass: ['class1']
        },
    ],
    [
        {
            id: "BAISHIGEHEWEIDA",
            relatedClass: ['class1']
        },
        {
            id: "BAISHIGEHEWEIXIAO",
            relatedClass: ['class1']
        },
        {
            id: "BAISHIGEHEWEIZHI",
            relatedClass: ['class1']
        },
        {
            id: "BAISHIGEHEWEIHE",
            relatedClass: ['class1']
        },
    ],
    [
        {
            id: "ONEMERGER",
            relatedClass: ['class2']
        },
    ],
    [
        {
            id: "TWOMERGER_00",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_01-09",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_11",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_12-19",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_22",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_23-29",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_33",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_34-39",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_44",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_45-49",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_55",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_56-59",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_66",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_67-69",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_77",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_78-79",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_88",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_89",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_99",
            relatedClass: ['class3']
        },
    ],
    [
        {
            id: "YIZIBAIWEI",
            relatedClass: ['class4']
        },
    ],
    [
        {
            id: "YIZISHIWEI",
            relatedClass: ['class4']
        },
    ],
    [
        {
            id: "YIZIGEWEI",
            relatedClass: ['class4']
        },
    ],
    [
        {
            id: "ERZIBAISHIWEI",
            relatedClass: ['class5']
        },
    ],
    [
        {
            id: "ERZIBAIGEWEI",
            relatedClass: ['class6']
        },
    ],
    [
        {
            id: "ERZISHIGEWEI",
            relatedClass: ['class7']
        },
    ],
    [
        {
            id: "ERZIBAISHIHE_0",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAISHIHE_1",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAISHIHE_2",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAISHIHE_3",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAISHIHE_4",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAISHIHE_5",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAISHIHE_6",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAISHIHE_7",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAISHIHE_8",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAISHIHE_9",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAISHIHE_10",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAISHIHE_11",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAISHIHE_12",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAISHIHE_13",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAISHIHE_14",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAISHIHE_15",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAISHIHE_16",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAISHIHE_17",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAISHIHE_18",
            relatedClass: ['class8']
        },
    ],
    [
        {
            id: "ERZIBAISHIHEWEI_0",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAISHIHEWEI_1",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAISHIHEWEI_2",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAISHIHEWEI_3",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAISHIHEWEI_4",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAISHIHEWEI_5",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAISHIHEWEI_6",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAISHIHEWEI_7",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAISHIHEWEI_8",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAISHIHEWEI_9",
            relatedClass: ['class8']
        },
    ],
    [
        {
            id: "ERZIBAIGEHE_0",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAIGEHE_1",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAIGEHE_2",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAIGEHE_3",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAIGEHE_4",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAIGEHE_5",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAIGEHE_6",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAIGEHE_7",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAIGEHE_8",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAIGEHE_9",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAIGEHE_10",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAIGEHE_11",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAIGEHE_12",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAIGEHE_13",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAIGEHE_14",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAIGEHE_15",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAIGEHE_16",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAIGEHE_17",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAIGEHE_18",
            relatedClass: ['class8']
        },
    ],
    [
        {
            id: "ERZIBAIGEHEWEI_0",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAIGEHEWEI_1",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAIGEHEWEI_2",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAIGEHEWEI_3",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAIGEHEWEI_4",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAIGEHEWEI_5",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAIGEHEWEI_6",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAIGEHEWEI_7",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAIGEHEWEI_8",
            relatedClass: ['class8']
        },
        {
            id: "ERZIBAIGEHEWEI_9",
            relatedClass: ['class8']
        },
    ],
    [
        {
            id: "ERZISHIGEHE_0",
            relatedClass: ['class8']
        },
        {
            id: "ERZISHIGEHE_1",
            relatedClass: ['class8']
        },
        {
            id: "ERZISHIGEHE_2",
            relatedClass: ['class8']
        },
        {
            id: "ERZISHIGEHE_3",
            relatedClass: ['class8']
        },
        {
            id: "ERZISHIGEHE_4",
            relatedClass: ['class8']
        },
        {
            id: "ERZISHIGEHE_5",
            relatedClass: ['class8']
        },
        {
            id: "ERZISHIGEHE_6",
            relatedClass: ['class8']
        },
        {
            id: "ERZISHIGEHE_7",
            relatedClass: ['class8']
        },
        {
            id: "ERZISHIGEHE_8",
            relatedClass: ['class8']
        },
        {
            id: "ERZISHIGEHE_9",
            relatedClass: ['class8']
        },
        {
            id: "ERZISHIGEHE_10",
            relatedClass: ['class8']
        },
        {
            id: "ERZISHIGEHE_11",
            relatedClass: ['class8']
        },
        {
            id: "ERZISHIGEHE_12",
            relatedClass: ['class8']
        },
        {
            id: "ERZISHIGEHE_13",
            relatedClass: ['class8']
        },
        {
            id: "ERZISHIGEHE_14",
            relatedClass: ['class8']
        },
        {
            id: "ERZISHIGEHE_15",
            relatedClass: ['class8']
        },
        {
            id: "ERZISHIGEHE_16",
            relatedClass: ['class8']
        },
        {
            id: "ERZISHIGEHE_17",
            relatedClass: ['class8']
        },
        {
            id: "ERZISHIGEHE_18",
            relatedClass: ['class8']
        },
    ],
    [
        {
            id: "ERZISHIGEHEWEI_0",
            relatedClass: ['class8']
        },
        {
            id: "ERZISHIGEHEWEI_1",
            relatedClass: ['class8']
        },
        {
            id: "ERZISHIGEHEWEI_2",
            relatedClass: ['class8']
        },
        {
            id: "ERZISHIGEHEWEI_3",
            relatedClass: ['class8']
        },
        {
            id: "ERZISHIGEHEWEI_4",
            relatedClass: ['class8']
        },
        {
            id: "ERZISHIGEHEWEI_5",
            relatedClass: ['class8']
        },
        {
            id: "ERZISHIGEHEWEI_6",
            relatedClass: ['class8']
        },
        {
            id: "ERZISHIGEHEWEI_7",
            relatedClass: ['class8']
        },
        {
            id: "ERZISHIGEHEWEI_8",
            relatedClass: ['class8']
        },
        {
            id: "ERZISHIGEHEWEI_9",
            relatedClass: ['class8']
        },
    ],
    [
        {
            id: "ZUXUANSAN_5",
            relatedClass: ['class9']
        },
        {
            id: "ZUXUANSAN_6",
            relatedClass: ['class9']
        },
        {
            id: "ZUXUANSAN_7",
            relatedClass: ['class9']
        },
        {
            id: "ZUXUANSAN_8",
            relatedClass: ['class9']
        },
        {
            id: "ZUXUANSAN_9",
            relatedClass: ['class9']
        },
        {
            id: "ZUXUANSAN_10",
            relatedClass: ['class9']
        },
    ],
    [
        {
            id: "ZUXUANLIU_4",
            relatedClass: ['class10']
        },
        {
            id: "ZUXUANLIU_5",
            relatedClass: ['class10']
        },
        {
            id: "ZUXUANLIU_6",
            relatedClass: ['class10']
        },
        {
            id: "ZUXUANLIU_7",
            relatedClass: ['class10']
        },
        {
            id: "ZUXUANLIU_8",
            relatedClass: ['class10']
        },
    ],
    [
        {
            id: "KUADU_0",
            relatedClass: ['class11']
        },
        {
            id: "KUADU_1",
            relatedClass: ['class11']
        },
        {
            id: "KUADU_2",
            relatedClass: ['class11']
        },
        {
            id: "KUADU_3",
            relatedClass: ['class11']
        },
        {
            id: "KUADU_4",
            relatedClass: ['class11']
        },
        {
            id: "KUADU_5",
            relatedClass: ['class11']
        },
        {
            id: "KUADU_6",
            relatedClass: ['class11']
        },
        {
            id: "KUADU_7",
            relatedClass: ['class11']
        },
        {
            id: "KUADU_8",
            relatedClass: ['class11']
        },
        {
            id: "KUADU_9",
            relatedClass: ['class11']
        },
    ],
]
const fc3dTypeRule = [
    [
        {
            id: "BAIDA",
            relatedClass: ['class1','class2']
        },
        {
            id: "BAIXIAO",
            relatedClass: ['class1','class2']
        },
        {
            id: "BAIDAN",
            relatedClass: ['class1','class2']
        },
        {
            id: "BAISHUANG",
            relatedClass: ['class1','class2']
        },
        {
            id: "BAIZHI",
            relatedClass: ['class1','class2']
        },
        {
            id: "BAIHE",
            relatedClass: ['class1','class2']
        },
    ],
    [
        {
            id: "SHIDA",
            relatedClass: ['class1','class2']
        },
        {
            id: "SHIXIAO",
            relatedClass: ['class1','class2']
        },
        {
            id: "SHIDAN",
            relatedClass: ['class1','class2']
        },
        {
            id: "SHISHUANG",
            relatedClass: ['class1','class2']
        },
        {
            id: "SHIZHI",
            relatedClass: ['class1','class2']
        },
        {
            id: "SHIHE",
            relatedClass: ['class1','class2']
        },
    ],
    [
        {
            id: "GEDA",
            relatedClass: ['class1','class2']
        },
        {
            id: "GEXIAO",
            relatedClass: ['class1','class2']
        },
        {
            id: "GEDAN",
            relatedClass: ['class1','class2']
        },
        {
            id: "GESHUANG",
            relatedClass: ['class1','class2']
        },
        {
            id: "GEZHI",
            relatedClass: ['class1','class2']
        },
        {
            id: "GEHE",
            relatedClass: ['class1','class2']
        },
    ],
    [
        {
            id: "BAISHIDAN",
            relatedClass: ['class1']
        },
        {
            id: "BAISHISHUANG",
            relatedClass: ['class1']
        },
    ],
    [
        {
            id: "BAIGEDAN",
            relatedClass: ['class1']
        },
        {
            id: "BAIGESHUANG",
            relatedClass: ['class1']
        },
    ],
    [
        {
            id: "SHIGEDAN",
            relatedClass: ['class1']
        },
        {
            id: "SHIGESHUANG",
            relatedClass: ['class1']
        },
    ],
    [
        {
            id: "BAISHIHEWEIDA",
            relatedClass: ['class1']
        },
        {
            id: "BAISHIHEWEIXIAO",
            relatedClass: ['class1']
        },
        {
            id: "BAISHIHEWEIZHI",
            relatedClass: ['class1']
        },
        {
            id: "BAISHIHEWEIHE",
            relatedClass: ['class1']
        },
    ],
    [
        {
            id: "BAIGEHEWEIDA",
            relatedClass: ['class1']
        },
        {
            id: "BAIGEHEWEIXIAO",
            relatedClass: ['class1']
        },
        {
            id: "BAIGEHEWEIZHI",
            relatedClass: ['class1']
        },
        {
            id: "BAIGEHEWEIHE",
            relatedClass: ['class1']
        },
    ],
    [
        {
            id: "SHIGEHEWEIDA",
            relatedClass: ['class1']
        },
        {
            id: "SHIGEHEWEIXIAO",
            relatedClass: ['class1']
        },
        {
            id: "SHIGEHEWEIZHI",
            relatedClass: ['class1']
        },
        {
            id: "SHIGEHEWEIHE",
            relatedClass: ['class1']
        },
    ],
    [
        {
            id: "BAISHIGEHEDA",
            relatedClass: ['class1']
        },
        {
            id: "BAISHIGEHEXIAO",
            relatedClass: ['class1']
        },
        {
            id: "BAISHIGEHEDAN",
            relatedClass: ['class1']
        },
        {
            id: "BAISHIGEHESHUANG",
            relatedClass: ['class1']
        },
    ],
    [
        {
            id: "BAISHIGEHEWEIDA",
            relatedClass: ['class1']
        },
        {
            id: "BAISHIGEHEWEIXIAO",
            relatedClass: ['class1']
        },
        {
            id: "BAISHIGEHEWEIZHI",
            relatedClass: ['class1']
        },
        {
            id: "BAISHIGEHEWEIHE",
            relatedClass: ['class1']
        },
    ],
    [
        {
            id: "ONEMERGER",
            relatedClass: ['class2']
        },
    ],
    [
        {
            id: "TWOMERGER_00",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_01-09",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_11",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_12-19",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_22",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_23-29",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_33",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_34-39",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_44",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_45-49",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_55",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_56-59",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_66",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_67-69",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_77",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_78-79",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_88",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_89",
            relatedClass: ['class3']
        },
        {
            id: "TWOMERGER_99",
            relatedClass: ['class3']
        },
    ],
    [
        {
            id: "YIZIBAIWEI",
            relatedClass: ['class4']
        },
    ],
    [
        {
            id: "YIZISHIWEI",
            relatedClass: ['class4']
        },
    ],
    [
        {
            id: "YIZIGEWEI",
            relatedClass: ['class4']
        },
    ],
    [
        {
            id: "ERZIBAISHIWEI",
            relatedClass: ['class5']
        },
    ],
    [
        {
            id: "ERZIBAIGEWEI",
            relatedClass: ['class5']
        },
    ],
    [
        {
            id: "ERZISHIGEWEI",
            relatedClass: ['class5']
        },
    ],
    [
        {
            id: "ERZIBAISHIHE_0",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAISHIHE_1",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAISHIHE_2",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAISHIHE_3",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAISHIHE_4",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAISHIHE_5",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAISHIHE_6",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAISHIHE_7",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAISHIHE_8",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAISHIHE_9",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAISHIHE_10",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAISHIHE_11",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAISHIHE_12",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAISHIHE_13",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAISHIHE_14",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAISHIHE_15",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAISHIHE_16",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAISHIHE_17",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAISHIHE_18",
            relatedClass: ['class6']
        },
    ],
    [
        {
            id: "ERZIBAISHIHEWEI_0",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAISHIHEWEI_1",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAISHIHEWEI_2",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAISHIHEWEI_3",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAISHIHEWEI_4",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAISHIHEWEI_5",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAISHIHEWEI_6",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAISHIHEWEI_7",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAISHIHEWEI_8",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAISHIHEWEI_9",
            relatedClass: ['class6']
        },
    ],
    [
        {
            id: "ERZIBAIGEHE_0",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAIGEHE_1",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAIGEHE_2",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAIGEHE_3",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAIGEHE_4",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAIGEHE_5",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAIGEHE_6",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAIGEHE_7",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAIGEHE_8",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAIGEHE_9",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAIGEHE_10",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAIGEHE_11",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAIGEHE_12",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAIGEHE_13",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAIGEHE_14",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAIGEHE_15",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAIGEHE_16",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAIGEHE_17",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAIGEHE_18",
            relatedClass: ['class6']
        },
    ],
    [
        {
            id: "ERZIBAIGEHEWEI_0",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAIGEHEWEI_1",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAIGEHEWEI_2",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAIGEHEWEI_3",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAIGEHEWEI_4",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAIGEHEWEI_5",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAIGEHEWEI_6",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAIGEHEWEI_7",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAIGEHEWEI_8",
            relatedClass: ['class6']
        },
        {
            id: "ERZIBAIGEHEWEI_9",
            relatedClass: ['class6']
        },
    ],
    [
        {
            id: "ERZISHIGEHE_0",
            relatedClass: ['class6']
        },
        {
            id: "ERZISHIGEHE_1",
            relatedClass: ['class6']
        },
        {
            id: "ERZISHIGEHE_2",
            relatedClass: ['class6']
        },
        {
            id: "ERZISHIGEHE_3",
            relatedClass: ['class6']
        },
        {
            id: "ERZISHIGEHE_4",
            relatedClass: ['class6']
        },
        {
            id: "ERZISHIGEHE_5",
            relatedClass: ['class6']
        },
        {
            id: "ERZISHIGEHE_6",
            relatedClass: ['class6']
        },
        {
            id: "ERZISHIGEHE_7",
            relatedClass: ['class6']
        },
        {
            id: "ERZISHIGEHE_8",
            relatedClass: ['class6']
        },
        {
            id: "ERZISHIGEHE_9",
            relatedClass: ['class6']
        },
        {
            id: "ERZISHIGEHE_10",
            relatedClass: ['class6']
        },
        {
            id: "ERZISHIGEHE_11",
            relatedClass: ['class6']
        },
        {
            id: "ERZISHIGEHE_12",
            relatedClass: ['class6']
        },
        {
            id: "ERZISHIGEHE_13",
            relatedClass: ['class6']
        },
        {
            id: "ERZISHIGEHE_14",
            relatedClass: ['class6']
        },
        {
            id: "ERZISHIGEHE_15",
            relatedClass: ['class6']
        },
        {
            id: "ERZISHIGEHE_16",
            relatedClass: ['class6']
        },
        {
            id: "ERZISHIGEHE_17",
            relatedClass: ['class6']
        },
        {
            id: "ERZISHIGEHE_18",
            relatedClass: ['class6']
        },
    ],
    [
        {
            id: "ERZISHIGEHEWEI_0",
            relatedClass: ['class6']
        },
        {
            id: "ERZISHIGEHEWEI_1",
            relatedClass: ['class6']
        },
        {
            id: "ERZISHIGEHEWEI_2",
            relatedClass: ['class6']
        },
        {
            id: "ERZISHIGEHEWEI_3",
            relatedClass: ['class6']
        },
        {
            id: "ERZISHIGEHEWEI_4",
            relatedClass: ['class6']
        },
        {
            id: "ERZISHIGEHEWEI_5",
            relatedClass: ['class6']
        },
        {
            id: "ERZISHIGEHEWEI_6",
            relatedClass: ['class6']
        },
        {
            id: "ERZISHIGEHEWEI_7",
            relatedClass: ['class6']
        },
        {
            id: "ERZISHIGEHEWEI_8",
            relatedClass: ['class6']
        },
        {
            id: "ERZISHIGEHEWEI_9",
            relatedClass: ['class6']
        },
    ],
    [
        {
            id: "ZUXUANSAN_5",
            relatedClass: ['class7']
        },
        {
            id: "ZUXUANSAN_6",
            relatedClass: ['class7']
        },
        {
            id: "ZUXUANSAN_7",
            relatedClass: ['class7']
        },
        {
            id: "ZUXUANSAN_8",
            relatedClass: ['class7']
        },
        {
            id: "ZUXUANSAN_9",
            relatedClass: ['class7']
        },
        {
            id: "ZUXUANSAN_10",
            relatedClass: ['class7']
        },
    ],
    [
        {
            id: "ZUXUANLIU_4",
            relatedClass: ['class8']
        },
        {
            id: "ZUXUANLIU_5",
            relatedClass: ['class8']
        },
        {
            id: "ZUXUANLIU_6",
            relatedClass: ['class8']
        },
        {
            id: "ZUXUANLIU_7",
            relatedClass: ['class8']
        },
        {
            id: "ZUXUANLIU_8",
            relatedClass: ['class8']
        },
    ],
    [
        {
            id: "KUADU_0",
            relatedClass: ['class9']
        },
        {
            id: "KUADU_1",
            relatedClass: ['class9']
        },
        {
            id: "KUADU_2",
            relatedClass: ['class9']
        },
        {
            id: "KUADU_3",
            relatedClass: ['class9']
        },
        {
            id: "KUADU_4",
            relatedClass: ['class9']
        },
        {
            id: "KUADU_5",
            relatedClass: ['class9']
        },
        {
            id: "KUADU_6",
            relatedClass: ['class9']
        },
        {
            id: "KUADU_7",
            relatedClass: ['class9']
        },
        {
            id: "KUADU_8",
            relatedClass: ['class9']
        },
        {
            id: "KUADU_9",
            relatedClass: ['class9']
        },
    ],
]
const fc3dErzidingweiNumberList=[]
function createFc3dErzidingweiNumber(){
    for (var j=1;j<7;j++) {
        for (var i=0;i<10;i++) {
            fc3dErzidingweiNumberList.push({
                title:i.toString(),
                select:false,
                odds:'',
                index:j,
            })
        }
    }

    defaultColorRule.forEach(item => {
        var _color = item.bgColor;
        item.related.forEach(item => {
            var index = fc3dErzidingweiNumberList.filter(data => {                  
                return data.title === parseInt(item).toString()
            }).forEach(item=>{
                item.color = _color
            })
        })
    })
}
createFc3dErzidingweiNumber();
const fc3dNumberList=[]
function createFc3dNumber(){
    for (var i=0;i<10;i++) {
        fc3dNumberList.push({
            title:i.toString(),
            select:false,
            odds:'',
        })
    }

    defaultColorRule.forEach(item => {
        var _color = item.bgColor;
        item.related.forEach(item => {
            var index = fc3dNumberList.filter(data => {                  
                return data.title === parseInt(item).toString()
            }).forEach(item=>{
                item.color = _color
            })
        })
    })
}
createFc3dNumber();

const tencentClassRule = [
    {
        class: '整合盘',
        id: 'class1'
    },
    {
        class: '龙虎斗',
        id: 'class2'
    },
]
const tencentTypeTitle = ['第一球', '第二球', '第三球', '第四球', '第五球', '总和/龙虎', '前三', '中三', '后三', '第一球VS第二球', '第一球VS第三球', '第一球VS第四球', '第一球VS第五球', '第二球VS第三球', '第二球VS第四球', '第二球VS第五球', '第三球VS第四球', '第三球VS第五球', '第四球VS第五球']
const tencentTypeRule = [
    [
        {
            id: "ONEDA",
            relatedClass: ['class1']
        },
        {
            id: "ONEXIAO",
            relatedClass: ['class1']
        },
        {
            id: "ONEDAN",
            relatedClass: ['class1']
        },
        {
            id: "ONESHUANG",
            relatedClass: ['class1']
        },
        {
            id: 'ONE_0',
            relatedClass: ['class1'],
        },
        {
            id: 'ONE_1',
            relatedClass: ['class1'],
        },
        {
            id: 'ONE_2',
            relatedClass: ['class1'],
        },
        {
            id: 'ONE_3',
            relatedClass: ['class1'],
        },
        {
            id: 'ONE_4',
            relatedClass: ['class1'],
        },
        {
            id: 'ONE_5',
            relatedClass: ['class1'],
        },
        {
            id: 'ONE_6',
            relatedClass: ['class1'],
        },
        {
            id: 'ONE_7',
            relatedClass: ['class1'],
        },
        {
            id: 'ONE_8',
            relatedClass: ['class1'],
        },
        {
            id: 'ONE_9',
            relatedClass: ['class1'],
        },
    ],
    [
        {
            id: "TWODA",
            relatedClass: ['class1'],
        },
        {
            id: "TWOXIAO",
            relatedClass: ['class1'],
        },
        {
            id: "TWODAN",
            relatedClass: ['class1'],
        },
        {
            id: "TWOSHUANG",
            relatedClass: ['class1'],
        },
        {
            id: "TWO_0",
            relatedClass: ['class1'],
        },
        {
            id: 'TWO_1',
            relatedClass: ['class1'],
        },
        {
            id: 'TWO_2',
            relatedClass: ['class1'],
        },
        {
            id: 'TWO_3',
            relatedClass: ['class1'],
        },
        {
            id: 'TWO_4',
            relatedClass: ['class1'],
        },
        {
            id: 'TWO_5',
            relatedClass: ['class1'],
        },
        {
            id: 'TWO_6',
            relatedClass: ['class1'],
        },
        {
            id: 'TWO_7',
            relatedClass: ['class1'],
        },
        {
            id: 'TWO_8',
            relatedClass: ['class1'],
        },
        {
            id: 'TWO_9',
            relatedClass: ['class1'],
        },
    ],
    [
        {
            id: "THREEDA",
            relatedClass: ['class1'],
        },
        {
            id: "THREEXIAO",
            relatedClass: ['class1'],
        },
        {
            id: "THREEDAN",
            relatedClass: ['class1'],
        },
        {
            id: "THREESHUANG",
            relatedClass: ['class1'],
        },
        {
            id: "THREE_0",
            relatedClass: ['class1'],
        },
        {
            id: 'THREE_1',
            relatedClass: ['class1'],
        },
        {
            id: 'THREE_2',
            relatedClass: ['class1'],
        },
        {
            id: 'THREE_3',
            relatedClass: ['class1'],
        },
        {
            id: 'THREE_4',
            relatedClass: ['class1'],
        },
        {
            id: 'THREE_5',
            relatedClass: ['class1'],
        },
        {
            id: 'THREE_6',
            relatedClass: ['class1'],
        },
        {
            id: 'THREE_7',
            relatedClass: ['class1'],
        },
        {
            id: 'THREE_8',
            relatedClass: ['class1'],
        },
        {
            id: 'THREE_9',
            relatedClass: ['class1'],
        },
    ],
    [
        {
            id: "FOURDA",
            relatedClass: ['class1'],
        },
        {
            id: "FOURXIAO",
            relatedClass: ['class1'],
        },
        {
            id: "FOURDAN",
            relatedClass: ['class1'],
        },
        {
            id: "FOURSHUANG",
            relatedClass: ['class1'],
        },
        {
            id: "FOUR_0",
            relatedClass: ['class1'],
        },
        {
            id: 'FOUR_1',
            relatedClass: ['class1'],
        },
        {
            id: 'FOUR_2',
            relatedClass: ['class1'],
        },
        {
            id: 'FOUR_3',
            relatedClass: ['class1'],
        },
        {
            id: 'FOUR_4',
            relatedClass: ['class1'],
        },
        {
            id: 'FOUR_5',
            relatedClass: ['class1'],
        },
        {
            id: 'FOUR_6',
            relatedClass: ['class1'],
        },
        {
            id: 'FOUR_7',
            relatedClass: ['class1'],
        },
        {
            id: 'FOUR_8',
            relatedClass: ['class1'],
        },
        {
            id: 'FOUR_9',
            relatedClass: ['class1'],
        },
    ],
    [
        {
            id: "FIVEDA",
            relatedClass: ['class1'],
        },
        {
            id: "FIVEXIAO",
            relatedClass: ['class1'],
        },
        {
            id: "FIVEDAN",
            relatedClass: ['class1'],
        },
        {
            id: "FIVESHUANG",
            relatedClass: ['class1'],
        },
        {
            id: "FIVE_0",
            relatedClass: ['class1'],
        },
        {
            id: 'FIVE_1',
            relatedClass: ['class1'],
        },
        {
            id: 'FIVE_2',
            relatedClass: ['class1'],
        },
        {
            id: 'FIVE_3',
            relatedClass: ['class1'],
        },
        {
            id: 'FIVE_4',
            relatedClass: ['class1'],
        },
        {
            id: 'FIVE_5',
            relatedClass: ['class1'],
        },
        {
            id: 'FIVE_6',
            relatedClass: ['class1'],
        },
        {
            id: 'FIVE_7',
            relatedClass: ['class1'],
        },
        {
            id: 'FIVE_8',
            relatedClass: ['class1'],
        },
        {
            id: 'FIVE_9',
            relatedClass: ['class1'],
        },
    ],
    [
        {
            id: "LONG",
            relatedClass: ['class2'],
        },
        {
            id: "HU",
            relatedClass: ['class2'],
        },
        {
            id: "ALLDA",
            relatedClass: ['class1'],
        },
        {
            id: "ALLXIAO",
            relatedClass: ['class1'],
        },
        {
            id: "ALLDAN",
            relatedClass: ['class1'],
        },
        {
            id: "ALLSHUANG",
            relatedClass: ['class1'],
        },
        {
            id: "HE",
            relatedClass: ['class2'],
        },
    ],
    [
        {
            id: "QIANSANBAOZI",
            relatedClass: ['class1'],
        },
        {
            id: "QIANSANSHUNZI",
            relatedClass: ['class1'],
        },
        {
            id: "QIANSANDUIZI",
            relatedClass: ['class1'],
        },
        {
            id: "QIANSANBANSHUN",
            relatedClass: ['class1'],
        },
        {
            id: "QIANSANZALIU",
            relatedClass: ['class1'],
        },
    ],
    [
        {
            id: "ZHONGSANBAOZI",
            relatedClass: ['class1'],
        },
        {
            id: "ZHONGSANSHUNZI",
            relatedClass: ['class1'],
        },
        {
            id: "ZHONGSANDUIZI",
            relatedClass: ['class1'],
        },
        {
            id: "ZHONGSANBANSHUN",
            relatedClass: ['class1'],
        },
        {
            id: "ZHONGSANZALIU",
            relatedClass: ['class1'],
        },
    ],
    [
        {
            id: "HOUSANBAOZI",
            relatedClass: ['class1'],
        },
        {
            id: "HOUSANSHUNZI",
            relatedClass: ['class1'],
        },
        {
            id: "HOUSANDUIZI",
            relatedClass: ['class1'],
        },
        {
            id: "HOUSANBANSHUN",
            relatedClass: ['class1'],
        },
        {
            id: "HOUSANZALIU",
            relatedClass: ['class1'],
        },
    ],
    [
        {
            id: "LONG_1_2",
            relatedClass: ['class2'],
        },
        {
            id: "HU_1_2",
            relatedClass: ['class2'],
        },
        {
            id: "HE_1_2",
            relatedClass: ['class2'],
        },
    ],
    [
        {
            id: "LONG_1_3",
            relatedClass: ['class2'],
        },
        {
            id: "HU_1_3",
            relatedClass: ['class2'],
        },
        {
            id: "HE_1_3",
            relatedClass: ['class2'],
        },
    ],
    [
        {
            id: "LONG_1_4",
            relatedClass: ['class2'],
        },
        {
            id: "HU_1_4",
            relatedClass: ['class2'],
        },
        {
            id: "HE_1_4",
            relatedClass: ['class2'],
        },
    ],
    [
        {
            id: "LONG_1_5",
            relatedClass: ['class2'],
        },
        {
            id: "HU_1_5",
            relatedClass: ['class2'],
        },
        {
            id: "HE_1_5",
            relatedClass: ['class2'],
        },
    ],
    [
        {
            id: "LONG_2_3",
            relatedClass: ['class2'],
        },
        {
            id: "HU_2_3",
            relatedClass: ['class2'],
        },
        {
            id: "HE_2_3",
            relatedClass: ['class2'],
        },
    ],
    [
        {
            id: "LONG_2_4",
            relatedClass: ['class2'],
        },
        {
            id: "HU_2_4",
            relatedClass: ['class2'],
        },
        {
            id: "HE_2_4",
            relatedClass: ['class2'],
        },
    ],
    [
        {
            id: "LONG_2_5",
            relatedClass: ['class2'],
        },
        {
            id: "HU_2_5",
            relatedClass: ['class2'],
        },
        {
            id: "HE_2_5",
            relatedClass: ['class2'],
        },
    ],
    [
        {
            id: "LONG_3_4",
            relatedClass: ['class2'],
        },
        {
            id: "HU_3_4",
            relatedClass: ['class2'],
        },
        {
            id: "HE_3_4",
            relatedClass: ['class2'],
        },
    ],
    [
        {
            id: "LONG_3_5",
            relatedClass: ['class2'],
        },
        {
            id: "HU_3_5",
            relatedClass: ['class2'],
        },
        {
            id: "HE_3_5",
            relatedClass: ['class2'],
        },
    ],
    [
        {
            id: "LONG_4_5",
            relatedClass: ['class2'],
        },
        {
            id: "HU_4_5",
            relatedClass: ['class2'],
        },
        {
            id: "HE_4_5",
            relatedClass: ['class2'],
        },
    ],
]

const jndxyClassRule = [
    {
        class: '数字盘',
        id: 'class1'
    },
    {
        class: '两面盘',
        id: 'class2'
    },
    {
        class: '第一球',
        id: 'class3'
    },
    {
        class: '第二球',
        id: 'class4'
    },
    {
        class: '第三球',
        id: 'class5'
    },
    {
        class: '第四球',
        id: 'class6'
    },
    {
        class: '第五球',
        id: 'class7'
    },
]
const jndxyTypeTitle = ['第一球', '第二球', '第三球', '第四球', '第五球', '总和/龙虎', '前三', '中三', '后三'];
const jndxyTypeRule = [
    [
        {
            id: "ONEDA",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONEXIAO",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONEDAN",
            relatedClass: ['class2','class3']
        },
        {
            id: "ONESHUANG",
            relatedClass: ['class2','class3']
        },
        {
            id: 'ONE_0',
            relatedClass: ['class1','class3'],
        },
        {
            id: 'ONE_1',
            relatedClass: ['class1','class3'],
        },
        {
            id: 'ONE_2',
            relatedClass: ['class1','class3'],
        },
        {
            id: 'ONE_3',
            relatedClass: ['class1','class3'],
        },
        {
            id: 'ONE_4',
            relatedClass: ['class1','class3'],
        },
        {
            id: 'ONE_5',
            relatedClass: ['class1','class3'],
        },
        {
            id: 'ONE_6',
            relatedClass: ['class1','class3'],
        },
        {
            id: 'ONE_7',
            relatedClass: ['class1','class3'],
        },
        {
            id: 'ONE_8',
            relatedClass: ['class1','class3'],
        },
        {
            id: 'ONE_9',
            relatedClass: ['class1','class3'],
        },
    ],
    [
        {
            id: "TWODA",
            relatedClass: ['class2','class4'],
        },
        {
            id: "TWOXIAO",
            relatedClass: ['class2','class4'],
        },
        {
            id: "TWODAN",
            relatedClass: ['class2','class4'],
        },
        {
            id: "TWOSHUANG",
            relatedClass: ['class2','class4'],
        },
        {
            id: "TWO_0",
            relatedClass: ['class1','class4'],
        },
        {
            id: 'TWO_1',
            relatedClass: ['class1','class4'],
        },
        {
            id: 'TWO_2',
            relatedClass: ['class1','class4'],
        },
        {
            id: 'TWO_3',
            relatedClass: ['class1','class4'],
        },
        {
            id: 'TWO_4',
            relatedClass: ['class1','class4'],
        },
        {
            id: 'TWO_5',
            relatedClass: ['class1','class4'],
        },
        {
            id: 'TWO_6',
            relatedClass: ['class1','class4'],
        },
        {
            id: 'TWO_7',
            relatedClass: ['class1','class4'],
        },
        {
            id: 'TWO_8',
            relatedClass: ['class1','class4'],
        },
        {
            id: 'TWO_9',
            relatedClass: ['class1','class4'],
        },
    ],
    [
        {
            id: "THREEDA",
            relatedClass: ['class2','class5'],
        },
        {
            id: "THREEXIAO",
            relatedClass: ['class2','class5'],
        },
        {
            id: "THREEDAN",
            relatedClass: ['class2','class5'],
        },
        {
            id: "THREESHUANG",
            relatedClass: ['class2','class5'],
        },
        {
            id: "THREE_0",
            relatedClass: ['class1','class5'],
        },
        {
            id: 'THREE_1',
            relatedClass: ['class1','class5'],
        },
        {
            id: 'THREE_2',
            relatedClass: ['class1','class5'],
        },
        {
            id: 'THREE_3',
            relatedClass: ['class1','class5'],
        },
        {
            id: 'THREE_4',
            relatedClass: ['class1','class5'],
        },
        {
            id: 'THREE_5',
            relatedClass: ['class1','class5'],
        },
        {
            id: 'THREE_6',
            relatedClass: ['class1','class5'],
        },
        {
            id: 'THREE_7',
            relatedClass: ['class1','class5'],
        },
        {
            id: 'THREE_8',
            relatedClass: ['class1','class5'],
        },
        {
            id: 'THREE_9',
            relatedClass: ['class1','class5'],
        },
    ],
    [
        {
            id: "FOURDA",
            relatedClass: ['class2','class6'],
        },
        {
            id: "FOURXIAO",
            relatedClass: ['class2','class6'],
        },
        {
            id: "FOURDAN",
            relatedClass: ['class2','class6'],
        },
        {
            id: "FOURSHUANG",
            relatedClass: ['class2','class6'],
        },
        {
            id: "FOUR_0",
            relatedClass: ['class1','class6'],
        },
        {
            id: 'FOUR_1',
            relatedClass: ['class1','class6'],
        },
        {
            id: 'FOUR_2',
            relatedClass: ['class1','class6'],
        },
        {
            id: 'FOUR_3',
            relatedClass: ['class1','class6'],
        },
        {
            id: 'FOUR_4',
            relatedClass: ['class1','class6'],
        },
        {
            id: 'FOUR_5',
            relatedClass: ['class1','class6'],
        },
        {
            id: 'FOUR_6',
            relatedClass: ['class1','class6'],
        },
        {
            id: 'FOUR_7',
            relatedClass: ['class1','class6'],
        },
        {
            id: 'FOUR_8',
            relatedClass: ['class1','class6'],
        },
        {
            id: 'FOUR_9',
            relatedClass: ['class1','class6'],
        },
    ],
    [
        {
            id: "FIVEDA",
            relatedClass: ['class2','class7'],
        },
        {
            id: "FIVEXIAO",
            relatedClass: ['class2','class7'],
        },
        {
            id: "FIVEDAN",
            relatedClass: ['class2','class7'],
        },
        {
            id: "FIVESHUANG",
            relatedClass: ['class2','class7'],
        },
        {
            id: "FIVE_0",
            relatedClass: ['class1','class7'],
        },
        {
            id: 'FIVE_1',
            relatedClass: ['class1','class7'],
        },
        {
            id: 'FIVE_2',
            relatedClass: ['class1','class7'],
        },
        {
            id: 'FIVE_3',
            relatedClass: ['class1','class7'],
        },
        {
            id: 'FIVE_4',
            relatedClass: ['class1','class7'],
        },
        {
            id: 'FIVE_5',
            relatedClass: ['class1','class7'],
        },
        {
            id: 'FIVE_6',
            relatedClass: ['class1','class7'],
        },
        {
            id: 'FIVE_7',
            relatedClass: ['class1','class7'],
        },
        {
            id: 'FIVE_8',
            relatedClass: ['class1','class7'],
        },
        {
            id: 'FIVE_9',
            relatedClass: ['class1','class7'],
        },
    ],    
    [
        {
            id: "LONG",
            relatedClass: ['class2', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "HU",
            relatedClass: ['class2', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "ALLDA",
            relatedClass: ['class2', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "ALLXIAO",
            relatedClass: ['class2', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "ALLDAN",
            relatedClass: ['class2', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "ALLSHUANG",
            relatedClass: ['class2', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "HE",
            relatedClass: ['class2', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
    ],
    [
        {
            id: "QIANSANBAOZI",
            relatedClass: ['class1', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "QIANSANSHUNZI",
            relatedClass: ['class1', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "QIANSANDUIZI",
            relatedClass: ['class1', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "QIANSANBANSHUN",
            relatedClass: ['class1', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "QIANSANZALIU",
            relatedClass: ['class1', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
    ],
    [
        {
            id: "ZHONGSANBAOZI",
            relatedClass: ['class1', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "ZHONGSANSHUNZI",
            relatedClass: ['class1', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "ZHONGSANDUIZI",
            relatedClass: ['class1', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "ZHONGSANBANSHUN",
            relatedClass: ['class1', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "ZHONGSANZALIU",
            relatedClass: ['class1', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
    ],
    [
        {
            id: "HOUSANBAOZI",
            relatedClass: ['class1', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "HOUSANSHUNZI",
            relatedClass: ['class1', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "HOUSANDUIZI",
            relatedClass: ['class1', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "HOUSANBANSHUN",
            relatedClass: ['class1', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "HOUSANZALIU",
            relatedClass: ['class1', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
    ]
    
]

const ttcTypeRule = [
    [
        {
            id: "TEMA1_1",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_2",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_3",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_4",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_5",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_6",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_7",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_8",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_9",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_10",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_11",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_12",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_13",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_14",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_15",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_16",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_17",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_18",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_19",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_20",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_21",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_22",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_23",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_24",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_25",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_26",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_27",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_28",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_29",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_30",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_31",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_32",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_33",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_34",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_35",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_36",
            relatedClass: ['class1']
        },

        {
            id: "TEMA1_DA",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_XIAO",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_DAN",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_SHUANG",
            relatedClass: ['class1']
        },
    ],
    [
        {
            id: "TEMA2_1",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_2",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_3",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_4",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_5",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_6",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_7",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_8",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_9",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_10",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_11",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_12",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_13",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_14",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_15",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_16",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_17",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_18",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_19",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_20",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_21",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_22",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_23",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_24",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_25",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_26",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_27",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_28",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_29",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_30",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_31",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_32",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_33",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_34",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_35",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_36",
            relatedClass: ['class1']
        },

        {
            id: "TEMA2_DA",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_XIAO",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_DAN",
            relatedClass: ['class1']
        },
        {
            id: "TEMA2_SHUANG",
            relatedClass: ['class1']
        },
    ],
    [
        {
            id: "LIANMA_5_5",
            relatedClass: ['class2']
        },
        {
            id: "LIANMA_5_TE",
            relatedClass: ['class2']
        },
        {
            id: "LIANMA_6_6",
            relatedClass: ['class2']
        },
        {
            id: "LIANMA_6_TE",
            relatedClass: ['class2']
        },
        {
            id: "LIANMA_7_7",
            relatedClass: ['class2']
        },
        {
            id: "LIANMA_7_TE",
            relatedClass: ['class2']
        },
        {
            id: "LIANMA_8_8",
            relatedClass: ['class2']
        },
        {
            id: "LIANMA_8_TE",
            relatedClass: ['class2']
        },
    ]
]
const TEMPLATE3_ttcTypeRule = [
    [
        {
            id: "TEMA1_1",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_2",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_3",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_4",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_5",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_6",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_7",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_8",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_9",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_10",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_11",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_12",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_13",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_14",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_15",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_16",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_17",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_18",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_19",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_20",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_21",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_22",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_23",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_24",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_25",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_26",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_27",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_28",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_29",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_30",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_31",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_32",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_33",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_34",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_35",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_36",
            relatedClass: ['class1']
        },

        {
            id: "TEMA1_DA",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_XIAO",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_DAN",
            relatedClass: ['class1']
        },
        {
            id: "TEMA1_SHUANG",
            relatedClass: ['class1']
        },
    ],
    [
        {
            id: "TEMA2_1",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_2",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_3",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_4",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_5",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_6",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_7",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_8",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_9",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_10",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_11",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_12",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_13",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_14",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_15",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_16",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_17",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_18",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_19",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_20",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_21",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_22",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_23",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_24",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_25",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_26",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_27",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_28",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_29",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_30",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_31",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_32",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_33",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_34",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_35",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_36",
            relatedClass: ['class2']
        },

        {
            id: "TEMA2_DA",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_XIAO",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_DAN",
            relatedClass: ['class2']
        },
        {
            id: "TEMA2_SHUANG",
            relatedClass: ['class2']
        },
    ],
    [
        {
            id: "LIANMA_5_5",
            relatedClass: ['class3']
        },
        {
            id: "LIANMA_5_TE",
            relatedClass: ['class3']
        },
    ],
    [
        {
            id: "LIANMA_6_6",
            relatedClass: ['class4']
        },
        {
            id: "LIANMA_6_TE",
            relatedClass: ['class4']
        },
    ],
    [
        {
            id: "LIANMA_7_7",
            relatedClass: ['class5']
        },
        {
            id: "LIANMA_7_TE",
            relatedClass: ['class5']
        },
    ],
    [        
        {
            id: "LIANMA_8_8",
            relatedClass: ['class6']
        },
        {
            id: "LIANMA_8_TE",
            relatedClass: ['class6']
        },
    ],
]
const tiantiancaiClassRule = function(){
    return [
        {
            class: getWord('item_tema'),
            id: 'class1'
        },
        {
            class: getWord('lianma'),
            id: 'class2'
        },
    ]
}
const TEMPLATE3_tiantiancaiClassRule = [
    {
        class: '特码一',
        id: 'class1'
    },
    {
        class: '特码二',
        id: 'class2'
    },
    {
        class: '连码五中特',
        id: 'class3'
    },
    {
        class: '连码六中特',
        id: 'class4'
    },
    {
        class: '连码七中特',
        id: 'class5'
    },
    {
        class: '连码八中特',
        id: 'class6'
    },    
];
const tiantiancaiColorRule = [{
    color: '#000000',
    bgColor: '#4393FF',
    related: ["01", "02", "03", "04", "05", "06", "07", "08", "09","10","11","12", "13", "14", "15", "16", "17", "18", "19", "20", "21","22","23","24", "25", "26", "27", "28", "29", "30", "31", "32", "33","34","35","36",]
}]
const TEMPLATE3_tiantiancaiColorRule = [{
    color: '#FFCD3D',
    bgColor: '#FFCD3D',
    related: ["01", "02", "03", "04", "05", "06", "07", "08", "09","10","11","12", "13", "14", "15", "16", "17", "18", "19", "20", "21","22","23","24", "25", "26", "27", "28", "29", "30", "31", "32", "33","34","35","36",]
}]
const ttcNumberList=[]
function createTtcNumber(){
    for (var i=1;i<37;i++) {
        ttcNumberList.push({
            title:i>9?i.toString():0+i.toString(),
            select:false,
            odds:'',
        })
    }

    (_TEMPLATE==='template-3'
        ?TEMPLATE3_tiantiancaiColorRule
        :tiantiancaiColorRule).forEach(item => {
        var _color = item.bgColor;
        item.related.forEach(item => {
            var index = ttcNumberList.filter(data => {        
                return data.title === item
            }).forEach(item=>{
                item.color = _color
            })
        })
    })
}

const dtClassicClassRule = [
    {
        class: '数字盘',
        id: 'class1'
    },
    {
        class: '两面盘',
        id: 'class2'
    },
    {
        class: '第一球',
        id: 'class3'
    },
    {
        class: '第二球',
        id: 'class4'
    },
    {
        class: '第三球',
        id: 'class5'
    },
    {
        class: '第四球',
        id: 'class6'
    },
    {
        class: '第五球',
        id: 'class7'
    },
]
const dtClassicTypeTitle = ['第一球', '第二球', '第三球', '第四球', '第五球', '总和/龙虎', '前三', '中三', '后三']
const dtClassicTypeRule = [
    [
        {
            id: "ONEDA",
            relatedClass: ['class2', 'class3']
        },
        {
            id: "ONEXIAO",
            relatedClass: ['class2', 'class3']
        },
        {
            id: "ONEDAN",
            relatedClass: ['class2', 'class3']
        },
        {
            id: "ONESHUANG",
            relatedClass: ['class2', 'class3']
        },
        {
            id: "ONE",
            relatedClass: ['class1', 'class3']
        }
    ],
    [
        {
            id: "TWODA",
            relatedClass: ['class2', 'class4'],
        },
        {
            id: "TWOXIAO",
            relatedClass: ['class2', 'class4'],
        },
        {
            id: "TWODAN",
            relatedClass: ['class2', 'class4'],
        },
        {
            id: "TWOSHUANG",
            relatedClass: ['class2', 'class4'],
        },
        {
            id: "TWO",
            relatedClass: ['class1', 'class4'],
        },
    ],
    [
        {
            id: "THREEDA",
            relatedClass: ['class2', 'class5'],
        },
        {
            id: "THREEXIAO",
            relatedClass: ['class2', 'class5'],
        },
        {
            id: "THREEDAN",
            relatedClass: ['class2', 'class5'],
        },
        {
            id: "THREESHUANG",
            relatedClass: ['class2', 'class5'],
        },
        {
            id: "THREE",
            relatedClass: ['class1', 'class5'],
        },
    ],
    [
        {
            id: "FOURDA",
            relatedClass: ['class2', 'class6'],
        },
        {
            id: "FOURXIAO",
            relatedClass: ['class2', 'class6'],
        },
        {
            id: "FOURDAN",
            relatedClass: ['class2', 'class6'],
        },
        {
            id: "FOURSHUANG",
            relatedClass: ['class2', 'class6'],
        },
        {
            id: "FOUR",
            relatedClass: ['class1', 'class6'],
        },
    ],
    [
        {
            id: "FIVEDA",
            relatedClass: ['class2', 'class7'],
        },
        {
            id: "FIVEXIAO",
            relatedClass: ['class2', 'class7'],
        },
        {
            id: "FIVEDAN",
            relatedClass: ['class2', 'class7'],
        },
        {
            id: "FIVESHUANG",
            relatedClass: ['class2', 'class7'],
        },
        {
            id: "FIVE",
            relatedClass: ['class1', 'class7'],
        },
    ],
    [
        {
            id: "LONG",
            relatedClass: ['class2', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "HU",
            relatedClass: ['class2', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "ALLDA",
            relatedClass: ['class2', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "ALLXIAO",
            relatedClass: ['class2', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "ALLDAN",
            relatedClass: ['class2', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "ALLSHUANG",
            relatedClass: ['class2', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "HE",
            relatedClass: ['class2', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
    ],
    [
        {
            id: "QIANSANBAOZI",
            relatedClass: ['class1', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "QIANSANSHUNZI",
            relatedClass: ['class1', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "QIANSANDUIZI",
            relatedClass: ['class1', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "QIANSANBANSHUN",
            relatedClass: ['class1', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "QIANSANZALIU",
            relatedClass: ['class1', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
    ],
    [
        {
            id: "ZHONGSANBAOZI",
            relatedClass: ['class1', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "ZHONGSANSHUNZI",
            relatedClass: ['class1', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "ZHONGSANDUIZI",
            relatedClass: ['class1', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "ZHONGSANBANSHUN",
            relatedClass: ['class1', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "ZHONGSANZALIU",
            relatedClass: ['class1', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
    ],
    [
        {
            id: "HOUSANBAOZI",
            relatedClass: ['class1', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "HOUSANSHUNZI",
            relatedClass: ['class1', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "HOUSANDUIZI",
            relatedClass: ['class1', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "HOUSANBANSHUN",
            relatedClass: ['class1', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
        {
            id: "HOUSANZALIU",
            relatedClass: ['class1', 'class3', 'class4', 'class5', 'class6', 'class7'],
        },
    ]
]

const lhcClassRule = [
    {
        class: '特码',
        id: 'class1'
    },
    {
        class: '正码',
        id: 'class2'
    },
    {
        class: '正特一',
        id: 'class3'
    },
    {
        class: '正特二',
        id: 'class4'
    },
    {
        class: '正特三',
        id: 'class5'
    },
    {
        class: '正特四',
        id: 'class6'
    },
    {
        class: '正特五',
        id: 'class7'
    },
    {
        class: '正特六',
        id: 'class8'
    },
    {
        class: '正特尾',
        id: 'class9'
    },
    {
        class: '特肖',
        id: 'class10',
    },
    {
        class: '一肖',
        id: 'class11'
    },
    {
        class: '合肖',
        id: 'class12'
    },
    {
        class: '生肖连',
        id: 'class13'
    },
    {
        class: '半波',
        id: 'class14'
    },
    {
        class: '连码',
        id: 'class15'
    },
    {
        class: '尾数连',
        id: 'class16'
    },
    {
        class: '全不中',
        id: 'class17'
    },
]
const TEMPLATE3_lhcClassRule = [
    {
        class: '特码',
        id: 'class1'
    },
    {
        class: '正码',
        id: 'class2'
    },
    {
        class: '正特一',
        id: 'class3'
    },
    {
        class: '正特二',
        id: 'class4'
    },
    {
        class: '正特三',
        id: 'class5'
    },
    {
        class: '正特四',
        id: 'class6'
    },
    {
        class: '正特五',
        id: 'class7'
    },
    {
        class: '正特六',
        id: 'class8'
    },
    {
        class: '正特尾',
        id: 'class9'
    },
    {
        class: '特肖',
        id: 'class10',
    },
    {
        class: '一肖',
        id: 'class11'
    },
    {
        class: '合肖',
        id: 'class12'
    },
    {
        class: '二肖连',
        id: 'class13'
    },
    {
        class: '三肖连',
        id: 'class14'
    },
    {
        class: '四肖连',
        id: 'class15'
    },
    {
        class: '五肖连',
        id: 'class16'
    },
    {
        class: '半波',
        id: 'class17'
    },
    {
        class: '四全中',
        id: 'class18'
    },
    {
        class: '三全中',
        id: 'class19'
    },
    {
        class: '三中二',
        id: 'class20'
    },
    {
        class: '二全中',
        id: 'class21'
    },
    {
        class: '二中特',
        id: 'class22'
    },
    {
        class: '特串',
        id: 'class23'
    },
    {
        class: '二尾连',
        id: 'class24'
    },
    {
        class: '三尾连',
        id: 'class25'
    },
    {
        class: '四尾连',
        id: 'class26'
    },
    {
        class: '五不中',
        id: 'class27'
    },
    {
        class: '六不中',
        id: 'class28'
    },
    {
        class: '七不中',
        id: 'class29'
    },
    {
        class: '八不中',
        id: 'class30'
    },
    {
        class: '九不中',
        id: 'class31'
    },
    {
        class: '十不中',
        id: 'class32'
    },
    {
        class: '十一不中',
        id: 'class33'
    },
    {
        class: '十二不中',
        id: 'class34'
    },
]
const lhcTypeTitle = ['特码', '正码', '正特一', '正特二', '正特三', '正特四', '正特五', '正特六', '正特尾', '特肖', '一肖', '合肖', '生肖连', '半波', '连码', '尾数连', '全不中']
const TEMPLATE3_lhcTypeTitle = ['特码', '正码', '正特一', '正特二', '正特三', '正特四', '正特五', '正特六', '正特尾', '特肖', '一肖', '合肖', '二肖连', '三肖连', '四肖连', '五肖连', '半波', '四全中', '三全中', '三中二', '二全中', '二中特', '特串', '二尾连', '三尾连', '四尾连', '五不中', '六不中', '七不中', '八不中', '九不中', '十不中', '十一不中', '十二不中']
const lhcTypeRule = [
    [
        {
            id: 'TEMA_1',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_2',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_3',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_4',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_5',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_6',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_7',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_8',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_9',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_10',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_11',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_12',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_13',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_14',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_15',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_16',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_17',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_18',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_19',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_20',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_21',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_22',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_23',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_24',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_25',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_26',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_27',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_28',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_29',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_30',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_31',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_32',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_33',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_34',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_35',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_36',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_37',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_38',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_39',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_40',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_41',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_42',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_43',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_44',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_45',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_46',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_47',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_48',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_49',
            relatedClass: ['class1'],
        },

        {
            id: 'TEMA_DAN',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_DA',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_HE_DAN',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_HE_DA',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_WEI_DAN',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_WEI_DA',
            relatedClass: ['class1'],
        },

        {
            id: 'TEMA_SHUANG',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_XIAO',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_HE_SHUANG',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_HE_XIAO',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_WEI_SHUANG',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_WEI_XIAO',
            relatedClass: ['class1'],
        },

        {
            id: 'TEMA_RED',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_BLUE',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_GREEN',
            relatedClass: ['class1'],
        },
    ],
    [
        {
            id: 'NUMBER_1',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_2',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_3',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_4',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_5',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_6',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_7',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_8',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_9',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_10',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_11',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_12',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_13',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_14',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_15',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_16',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_17',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_18',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_19',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_20',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_21',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_22',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_23',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_24',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_25',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_26',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_27',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_28',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_29',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_30',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_31',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_32',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_33',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_34',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_35',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_36',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_37',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_38',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_39',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_40',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_41',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_42',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_43',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_44',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_45',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_46',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_47',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_48',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_49',
            relatedClass: ['class2'],
        },

        {
            id: 'NUMBER_TOTAL_DAN',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_TOTAL_SHUANG',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_TOTAL_DA',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_TOTAL_XIAO',
            relatedClass: ['class2'],
        },
    ],
    [
        {
            id: 'ONE_1',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_2',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_3',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_4',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_5',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_6',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_7',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_8',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_9',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_10',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_11',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_12',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_13',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_14',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_15',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_16',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_17',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_18',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_19',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_20',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_21',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_22',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_23',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_24',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_25',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_26',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_27',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_28',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_29',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_30',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_31',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_32',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_33',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_34',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_35',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_36',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_37',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_38',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_39',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_40',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_41',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_42',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_43',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_44',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_45',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_46',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_47',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_48',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_49',
            relatedClass: ['class3'],
        },

        {
            id: 'ONE_DAN',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_DA',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_HE_DAN',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_HE_DA',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_WEI_DAN',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_WEI_DA',
            relatedClass: ['class3'],
        },

        {
            id: 'ONE_SHUANG',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_XIAO',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_HE_SHUANG',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_HE_XIAO',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_WEI_SHUANG',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_WEI_XIAO',
            relatedClass: ['class3'],
        },

        {
            id: 'ONE_RED',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_BLUE',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_GREEN',
            relatedClass: ['class3'],
        },
    ],
    [
        {
            id: 'TWO_1',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_2',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_3',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_4',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_5',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_6',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_7',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_8',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_9',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_10',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_11',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_12',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_13',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_14',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_15',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_16',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_17',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_18',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_19',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_20',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_21',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_22',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_23',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_24',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_25',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_26',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_27',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_28',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_29',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_30',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_31',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_32',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_33',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_34',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_35',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_36',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_37',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_38',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_39',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_40',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_41',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_42',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_43',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_44',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_45',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_46',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_47',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_48',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_49',
            relatedClass: ['class4'],
        },

        {
            id: 'TWO_DAN',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_DA',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_HE_DAN',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_HE_DA',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_WEI_DAN',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_WEI_DA',
            relatedClass: ['class4'],
        },

        {
            id: 'TWO_SHUANG',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_XIAO',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_HE_SHUANG',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_HE_XIAO',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_WEI_SHUANG',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_WEI_XIAO',
            relatedClass: ['class4'],
        },

        {
            id: 'TWO_RED',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_BLUE',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_GREEN',
            relatedClass: ['class4'],
        },
    ],
    [
        {
            id: 'THREE_1',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_2',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_3',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_4',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_5',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_6',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_7',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_8',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_9',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_10',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_11',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_12',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_13',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_14',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_15',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_16',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_17',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_18',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_19',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_20',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_21',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_22',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_23',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_24',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_25',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_26',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_27',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_28',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_29',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_30',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_31',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_32',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_33',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_34',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_35',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_36',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_37',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_38',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_39',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_40',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_41',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_42',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_43',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_44',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_45',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_46',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_47',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_48',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_49',
            relatedClass: ['class5'],
        },

        {
            id: 'THREE_DAN',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_DA',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_HE_DAN',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_HE_DA',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_WEI_DAN',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_WEI_DA',
            relatedClass: ['class5'],
        },

        {
            id: 'THREE_SHUANG',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_XIAO',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_HE_SHUANG',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_HE_XIAO',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_WEI_SHUANG',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_WEI_XIAO',
            relatedClass: ['class5'],
        },

        {
            id: 'THREE_RED',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_BLUE',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_GREEN',
            relatedClass: ['class5'],
        },
    ],
    [
        {
            id: 'FOUR_1',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_2',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_3',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_4',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_5',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_6',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_7',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_8',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_9',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_10',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_11',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_12',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_13',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_14',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_15',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_16',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_17',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_18',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_19',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_20',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_21',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_22',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_23',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_24',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_25',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_26',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_27',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_28',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_29',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_30',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_31',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_32',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_33',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_34',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_35',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_36',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_37',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_38',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_39',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_40',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_41',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_42',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_43',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_44',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_45',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_46',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_47',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_48',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_49',
            relatedClass: ['class6'],
        },

        {
            id: 'FOUR_DAN',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_DA',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_HE_DAN',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_HE_DA',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_WEI_DAN',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_WEI_DA',
            relatedClass: ['class6'],
        },

        {
            id: 'FOUR_SHUANG',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_XIAO',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_HE_SHUANG',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_HE_XIAO',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_WEI_SHUANG',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_WEI_XIAO',
            relatedClass: ['class6'],
        },

        {
            id: 'FOUR_RED',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_BLUE',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_GREEN',
            relatedClass: ['class6'],
        },
    ],
    [
        {
            id: 'FIVE_1',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_2',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_3',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_4',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_5',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_6',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_7',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_8',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_9',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_10',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_11',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_12',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_13',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_14',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_15',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_16',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_17',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_18',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_19',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_20',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_21',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_22',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_23',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_24',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_25',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_26',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_27',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_28',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_29',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_30',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_31',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_32',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_33',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_34',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_35',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_36',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_37',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_38',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_39',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_40',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_41',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_42',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_43',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_44',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_45',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_46',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_47',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_48',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_49',
            relatedClass: ['class7'],
        },

        {
            id: 'FIVE_DAN',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_DA',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_HE_DAN',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_HE_DA',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_WEI_DAN',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_WEI_DA',
            relatedClass: ['class7'],
        },

        {
            id: 'FIVE_SHUANG',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_XIAO',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_HE_SHUANG',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_HE_XIAO',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_WEI_SHUANG',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_WEI_XIAO',
            relatedClass: ['class7'],
        },

        {
            id: 'FIVE_RED',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_BLUE',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_GREEN',
            relatedClass: ['class7'],
        },
    ],
    [
        {
            id: 'SIX_1',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_2',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_3',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_4',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_5',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_6',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_7',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_8',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_9',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_10',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_11',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_12',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_13',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_14',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_15',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_16',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_17',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_18',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_19',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_20',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_21',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_22',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_23',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_24',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_25',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_26',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_27',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_28',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_29',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_30',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_31',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_32',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_33',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_34',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_35',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_36',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_37',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_38',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_39',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_40',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_41',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_42',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_43',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_44',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_45',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_46',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_47',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_48',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_49',
            relatedClass: ['class8'],
        },

        {
            id: 'SIX_DAN',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_DA',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_HE_DAN',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_HE_DA',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_WEI_DAN',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_WEI_DA',
            relatedClass: ['class8'],
        },

        {
            id: 'SIX_SHUANG',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_XIAO',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_HE_SHUANG',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_HE_XIAO',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_WEI_SHUANG',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_WEI_XIAO',
            relatedClass: ['class8'],
        },

        {
            id: 'SIX_RED',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_BLUE',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_GREEN',
            relatedClass: ['class8'],
        },
    ],
    [
        {
            id: 'WEI_0',
            relatedClass: ['class9'],
        },
        {
            id: 'WEI_1',
            relatedClass: ['class9'],
        },
        {
            id: 'WEI_2',
            relatedClass: ['class9'],
        },
        {
            id: 'WEI_3',
            relatedClass: ['class9'],
        },
        {
            id: 'WEI_4',
            relatedClass: ['class9'],
        },
        {
            id: 'WEI_5',
            relatedClass: ['class9'],
        },
        {
            id: 'WEI_6',
            relatedClass: ['class9'],
        },
        {
            id: 'WEI_7',
            relatedClass: ['class9'],
        },
        {
            id: 'WEI_8',
            relatedClass: ['class9'],
        },
        {
            id: 'WEI_9',
            relatedClass: ['class9'],
        },
    ],
    [
        {
            id: 'TEMA_ANIMAL_SHU',
            relatedClass: ['class10'],
        },
        {
            id: 'TEMA_ANIMAL_NIU',
            relatedClass: ['class10'],
        },
        {
            id: 'TEMA_ANIMAL_HU',
            relatedClass: ['class10'],
        },
        {
            id: 'TEMA_ANIMAL_TU',
            relatedClass: ['class10'],
        },
        {
            id: 'TEMA_ANIMAL_LONG',
            relatedClass: ['class10'],
        },
        {
            id: 'TEMA_ANIMAL_SHE',
            relatedClass: ['class10'],
        },
        {
            id: 'TEMA_ANIMAL_MA',
            relatedClass: ['class10'],
        },
        {
            id: 'TEMA_ANIMAL_YANG',
            relatedClass: ['class10'],
        },
        {
            id: 'TEMA_ANIMAL_HOU',
            relatedClass: ['class10'],
        },
        {
            id: 'TEMA_ANIMAL_JI',
            relatedClass: ['class10'],
        },
        {
            id: 'TEMA_ANIMAL_GOU',
            relatedClass: ['class10'],
        },
        {
            id: 'TEMA_ANIMAL_ZHU',
            relatedClass: ['class10'],
        },
    ],
    [
        {
            id: 'ANIMAL_ONE_SHU',
            relatedClass: ['class11'],
        },
        {
            id: 'ANIMAL_ONE_NIU',
            relatedClass: ['class11'],
        },
        {
            id: 'ANIMAL_ONE_HU',
            relatedClass: ['class11'],
        },
        {
            id: 'ANIMAL_ONE_TU',
            relatedClass: ['class11'],
        },
        {
            id: 'ANIMAL_ONE_LONG',
            relatedClass: ['class11'],
        },
        {
            id: 'ANIMAL_ONE_SHE',
            relatedClass: ['class11'],
        },
        {
            id: 'ANIMAL_ONE_MA',
            relatedClass: ['class11'],
        },
        {
            id: 'ANIMAL_ONE_YANG',
            relatedClass: ['class11'],
        },
        {
            id: 'ANIMAL_ONE_HOU',
            relatedClass: ['class11'],
        },
        {
            id: 'ANIMAL_ONE_JI',
            relatedClass: ['class11'],
        },
        {
            id: 'ANIMAL_ONE_GOU',
            relatedClass: ['class11'],
        },
        {
            id: 'ANIMAL_ONE_ZHU',
            relatedClass: ['class11'],
        },
    ],
    [
        {
            id: 'TEMA_HE_ANIMAL_1_IN',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_2_IN',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_3_IN',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_4_IN',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_5_IN',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_6_IN',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_7_IN',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_8_IN',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_9_IN',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_10_IN',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_11_IN',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_12_IN',
            relatedClass: ['class12'],
        },

        {
            id: 'TEMA_HE_ANIMAL_1_NOT',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_2_NOT',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_3_NOT',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_4_NOT',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_5_NOT',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_6_NOT',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_7_NOT',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_8_NOT',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_9_NOT',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_10_NOT',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_11_NOT',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_12_NOT',
            relatedClass: ['class12'],
        },
    ],
    [
        {
            id: 'ANIMAL_LIAN_2_IN',
            relatedClass: ['class13'],
        },
        {
            id: 'ANIMAL_LIAN_2_IN_BEN',
            relatedClass: ['class13'],
        },
        {
            id: 'ANIMAL_LIAN_3_IN',
            relatedClass: ['class13'],
        },
        {
            id: 'ANIMAL_LIAN_3_IN_BEN',
            relatedClass: ['class13'],
        },
        {
            id: 'ANIMAL_LIAN_4_IN',
            relatedClass: ['class13'],
        },
        {
            id: 'ANIMAL_LIAN_4_IN_BEN',
            relatedClass: ['class13'],
        },
        {
            id: 'ANIMAL_LIAN_5_IN',
            relatedClass: ['class13'],
        },
        {
            id: 'ANIMAL_LIAN_5_IN_BEN',
            relatedClass: ['class13'],
        },

        {
            id: 'ANIMAL_LIAN_2_NOT',
            relatedClass: ['class13'],
        },
        {
            id: 'ANIMAL_LIAN_2_NOT_BEN',
            relatedClass: ['class13'],
        },
        {
            id: 'ANIMAL_LIAN_3_NOT',
            relatedClass: ['class13'],
        },
        {
            id: 'ANIMAL_LIAN_3_NOT_BEN',
            relatedClass: ['class13'],
        },
        {
            id: 'ANIMAL_LIAN_4_NOT',
            relatedClass: ['class13'],
        },
        {
            id: 'ANIMAL_LIAN_4_NOT_BEN',
            relatedClass: ['class13'],
        },
        {
            id: 'ANIMAL_LIAN_5_NOT',
            relatedClass: ['class13'],
        },
        {
            id: 'ANIMAL_LIAN_5_NOT_BEN',
            relatedClass: ['class13'],
        },
    ],
    [
        {
            id: 'COLOR_RED_DA',
            relatedClass: ['class14'],
        },
        {
            id: 'COLOR_RED_XIAO',
            relatedClass: ['class14'],
        },
        {
            id: 'COLOR_RED_DAN',
            relatedClass: ['class14'],
        },
        {
            id: 'COLOR_RED_SHUANG',
            relatedClass: ['class14'],
        },

        {
            id: 'COLOR_BLUE_DA',
            relatedClass: ['class14'],
        },
        {
            id: 'COLOR_BLUE_XIAO',
            relatedClass: ['class14'],
        },
        {
            id: 'COLOR_BLUE_DAN',
            relatedClass: ['class14'],
        },
        {
            id: 'COLOR_BLUE_SHUANG',
            relatedClass: ['class14'],
        },

        {
            id: 'COLOR_GREEN_DA',
            relatedClass: ['class14'],
        },
        {
            id: 'COLOR_GREEN_XIAO',
            relatedClass: ['class14'],
        },
        {
            id: 'COLOR_GREEN_DAN',
            relatedClass: ['class14'],
        },
        {
            id: 'COLOR_GREEN_SHUANG',
            relatedClass: ['class14'],
        },
    ],
    [
        {
            id: 'LIANMA_4_ALLIN',
            relatedClass: ['class15'],
        },
        {
            id: 'LIANMA_3_ALLIN',
            relatedClass: ['class15'],
        },
        {
            id: 'LIANMA_3_IN_2',
            relatedClass: ['class15'],
        },
        {
            id: 'LIANMA_3_IN_3',
            relatedClass: ['class15'],
        },
        {
            id: 'LIANMA_2_ALLIN',
            relatedClass: ['class15'],
        },
        {
            id: 'LIANMA_2_IN_TEMA',
            relatedClass: ['class15'],
        },
        {
            id: 'LIANMA_2_IN_2',
            relatedClass: ['class15'],
        },
        {
            id: 'LIANMA_1_IN_TEMA',
            relatedClass: ['class15'],
        },
    ],
    [
        {
            id: 'WEI_LIAN_2_IN',
            relatedClass: ['class16'],
        },
        {
            id: 'WEI_LIAN_2_IN_0',
            relatedClass: ['class16'],
        },
        {
            id: 'WEI_LIAN_3_IN',
            relatedClass: ['class16'],
        },
        {
            id: 'WEI_LIAN_3_IN_0',
            relatedClass: ['class16'],
        },

        {
            id: 'WEI_LIAN_4_IN',
            relatedClass: ['class16'],
        },
        {
            id: 'WEI_LIAN_4_IN_0',
            relatedClass: ['class16'],
        },
        {
            id: 'WEI_LIAN_2_NOT',
            relatedClass: ['class16'],
        },
        {
            id: 'WEI_LIAN_2_NOT_0',
            relatedClass: ['class16'],
        },

        {
            id: 'WEI_LIAN_3_NOT',
            relatedClass: ['class16'],
        },
        {
            id: 'WEI_LIAN_3_NOT_0',
            relatedClass: ['class16'],
        },
        {
            id: 'WEI_LIAN_4_NOT',
            relatedClass: ['class16'],
        },
        {
            id: 'WEI_LIAN_4_NOT_0',
            relatedClass: ['class16'],
        },
    ],
    [
        {
            id: 'ALLNOTIN_5',
            relatedClass: ['class17'],
        },
        {
            id: 'ALLNOTIN_6',
            relatedClass: ['class17'],
        },
        {
            id: 'ALLNOTIN_7',
            relatedClass: ['class17'],
        },
        {
            id: 'ALLNOTIN_8',
            relatedClass: ['class17'],
        },
        {
            id: 'ALLNOTIN_9',
            relatedClass: ['class17'],
        },
        {
            id: 'ALLNOTIN_10',
            relatedClass: ['class17'],
        },
        {
            id: 'ALLNOTIN_11',
            relatedClass: ['class17'],
        },
        {
            id: 'ALLNOTIN_12',
            relatedClass: ['class17'],
        },
    ],
]
const TEMPLATE3_lhcTypeRule = [
    [
        {
            id: 'TEMA_1',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_2',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_3',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_4',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_5',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_6',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_7',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_8',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_9',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_10',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_11',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_12',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_13',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_14',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_15',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_16',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_17',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_18',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_19',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_20',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_21',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_22',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_23',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_24',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_25',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_26',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_27',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_28',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_29',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_30',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_31',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_32',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_33',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_34',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_35',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_36',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_37',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_38',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_39',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_40',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_41',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_42',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_43',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_44',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_45',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_46',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_47',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_48',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_49',
            relatedClass: ['class1'],
        },

        {
            id: 'TEMA_DAN',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_DA',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_HE_DAN',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_HE_DA',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_WEI_DAN',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_WEI_DA',
            relatedClass: ['class1'],
        },

        {
            id: 'TEMA_SHUANG',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_XIAO',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_HE_SHUANG',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_HE_XIAO',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_WEI_SHUANG',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_WEI_XIAO',
            relatedClass: ['class1'],
        },

        {
            id: 'TEMA_RED',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_BLUE',
            relatedClass: ['class1'],
        },
        {
            id: 'TEMA_GREEN',
            relatedClass: ['class1'],
        },
    ],
    [
        {
            id: 'NUMBER_1',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_2',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_3',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_4',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_5',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_6',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_7',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_8',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_9',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_10',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_11',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_12',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_13',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_14',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_15',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_16',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_17',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_18',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_19',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_20',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_21',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_22',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_23',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_24',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_25',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_26',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_27',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_28',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_29',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_30',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_31',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_32',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_33',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_34',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_35',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_36',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_37',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_38',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_39',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_40',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_41',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_42',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_43',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_44',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_45',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_46',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_47',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_48',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_49',
            relatedClass: ['class2'],
        },

        {
            id: 'NUMBER_TOTAL_DAN',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_TOTAL_SHUANG',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_TOTAL_DA',
            relatedClass: ['class2'],
        },
        {
            id: 'NUMBER_TOTAL_XIAO',
            relatedClass: ['class2'],
        },
    ],
    [
        {
            id: 'ONE_1',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_2',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_3',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_4',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_5',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_6',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_7',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_8',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_9',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_10',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_11',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_12',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_13',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_14',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_15',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_16',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_17',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_18',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_19',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_20',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_21',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_22',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_23',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_24',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_25',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_26',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_27',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_28',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_29',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_30',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_31',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_32',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_33',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_34',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_35',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_36',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_37',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_38',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_39',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_40',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_41',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_42',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_43',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_44',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_45',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_46',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_47',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_48',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_49',
            relatedClass: ['class3'],
        },

        {
            id: 'ONE_DAN',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_DA',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_HE_DAN',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_HE_DA',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_WEI_DAN',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_WEI_DA',
            relatedClass: ['class3'],
        },

        {
            id: 'ONE_SHUANG',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_XIAO',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_HE_SHUANG',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_HE_XIAO',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_WEI_SHUANG',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_WEI_XIAO',
            relatedClass: ['class3'],
        },

        {
            id: 'ONE_RED',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_BLUE',
            relatedClass: ['class3'],
        },
        {
            id: 'ONE_GREEN',
            relatedClass: ['class3'],
        },
    ],
    [
        {
            id: 'TWO_1',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_2',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_3',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_4',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_5',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_6',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_7',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_8',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_9',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_10',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_11',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_12',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_13',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_14',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_15',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_16',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_17',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_18',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_19',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_20',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_21',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_22',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_23',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_24',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_25',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_26',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_27',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_28',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_29',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_30',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_31',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_32',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_33',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_34',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_35',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_36',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_37',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_38',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_39',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_40',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_41',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_42',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_43',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_44',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_45',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_46',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_47',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_48',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_49',
            relatedClass: ['class4'],
        },

        {
            id: 'TWO_DAN',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_DA',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_HE_DAN',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_HE_DA',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_WEI_DAN',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_WEI_DA',
            relatedClass: ['class4'],
        },

        {
            id: 'TWO_SHUANG',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_XIAO',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_HE_SHUANG',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_HE_XIAO',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_WEI_SHUANG',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_WEI_XIAO',
            relatedClass: ['class4'],
        },

        {
            id: 'TWO_RED',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_BLUE',
            relatedClass: ['class4'],
        },
        {
            id: 'TWO_GREEN',
            relatedClass: ['class4'],
        },
    ],
    [
        {
            id: 'THREE_1',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_2',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_3',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_4',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_5',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_6',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_7',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_8',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_9',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_10',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_11',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_12',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_13',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_14',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_15',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_16',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_17',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_18',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_19',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_20',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_21',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_22',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_23',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_24',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_25',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_26',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_27',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_28',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_29',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_30',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_31',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_32',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_33',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_34',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_35',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_36',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_37',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_38',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_39',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_40',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_41',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_42',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_43',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_44',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_45',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_46',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_47',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_48',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_49',
            relatedClass: ['class5'],
        },

        {
            id: 'THREE_DAN',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_DA',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_HE_DAN',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_HE_DA',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_WEI_DAN',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_WEI_DA',
            relatedClass: ['class5'],
        },

        {
            id: 'THREE_SHUANG',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_XIAO',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_HE_SHUANG',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_HE_XIAO',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_WEI_SHUANG',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_WEI_XIAO',
            relatedClass: ['class5'],
        },

        {
            id: 'THREE_RED',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_BLUE',
            relatedClass: ['class5'],
        },
        {
            id: 'THREE_GREEN',
            relatedClass: ['class5'],
        },
    ],
    [
        {
            id: 'FOUR_1',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_2',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_3',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_4',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_5',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_6',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_7',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_8',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_9',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_10',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_11',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_12',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_13',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_14',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_15',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_16',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_17',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_18',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_19',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_20',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_21',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_22',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_23',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_24',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_25',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_26',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_27',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_28',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_29',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_30',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_31',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_32',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_33',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_34',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_35',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_36',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_37',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_38',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_39',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_40',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_41',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_42',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_43',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_44',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_45',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_46',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_47',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_48',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_49',
            relatedClass: ['class6'],
        },

        {
            id: 'FOUR_DAN',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_DA',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_HE_DAN',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_HE_DA',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_WEI_DAN',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_WEI_DA',
            relatedClass: ['class6'],
        },

        {
            id: 'FOUR_SHUANG',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_XIAO',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_HE_SHUANG',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_HE_XIAO',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_WEI_SHUANG',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_WEI_XIAO',
            relatedClass: ['class6'],
        },

        {
            id: 'FOUR_RED',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_BLUE',
            relatedClass: ['class6'],
        },
        {
            id: 'FOUR_GREEN',
            relatedClass: ['class6'],
        },
    ],
    [
        {
            id: 'FIVE_1',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_2',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_3',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_4',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_5',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_6',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_7',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_8',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_9',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_10',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_11',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_12',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_13',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_14',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_15',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_16',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_17',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_18',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_19',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_20',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_21',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_22',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_23',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_24',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_25',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_26',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_27',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_28',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_29',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_30',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_31',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_32',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_33',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_34',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_35',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_36',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_37',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_38',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_39',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_40',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_41',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_42',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_43',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_44',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_45',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_46',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_47',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_48',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_49',
            relatedClass: ['class7'],
        },

        {
            id: 'FIVE_DAN',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_DA',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_HE_DAN',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_HE_DA',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_WEI_DAN',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_WEI_DA',
            relatedClass: ['class7'],
        },

        {
            id: 'FIVE_SHUANG',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_XIAO',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_HE_SHUANG',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_HE_XIAO',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_WEI_SHUANG',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_WEI_XIAO',
            relatedClass: ['class7'],
        },

        {
            id: 'FIVE_RED',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_BLUE',
            relatedClass: ['class7'],
        },
        {
            id: 'FIVE_GREEN',
            relatedClass: ['class7'],
        },
    ],
    [
        {
            id: 'SIX_1',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_2',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_3',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_4',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_5',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_6',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_7',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_8',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_9',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_10',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_11',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_12',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_13',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_14',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_15',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_16',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_17',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_18',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_19',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_20',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_21',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_22',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_23',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_24',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_25',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_26',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_27',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_28',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_29',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_30',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_31',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_32',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_33',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_34',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_35',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_36',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_37',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_38',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_39',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_40',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_41',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_42',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_43',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_44',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_45',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_46',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_47',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_48',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_49',
            relatedClass: ['class8'],
        },

        {
            id: 'SIX_DAN',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_DA',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_HE_DAN',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_HE_DA',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_WEI_DAN',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_WEI_DA',
            relatedClass: ['class8'],
        },

        {
            id: 'SIX_SHUANG',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_XIAO',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_HE_SHUANG',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_HE_XIAO',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_WEI_SHUANG',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_WEI_XIAO',
            relatedClass: ['class8'],
        },

        {
            id: 'SIX_RED',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_BLUE',
            relatedClass: ['class8'],
        },
        {
            id: 'SIX_GREEN',
            relatedClass: ['class8'],
        },
    ],
    [
        {
            id: 'WEI_0',
            relatedClass: ['class9'],
        },
        {
            id: 'WEI_1',
            relatedClass: ['class9'],
        },
        {
            id: 'WEI_2',
            relatedClass: ['class9'],
        },
        {
            id: 'WEI_3',
            relatedClass: ['class9'],
        },
        {
            id: 'WEI_4',
            relatedClass: ['class9'],
        },
        {
            id: 'WEI_5',
            relatedClass: ['class9'],
        },
        {
            id: 'WEI_6',
            relatedClass: ['class9'],
        },
        {
            id: 'WEI_7',
            relatedClass: ['class9'],
        },
        {
            id: 'WEI_8',
            relatedClass: ['class9'],
        },
        {
            id: 'WEI_9',
            relatedClass: ['class9'],
        },
    ],
    [
        {
            id: 'TEMA_ANIMAL_SHU',
            relatedClass: ['class10'],
        },
        {
            id: 'TEMA_ANIMAL_NIU',
            relatedClass: ['class10'],
        },
        {
            id: 'TEMA_ANIMAL_HU',
            relatedClass: ['class10'],
        },
        {
            id: 'TEMA_ANIMAL_TU',
            relatedClass: ['class10'],
        },
        {
            id: 'TEMA_ANIMAL_LONG',
            relatedClass: ['class10'],
        },
        {
            id: 'TEMA_ANIMAL_SHE',
            relatedClass: ['class10'],
        },
        {
            id: 'TEMA_ANIMAL_MA',
            relatedClass: ['class10'],
        },
        {
            id: 'TEMA_ANIMAL_YANG',
            relatedClass: ['class10'],
        },
        {
            id: 'TEMA_ANIMAL_HOU',
            relatedClass: ['class10'],
        },
        {
            id: 'TEMA_ANIMAL_JI',
            relatedClass: ['class10'],
        },
        {
            id: 'TEMA_ANIMAL_GOU',
            relatedClass: ['class10'],
        },
        {
            id: 'TEMA_ANIMAL_ZHU',
            relatedClass: ['class10'],
        },
    ],
    [
        {
            id: 'ANIMAL_ONE_SHU',
            relatedClass: ['class11'],
        },
        {
            id: 'ANIMAL_ONE_NIU',
            relatedClass: ['class11'],
        },
        {
            id: 'ANIMAL_ONE_HU',
            relatedClass: ['class11'],
        },
        {
            id: 'ANIMAL_ONE_TU',
            relatedClass: ['class11'],
        },
        {
            id: 'ANIMAL_ONE_LONG',
            relatedClass: ['class11'],
        },
        {
            id: 'ANIMAL_ONE_SHE',
            relatedClass: ['class11'],
        },
        {
            id: 'ANIMAL_ONE_MA',
            relatedClass: ['class11'],
        },
        {
            id: 'ANIMAL_ONE_YANG',
            relatedClass: ['class11'],
        },
        {
            id: 'ANIMAL_ONE_HOU',
            relatedClass: ['class11'],
        },
        {
            id: 'ANIMAL_ONE_JI',
            relatedClass: ['class11'],
        },
        {
            id: 'ANIMAL_ONE_GOU',
            relatedClass: ['class11'],
        },
        {
            id: 'ANIMAL_ONE_ZHU',
            relatedClass: ['class11'],
        },
    ],
    [
        {
            id: 'TEMA_HE_ANIMAL_1_IN',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_2_IN',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_3_IN',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_4_IN',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_5_IN',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_6_IN',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_7_IN',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_8_IN',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_9_IN',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_10_IN',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_11_IN',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_12_IN',
            relatedClass: ['class12'],
        },

        {
            id: 'TEMA_HE_ANIMAL_1_NOT',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_2_NOT',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_3_NOT',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_4_NOT',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_5_NOT',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_6_NOT',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_7_NOT',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_8_NOT',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_9_NOT',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_10_NOT',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_11_NOT',
            relatedClass: ['class12'],
        },
        {
            id: 'TEMA_HE_ANIMAL_12_NOT',
            relatedClass: ['class12'],
        },
    ],
    [
        {
            id: 'ANIMAL_LIAN_2_IN',
            relatedClass: ['class13'],
        },
        {
            id: 'ANIMAL_LIAN_2_IN_BEN',
            relatedClass: ['class13'],
        },
        {
            id: 'ANIMAL_LIAN_2_NOT',
            relatedClass: ['class13'],
        },
        {
            id: 'ANIMAL_LIAN_2_NOT_BEN',
            relatedClass: ['class13'],
        },
    ],
    [
        {
            id: 'ANIMAL_LIAN_3_IN',
            relatedClass: ['class14'],
        },
        {
            id: 'ANIMAL_LIAN_3_IN_BEN',
            relatedClass: ['class14'],
        },
        {
            id: 'ANIMAL_LIAN_3_NOT',
            relatedClass: ['class14'],
        },
        {
            id: 'ANIMAL_LIAN_3_NOT_BEN',
            relatedClass: ['class14'],
        },
    ],
    [
        {
            id: 'ANIMAL_LIAN_4_IN',
            relatedClass: ['class15'],
        },
        {
            id: 'ANIMAL_LIAN_4_IN_BEN',
            relatedClass: ['class15'],
        },
        {
            id: 'ANIMAL_LIAN_4_NOT',
            relatedClass: ['class15'],
        },
        {
            id: 'ANIMAL_LIAN_4_NOT_BEN',
            relatedClass: ['class15'],
        },
    ],
    [
        {
            id: 'ANIMAL_LIAN_5_IN',
            relatedClass: ['class16'],
        },
        {
            id: 'ANIMAL_LIAN_5_IN_BEN',
            relatedClass: ['class16'],
        },
        {
            id: 'ANIMAL_LIAN_5_NOT',
            relatedClass: ['class16'],
        },
        {
            id: 'ANIMAL_LIAN_5_NOT_BEN',
            relatedClass: ['class16'],
        },
    ],
    [
        {
            id: 'COLOR_RED_DA',
            relatedClass: ['class17'],
        },
        {
            id: 'COLOR_RED_XIAO',
            relatedClass: ['class17'],
        },
        {
            id: 'COLOR_RED_DAN',
            relatedClass: ['class17'],
        },
        {
            id: 'COLOR_RED_SHUANG',
            relatedClass: ['class17'],
        },

        {
            id: 'COLOR_BLUE_DA',
            relatedClass: ['class17'],
        },
        {
            id: 'COLOR_BLUE_XIAO',
            relatedClass: ['class17'],
        },
        {
            id: 'COLOR_BLUE_DAN',
            relatedClass: ['class17'],
        },
        {
            id: 'COLOR_BLUE_SHUANG',
            relatedClass: ['class17'],
        },

        {
            id: 'COLOR_GREEN_DA',
            relatedClass: ['class17'],
        },
        {
            id: 'COLOR_GREEN_XIAO',
            relatedClass: ['class17'],
        },
        {
            id: 'COLOR_GREEN_DAN',
            relatedClass: ['class17'],
        },
        {
            id: 'COLOR_GREEN_SHUANG',
            relatedClass: ['class17'],
        },
    ],
    [
        {
            id: 'LIANMA_4_ALLIN',
            relatedClass: ['class18'],
        },
    ],
    [
        {
            id: 'LIANMA_3_ALLIN',
            relatedClass: ['class19'],
        },
    ],
    [
        {
            id: 'LIANMA_3_IN_2',
            relatedClass: ['class20'],
        },
        {
            id: 'LIANMA_3_IN_3',
            relatedClass: ['class20'],
        },
    ],
    [
        {
            id: 'LIANMA_2_ALLIN',
            relatedClass: ['class21'],
        },
        {
            id: 'LIANMA_2_IN_2',
            relatedClass: ['class21'],
        },
    ],
    [
        {
            id: 'LIANMA_2_IN_TEMA',
            relatedClass: ['class22'],
        },
    ],
    [
        {
            id: 'LIANMA_1_IN_TEMA',
            relatedClass: ['class23'],
        },
    ],
    [
        {
            id: 'WEI_LIAN_2_IN',
            relatedClass: ['class24'],
        },
        {
            id: 'WEI_LIAN_2_IN_0',
            relatedClass: ['class24'],
        },
        {
            id: 'WEI_LIAN_2_NOT',
            relatedClass: ['class24'],
        },
        {
            id: 'WEI_LIAN_2_NOT_0',
            relatedClass: ['class24'],
        },
    ],
    [
        {
            id: 'WEI_LIAN_3_IN',
            relatedClass: ['class25'],
        },
        {
            id: 'WEI_LIAN_3_IN_0',
            relatedClass: ['class25'],
        },
        {
            id: 'WEI_LIAN_3_NOT',
            relatedClass: ['class25'],
        },
        {
            id: 'WEI_LIAN_3_NOT_0',
            relatedClass: ['class25'],
        },
        
    ],    
    [
        {
            id: 'WEI_LIAN_4_IN',
            relatedClass: ['class26'],
        },
        {
            id: 'WEI_LIAN_4_IN_0',
            relatedClass: ['class26'],
        },
        {
            id: 'WEI_LIAN_4_NOT',
            relatedClass: ['class26'],
        },
        {
            id: 'WEI_LIAN_4_NOT_0',
            relatedClass: ['class26'],
        },
    ],
    [
        {
            id: 'ALLNOTIN_5',
            relatedClass: ['class27'],
        },
    ],
    [
        {
            id: 'ALLNOTIN_6',
            relatedClass: ['class28'],
        },
    ],
    [
        {
            id: 'ALLNOTIN_7',
            relatedClass: ['class29'],
        },
    ],
    [
        {
            id: 'ALLNOTIN_8',
            relatedClass: ['class30'],
        },
    ],
    [
        {
            id: 'ALLNOTIN_9',
            relatedClass: ['class31'],
        },
    ],
    [
        {
            id: 'ALLNOTIN_10',
            relatedClass: ['class32'],
        },
    ],
    [
        {
            id: 'ALLNOTIN_11',
            relatedClass: ['class33'],
        },
    ],
    [
        {
            id: 'ALLNOTIN_12',
            relatedClass: ['class34'],
        },
    ],
]
const lhcBanBoRule = [
    {
        id:'COLOR_RED_DA',
        color:'#E83535',
        related:['29','30','34','35','40','45','46'],
    },
    {
        id:'COLOR_RED_XIAO',
        color:'#E83535',
        related:['01','02','07','08','12','13','18','19','23','24'],
    },
    {
        id:'COLOR_RED_DAN',
        color:'#E83535',
        related:['01','07','13','19','23','29','35','45'],
    },
    {
        id:'COLOR_RED_SHUANG',
        color:'#E83535',
        related:['02','08','12','18','24','30','34','40','46'],
    },
    
    {
        id:'COLOR_BLUE_DA',
        color:'#3985E1',
        related:['25','26','31','36','37','41','42','47','48'],
    },
    {
        id:'COLOR_BLUE_XIAO',
        color:'#3985E1',
        related:['03','04','09','10','14','15','20'],
    },
    {
        id:'COLOR_BLUE_DAN',
        color:'#3985E1',
        related:['03','09','15','25','31','37','41','47'],
    },
    {
        id:'COLOR_BLUE_SHUANG',
        color:'#3985E1',
        related:['04','10','14','20','26','36','42','48'],
    },
    
    {
        id:'COLOR_GREEN_DA',
        color:'#48BA2B',
        related:['27','28','32','33','38','39','43','44','49'],
    },
    {
        id:'COLOR_GREEN_XIAO',
        color:'#48BA2B',
        related:['05','06','11','16','17','21','22'],
    },
    {
        id:'COLOR_GREEN_DAN',
        color:'#48BA2B',
        related:['05','11','17','21','27','33','39','43','49'],
    },
    {
        id:'COLOR_GREEN_SHUANG',
        color:'#48BA2B',
        related:['06','16','22','28','32','38','44']
    }
]
const lhcColorRule = [
    {
        name:'red',
        color:'#E83535',
        related:['红波','01','02','07','08','12','13','18','19','23','24','29','30','34','35','40','45','46'],    
    },
    {
        name:'blue',
        color:'#3985E1',
        related:['蓝波','03','04','09','10','14','15','20','25','26','31','36','37','41','42','47','48'],
    },
    {
        name:'green',
        color:'#48BA2B',
        related:['绿波','05','06','11','16','17','21','22','27','28','32','33','38','39','43','44','49'],
    }
]
const lhcWeiLianRule = function(){
    var data=[
        {
            title: getWord('weishu')+'1',
            related:['01','11','21','31','41'],
            select:false,
        },
        {
            title: getWord('weishu')+'2',
            related:['02','12','22','32','42'],
            select:false,
        },
        {
            title: getWord('weishu')+'3',
            related:['03','13','23','33','43'],
            select:false,
        },
        {
            title: getWord('weishu')+'4',
            related:['04','14','24','34','44'],
            select:false,
        },
        {
            title: getWord('weishu')+'5',
            related:['05','15','25','35','45'],
            select:false,
        },
        {
            title: getWord('weishu')+'6',
            related:['06','16','26','36','46'],
            select:false,
        },
        {
            title: getWord('weishu')+'7',
            related:['07','17','27','37','47'],
            select:false,
        },
        {
            title: getWord('weishu')+'8',
            related:['08','18','28','38','48'],
            select:false,
        },
        {
            title: getWord('weishu')+'9',
            related:['09','19','29','39','49'],
            select:false,
        },
        {
            title: getWord('weishu')+'0',
            related:['10','20','30','40'],
            select:false,
            special:true,
        },
    ]
    data.forEach(item=>{
        item.related=formatNumbers(item.related)
    })    
    return data
}

const lhcZodiacSeries=function(){
    return [
        {
            title: getWord('yeshou'),
            relatedIndex:[1,3,4,5,6,9],
            select: false,
        },
        {
            title: getWord('jiaqin'),
            relatedIndex:[2,7,8,10,11,12],
            select: false,
        },
        {
            title: getWord('zuo'),
            relatedIndex:[1,3,5,7,9,11],
            select: false,
        },
        {
            title: getWord('you'),
            relatedIndex:[2,4,6,8,10,12],
            select: false,
        },
        {
            title: getWord('qianxiao'),
            relatedIndex:[1,2,3,4,5,6],
            select: false,
        },
        {
            title: getWord('houxiao'),
            relatedIndex:[7,8,9,10,11,12],
            select: false,
        },
        {
            title: getWord('tianxiao'),
            relatedIndex:[2,4,5,7,9,12],
            select: false,
        },
        {
            title: getWord('dixiao'),
            relatedIndex:[1,3,6,8,10,11],
            select: false,
        },
    ]
}
const lhcZodiacList=function(){
    return [
        {
            id:'SHU',
            title: getWord('rat'),
            select: false,
        },
        {
            id:'NIU',
            title: getWord('ox'),
            select: false,
        },
        {
            id:'HU',
            title: getWord('hu'),
            select: false,
        },
        {
            id:'TU',
            title: getWord('hare'),
            select: false,
        },
        {
            id:'LONG',
            title: getWord('long'),
            select: false,
        },
        {
            id:'SHE',
            title: getWord('snake'),
            select: false,
        },
        {
            id:'MA',
            title: getWord('horse'),
            select: false,
        },
        {
            id:'YANG',
            title: getWord('sheep'),
            select: false,
        },
        {
            id:'HOU',
            title: getWord('monkey'),
            select: false,
        },
        {
            id:'JI',
            title: getWord('rooster'),
            select: false,
        },
        {
            id:'GOU',
            title: getWord('dog'),
            select: false,
        },
        {
            id:'ZHU',
            title: getWord('boar'),
            select: false,
        },
    ]
}
const lhcNumberList=[]
function createLhcNumber(){
    for (var i=1;i<50;i++) {
        lhcNumberList.push({
            title:i<10?'0'+i:i.toString(),
            select:false,
            odds:''
        })
    }

    lhcColorRule.forEach(item => {
        var _color = item.color;
        var _name = item.name;
        item.related.forEach(item => {
            var index = lhcNumberList.filter(data => {                  
                return data.title === item
            }).forEach(item=>{
                item.color = _color;
                item.colorName = _name;
            })
        })
    })
}
createLhcNumber();

const pk10ColorRule = [
    {
        color: '#ffffff',
        bgColor: '#FFF600',
        related: ['01']
    },
    {
        color: '#ffffff',
        bgColor: '#008FFF',
        related: ['02']
    },
    {
        color: '#ffffff',
        bgColor: '#4B4B4B',
        related: ['03']
    },
    {
        color: '#ffffff',
        bgColor: '#FF7600',
        related: ['04']
    },
    {
        color: '#ffffff',
        bgColor: '#7CFDFF',
        related: ['05']
    },
    {
        color: '#ffffff',
        bgColor: '#5234FF',
        related: ['06']
    },
    {
        color: '#ffffff',
        bgColor: '#E4E4E4',
        related: ['07']
    },
    {
        color: '#ffffff',
        bgColor: '#EC2829',
        related: ['08']
    },
    {
        color: '#ffffff',
        bgColor: '#780B00',
        related: ['09']
    },
    {
        color: '#ffffff',
        bgColor: '#52B653',
        related: ['10']
    }
]
const pk10ClassRule = [
    {
        class: '数字盘',
        id: 'class1'
    },
    {
        class: '两面盘',
        id: 'class2'
    },
    {
        class: '冠亚军和',
        id: 'class3'
    },
    {
        class: '冠亚军组合',
        id: 'class4'
    },
    {
        class: '1~5名',
        id: 'class5'
    },
    {
        class: '6~10名',
        id: 'class6'
    },
]
const pk10TypeTitle = ['冠亚军组合', '冠亚军和', '冠军', '亚军', '第三名', '第四名', '第五名', '第六名', '第七名', '第八名', '第九名', '第十名']
const pk10TypeRule = [
    [
        {
            id: 'GUANYAZUHE12',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE13',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE14',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE15',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE16',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE17',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE18',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE19',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE110',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE23',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE24',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE25',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE26',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE27',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE28',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE29',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE210',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE34',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE35',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE36',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE37',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE38',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE39',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE310',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE45',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE46',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE47',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE48',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE49',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE410',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE56',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE57',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE58',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE59',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE510',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE67',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE68',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE69',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE610',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE78',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE79',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE710',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE89',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE810',
            relatedClass: ['class4'],
        },
        {
            id: 'GUANYAZUHE910',
            relatedClass: ['class4'],
        },
    ],
    [
        {
            id: 'GUANYAHEDA',
            relatedClass: ['class2', 'class3'],
        },
        {
            id: 'GUANYAHEXIAO',
            relatedClass: ['class2', 'class3'],
        },
        {
            id: 'GUANYAHEDAN',
            relatedClass: ['class2', 'class3'],
        },
        {
            id: 'GUANYAHESHUANG',
            relatedClass: ['class2', 'class3'],
        },
        {
            id: 'GUANYAHE3',
            relatedClass: ['class3'],
        },
        {
            id: 'GUANYAHE4',
            relatedClass: ['class3'],
        },
        {
            id: 'GUANYAHE5',
            relatedClass: ['class3'],
        },
        {
            id: 'GUANYAHE6',
            relatedClass: ['class3'],
        },
        {
            id: 'GUANYAHE7',
            relatedClass: ['class3'],
        },
        {
            id: 'GUANYAHE8',
            relatedClass: ['class3'],
        },
        {
            id: 'GUANYAHE9',
            relatedClass: ['class3'],
        },
        {
            id: 'GUANYAHE10',
            relatedClass: ['class3'],
        },
        {
            id: 'GUANYAHE11',
            relatedClass: ['class3'],
        },
        {
            id: 'GUANYAHE12',
            relatedClass: ['class3'],
        },
        {
            id: 'GUANYAHE13',
            relatedClass: ['class3'],
        },
        {
            id: 'GUANYAHE14',
            relatedClass: ['class3'],
        },
        {
            id: 'GUANYAHE15',
            relatedClass: ['class3'],
        },
        {
            id: 'GUANYAHE16',
            relatedClass: ['class3'],
        },
        {
            id: 'GUANYAHE17',
            relatedClass: ['class3'],
        },
        {
            id: 'GUANYAHE18',
            relatedClass: ['class3'],
        },
        {
            id: 'GUANYAHE19',
            relatedClass: ['class3'],
        },
    ],
    [
        {
            id: 'ONEDA',
            relatedClass: ['class2', 'class5'],
        },
        {
            id: 'ONEXIAO',
            relatedClass: ['class2', 'class5'],
        },
        {
            id: 'ONEDAN',
            relatedClass: ['class2', 'class5'],
        },
        {
            id: 'ONESHUANG',
            relatedClass: ['class2', 'class5'],
        },
        {
            id: 'GUANJUNLONG',
            relatedClass: ['class2', 'class5'],
        },
        {
            id: 'GUANJUNHU',
            relatedClass: ['class2', 'class5'],
        },
        {
            id: 'ONE',
            relatedClass: ['class1', 'class5'],
        },
    ],
    [
        {
            id: 'TWODA',
            relatedClass: ['class2', 'class5'],
        },
        {
            id: 'TWOXIAO',
            relatedClass: ['class2', 'class5'],
        },
        {
            id: 'TWODAN',
            relatedClass: ['class2', 'class5'],
        },
        {
            id: 'TWOSHUANG',
            relatedClass: ['class2', 'class5'],
        },
        {
            id: 'YAJUNLONG',
            relatedClass: ['class2', 'class5'],
        },
        {
            id: 'YAJUNHU',
            relatedClass: ['class2', 'class5'],
        },
        {
            id: 'TWO',
            relatedClass: ['class1', 'class5'],
        },
    ],
    [
        {
            id: 'THREEDA',
            relatedClass: ['class2', 'class5'],
        },
        {
            id: 'THREEXIAO',
            relatedClass: ['class2', 'class5'],
        },
        {
            id: 'THREEDAN',
            relatedClass: ['class2', 'class5'],
        },
        {
            id: 'THREESHUANG',
            relatedClass: ['class2', 'class5'],
        },
        {
            id: 'JIJUNLONG',
            relatedClass: ['class2', 'class5'],
        },
        {
            id: 'JIJUNHU',
            relatedClass: ['class2', 'class5'],
        },
        {
            id: 'THREE',
            relatedClass: ['class1', 'class5'],
        },
    ],
    [
        {
            id: 'FOURDA',
            relatedClass: ['class2', 'class5'],
        },
        {
            id: 'FOURXIAO',
            relatedClass: ['class2', 'class5'],
        },
        {
            id: 'FOURDAN',
            relatedClass: ['class2', 'class5'],
        },
        {
            id: 'FOURSHUANG',
            relatedClass: ['class2', 'class5'],
        },
        {
            id: 'FOURLONG',
            relatedClass: ['class2', 'class5'],
        },
        {
            id: 'FOURHU',
            relatedClass: ['class2', 'class5'],
        },
        {
            id: 'FOUR',
            relatedClass: ['class1', 'class5'],
        },
    ],
    [
        {
            id: 'FIVEDA',
            relatedClass: ['class2', 'class5'],
        },
        {
            id: 'FIVEXIAO',
            relatedClass: ['class2', 'class5'],
        },
        {
            id: 'FIVEDAN',
            relatedClass: ['class2', 'class5'],
        },
        {
            id: 'FIVESHUANG',
            relatedClass: ['class2', 'class5'],
        },
        {
            id: 'FIVELONG',
            relatedClass: ['class2', 'class5'],
        },
        {
            id: 'FIVEHU',
            relatedClass: ['class2', 'class5'],
        },
        {
            id: 'FIVE',
            relatedClass: ['class1', 'class5'],
        },
    ],
    [
        {
            id: 'SIXDA',
            relatedClass: ['class2', 'class6'],
        },
        {
            id: 'SIXXIAO',
            relatedClass: ['class2', 'class6'],
        },
        {
            id: 'SIXDAN',
            relatedClass: ['class2', 'class6'],
        },
        {
            id: 'SIXSHUANG',
            relatedClass: ['class2', 'class6'],
        },
        {
            id: 'SIX',
            relatedClass: ['class1', 'class6'],
        },
    ],
    [
        {
            id: 'SEVENDA',
            relatedClass: ['class2', 'class6'],
        },
        {
            id: 'SEVENXIAO',
            relatedClass: ['class2', 'class6'],
        },
        {
            id: 'SEVENDAN',
            relatedClass: ['class2', 'class6'],
        },
        {
            id: 'SEVENSHUANG',
            relatedClass: ['class2', 'class6'],
        },
        {
            id: 'SEVEN',
            relatedClass: ['class1', 'class6'],
        },
    ],
    [
        {
            id: 'EIGHTDA',
            relatedClass: ['class2', 'class6'],
        },
        {
            id: 'EIGHTXIAO',
            relatedClass: ['class2', 'class6'],
        },
        {
            id: 'EIGHTDAN',
            relatedClass: ['class2', 'class6'],
        },
        {
            id: 'EIGHTSHUANG',
            relatedClass: ['class2', 'class6'],
        },
        {
            id: 'EIGHT',
            relatedClass: ['class1', 'class6'],
        },
    ],
    [
        {
            id: 'NINEDA',
            relatedClass: ['class2', 'class6'],
        },
        {
            id: 'NINEXIAO',
            relatedClass: ['class2', 'class6'],
        },
        {
            id: 'NINEDAN',
            relatedClass: ['class2', 'class6'],
        },
        {
            id: 'NINESHUANG',
            relatedClass: ['class2', 'class6'],
        },
        {
            id: 'NINE',
            relatedClass: ['class1', 'class6'],
        },
    ],
    [
        {
            id: 'TENDA',
            relatedClass: ['class2', 'class6', ],
        },
        {
            id: 'TENXIAO',
            relatedClass: ['class2', 'class6'],
        },
        {
            id: 'TENDAN',
            relatedClass: ['class2', 'class6'],
        },
        {
            id: 'TENSHUANG',
            relatedClass: ['class2', 'class6'],
        },
        {
            id: 'TEN',
            relatedClass: ['class1', 'class6'],
        },
    ],
]

const xyncColorRule = [
    {
        color: '#ffffff',
        bgColor: '#4D4D4D',
        related: ['01']
    },
    {
        color: '#ffffff',
        bgColor: '#FFEE48',
        related: ['02']
    },
    {
        color: '#ffffff',
        bgColor: '#4712B7',
        related: ['03']
    },
    {
        color: '#ffffff',
        bgColor: '#3985E1',
        related: ['04']
    },
    {
        color: '#ffffff',
        bgColor: '#46CCC9',
        related: ['05']
    },
    {
        color: '#ffffff',
        bgColor: '#ED7428',
        related: ['06']
    },
    {
        color: '#ffffff',
        bgColor: '#48BA2B',
        related: ['07']
    },
    {
        color: '#ffffff',
        bgColor: '#740100',
        related: ['08']
    },
    {
        color: '#ffffff',
        bgColor: '#E83535',
        related: ['09']
    },
    {
        color: '#ffffff',
        bgColor: '#B9B9B7',
        related: ['10']
    }
]
const xyncClassRule = [
    {
        class: '两面盘',
        id: 'class1'
    },
    {
        class: '单号1-8',
        id: 'class2'
    },
    {
        class: '正码',
        id: 'class3'
    },
    {
        class: '连码',
        id: 'class4'
    },
    {
        class: '第一球',
        id: 'class5'
    },
    {
        class: '第二球',
        id: 'class6'
    },
    {
        class: '第三球',
        id: 'class7'
    },
    {
        class: '第四球',
        id: 'class8'
    },
    {
        class: '第五球',
        id: 'class9'
    },
    {
        class: '第六球',
        id: 'class10'
    },
    {
        class: '第七球',
        id: 'class11'
    },
    {
        class: '第八球',
        id: 'class12'
    },
]
const TEMPLATE3_xyncClassRule = [
    {
        class: '两面盘',
        id: 'class1'
    },
    {
        class: '单号1-8',
        id: 'class2'
    },
    {
        class: '正码',
        id: 'class3'
    },
    {
        class: '任选二',
        id: 'class4'
    },
    {
        class: '选二连组',
        id: 'class5'
    },
    {
        class: '任选三',
        id: 'class6'
    },
    {
        class: '选三前组',
        id: 'class7'
    },
    {
        class: '任选四',
        id: 'class8'
    },
    {
        class: '任选五',
        id: 'class9'
    },
    {
        class: '第一球',
        id: 'class10'
    },
    {
        class: '第二球',
        id: 'class11'
    },
    {
        class: '第三球',
        id: 'class12'
    },
    {
        class: '第四球',
        id: 'class13'
    },
    {
        class: '第五球',
        id: 'class14'
    },
    {
        class: '第六球',
        id: 'class15'
    },
    {
        class: '第七球',
        id: 'class16'
    },
    {
        class: '第八球',
        id: 'class17'
    },
]
const xyncTypeTitle = [
    '总和', '第一球', '第二球', '第三球', '第四球', '第五球', '第六球', '第七球', '第八球', 
    '第一球', '第二球', '第三球', '第四球', '第五球', '第六球', '第七球', '第八球', 
    '正码', 
    '连码', 
    '第一球', '第二球', '第三球', '第四球', '第五球', '第六球', '第七球', '第八球'
]
const TEMPLATE3_xyncTypeTitle = [
    '总和', '第一球', '第二球', '第三球', '第四球', '第五球', '第六球', '第七球', '第八球', 
    '第一球', '第二球', '第三球', '第四球', '第五球', '第六球', '第七球', '第八球', 
    '正码', 
    '任选二', '选二连组','任选三','选三前组','任选四','任选五',
    '第一球', '第二球', '第三球', '第四球', '第五球', '第六球', '第七球', '第八球'
]
const xyncTypeRule = [
    [
        {
            id: 'TOTAL_DA',
            relatedClass: ['class1','class3'],
        },
        {
            id: 'TOTAL_XIAO',
            relatedClass: ['class1','class3'],
        },
        {
            id: 'TOTAL_DAN',
            relatedClass: ['class1','class3'],
        },
        {
            id: 'TOTAL_SHUANG',
            relatedClass: ['class1','class3'],
        },
        {
            id: 'TOTAL_WEI_DA',
            relatedClass: ['class1','class3'],
        },
        {
            id: 'TOTAL_WEI_XIAO',
            relatedClass: ['class1','class3'],
        },
    ],
    [
        {
            id: 'ONE_DA',
            relatedClass: ['class1','class5'],
        },
        {
            id: 'ONE_XIAO',
            relatedClass: ['class1','class5'],
        },
        {
            id: 'ONE_DAN',
            relatedClass: ['class1','class5'],
        },
        {
            id: 'ONE_SHUANG',
            relatedClass: ['class1','class5'],
        },
        {
            id: 'ONE_LONG',
            relatedClass: ['class1','class5'],
        },
        {
            id: 'ONE_HU',
            relatedClass: ['class1','class5'],
        },
        {
            id: 'ONE_HE_DAN',
            relatedClass: ['class1','class5'],
        },
        {
            id: 'ONE_HE_SHUANG',
            relatedClass: ['class1','class5'],
        },
        {
            id: 'ONE_WEI_DA',
            relatedClass: ['class1','class5'],
        },
        {
            id: 'ONE_WEI_XIAO',
            relatedClass: ['class1','class5'],
        },
    ],
    [
        {
            id: 'TWO_DA',
            relatedClass: ['class1','class6'],
        },
        {
            id: 'TWO_XIAO',
            relatedClass: ['class1','class6'],
        },
        {
            id: 'TWO_DAN',
            relatedClass: ['class1','class6'],
        },
        {
            id: 'TWO_SHUANG',
            relatedClass: ['class1','class6'],
        },
        {
            id: 'TWO_LONG',
            relatedClass: ['class1','class6'],
        },
        {
            id: 'TWO_HU',
            relatedClass: ['class1','class6'],
        },
        {
            id: 'TWO_HE_DAN',
            relatedClass: ['class1','class6'],
        },
        {
            id: 'TWO_HE_SHUANG',
            relatedClass: ['class1','class6'],
        },
        {
            id: 'TWO_WEI_DA',
            relatedClass: ['class1','class6'],
        },
        {
            id: 'TWO_WEI_XIAO',
            relatedClass: ['class1','class6'],
        },
    ],
    [
        {
            id: 'THREE_DA',
            relatedClass: ['class1','class7'],
        },
        {
            id: 'THREE_XIAO',
            relatedClass: ['class1','class7'],
        },
        {
            id: 'THREE_DAN',
            relatedClass: ['class1','class7'],
        },
        {
            id: 'THREE_SHUANG',
            relatedClass: ['class1','class7'],
        },
        {
            id: 'THREE_LONG',
            relatedClass: ['class1','class7'],
        },
        {
            id: 'THREE_HU',
            relatedClass: ['class1','class7'],
        }, 
        {
            id: 'THREE_HE_DAN',
            relatedClass: ['class1','class7'],
        },
        {
            id: 'THREE_HE_SHUANG',
            relatedClass: ['class1','class7'],
        },
        {
            id: 'THREE_WEI_DA',
            relatedClass: ['class1','class7'],
        },
        {
            id: 'THREE_WEI_XIAO',
            relatedClass: ['class1','class7'],
        },
    ],
    [
        {
            id: 'FOUR_DA',
            relatedClass: ['class1','class8'],
        },
        {
            id: 'FOUR_XIAO',
            relatedClass: ['class1','class8'],
        },
        {
            id: 'FOUR_DAN',
            relatedClass: ['class1','class8'],
        },
        {
            id: 'FOUR_SHUANG',
            relatedClass: ['class1','class8'],
        },
        {
            id: 'FOUR_LONG',
            relatedClass: ['class1','class8'],
        },
        {
            id: 'FOUR_HU',
            relatedClass: ['class1','class8'],
        }, 
        {
            id: 'FOUR_HE_DAN',
            relatedClass: ['class1','class8'],
        },
        {
            id: 'FOUR_HE_SHUANG',
            relatedClass: ['class1','class8'],
        },
        {
            id: 'FOUR_WEI_DA',
            relatedClass: ['class1','class8'],
        },
        {
            id: 'FOUR_WEI_XIAO',
            relatedClass: ['class1','class8'],
        },
    ],
    [
        {
            id: 'FIVE_DA',
            relatedClass: ['class1','class9'],
        },
        {
            id: 'FIVE_XIAO',
            relatedClass: ['class1','class9'],
        },
        {
            id: 'FIVE_DAN',
            relatedClass: ['class1','class9'],
        },
        {
            id: 'FIVE_SHUANG',
            relatedClass: ['class1','class9'],
        },
        {
            id: 'FIVE_LONG',
            relatedClass: ['class1','class9'],
        },
        {
            id: 'FIVE_HU',
            relatedClass: ['class1','class9'],
        },
        {
            id: 'FIVE_HE_DAN',
            relatedClass: ['class1','class9'],
        },
        {
            id: 'FIVE_HE_SHUANG',
            relatedClass: ['class1','class9'],
        },        
        {
            id: 'FIVE_WEI_DA',
            relatedClass: ['class1','class9'],
        },
        {
            id: 'FIVE_WEI_XIAO',
            relatedClass: ['class1','class9'],
        },
    ],
    [
        {
            id: 'SIX_DA',
            relatedClass: ['class1','class10'],
        },
        {
            id: 'SIX_XIAO',
            relatedClass: ['class1','class10'],
        },
        {
            id: 'SIX_DAN',
            relatedClass: ['class1','class10'],
        },
        {
            id: 'SIX_SHUANG',
            relatedClass: ['class1','class10'],
        },
        {
            id: 'SIX_LONG',
            relatedClass: ['class1','class10'],
        },
        {
            id: 'SIX_HU',
            relatedClass: ['class1','class10'],
        },        
        {
            id: 'SIX_HE_DAN',
            relatedClass: ['class1','class10'],
        },
        {
            id: 'SIX_HE_SHUANG',
            relatedClass: ['class1','class10'],
        },
        {
            id: 'SIX_WEI_DA',
            relatedClass: ['class1','class10'],
        },
        {
            id: 'SIX_WEI_XIAO',
            relatedClass: ['class1','class10'],
        },
    ],
    [
        {
            id: 'SEVEN_DA',
            relatedClass: ['class1','class11'],
        },
        {
            id: 'SEVEN_XIAO',
            relatedClass: ['class1','class11'],
        },
        {
            id: 'SEVEN_DAN',
            relatedClass: ['class1','class11'],
        },
        {
            id: 'SEVEN_SHUANG',
            relatedClass: ['class1','class11'],
        },
        {
            id: 'SEVEN_LONG',
            relatedClass: ['class1','class11'],
        },
        {
            id: 'SEVEN_HU',
            relatedClass: ['class1','class11'],
        },
        {
            id: 'SEVEN_HE_DAN',
            relatedClass: ['class1','class11'],
        },
        {
            id: 'SEVEN_HE_SHUANG',
            relatedClass: ['class1','class11'],
        },
        {
            id: 'SEVEN_WEI_DA',
            relatedClass: ['class1','class11'],
        },
        {
            id: 'SEVEN_WEI_XIAO',
            relatedClass: ['class1','class11'],
        },
    ],
    [
        {
            id: 'EIGHT_DA',
            relatedClass: ['class1','class12'],
        },
        {
            id: 'EIGHT_XIAO',
            relatedClass: ['class1','class12'],
        },
        {
            id: 'EIGHT_DAN',
            relatedClass: ['class1','class12'],
        },
        {
            id: 'EIGHT_SHUANG',
            relatedClass: ['class1','class12'],
        },
        {
            id: 'EIGHT_LONG',
            relatedClass: ['class1','class12'],
        },
        {
            id: 'EIGHT_HU',
            relatedClass: ['class1','class12'],
        },
        {
            id: 'EIGHT_HE_DAN',
            relatedClass: ['class1','class12'],
        },
        {
            id: 'EIGHT_HE_SHUANG',
            relatedClass: ['class1','class12'],
        },
        {
            id: 'EIGHT_WEI_DA',
            relatedClass: ['class1','class12'],
        },
        {
            id: 'EIGHT_WEI_XIAO',
            relatedClass: ['class1','class12'],
        },
    ],

    [
        {
            id: 'ONE_01',
            relatedClass: ['class2','class5'],
        },
        {
            id: 'ONE_02',
            relatedClass: ['class2','class5'],
        },
        {
            id: 'ONE_03',
            relatedClass: ['class2','class5'],
        },
        {
            id: 'ONE_04',
            relatedClass: ['class2','class5'],
        },
        {
            id: 'ONE_05',
            relatedClass: ['class2','class5'],
        },
        {
            id: 'ONE_06',
            relatedClass: ['class2','class5'],
        },
        {
            id: 'ONE_07',
            relatedClass: ['class2','class5'],
        },
        {
            id: 'ONE_08',
            relatedClass: ['class2','class5'],
        },
        {
            id: 'ONE_09',
            relatedClass: ['class2','class5'],
        },
        {
            id: 'ONE_10',
            relatedClass: ['class2','class5'],
        },
        {
            id: 'ONE_11',
            relatedClass: ['class2','class5'],
        },
        {
            id: 'ONE_12',
            relatedClass: ['class2','class5'],
        },
        {
            id: 'ONE_13',
            relatedClass: ['class2','class5'],
        },
        {
            id: 'ONE_14',
            relatedClass: ['class2','class5'],
        },
        {
            id: 'ONE_15',
            relatedClass: ['class2','class5'],
        },
        {
            id: 'ONE_16',
            relatedClass: ['class2','class5'],
        },
        {
            id: 'ONE_17',
            relatedClass: ['class2','class5'],
        },
        {
            id: 'ONE_18',
            relatedClass: ['class2','class5'],
        },
        {
            id: 'ONE_19',
            relatedClass: ['class2','class5'],
        },
        {
            id: 'ONE_20',
            relatedClass: ['class2','class5'],
        },
    ],
    [
        {
            id: 'TWO_01',
            relatedClass: ['class2','class6'],
        },
        {
            id: 'TWO_02',
            relatedClass: ['class2','class6'],
        },
        {
            id: 'TWO_03',
            relatedClass: ['class2','class6'],
        },
        {
            id: 'TWO_04',
            relatedClass: ['class2','class6'],
        },
        {
            id: 'TWO_05',
            relatedClass: ['class2','class6'],
        },
        {
            id: 'TWO_06',
            relatedClass: ['class2','class6'],
        },
        {
            id: 'TWO_07',
            relatedClass: ['class2','class6'],
        },
        {
            id: 'TWO_08',
            relatedClass: ['class2','class6'],
        },
        {
            id: 'TWO_09',
            relatedClass: ['class2','class6'],
        },
        {
            id: 'TWO_10',
            relatedClass: ['class2','class6'],
        },
        {
            id: 'TWO_11',
            relatedClass: ['class2','class6'],
        },
        {
            id: 'TWO_12',
            relatedClass: ['class2','class6'],
        },
        {
            id: 'TWO_13',
            relatedClass: ['class2','class6'],
        },
        {
            id: 'TWO_14',
            relatedClass: ['class2','class6'],
        },
        {
            id: 'TWO_15',
            relatedClass: ['class2','class6'],
        },
        {
            id: 'TWO_16',
            relatedClass: ['class2','class6'],
        },
        {
            id: 'TWO_17',
            relatedClass: ['class2','class6'],
        },
        {
            id: 'TWO_18',
            relatedClass: ['class2','class6'],
        },
        {
            id: 'TWO_19',
            relatedClass: ['class2','class6'],
        },
        {
            id: 'TWO_20',
            relatedClass: ['class2','class6'],
        },
    ],
    [
        {
            id: 'THREE_01',
            relatedClass: ['class2','class7'],
        },
        {
            id: 'THREE_02',
            relatedClass: ['class2','class7'],
        },
        {
            id: 'THREE_03',
            relatedClass: ['class2','class7'],
        },
        {
            id: 'THREE_04',
            relatedClass: ['class2','class7'],
        },
        {
            id: 'THREE_05',
            relatedClass: ['class2','class7'],
        },
        {
            id: 'THREE_06',
            relatedClass: ['class2','class7'],
        },
        {
            id: 'THREE_07',
            relatedClass: ['class2','class7'],
        },
        {
            id: 'THREE_08',
            relatedClass: ['class2','class7'],
        },
        {
            id: 'THREE_09',
            relatedClass: ['class2','class7'],
        },
        {
            id: 'THREE_10',
            relatedClass: ['class2','class7'],
        },
        {
            id: 'THREE_11',
            relatedClass: ['class2','class7'],
        },
        {
            id: 'THREE_12',
            relatedClass: ['class2','class7'],
        },
        {
            id: 'THREE_13',
            relatedClass: ['class2','class7'],
        },
        {
            id: 'THREE_14',
            relatedClass: ['class2','class7'],
        },
        {
            id: 'THREE_15',
            relatedClass: ['class2','class7'],
        },
        {
            id: 'THREE_16',
            relatedClass: ['class2','class7'],
        },
        {
            id: 'THREE_17',
            relatedClass: ['class2','class7'],
        },
        {
            id: 'THREE_18',
            relatedClass: ['class2','class7'],
        },
        {
            id: 'THREE_19',
            relatedClass: ['class2','class7'],
        },
        {
            id: 'THREE_20',
            relatedClass: ['class2','class7'],
        },
    ],
    [
        {
            id: 'FOUR_01',
            relatedClass: ['class2','class8'],
        },
        {
            id: 'FOUR_02',
            relatedClass: ['class2','class8'],
        },
        {
            id: 'FOUR_03',
            relatedClass: ['class2','class8'],
        },
        {
            id: 'FOUR_04',
            relatedClass: ['class2','class8'],
        },
        {
            id: 'FOUR_05',
            relatedClass: ['class2','class8'],
        },
        {
            id: 'FOUR_06',
            relatedClass: ['class2','class8'],
        },
        {
            id: 'FOUR_07',
            relatedClass: ['class2','class8'],
        },
        {
            id: 'FOUR_08',
            relatedClass: ['class2','class8'],
        },
        {
            id: 'FOUR_09',
            relatedClass: ['class2','class8'],
        },
        {
            id: 'FOUR_10',
            relatedClass: ['class2','class8'],
        },
        {
            id: 'FOUR_11',
            relatedClass: ['class2','class8'],
        },
        {
            id: 'FOUR_12',
            relatedClass: ['class2','class8'],
        },
        {
            id: 'FOUR_13',
            relatedClass: ['class2','class8'],
        },
        {
            id: 'FOUR_14',
            relatedClass: ['class2','class8'],
        },
        {
            id: 'FOUR_15',
            relatedClass: ['class2','class8'],
        },
        {
            id: 'FOUR_16',
            relatedClass: ['class2','class8'],
        },
        {
            id: 'FOUR_17',
            relatedClass: ['class2','class8'],
        },
        {
            id: 'FOUR_18',
            relatedClass: ['class2','class8'],
        },
        {
            id: 'FOUR_19',
            relatedClass: ['class2','class8'],
        },
        {
            id: 'FOUR_20',
            relatedClass: ['class2','class8'],
        },
    ],
    [
        {
            id: 'FIVE_01',
            relatedClass: ['class2','class9'],
        },
        {
            id: 'FIVE_02',
            relatedClass: ['class2','class9'],
        },
        {
            id: 'FIVE_03',
            relatedClass: ['class2','class9'],
        },
        {
            id: 'FIVE_04',
            relatedClass: ['class2','class9'],
        },
        {
            id: 'FIVE_05',
            relatedClass: ['class2','class9'],
        },
        {
            id: 'FIVE_06',
            relatedClass: ['class2','class9'],
        },
        {
            id: 'FIVE_07',
            relatedClass: ['class2','class9'],
        },
        {
            id: 'FIVE_08',
            relatedClass: ['class2','class9'],
        },
        {
            id: 'FIVE_09',
            relatedClass: ['class2','class9'],
        },
        {
            id: 'FIVE_10',
            relatedClass: ['class2','class9'],
        },
        {
            id: 'FIVE_11',
            relatedClass: ['class2','class9'],
        },
        {
            id: 'FIVE_12',
            relatedClass: ['class2','class9'],
        },
        {
            id: 'FIVE_13',
            relatedClass: ['class2','class9'],
        },
        {
            id: 'FIVE_14',
            relatedClass: ['class2','class9'],
        },
        {
            id: 'FIVE_15',
            relatedClass: ['class2','class9'],
        },
        {
            id: 'FIVE_16',
            relatedClass: ['class2','class9'],
        },
        {
            id: 'FIVE_17',
            relatedClass: ['class2','class9'],
        },
        {
            id: 'FIVE_18',
            relatedClass: ['class2','class9'],
        },
        {
            id: 'FIVE_19',
            relatedClass: ['class2','class9'],
        },
        {
            id: 'FIVE_20',
            relatedClass: ['class2','class9'],
        },
    ],
    [
        {
            id: 'SIX_01',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'SIX_02',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'SIX_03',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'SIX_04',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'SIX_05',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'SIX_06',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'SIX_07',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'SIX_08',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'SIX_09',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'SIX_10',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'SIX_11',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'SIX_12',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'SIX_13',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'SIX_14',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'SIX_15',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'SIX_16',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'SIX_17',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'SIX_18',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'SIX_19',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'SIX_20',
            relatedClass: ['class2','class10'],
        },
    ],
    [
        {
            id: 'SEVEN_01',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'SEVEN_02',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'SEVEN_03',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'SEVEN_04',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'SEVEN_05',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'SEVEN_06',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'SEVEN_07',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'SEVEN_08',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'SEVEN_09',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'SEVEN_10',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'SEVEN_11',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'SEVEN_12',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'SEVEN_13',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'SEVEN_14',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'SEVEN_15',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'SEVEN_16',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'SEVEN_17',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'SEVEN_18',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'SEVEN_19',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'SEVEN_20',
            relatedClass: ['class2','class11'],
        },
    ],
    [
        {
            id: 'EIGHT_01',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'EIGHT_02',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'EIGHT_03',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'EIGHT_04',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'EIGHT_05',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'EIGHT_06',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'EIGHT_07',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'EIGHT_08',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'EIGHT_09',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'EIGHT_10',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'EIGHT_11',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'EIGHT_12',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'EIGHT_13',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'EIGHT_14',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'EIGHT_15',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'EIGHT_16',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'EIGHT_17',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'EIGHT_18',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'EIGHT_19',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'EIGHT_20',
            relatedClass: ['class2','class12'],
        },
    ],

    [
        {
            id: 'ZHENG_01',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_02',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_03',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_04',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_05',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_06',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_07',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_08',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_09',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_10',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_11',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_12',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_13',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_14',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_15',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_16',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_17',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_18',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_19',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_20',
            relatedClass: ['class3'],
        },
    ],

    [
        {
            id: 'LIAN_REN_2',
            relatedClass: ['class4'],
        },
        {
            id: 'LIAN_REN_ZU_2',
            relatedClass: ['class4'],
        },
        {
            id: 'LIAN_REN_3',
            relatedClass: ['class4'],
        },
        {
            id: 'LIAN_REN_ZU_3',
            relatedClass: ['class4'],
        },
        {
            id: 'LIAN_REN_4',
            relatedClass: ['class4'],
        },
        {
            id: 'LIAN_REN_5',
            relatedClass: ['class4'],
        },
    ],

    [
        {
            id: 'ONE_DONG',
            relatedClass: ['class5'],
        },
        {
            id: 'ONE_NAN',
            relatedClass: ['class5'],
        },
        {
            id: 'ONE_XI',
            relatedClass: ['class5'],
        },
        {
            id: 'ONE_BEI',
            relatedClass: ['class5'],
        },
        {
            id: 'ONE_ZHONG',
            relatedClass: ['class5'],
        },
        {
            id: 'ONE_FA',
            relatedClass: ['class5'],
        },
        {
            id: 'ONE_BAI',
            relatedClass: ['class5'],
        },
    ],
    [
        {
            id: 'TWO_DONG',
            relatedClass: ['class6'],
        },
        {
            id: 'TWO_NAN',
            relatedClass: ['class6'],
        },
        {
            id: 'TWO_XI',
            relatedClass: ['class6'],
        },
        {
            id: 'TWO_BEI',
            relatedClass: ['class6'],
        },
        {
            id: 'TWO_ZHONG',
            relatedClass: ['class6'],
        },
        {
            id: 'TWO_FA',
            relatedClass: ['class6'],
        },
        {
            id: 'TWO_BAI',
            relatedClass: ['class6'],
        },
    ],
    [
        {
            id: 'THREE_DONG',
            relatedClass: ['class7'],
        },
        {
            id: 'THREE_NAN',
            relatedClass: ['class7'],
        },
        {
            id: 'THREE_XI',
            relatedClass: ['class7'],
        },
        {
            id: 'THREE_BEI',
            relatedClass: ['class7'],
        },
        {
            id: 'THREE_ZHONG',
            relatedClass: ['class7'],
        },
        {
            id: 'THREE_FA',
            relatedClass: ['class7'],
        },
        {
            id: 'THREE_BAI',
            relatedClass: ['class7'],
        },
    ],
    [
        {
            id: 'FOUR_DONG',
            relatedClass: ['class8'],
        },
        {
            id: 'FOUR_NAN',
            relatedClass: ['class8'],
        },
        {
            id: 'FOUR_XI',
            relatedClass: ['class8'],
        },
        {
            id: 'FOUR_BEI',
            relatedClass: ['class8'],
        },
        {
            id: 'FOUR_ZHONG',
            relatedClass: ['class8'],
        },
        {
            id: 'FOUR_FA',
            relatedClass: ['class8'],
        },
        {
            id: 'FOUR_BAI',
            relatedClass: ['class8'],
        },
    ],
    [
        {
            id: 'FIVE_DONG',
            relatedClass: ['class9'],
        },
        {
            id: 'FIVE_NAN',
            relatedClass: ['class9'],
        },
        {
            id: 'FIVE_XI',
            relatedClass: ['class9'],
        },
        {
            id: 'FIVE_BEI',
            relatedClass: ['class9'],
        },
        {
            id: 'FIVE_ZHONG',
            relatedClass: ['class9'],
        },
        {
            id: 'FIVE_FA',
            relatedClass: ['class9'],
        },
        {
            id: 'FIVE_BAI',
            relatedClass: ['class9'],
        },
    ],
    [
        {
            id: 'SIX_DONG',
            relatedClass: ['class10'],
        },
        {
            id: 'SIX_NAN',
            relatedClass: ['class10'],
        },
        {
            id: 'SIX_XI',
            relatedClass: ['class10'],
        },
        {
            id: 'SIX_BEI',
            relatedClass: ['class10'],
        },
        {
            id: 'SIX_ZHONG',
            relatedClass: ['class10'],
        },
        {
            id: 'SIX_FA',
            relatedClass: ['class10'],
        },
        {
            id: 'SIX_BAI',
            relatedClass: ['class10'],
        },
    ],
    [
        {
            id: 'SEVEN_DONG',
            relatedClass: ['class11'],
        },
        {
            id: 'SEVEN_NAN',
            relatedClass: ['class11'],
        },
        {
            id: 'SEVEN_XI',
            relatedClass: ['class11'],
        },
        {
            id: 'SEVEN_BEI',
            relatedClass: ['class11'],
        },
        {
            id: 'SEVEN_ZHONG',
            relatedClass: ['class11'],
        },
        {
            id: 'SEVEN_FA',
            relatedClass: ['class11'],
        },
        {
            id: 'SEVEN_BAI',
            relatedClass: ['class11'],
        },
    ],
    [
        {
            id: 'EIGHT_DONG',
            relatedClass: ['class12'],
        },
        {
            id: 'EIGHT_NAN',
            relatedClass: ['class12'],
        },
        {
            id: 'EIGHT_XI',
            relatedClass: ['class12'],
        },
        {
            id: 'EIGHT_BEI',
            relatedClass: ['class12'],
        },
        {
            id: 'EIGHT_ZHONG',
            relatedClass: ['class12'],
        },
        {
            id: 'EIGHT_FA',
            relatedClass: ['class12'],
        },
        {
            id: 'EIGHT_BAI',
            relatedClass: ['class12'],
        },
    ],
]
const TEMPLATE3_xyncTypeRule = [
    [
        {
            id: 'TOTAL_DA',
            relatedClass: ['class1','class3'],
        },
        {
            id: 'TOTAL_XIAO',
            relatedClass: ['class1','class3'],
        },
        {
            id: 'TOTAL_DAN',
            relatedClass: ['class1','class3'],
        },
        {
            id: 'TOTAL_SHUANG',
            relatedClass: ['class1','class3'],
        },
        {
            id: 'TOTAL_WEI_DA',
            relatedClass: ['class1','class3'],
        },
        {
            id: 'TOTAL_WEI_XIAO',
            relatedClass: ['class1','class3'],
        },
    ],
    [
        {
            id: 'ONE_DA',
            relatedClass: ['class1','class10'],
        },
        {
            id: 'ONE_XIAO',
            relatedClass: ['class1','class10'],
        },
        {
            id: 'ONE_DAN',
            relatedClass: ['class1','class10'],
        },
        {
            id: 'ONE_SHUANG',
            relatedClass: ['class1','class10'],
        },
        {
            id: 'ONE_LONG',
            relatedClass: ['class1','class10'],
        },
        {
            id: 'ONE_HU',
            relatedClass: ['class1','class10'],
        },
        {
            id: 'ONE_HE_DAN',
            relatedClass: ['class1','class10'],
        },
        {
            id: 'ONE_HE_SHUANG',
            relatedClass: ['class1','class10'],
        },
        {
            id: 'ONE_WEI_DA',
            relatedClass: ['class1','class10'],
        },
        {
            id: 'ONE_WEI_XIAO',
            relatedClass: ['class1','class10'],
        },
    ],
    [
        {
            id: 'TWO_DA',
            relatedClass: ['class1','class11'],
        },
        {
            id: 'TWO_XIAO',
            relatedClass: ['class1','class11'],
        },
        {
            id: 'TWO_DAN',
            relatedClass: ['class1','class11'],
        },
        {
            id: 'TWO_SHUANG',
            relatedClass: ['class1','class11'],
        },
        {
            id: 'TWO_LONG',
            relatedClass: ['class1','class11'],
        },
        {
            id: 'TWO_HU',
            relatedClass: ['class1','class11'],
        },
        {
            id: 'TWO_HE_DAN',
            relatedClass: ['class1','class11'],
        },
        {
            id: 'TWO_HE_SHUANG',
            relatedClass: ['class1','class11'],
        },
        {
            id: 'TWO_WEI_DA',
            relatedClass: ['class1','class11'],
        },
        {
            id: 'TWO_WEI_XIAO',
            relatedClass: ['class1','class11'],
        },
    ],
    [
        {
            id: 'THREE_DA',
            relatedClass: ['class1','class12'],
        },
        {
            id: 'THREE_XIAO',
            relatedClass: ['class1','class12'],
        },
        {
            id: 'THREE_DAN',
            relatedClass: ['class1','class12'],
        },
        {
            id: 'THREE_SHUANG',
            relatedClass: ['class1','class12'],
        },
        {
            id: 'THREE_LONG',
            relatedClass: ['class1','class12'],
        },
        {
            id: 'THREE_HU',
            relatedClass: ['class1','class12'],
        }, 
        {
            id: 'THREE_HE_DAN',
            relatedClass: ['class1','class12'],
        },
        {
            id: 'THREE_HE_SHUANG',
            relatedClass: ['class1','class12'],
        },
        {
            id: 'THREE_WEI_DA',
            relatedClass: ['class1','class12'],
        },
        {
            id: 'THREE_WEI_XIAO',
            relatedClass: ['class1','class12'],
        },
    ],
    [
        {
            id: 'FOUR_DA',
            relatedClass: ['class1','class13'],
        },
        {
            id: 'FOUR_XIAO',
            relatedClass: ['class1','class13'],
        },
        {
            id: 'FOUR_DAN',
            relatedClass: ['class1','class13'],
        },
        {
            id: 'FOUR_SHUANG',
            relatedClass: ['class1','class13'],
        },
        {
            id: 'FOUR_LONG',
            relatedClass: ['class1','class13'],
        },
        {
            id: 'FOUR_HU',
            relatedClass: ['class1','class13'],
        }, 
        {
            id: 'FOUR_HE_DAN',
            relatedClass: ['class1','class13'],
        },
        {
            id: 'FOUR_HE_SHUANG',
            relatedClass: ['class1','class13'],
        },
        {
            id: 'FOUR_WEI_DA',
            relatedClass: ['class1','class13'],
        },
        {
            id: 'FOUR_WEI_XIAO',
            relatedClass: ['class1','class13'],
        },
    ],
    [
        {
            id: 'FIVE_DA',
            relatedClass: ['class1','class14'],
        },
        {
            id: 'FIVE_XIAO',
            relatedClass: ['class1','class14'],
        },
        {
            id: 'FIVE_DAN',
            relatedClass: ['class1','class14'],
        },
        {
            id: 'FIVE_SHUANG',
            relatedClass: ['class1','class14'],
        },
        {
            id: 'FIVE_LONG',
            relatedClass: ['class1','class14'],
        },
        {
            id: 'FIVE_HU',
            relatedClass: ['class1','class14'],
        },
        {
            id: 'FIVE_HE_DAN',
            relatedClass: ['class1','class14'],
        },
        {
            id: 'FIVE_HE_SHUANG',
            relatedClass: ['class1','class14'],
        },        
        {
            id: 'FIVE_WEI_DA',
            relatedClass: ['class1','class14'],
        },
        {
            id: 'FIVE_WEI_XIAO',
            relatedClass: ['class1','class14'],
        },
    ],
    [
        {
            id: 'SIX_DA',
            relatedClass: ['class1','class15'],
        },
        {
            id: 'SIX_XIAO',
            relatedClass: ['class1','class15'],
        },
        {
            id: 'SIX_DAN',
            relatedClass: ['class1','class15'],
        },
        {
            id: 'SIX_SHUANG',
            relatedClass: ['class1','class15'],
        },
        {
            id: 'SIX_LONG',
            relatedClass: ['class1','class15'],
        },
        {
            id: 'SIX_HU',
            relatedClass: ['class1','class15'],
        },        
        {
            id: 'SIX_HE_DAN',
            relatedClass: ['class1','class15'],
        },
        {
            id: 'SIX_HE_SHUANG',
            relatedClass: ['class1','class15'],
        },
        {
            id: 'SIX_WEI_DA',
            relatedClass: ['class1','class15'],
        },
        {
            id: 'SIX_WEI_XIAO',
            relatedClass: ['class1','class15'],
        },
    ],
    [
        {
            id: 'SEVEN_DA',
            relatedClass: ['class1','class16'],
        },
        {
            id: 'SEVEN_XIAO',
            relatedClass: ['class1','class16'],
        },
        {
            id: 'SEVEN_DAN',
            relatedClass: ['class1','class16'],
        },
        {
            id: 'SEVEN_SHUANG',
            relatedClass: ['class1','class16'],
        },
        {
            id: 'SEVEN_LONG',
            relatedClass: ['class1','class16'],
        },
        {
            id: 'SEVEN_HU',
            relatedClass: ['class1','class16'],
        },
        {
            id: 'SEVEN_HE_DAN',
            relatedClass: ['class1','class16'],
        },
        {
            id: 'SEVEN_HE_SHUANG',
            relatedClass: ['class1','class16'],
        },
        {
            id: 'SEVEN_WEI_DA',
            relatedClass: ['class1','class16'],
        },
        {
            id: 'SEVEN_WEI_XIAO',
            relatedClass: ['class1','class16'],
        },
    ],
    [
        {
            id: 'EIGHT_DA',
            relatedClass: ['class1','class17'],
        },
        {
            id: 'EIGHT_XIAO',
            relatedClass: ['class1','class17'],
        },
        {
            id: 'EIGHT_DAN',
            relatedClass: ['class1','class17'],
        },
        {
            id: 'EIGHT_SHUANG',
            relatedClass: ['class1','class17'],
        },
        {
            id: 'EIGHT_LONG',
            relatedClass: ['class1','class17'],
        },
        {
            id: 'EIGHT_HU',
            relatedClass: ['class1','class17'],
        },
        {
            id: 'EIGHT_HE_DAN',
            relatedClass: ['class1','class17'],
        },
        {
            id: 'EIGHT_HE_SHUANG',
            relatedClass: ['class1','class17'],
        },
        {
            id: 'EIGHT_WEI_DA',
            relatedClass: ['class1','class17'],
        },
        {
            id: 'EIGHT_WEI_XIAO',
            relatedClass: ['class1','class17'],
        },
    ],

    [
        {
            id: 'ONE_01',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'ONE_02',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'ONE_03',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'ONE_04',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'ONE_05',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'ONE_06',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'ONE_07',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'ONE_08',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'ONE_09',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'ONE_10',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'ONE_11',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'ONE_12',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'ONE_13',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'ONE_14',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'ONE_15',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'ONE_16',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'ONE_17',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'ONE_18',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'ONE_19',
            relatedClass: ['class2','class10'],
        },
        {
            id: 'ONE_20',
            relatedClass: ['class2','class10'],
        },
    ],
    [
        {
            id: 'TWO_01',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'TWO_02',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'TWO_03',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'TWO_04',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'TWO_05',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'TWO_06',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'TWO_07',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'TWO_08',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'TWO_09',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'TWO_10',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'TWO_11',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'TWO_12',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'TWO_13',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'TWO_14',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'TWO_15',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'TWO_16',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'TWO_17',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'TWO_18',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'TWO_19',
            relatedClass: ['class2','class11'],
        },
        {
            id: 'TWO_20',
            relatedClass: ['class2','class11'],
        },
    ],
    [
        {
            id: 'THREE_01',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'THREE_02',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'THREE_03',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'THREE_04',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'THREE_05',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'THREE_06',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'THREE_07',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'THREE_08',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'THREE_09',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'THREE_10',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'THREE_11',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'THREE_12',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'THREE_13',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'THREE_14',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'THREE_15',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'THREE_16',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'THREE_17',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'THREE_18',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'THREE_19',
            relatedClass: ['class2','class12'],
        },
        {
            id: 'THREE_20',
            relatedClass: ['class2','class12'],
        },
    ],
    [
        {
            id: 'FOUR_01',
            relatedClass: ['class2','class13'],
        },
        {
            id: 'FOUR_02',
            relatedClass: ['class2','class13'],
        },
        {
            id: 'FOUR_03',
            relatedClass: ['class2','class13'],
        },
        {
            id: 'FOUR_04',
            relatedClass: ['class2','class13'],
        },
        {
            id: 'FOUR_05',
            relatedClass: ['class2','class13'],
        },
        {
            id: 'FOUR_06',
            relatedClass: ['class2','class13'],
        },
        {
            id: 'FOUR_07',
            relatedClass: ['class2','class13'],
        },
        {
            id: 'FOUR_08',
            relatedClass: ['class2','class13'],
        },
        {
            id: 'FOUR_09',
            relatedClass: ['class2','class13'],
        },
        {
            id: 'FOUR_10',
            relatedClass: ['class2','class13'],
        },
        {
            id: 'FOUR_11',
            relatedClass: ['class2','class13'],
        },
        {
            id: 'FOUR_12',
            relatedClass: ['class2','class13'],
        },
        {
            id: 'FOUR_13',
            relatedClass: ['class2','class13'],
        },
        {
            id: 'FOUR_14',
            relatedClass: ['class2','class13'],
        },
        {
            id: 'FOUR_15',
            relatedClass: ['class2','class13'],
        },
        {
            id: 'FOUR_16',
            relatedClass: ['class2','class13'],
        },
        {
            id: 'FOUR_17',
            relatedClass: ['class2','class13'],
        },
        {
            id: 'FOUR_18',
            relatedClass: ['class2','class13'],
        },
        {
            id: 'FOUR_19',
            relatedClass: ['class2','class13'],
        },
        {
            id: 'FOUR_20',
            relatedClass: ['class2','class13'],
        },
    ],
    [
        {
            id: 'FIVE_01',
            relatedClass: ['class2','class14'],
        },
        {
            id: 'FIVE_02',
            relatedClass: ['class2','class14'],
        },
        {
            id: 'FIVE_03',
            relatedClass: ['class2','class14'],
        },
        {
            id: 'FIVE_04',
            relatedClass: ['class2','class14'],
        },
        {
            id: 'FIVE_05',
            relatedClass: ['class2','class14'],
        },
        {
            id: 'FIVE_06',
            relatedClass: ['class2','class14'],
        },
        {
            id: 'FIVE_07',
            relatedClass: ['class2','class14'],
        },
        {
            id: 'FIVE_08',
            relatedClass: ['class2','class14'],
        },
        {
            id: 'FIVE_09',
            relatedClass: ['class2','class14'],
        },
        {
            id: 'FIVE_10',
            relatedClass: ['class2','class14'],
        },
        {
            id: 'FIVE_11',
            relatedClass: ['class2','class14'],
        },
        {
            id: 'FIVE_12',
            relatedClass: ['class2','class14'],
        },
        {
            id: 'FIVE_13',
            relatedClass: ['class2','class14'],
        },
        {
            id: 'FIVE_14',
            relatedClass: ['class2','class14'],
        },
        {
            id: 'FIVE_15',
            relatedClass: ['class2','class14'],
        },
        {
            id: 'FIVE_16',
            relatedClass: ['class2','class14'],
        },
        {
            id: 'FIVE_17',
            relatedClass: ['class2','class14'],
        },
        {
            id: 'FIVE_18',
            relatedClass: ['class2','class14'],
        },
        {
            id: 'FIVE_19',
            relatedClass: ['class2','class14'],
        },
        {
            id: 'FIVE_20',
            relatedClass: ['class2','class14'],
        },
    ],
    [
        {
            id: 'SIX_01',
            relatedClass: ['class2','class15'],
        },
        {
            id: 'SIX_02',
            relatedClass: ['class2','class15'],
        },
        {
            id: 'SIX_03',
            relatedClass: ['class2','class15'],
        },
        {
            id: 'SIX_04',
            relatedClass: ['class2','class15'],
        },
        {
            id: 'SIX_05',
            relatedClass: ['class2','class15'],
        },
        {
            id: 'SIX_06',
            relatedClass: ['class2','class15'],
        },
        {
            id: 'SIX_07',
            relatedClass: ['class2','class15'],
        },
        {
            id: 'SIX_08',
            relatedClass: ['class2','class15'],
        },
        {
            id: 'SIX_09',
            relatedClass: ['class2','class15'],
        },
        {
            id: 'SIX_10',
            relatedClass: ['class2','class15'],
        },
        {
            id: 'SIX_11',
            relatedClass: ['class2','class15'],
        },
        {
            id: 'SIX_12',
            relatedClass: ['class2','class15'],
        },
        {
            id: 'SIX_13',
            relatedClass: ['class2','class15'],
        },
        {
            id: 'SIX_14',
            relatedClass: ['class2','class15'],
        },
        {
            id: 'SIX_15',
            relatedClass: ['class2','class15'],
        },
        {
            id: 'SIX_16',
            relatedClass: ['class2','class15'],
        },
        {
            id: 'SIX_17',
            relatedClass: ['class2','class15'],
        },
        {
            id: 'SIX_18',
            relatedClass: ['class2','class15'],
        },
        {
            id: 'SIX_19',
            relatedClass: ['class2','class15'],
        },
        {
            id: 'SIX_20',
            relatedClass: ['class2','class15'],
        },
    ],
    [
        {
            id: 'SEVEN_01',
            relatedClass: ['class2','class16'],
        },
        {
            id: 'SEVEN_02',
            relatedClass: ['class2','class16'],
        },
        {
            id: 'SEVEN_03',
            relatedClass: ['class2','class16'],
        },
        {
            id: 'SEVEN_04',
            relatedClass: ['class2','class16'],
        },
        {
            id: 'SEVEN_05',
            relatedClass: ['class2','class16'],
        },
        {
            id: 'SEVEN_06',
            relatedClass: ['class2','class16'],
        },
        {
            id: 'SEVEN_07',
            relatedClass: ['class2','class16'],
        },
        {
            id: 'SEVEN_08',
            relatedClass: ['class2','class16'],
        },
        {
            id: 'SEVEN_09',
            relatedClass: ['class2','class16'],
        },
        {
            id: 'SEVEN_10',
            relatedClass: ['class2','class16'],
        },
        {
            id: 'SEVEN_11',
            relatedClass: ['class2','class16'],
        },
        {
            id: 'SEVEN_12',
            relatedClass: ['class2','class16'],
        },
        {
            id: 'SEVEN_13',
            relatedClass: ['class2','class16'],
        },
        {
            id: 'SEVEN_14',
            relatedClass: ['class2','class16'],
        },
        {
            id: 'SEVEN_15',
            relatedClass: ['class2','class16'],
        },
        {
            id: 'SEVEN_16',
            relatedClass: ['class2','class16'],
        },
        {
            id: 'SEVEN_17',
            relatedClass: ['class2','class16'],
        },
        {
            id: 'SEVEN_18',
            relatedClass: ['class2','class16'],
        },
        {
            id: 'SEVEN_19',
            relatedClass: ['class2','class16'],
        },
        {
            id: 'SEVEN_20',
            relatedClass: ['class2','class16'],
        },
    ],
    [
        {
            id: 'EIGHT_01',
            relatedClass: ['class2','class17'],
        },
        {
            id: 'EIGHT_02',
            relatedClass: ['class2','class17'],
        },
        {
            id: 'EIGHT_03',
            relatedClass: ['class2','class17'],
        },
        {
            id: 'EIGHT_04',
            relatedClass: ['class2','class17'],
        },
        {
            id: 'EIGHT_05',
            relatedClass: ['class2','class17'],
        },
        {
            id: 'EIGHT_06',
            relatedClass: ['class2','class17'],
        },
        {
            id: 'EIGHT_07',
            relatedClass: ['class2','class17'],
        },
        {
            id: 'EIGHT_08',
            relatedClass: ['class2','class17'],
        },
        {
            id: 'EIGHT_09',
            relatedClass: ['class2','class17'],
        },
        {
            id: 'EIGHT_10',
            relatedClass: ['class2','class17'],
        },
        {
            id: 'EIGHT_11',
            relatedClass: ['class2','class17'],
        },
        {
            id: 'EIGHT_12',
            relatedClass: ['class2','class17'],
        },
        {
            id: 'EIGHT_13',
            relatedClass: ['class2','class17'],
        },
        {
            id: 'EIGHT_14',
            relatedClass: ['class2','class17'],
        },
        {
            id: 'EIGHT_15',
            relatedClass: ['class2','class17'],
        },
        {
            id: 'EIGHT_16',
            relatedClass: ['class2','class17'],
        },
        {
            id: 'EIGHT_17',
            relatedClass: ['class2','class17'],
        },
        {
            id: 'EIGHT_18',
            relatedClass: ['class2','class17'],
        },
        {
            id: 'EIGHT_19',
            relatedClass: ['class2','class17'],
        },
        {
            id: 'EIGHT_20',
            relatedClass: ['class2','class17'],
        },
    ],

    [
        {
            id: 'ZHENG_01',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_02',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_03',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_04',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_05',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_06',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_07',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_08',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_09',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_10',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_11',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_12',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_13',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_14',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_15',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_16',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_17',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_18',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_19',
            relatedClass: ['class3'],
        },
        {
            id: 'ZHENG_20',
            relatedClass: ['class3'],
        },
    ],

    [
        {
            id: 'LIAN_REN_2',
            relatedClass: ['class4'],
        },
    ],
    [
        {
            id: 'LIAN_REN_ZU_2',
            relatedClass: ['class5'],
        },
    ],
    [
        {
            id: 'LIAN_REN_3',
            relatedClass: ['class6'],
        },
    ],
    [
        {
            id: 'LIAN_REN_ZU_3',
            relatedClass: ['class7'],
        },
    ],
    [
        {
            id: 'LIAN_REN_4',
            relatedClass: ['class8'],
        },
    ],
    [
        {
            id: 'LIAN_REN_5',
            relatedClass: ['class9'],
        },
    ],

    [
        {
            id: 'ONE_DONG',
            relatedClass: ['class10'],
        },
        {
            id: 'ONE_NAN',
            relatedClass: ['class10'],
        },
        {
            id: 'ONE_XI',
            relatedClass: ['class10'],
        },
        {
            id: 'ONE_BEI',
            relatedClass: ['class10'],
        },
        {
            id: 'ONE_ZHONG',
            relatedClass: ['class10'],
        },
        {
            id: 'ONE_FA',
            relatedClass: ['class10'],
        },
        {
            id: 'ONE_BAI',
            relatedClass: ['class10'],
        },
    ],
    [
        {
            id: 'TWO_DONG',
            relatedClass: ['class11'],
        },
        {
            id: 'TWO_NAN',
            relatedClass: ['class11'],
        },
        {
            id: 'TWO_XI',
            relatedClass: ['class11'],
        },
        {
            id: 'TWO_BEI',
            relatedClass: ['class11'],
        },
        {
            id: 'TWO_ZHONG',
            relatedClass: ['class11'],
        },
        {
            id: 'TWO_FA',
            relatedClass: ['class11'],
        },
        {
            id: 'TWO_BAI',
            relatedClass: ['class11'],
        },
    ],
    [
        {
            id: 'THREE_DONG',
            relatedClass: ['class12'],
        },
        {
            id: 'THREE_NAN',
            relatedClass: ['class12'],
        },
        {
            id: 'THREE_XI',
            relatedClass: ['class12'],
        },
        {
            id: 'THREE_BEI',
            relatedClass: ['class12'],
        },
        {
            id: 'THREE_ZHONG',
            relatedClass: ['class12'],
        },
        {
            id: 'THREE_FA',
            relatedClass: ['class12'],
        },
        {
            id: 'THREE_BAI',
            relatedClass: ['class12'],
        },
    ],
    [
        {
            id: 'FOUR_DONG',
            relatedClass: ['class13'],
        },
        {
            id: 'FOUR_NAN',
            relatedClass: ['class13'],
        },
        {
            id: 'FOUR_XI',
            relatedClass: ['class13'],
        },
        {
            id: 'FOUR_BEI',
            relatedClass: ['class13'],
        },
        {
            id: 'FOUR_ZHONG',
            relatedClass: ['class13'],
        },
        {
            id: 'FOUR_FA',
            relatedClass: ['class13'],
        },
        {
            id: 'FOUR_BAI',
            relatedClass: ['class13'],
        },
    ],
    [
        {
            id: 'FIVE_DONG',
            relatedClass: ['class14'],
        },
        {
            id: 'FIVE_NAN',
            relatedClass: ['class14'],
        },
        {
            id: 'FIVE_XI',
            relatedClass: ['class14'],
        },
        {
            id: 'FIVE_BEI',
            relatedClass: ['class14'],
        },
        {
            id: 'FIVE_ZHONG',
            relatedClass: ['class14'],
        },
        {
            id: 'FIVE_FA',
            relatedClass: ['class14'],
        },
        {
            id: 'FIVE_BAI',
            relatedClass: ['class14'],
        },
    ],
    [
        {
            id: 'SIX_DONG',
            relatedClass: ['class15'],
        },
        {
            id: 'SIX_NAN',
            relatedClass: ['class15'],
        },
        {
            id: 'SIX_XI',
            relatedClass: ['class15'],
        },
        {
            id: 'SIX_BEI',
            relatedClass: ['class15'],
        },
        {
            id: 'SIX_ZHONG',
            relatedClass: ['class15'],
        },
        {
            id: 'SIX_FA',
            relatedClass: ['class15'],
        },
        {
            id: 'SIX_BAI',
            relatedClass: ['class15'],
        },
    ],
    [
        {
            id: 'SEVEN_DONG',
            relatedClass: ['class16'],
        },
        {
            id: 'SEVEN_NAN',
            relatedClass: ['class16'],
        },
        {
            id: 'SEVEN_XI',
            relatedClass: ['class16'],
        },
        {
            id: 'SEVEN_BEI',
            relatedClass: ['class16'],
        },
        {
            id: 'SEVEN_ZHONG',
            relatedClass: ['class16'],
        },
        {
            id: 'SEVEN_FA',
            relatedClass: ['class16'],
        },
        {
            id: 'SEVEN_BAI',
            relatedClass: ['class16'],
        },
    ],
    [
        {
            id: 'EIGHT_DONG',
            relatedClass: ['class17'],
        },
        {
            id: 'EIGHT_NAN',
            relatedClass: ['class17'],
        },
        {
            id: 'EIGHT_XI',
            relatedClass: ['class17'],
        },
        {
            id: 'EIGHT_BEI',
            relatedClass: ['class17'],
        },
        {
            id: 'EIGHT_ZHONG',
            relatedClass: ['class17'],
        },
        {
            id: 'EIGHT_FA',
            relatedClass: ['class17'],
        },
        {
            id: 'EIGHT_BAI',
            relatedClass: ['class17'],
        },
    ],
]

const lastDrawingRule = ['num_one', 'num_two', 'num_three', 'num_four', 'num_five', 'num_six', 'num_seven', 'num_eight', 'num_nine', 'num_ten', 'num_count', 'num_tema', 'num_tema1', 'num_tema2']

const TEMPLATE3_headerMenu = function(){
    return {
        level1:[
            {
                title: getWord('trade_history'),
                icon: 'icon-text',
                linkTo: '/pc28/TradeHistory'
            },
            {
                title: getWord('trend3'),
                icon: 'icon-schedule',
                linkTo: '/pc28/DrawingHistory'
            },
            {
                title: getWord('trend3')+'/'+getWord('changlong'),
                icon: 'icon-long',
                linkTo: '/pc28/TrendsByLuZhu'
            },
            {
                title: getWord('analysis'),
                icon: 'icon-piechart',
                linkTo: '/beijing28/Analysis'
            },
            {
                title: getWord('trend5'),
                icon: 'icon-zoushi',
                linkTo: '/beijing28/TrendsToChart'
            },
            {
                title: getWord('rule_explain'),
                icon: 'icon-info',
                linkTo: '/pc28/GameRule'
            },
            {
                tag:'chatroom',
                title: getWord('chatroom'),
                icon: 'icon-msg',
                invokeFunc: 'toggleChatRoom',
            },
        ],
        level2:[
            {
                title: getWord('trade_history'),
                icon: 'icon-text',
                linkTo: '/pc28/TradeHistory'
            },
            {
                title: getWord('trend3'),
                icon: 'icon-schedule',
                linkTo: '/pc28/DrawingHistory'
            },
            {
                title: getWord('trend5'),
                icon: 'icon-zoushi',
                linkTo: '/pc28/TrendsByLuZhu'
            },
            {
                title: getWord('rule_explain'),
                icon: 'icon-info',
                linkTo: '/pc28/GameRule'
            },
            {
                tag:'chatroom',
                title: getWord('chatroom'),
                icon: 'icon-msg',
                invokeFunc: 'toggleChatRoom',
            },
        ],
        level3:[
            {
                title: getWord('trade_history'),
                icon: 'icon-text',
                linkTo: '/pc28/TradeHistory'
            },
            {
                title: getWord('trend3'),
                icon: 'icon-schedule',
                linkTo: '/pc28/DrawingHistory'
            },
            {
                title: getWord('trend5'),
                icon: 'icon-zoushi',
                linkTo: '/pc28/TrendsByLuZhu'
            },
            {
                title: getWord('rule_explain'),
                icon: 'icon-info',
                linkTo: '/pc28/GameRule'
            },
            {
                tag:'chatroom',
                title: getWord('chatroom'),
                icon: 'icon-msg',
                invokeFunc: 'toggleChatRoom',
            },
        ],
        level4:[
            {
                title: getWord('trade_history'),
                icon: 'icon-text',
                linkTo: '/pc28/TradeHistory'
            },
            {
                title: getWord('trend3'),
                icon: 'icon-schedule',
                linkTo: '/pc28/DrawingHistory'
            },
            {
                title: getWord('rule_explain'),
                icon: 'icon-info',
                linkTo: '/pc28/GameRule'
            },
        ],
    }
}
const TEMPLATE2_headerMenu = function(){
    return {
        pc28:[
            {
                title: getWord('trade_history'),
                icon: 'icon-quercode',
                linkTo: '/pc28/TradeHistory'
            },
        ],
        wanneng:[
            {
                title: getWord('trade_history'),
                icon: 'icon-quercode',
                linkTo: '/pc28/TradeHistory'
            },
            {
                title: getWord('trend3'),
                icon: 'icon-openhis',
                linkTo: '/pc28/DrawingHistory'
            },
            {
                title: getWord('rule_explain'),
                icon: 'icon-gametext',
                linkTo: '/UserGuide?type=wanneng&name='+getWord('type_wanneng')
            },
        ],
        damacai:[
            {
                title: getWord('trade_history'),
                icon: 'icon-quercode',
                linkTo: '/pc28/TradeHistory'
            },
            {
                title: getWord('trend3'),
                icon: 'icon-openhis',
                linkTo: '/pc28/DrawingHistory'
            },
            {
                title: getWord('rule_explain'),
                icon: 'icon-gametext',
                linkTo: '/UserGuide?type=damacai&name='+getWord('type_damacai')
            },
        ],
        duoduo:[
            {
                title: getWord('trade_history'),
                icon: 'icon-quercode',
                linkTo: '/pc28/TradeHistory'
            },
            {
                title: getWord('trend3'),
                icon: 'icon-openhis',
                linkTo: '/pc28/DrawingHistory'
            },
            {
                title: getWord('rule_explain'),
                icon: 'icon-gametext',
                linkTo: '/UserGuide?type=duoduo&name='+getWord('type_wanneng3')
            },
        ],
        yuedawan:[
            {
                title: getWord('trade_history'),
                icon: 'icon-quercode',
                linkTo: '/pc28/TradeHistory'
            },
            {
                title: getWord('trend3'),
                icon: 'icon-openhis',
                linkTo: '/pc28/DrawingHistory'
            },
            {
                title: getWord('rule_explain'),
                icon: 'icon-gametext',
                linkTo: '/UserGuide?type=yuedawan&name='+getWord('type_wanneng4')
            },
        ],
        shaba88:[
            {
                title: getWord('trade_history'),
                icon: 'icon-quercode',
                linkTo: '/pc28/TradeHistory'
            },
            {
                title: getWord('trend3'),
                icon: 'icon-openhis',
                linkTo: '/pc28/DrawingHistory'
            },
            {
                title: getWord('rule_explain'),
                icon: 'icon-gametext',
                linkTo: '/UserGuide?type=shaba88&name='+getWord('type_wanneng5')
            },
        ],
        saimahui:[
            {
                title: getWord('trade_history'),
                icon: 'icon-quercode',
                linkTo: '/pc28/TradeHistory'
            },
            {
                title: getWord('trend3'),
                icon: 'icon-openhis',
                linkTo: '/pc28/DrawingHistory'
            },
            {
                title: getWord('rule_explain'),
                icon: 'icon-gametext',
                linkTo: '/UserGuide?type=saimahui&name='+getWord('type_wanneng6')
            },
        ],
        xinjapowz:[
            {
                title: getWord('trade_history'),
                icon: 'icon-quercode',
                linkTo: '/pc28/TradeHistory'
            },
            {
                title: getWord('trend3'),
                icon: 'icon-openhis',
                linkTo: '/pc28/DrawingHistory'
            },
            {
                title: getWord('rule_explain'),
                icon: 'icon-gametext',
                linkTo: '/UserGuide?type=xinjapowz&name='+getWord('type_wanneng7')
            },
        ],
    }
}
const headerMenu = function(){
    return {
        pc28: [{
            title: getWord('trade_history'),
            icon: 'icon-quercode',
            linkTo: '/pc28/TradeHistory'
        }, ],
        wanneng: [{
                title: getWord('trade_history'),
                icon: 'icon-quercode',
                linkTo: '/pc28/TradeHistory'
            },
            {
                title: getWord('trend3'),
                icon: 'icon-openhis',
                linkTo: '/pc28/DrawingHistory'
            },
            {
                title: getWord('rule_explain'),
                icon: 'icon-gametext',
                linkTo: '/UserGuide?type=wanneng&name=' + getWord('type_wanneng')
            },
        ],
        damacai: [{
                title: getWord('trade_history'),
                icon: 'icon-quercode',
                linkTo: '/pc28/TradeHistory'
            },
            {
                title: getWord('trend3'),
                icon: 'icon-openhis',
                linkTo: '/pc28/DrawingHistory'
            },
            {
                title: getWord('rule_explain'),
                icon: 'icon-gametext',
                linkTo: '/UserGuide?type=damacai&name=' + getWord('type_damacai')
            },
        ],
        duoduo: [{
                title: getWord('trade_history'),
                icon: 'icon-quercode',
                linkTo: '/pc28/TradeHistory'
            },
            {
                title: getWord('trend3'),
                icon: 'icon-openhis',
                linkTo: '/pc28/DrawingHistory'
            },
            {
                title: getWord('rule_explain'),
                icon: 'icon-gametext',
                linkTo: '/UserGuide?type=duoduo&name=' + getWord('type_wanneng3')
            },
        ],
        yuedawan: [{
                title: getWord('trade_history'),
                icon: 'icon-quercode',
                linkTo: '/pc28/TradeHistory'
            },
            {
                title: getWord('trend3'),
                icon: 'icon-openhis',
                linkTo: '/pc28/DrawingHistory'
            },
            {
                title: getWord('rule_explain'),
                icon: 'icon-gametext',
                linkTo: '/UserGuide?type=yuedawan&name=' + getWord('type_wanneng4')
            },
        ],
        shaba88: [{
                title: getWord('trade_history'),
                icon: 'icon-quercode',
                linkTo: '/pc28/TradeHistory'
            },
            {
                title: getWord('trend3'),
                icon: 'icon-openhis',
                linkTo: '/pc28/DrawingHistory'
            },
            {
                title: getWord('rule_explain'),
                icon: 'icon-gametext',
                linkTo: '/UserGuide?type=shaba88&name=' + getWord('type_wanneng5')
            },
        ],
        saimahui: [{
                title: getWord('trade_history'),
                icon: 'icon-quercode',
                linkTo: '/pc28/TradeHistory'
            },
            {
                title: getWord('trend3'),
                icon: 'icon-openhis',
                linkTo: '/pc28/DrawingHistory'
            },
            {
                title: getWord('rule_explain'),
                icon: 'icon-gametext',
                linkTo: '/UserGuide?type=saimahui&name=' + getWord('type_wanneng6')
            },
        ],
        xinjapowz: [{
                title: getWord('trade_history'),
                icon: 'icon-quercode',
                linkTo: '/pc28/TradeHistory'
            },
            {
                title: getWord('trend3'),
                icon: 'icon-openhis',
                linkTo: '/pc28/DrawingHistory'
            },
            {
                title: getWord('rule_explain'),
                icon: 'icon-gametext',
                linkTo: '/UserGuide?type=xinjapowz&name=' + getWord('type_wanneng7')
            },
        ]
    }
}

const TEMPLATE3_titleMenu = function(){
    return {
        pk10:[
            {
                type: getWord('shuzipan'),
                id:'1',
                active:false,
                list:[
                    {
                        title: getWord('shuzipan'),
                        id:'1-1',
                        active:false,
                        id: 'class1'
                    },
                ]
            },
            {
                type: getWord('liangmianpan'),
                id:'2',
                active:false,
                list:[
                    {
                        title: getWord('liangmianpan'),
                        id:'2-2',
                        active:false,
                        id: 'class2'
                    },
                ]
            },
            {
                type: getWord(['first2','second4']),
                id:'3',
                active:false,
                list:[
                    {
                        title: getWord('guanyahe'),
                        id:'3-1',
                        active:false,
                        id: 'class3'
                    },
                    {
                        title: getWord('guanyazuhe'),
                        id:'3-2',
                        active:false,
                        id: 'class4'
                    },
                ]
            },
            {
                type: getWord('danhao')+'1-5',
                id:'4',
                active:false,
                list:[
                    {
                        title:'1~5'+getWord('rank'),
                        id:'4-1',
                        active:false,
                        id: 'class5'
                    },
                ]
            },
            {
                type: getWord('danhao')+'6-10',
                id:'5',
                active:false,
                list:[
                    {
                        title:'6~10'+getWord('rank'),
                        id:'5-1',
                        active:false,
                        id: 'class6'
                    },
                ]
            }
        ],
        ssc:[
            {
                type: getWord('zhenghepan'),
                id:'1',
                active:false,
                list:[
                    {
                        title: getWord('zhenghepan'),
                        id:'1-1',
                        active:false,
                        id: 'class0'
                    },                
                ]
            },
            {
                type: getWord('shuzipan'),
                id:'2',
                active:false,
                list:[
                    {
                        title: getWord('shuzipan'),
                        id:'2-1',
                        active:false,
                        id: 'class1'
                    },
                ]
            },
            {
                type: getWord('liangmianpan'),
                id:'3',
                active:false,
                list:[
                    {
                        title: getWord('liangmianpan'),
                        id:'3-1',
                        active:false,
                        id: 'class2'
                    },
                ]
            },
            {
                type: getWord('first_ball'),
                id:'4',
                active:false,
                list:[
                    {
                        title: getWord('first_ball'),
                        id:'4-1',
                        active:false,
                        id: 'class3'
                    },
                ]
            },
            {
                type: getWord('second_ball'),
                id:'5',
                active:false,
                list:[
                    {
                        title: getWord('second_ball'),
                        id:'5-1',
                        active:false,
                        id: 'class4'
                    },
                ]
            },
            {
                type: getWord('third_ball'),
                id:'6',
                active:false,
                list:[
                    {
                        title: getWord('third_ball'),
                        id:'6-1',
                        active:false,
                        id: 'class5'
                    },
                ]
            },
            {
                type: getWord('fourth_ball'),
                id:'7',
                active:false,
                list:[
                    {
                        title: getWord('fourth_ball'),
                        id:'7-1',
                        active:false,
                        id: 'class6'
                    },
                ]
            },
            {
                type: getWord('fifth_ball'),
                id:'8',
                active:false,
                list:[
                    {
                        title: getWord('fifth_ball'),
                        id:'8-1',
                        active:false,
                        id: 'class7'
                    },
                ]
            },
        ],
        ttc:[
            {
                type: getWord('item_tema'),
                active:false,
                list:[
                    {
                        title: getWord(['item_tema','yi']),
                        active:false,
                        id: 'class1',
                        parentId: '1',
                    }, 
                    {
                        title: getWord(['item_tema','er']),
                        active:false,
                        id: 'class2',
                        parentId: '1',
                    }, 
                ]
            },
            {
                type: getWord('lianma'),
                active:false,
                list:[
                    {
                        title: getWord(['lianma','wu','zhong','te']),
                        active:false,
                        id: 'class3',
                        parentId: '2',
                    }, 
                    {
                        title: getWord(['lianma','liu','zhong','te']),
                        active:false,
                        id: 'class4',
                        parentId: '2',
                    }, 
                    {
                        title: getWord(['lianma','qi','zhong','te']),
                        active:false,
                        id: 'class5',
                        parentId: '2',
                    }, 
                    {
                        title: getWord(['lianma','ba','zhong','te']),
                        active:false,
                        id: 'class6',
                        parentId: '2',
                    }, 
                ]
            },
        ],
        lhc:[
            {
                type: getWord('item_tema'),
                id:'1',
                active:false,
                list:[
                    {
                        title: getWord('item_tema'),
                        id: 'class1',
                        active:false,
                    },                
                ]
            },
            {
                type: getWord('zhengma'),
                id:'2',
                active:false,
                list:[
                    {
                        title: getWord('zhengma'),
                        id: 'class2',
                        active:false,
                    },                
                ]
            },
            {
                type: getWord('zhengte'),
                id:'3',
                active:false,
                list:[
                    {
                        title: getWord(['zhengte','yi']),
                        id: 'class3',
                        parentId:'3',
                        active:false,
                    },   
                    {
                        title: getWord(['zhengte','er']),
                        id: 'class4',
                        parentId:'3',
                        active:false,
                    },    
                    {
                        title: getWord(['zhengte','san']),
                        id: 'class5',
                        parentId:'3',
                        active:false,
                    },     
                    {
                        title: getWord(['zhengte','si']),
                        id: 'class6',
                        parentId:'3',
                        active:false,
                    },    
                    {
                        title: getWord(['zhengte','wu']),
                        id: 'class7',
                        parentId:'3',
                        active:false,
                    },     
                    {
                        title: getWord(['zhengte','liu']),
                        id: 'class8',
                        parentId:'3',
                        active:false,
                    },  
                ]
            },        
            {
                type: getWord('zhengtewei'),
                id:'4',
                active:false,
                list:[
                    {
                        title: getWord('zhengtewei'),
                        id: 'class9',
                        active:false,
                    },                
                ]
            },
            {
                type: getWord('texiao'),
                id:'5',
                active:false,
                list:[
                    {
                        title: getWord('texiao'),
                        id: 'class10',
                        active:false,
                    },                
                ]
            },
            {
                type: getWord(['yi', 'xiao']),
                id:'6',
                active:false,
                list:[
                    {
                        title: getWord(['yi', 'xiao']),
                        id: 'class11',
                        active:false,
                    },                
                ]
            },
            {
                type: getWord('hexiao'),
                id:'7',
                active:false,
                list:[
                    {
                        title: getWord('hexiao'),
                        id: 'class12',
                        parentId: '7',
                        active:false,
                    },                
                ]
            },
            {
                type: getWord('xiaolian'),
                id:'8',
                active:false,
                list:[
                    {
                        title: getWord(['er', 'xiao', 'lian']),
                        id: 'class13',
                        parentId:'8',
                        active:false,
                    },
                    {
                        title: getWord(['san', 'xiao', 'lian']),
                        id: 'class14',
                        parentId:'8',
                        active:false,
                    },
                    {
                        title: getWord(['si', 'xiao', 'lian']),
                        id: 'class15',
                        parentId:'8',
                        active:false,
                    },
                    {
                        title: getWord(['wu', 'xiao', 'lian']),
                        id: 'class16',
                        parentId:'8',
                        active:false,
                    },
                ]
            },
            {
                type: getWord('banbo'),
                id:'9',
                active:false,
                list:[
                    {
                        title: getWord('banbo'),
                        id: 'class17',
                        active:false,
                    },                
                ]
            },
            {
                type: getWord('lianma'),
                id:'10',
                active:false,
                list:[
                    {
                        title: getWord(['si', 'quan', 'zhong']),
                        id: 'class18',
                        parentId:'10',
                        active:false,
                    },
                    {
                        title: getWord(['san', 'quan', 'zhong']),
                        id: 'class19',
                        parentId:'10',
                        active:false,
                    },
                    {
                        title: getWord(['san', 'zhong', 'er']),
                        id: 'class20',
                        parentId:'10',
                        active:false,
                    },
                    {
                        title: getWord(['er', 'quan', 'zhong']),
                        id: 'class21',
                        parentId:'10',
                        active:false,
                    },
                    {
                        title: getWord(['er', 'zhong', 'te']),
                        id: 'class22',
                        parentId:'10',
                        active:false,
                    },
                    {
                        title: getWord('techuan'),
                        id: 'class23',
                        parentId:'10',
                        active:false,
                    },
                ]
            },
            {
                type: getWord('weishulian'),
                id:'11',
                active:false,
                list:[
                    {
                        title: getWord(['er', 'wei', 'lian']),
                        id:'class24',
                        parentId: '11',
                        active:false,
                    },
                    {
                        title: getWord(['san', 'wei', 'lian']),
                        id:'class25',
                        parentId: '11',
                        active:false,
                    },
                    {
                        title: getWord(['si', 'wei', 'lian']),
                        id:'class26',
                        parentId: '11',
                        active:false,
                    },
                ]
            },
            {
                type: getWord('quanbuzhong'),
                id:'12',
                active:false,
                list:[
                    {
                        title: getWord(['wu','buzhong']),
                        id:'class27',
                        parentId: '12',
                        active:false,
                    },
                    {
                        title: getWord(['liu','buzhong']),
                        id:'class28',
                        parentId: '12',
                        active:false,
                    },
                    {
                        title: getWord(['qi','buzhong']),
                        id:'class29',
                        parentId: '12',
                        active:false,
                    },
                    {
                        title: getWord(['ba','buzhong']),
                        id:'class30',
                        parentId: '12',
                        active:false,
                    },
                    {
                        title: getWord(['jiu','buzhong']),
                        id:'class31',
                        parentId: '12',
                        active:false,
                    },
                    {
                        title: getWord(['shi','buzhong']),
                        id:'class32',
                        parentId: '12',
                        active:false,
                    },
                    {
                        title: getWord(['eleven','buzhong']),
                        id:'class33',
                        parentId: '12',
                        active:false,
                    },
                    {
                        title: getWord(['twelve','buzhong']),
                        id:'class34',
                        parentId: '12',
                        active:false,
                    },
                ]
            },
        ],
        fiveselect:[
            {
                type: getWord('shuzipan'),
                id:'1',
                active:false,
                list:[
                    {
                        title: getWord('shuzipan'),
                        id:'class1',
                        active:false,
                    },                
                ]
            },
            {
                type: getWord('liangmianpan'),
                id:'2',
                active:false,
                list:[
                    {
                        title: getWord('liangmianpan'),
                        id:'class2',
                        active:false,
                    },
                ]
            },
            {
                type: getWord('lianma'),
                id:'3',
                active:false,
                list:[
                    {
                        title: getWord(['lianma','yi','zhong','yi']),
                        id:'class3',
                        parentId: '3',
                        active:false,
                    },
                    {
                        title: getWord(['lianma','er','zhong','er']),
                        id:'class4',
                        parentId: '3',
                        active:false,
                    },
                    {
                        title: getWord(['lianma','san','zhong','san']),
                        id:'class5',
                        parentId: '3',
                        active:false,
                    },
                    {
                        title: getWord(['lianma','si','zhong','si']),
                        id:'class6',
                        parentId: '3',
                        active:false,
                    },
                    {
                        title: getWord(['lianma','wu','zhong','wu']),
                        id:'class7',
                        parentId: '3',
                        active:false,
                    },
                    {
                        title: getWord(['lianma','liu','zhong','wu']),
                        id:'class8',
                        parentId: '3',
                        active:false,
                    },
                    {
                        title: getWord(['lianma','qi','zhong','wu']),
                        id:'class9',
                        parentId: '3',
                        active:false,
                    },
                    {
                        title: getWord(['lianma','ba','zhong','wu']),
                        id:'class10',
                        parentId: '3',
                        active:false,
                    },
                    {
                        title: getWord(['qianer', 'zuxuan']),
                        id:'class11',
                        parentId: '3',
                        active:false,
                    },
                    {
                        title: getWord(['qiansan', 'zuxuan']),
                        id:'class12',
                        parentId: '3',
                        active:false,
                    },
                ]
            },
            {
                type: getWord('zhixuan'),
                id:'4',
                active:false,
                list:[
                    {
                        title: getWord(['qianer', 'zhixuan']),
                        id:'class13',
                        parentId: '4',
                        active:false,
                    },
                    {
                        title: getWord(['qiansan', 'zhixuan']),
                        id:'class14',
                        parentId: '4',
                        active:false,
                    },
                ]
            },
        ],
        klsf:[
            {
                type: getWord('shuzipan'),
                active:false,
                list:[
                    {
                        title: getWord('shuzipan'),
                        id: 'class1',
                        active:false,
                    },
                ]
            },
            {
                type: getWord('liangmianpan'),
                active:false,
                list:[
                    {
                        title: getWord('liangmianpan'),
                        id: 'class2',
                        active:false,
                    },
                ]
            },
            {
                type: getWord('number_of_item3'),
                active:false,
                list:[
                    {
                        title: getWord('first_ball'),
                        id: 'class3',
                        parentId: '3',
                        active:false,
                    },
                    {
                        title: getWord('second_ball'),
                        id: 'class4',
                        parentId: '3',
                        active:false,
                    },
                    {
                        title: getWord('third_ball'),
                        id: 'class5',
                        parentId: '3',
                        active:false,
                    },
                    {
                        title: getWord('fourth_ball'),
                        id: 'class6',
                        parentId: '3',
                        active:false,
                    },
                    {
                        title: getWord('fifth_ball'),
                        id: 'class7',
                        parentId: '3',
                        active:false,
                    },
                ]
            },
        ],
        klten:[
            {
                type: getWord('liangmianpan'),
                active:false,
                list:[
                    {
                        title: getWord('liangmianpan'),
                        id: 'class1',
                        active:false,
                    },
                ],
            },
            {
                type: getWord('danhao')+'1-8',
                active:false,
                list:[
                    {
                        title: getWord('danhao')+'1-8',
                        id: 'class2',
                        active:false,
                    },
                ],
            },
            {
                type: getWord('number_of_item3'),
                active:false,
                list:[
                    {
                        title: getWord('first_ball'),
                        id: 'class3',
                        parentId: '3',
                        active:false,
                    },
                    {
                        title: getWord('second_ball'),
                        id: 'class4',
                        parentId: '3',
                        active:false,
                    },
                    {
                        title: getWord('third_ball'),
                        id: 'class5',
                        parentId: '3',
                        active:false,
                    },
                    {
                        title: getWord('fourth_ball'),
                        id: 'class6',
                        parentId: '3',
                        active:false,
                    },
                    {
                        title: getWord('fifth_ball'),
                        id: 'class7',
                        parentId: '3',
                        active:false,
                    },
                    {
                        title: getWord('sixth_ball'),
                        id: 'class8',
                        parentId: '3',
                        active:false,
                    },
                    {
                        title: getWord('seventh_ball'),
                        id: 'class9',
                        parentId: '3',
                        active:false,
                    },
                    {
                        title: getWord('eighth_ball'),
                        id: 'class10',
                        parentId: '3',
                        active:false,
                    },
                ],
            },
            {
                type: getWord('zhengma'),
                active:false,
                list:[
                    {
                        title: getWord('zhengma'),
                        id: 'class11',
                        parentId: '4',
                        active:false,
                    },
                ],
            },
            {
                type: getWord('lianma'),
                active:false,
                list:[
                    {
                        title: getWord(['renxuan', 'er']),
                        id: 'class12',
                        parentId: '5',
                        active:false,
                    },
                    {
                        title: getWord('xuanerlianzu'),
                        id: 'class13',
                        parentId: '5',
                        active:false,
                    },
                    {
                        title: getWord(['renxuan', 'san']),
                        id: 'class14',
                        parentId: '5',
                        active:false,
                    },
                    {
                        title: getWord('xuansanqianzu'),
                        id: 'class15',
                        parentId: '5',
                        active:false,
                    },
                    {
                        title: getWord(['renxuan', 'si']),
                        id: 'class16',
                        parentId: '5',
                        active:false,
                    },
                    {
                        title: getWord(['renxuan', 'wu']),
                        id: 'class17',
                        parentId: '5',
                        active:false,
                    },
                ]
            },
        ],
        fc3d:[
            {
                type: getWord('zhushipan'),
                id:'1',
                active:false,
                list:[
                    {
                        title: getWord('zhushipan'),
                        id:'class1',
                        parentId: '1',
                        active:true,
                    }, 
                ]
            },
            {
                type: getWord(['yi', 'zi', 'combine']),
                id:'2',
                active:false,
                list:[
                    {
                        title: getWord(['yi', 'zi', 'combine']),
                        id:'class2',
                        parentId: '2',
                        active:false,
                    },
                ],
            },
            {
                type: getWord(['er', 'zi', 'combine']),
                id:'3',
                active:false,
                list:[
                    {
                        title: getWord(['er', 'zi', 'combine']),
                        id:'class3',
                        parentId: '3',
                        active:false,
                    },
                ]
            },
            {
                type: getWord(['yi', 'zi', 'positioning']),
                id:'4',
                active:false,
                list:[
                    {
                        title: getWord(['yi', 'zi', 'positioning']),
                        id:'class4',
                        parentId: '4',
                        active:false,
                    },
                ],
            },
            {
                type: getWord(['er', 'zi', 'positioning']),
                id:'5',
                active:false,
                list:[
                    {
                        title: getWord(['er', 'zi', 'positioning', 'bai2', 'shi2', 'place']),
                        id:'class5',
                        parentId: '5',
                        active:false,
                    },
                    {
                        title: getWord(['er', 'zi', 'positioning', 'bai2', 'ge', 'place']),
                        id:'class6',
                        parentId: '5',
                        active:false,
                    },
                    {
                        title: getWord(['er', 'zi', 'positioning', 'shi2', 'ge', 'place']),
                        id:'class7',
                        parentId: '5',
                        active:false,
                    },
                ],
            },
            {
                type: getWord(['er', 'zi', 'heshu']),
                id:'6',
                active:false,
                list:[
                    {
                        title: getWord(['er', 'zi', 'heshu']),
                        id:'class8',
                        parentId: '6',
                        active:false,
                    },
                ],
            },        
            {
                type: getWord(['zuxuan', 'san']),
                id:'7',
                active:false,
                list:[
                    {
                        title: getWord(['zuxuan', 'san']),
                        id:'class9',
                        parentId: '7',
                        active:false,
                    },
                ],
            },
            {
                type: getWord(['zuxuan', 'liu']),
                id:'8',
                active:false,
                list:[
                    {
                        title: getWord(['zuxuan', 'liu']),
                        id:'class10',
                        parentId: '8',
                        active:false,
                    },
                ],
            },
            {
                type: getWord('kuadu'),
                id:'9',
                active:false,
                list:[
                    {
                        title: getWord('kuadu'),
                        id:'class11',
                        parentId: '9',
                        active:false,
                    },
                ]
            },
        ],
        xync:[
            {
                type: getWord('liangmianpan'),
                active:false,
                list:[
                    {
                        title: getWord('liangmianpan'),
                        id: 'class1',
                        parentId: '1',
                        active:true,
                    }, 
                ]
            },
            {
                type: getWord('danhao')+'1-8',
                active:false,
                list:[
                    {
                        title: getWord('danhao')+'1-8',
                        id: 'class2',
                        parentId: '2',
                        active:false,
                    },
                ],
            },
            {
                type: getWord('zhengma'),
                active:false,
                list:[
                    {
                        title: getWord('zhengma'),
                        id: 'class3',
                        parentId: '4',
                        active:false,
                    },
                ]
            },
            {
                type: getWord('lianma'),
                active:false,
                list:[
                    {
                        title: getWord(['renxuan', 'er']),
                        id: 'class4',
                        parentId: '5',
                        active:false,
                    },
                    {
                        title: getWord('xuanerlianzu'),
                        id: 'class5',
                        parentId: '5',
                        active:false,
                    },
                    {
                        title: getWord(['renxuan', 'san']),
                        id: 'class6',
                        parentId: '5',
                        active:false,
                    },
                    {
                        title: getWord('xuansanqianzu'),
                        id: 'class7',
                        parentId: '5',
                        active:false,
                    },
                    {
                        title: getWord(['renxuan', 'si']),
                        id: 'class8',
                        parentId: '5',
                        active:false,
                    },
                    {
                        title: getWord(['renxuan', 'wu']),
                        id: 'class9',
                        parentId: '5',
                        active:false,
                    },
                ],
            },
            {
                type: getWord('first_ball'),
                active:false,
                list:[
                    {
                        title:getWord('first_ball'),
                        id: 'class10',
                        parentId: '3',
                        active:false,
                    },
                    {
                        title:getWord('second_ball'),
                        id: 'class11',
                        parentId: '3',
                        active:false,
                    },
                    {
                        title:getWord('third_ball'),
                        id: 'class12',
                        parentId: '3',
                        active:false,
                    },
                    {
                        title:getWord('fourth_ball'),
                        id: 'class13',
                        parentId: '3',
                        active:false,
                    },
                    {
                        title:getWord('fifth_ball'),
                        id: 'class14',
                        parentId: '3',
                        active:false,
                    },
                    {
                        title:getWord('sixth_ball'),
                        id: 'class15',
                        parentId: '3',
                        active:false,
                    },
                    {
                        title:getWord('seventh_ball'),
                        id: 'class16',
                        parentId: '3',
                        active:false,
                    },
                    {
                        title:getWord('eighth_ball'),
                        id: 'class17',
                        parentId: '3',
                        active:false,
                    },
                ],
            },
        ],
        tencent:[        
            {
                type: getWord('zhenghepan'),
                active:false,
                list:[
                    {
                        title: getWord('zhenghepan'),
                        id: 'class1',
                        active:false,
                    },
                ]
            },
            {
                type: getWord('longhudou'),
                active:false,
                list:[
                    {
                        title: getWord('longhudou'),
                        id: 'class2',
                        active:false,
                    }
                ]
            },
        ],
        wanneng:[
            {            
                type: getWord('single_number_of_item'),
                active:false,
                list:[
                    {
                        id:'1',
                        title: getWord('single_number_of_item'),
                        active:true,
                        alias:'ONE',
                    }, 
                ]
            },
            {            
                type: getWord('type_text'),
                active:false,
                list:[
                    {
                        id:'2',
                        title: getWord('type_text'),
                        active:false,
                        alias:'DA',
                    },
                ],
            },
            {            
                type: getWord('type_baozi'),
                active:false,
                list:[
                    {
                        id:'3',
                        title: getWord('type_baozi'),
                        active:false,
                        alias:'BAO',
                    },
                ]
            },
            {            
                type: getWord('type_wanbao'),
                active:false,
                list:[
                    {
                        id:'4',
                        title: getWord('type_wanbao'),
                        active:false,
                        alias:'WAN',
                    },
                ],
            },
        ],
        damacai:[
            {            
                type: getWord('single_number_of_item2'),
                active:false,
                list:[
                    {
                        id:'1',
                        title: getWord('single_number_of_item2'),
                        active:true,
                        alias:'ONE',
                    }, 
                ]
            },
            {            
                type: getWord('type_text2'),
                active:false,
                list:[
                    {
                        id:'2',
                        title: getWord('type_text2'),
                        active:false,
                        alias:'DA',
                    },
                ],
            },
            {            
                type: getWord('type_baozi2'),
                active:false,
                list:[
                    {
                        id:'3',
                        title: getWord('type_baozi2'),
                        active:false,
                        alias:'BAO',
                    },
                ]
            },
            {            
                type: getWord('type_wanbao2'),
                active:false,
                list:[
                    {
                        id:'4',
                        title: getWord('type_wanbao2'),
                        active:false,
                        alias:'WAN',
                    },
                ],
            },
        ],        
        duoduo:[
            {            
                type: getWord('single_number_of_item3'),
                active:false,
                list:[
                    {
                        id:'1',
                        title: getWord('single_number_of_item3'),
                        active:true,
                        alias:'ONE',
                    }, 
                ]
            },
            {            
                type: getWord('type_text3'),
                active:false,
                list:[
                    {
                        id:'2',
                        title: getWord('type_text3'),
                        active:false,
                        alias:'DA',
                    },
                ],
            },
            {            
                type: getWord('type_baozi2'),
                active:false,
                list:[
                    {
                        id:'3',
                        title: getWord('type_baozi2'),
                        active:false,
                        alias:'BAO',
                    },
                ]
            },
            {            
                type: getWord('type_wanbao2'),
                active:false,
                list:[
                    {
                        id:'4',
                        title: getWord('type_wanbao2'),
                        active:false,
                        alias:'WAN',
                    },
                ],
            },
        ],
        yuedawan:[
            {            
                type: getWord('single_number_of_item3'),
                active:false,
                list:[
                    {
                        id:'1',
                        title: getWord('single_number_of_item3'),
                        active:true,
                        alias:'ONE',
                    }, 
                ]
            },
            {            
                type: getWord('type_text'),
                active:false,
                list:[
                    {
                        id:'2',
                        title: getWord('type_text'),
                        active:false,
                        alias:'DA',
                    },
                ],
            },
            {            
                type: getWord('type_baozi2'),
                active:false,
                list:[
                    {
                        id:'3',
                        title: getWord('type_baozi2'),
                        active:false,
                        alias:'BAO',
                    },
                ]
            },
            {            
                type: getWord('type_wanbao2'),
                active:false,
                list:[
                    {
                        id:'4',
                        title: getWord('type_wanbao2'),
                        active:false,
                        alias:'WAN',
                    },
                ],
            },
        ],
        shaba88:[
            {            
                type: getWord('single_number_of_item3'),
                active:false,
                list:[
                    {
                        id:'1',
                        title: getWord('single_number_of_item3'),
                        active:true,
                        alias:'ONE',
                    }, 
                ]
            },
            {            
                type: getWord('type_text'),
                active:false,
                list:[
                    {
                        id:'2',
                        title: getWord('type_text'),
                        active:false,
                        alias:'DA',
                    },
                ],
            },
            {            
                type: getWord('type_baozi2'),
                active:false,
                list:[
                    {
                        id:'3',
                        title: getWord('type_baozi2'),
                        active:false,
                        alias:'BAO',
                    },
                ]
            },
            {            
                type: getWord('type_wanbao2'),
                active:false,
                list:[
                    {
                        id:'4',
                        title: getWord('type_wanbao2'),
                        active:false,
                        alias:'WAN',
                    },
                ],
            },
        ],
        saimahui:[
            {            
                type: getWord('single_number_of_item3'),
                active:false,
                list:[
                    {
                        id:'1',
                        title: getWord('single_number_of_item3'),
                        active:true,
                        alias:'ONE',
                    }, 
                ]
            },
            {            
                type: getWord('type_text'),
                active:false,
                list:[
                    {
                        id:'2',
                        title: getWord('type_text'),
                        active:false,
                        alias:'DA',
                    },
                ],
            },
            {            
                type: getWord('type_baozi2'),
                active:false,
                list:[
                    {
                        id:'3',
                        title: getWord('type_baozi2'),
                        active:false,
                        alias:'BAO',
                    },
                ]
            },
            {            
                type: getWord('type_wanbao2'),
                active:false,
                list:[
                    {
                        id:'4',
                        title: getWord('type_wanbao2'),
                        active:false,
                        alias:'WAN',
                    },
                ],
            },
        ],
        xinjapowz:[
            {            
                type: getWord('single_number_of_item4'),
                active:false,
                list:[
                    {
                        id:'1',
                        title: getWord('single_number_of_item4'),
                        active:true,
                        alias:'ONE',
                    }, 
                ]
            },
            {            
                type: getWord('type_text4'),
                active:false,
                list:[
                    {
                        id:'2',
                        title: getWord('type_text4'),
                        active:false,
                        alias:'DA',
                    },
                ],
            },
            {            
                type: getWord('type_baozi3'),
                active:false,
                list:[
                    {
                        id:'3',
                        title: getWord('type_baozi3'),
                        active:false,
                        alias:'BAO',
                    },
                ]
            },
            {            
                type: getWord('type_wanbao3'),
                active:false,
                list:[
                    {
                        id:'4',
                        title: getWord('type_wanbao3'),
                        active:false,
                        alias:'WAN',
                    },
                ],
            },
        ],
    }
}
const TEMPLATE2_titleMenu = function(){
    return {
        pc28:[
            {
                list:[
                    {
                        title: getWord('2010'),
                        linkTo:'/beijing28/room_list?_pathname=%2Fhome&_search=&type=bj28&name='+getWord('2010'),
                    },
                    {
                        title: getWord('2011'),
                        linkTo:'/beijing28/room_list?_pathname=%2Fhome&_search=&type=jnd28&name='+getWord('2011'),
                    },
                    {
                        title: getWord('twbg28'),
                        linkTo:'/beijing28/room_list?_pathname=%2Fhome&_search=&type=twbg28&name='+getWord('twbg28'),
                    },
                    {
                        title: getWord('mgelg28'),
                        linkTo:'/beijing28/room_list?_pathname=%2Fhome&_search=&type=mgelg28&name='+getWord('mgelg28'),
                    },
                    {
                        title: getWord('jndx28'),
                        linkTo:'/beijing28/room_list?_pathname=%2Fhome&_search=&type=jndx28&name='+getWord('jndx28'),
                    },
                    {
                        title: getWord('snfk28'),
                        linkTo:'/beijing28/room_list?_pathname=%2Fhome&_search=&type=snfk28&name='+getWord('snfk28'),
                    },
                ]
            }
        ],
        ssc:[
            {
                type:getWord('zhenghepan'),
                list:[
                    {
                        title:getWord('zhenghepan'),
                        active:false,
                        id:'class0',
                    },
                ]
            },
            {
                type:getWord('shuzipan'),
                list:[
                    {
                        title:getWord('shuzipan'),
                        active:false,
                        id:'class1',
                    },
                ]
            },
            {
                type:getWord('liangmianpan'),
                list:[
                    {
                        title:getWord('liangmianpan'),
                        active:false,
                        id:'class2',
                    }
                ]
            },
            {
                type:getWord('number_of_item3'),
                list:[
                    {
                        title:getWord(['number', 'yi', 'number_of_item2']),
                        active:false,
                        id:'class3',
                    },
                    {
                        title:getWord(['number', 'er', 'number_of_item2']),
                        active:false,
                        id:'class4',
                    },
                    {
                        title:getWord(['number', 'san', 'number_of_item2']),
                        active:false,
                        id:'class5',
                    },
                    {
                        title:getWord(['number', 'si', 'number_of_item2']),
                        active:false,
                        id:'class6',
                    },
                    {
                        title:getWord(['number', 'wu', 'number_of_item2']),
                        active:false,
                        id:'class7',
                    }
                ]
            }
        ],
        ttc:[
            {
                active:true,
                childNav:false,
                type: getWord('item_tema'),
                id:'1',
                list:[
                    {
                        
                        title: getWord('item_tema'),
                        active:false,
                        id:'class1',
                        parentId: '1',
                    },
                ],
            },
            {
                active:false,
                childNav:false,
                type: getWord('lianma'),
                list:[
                    {
                        title: getWord('lianma'),
                        active:false,
                        id:'class2',
                        parentId: '2',
                    },
                ]
            },
        ],
        pk10:[
            {
                active:true,
                childNav:false,
                type: getWord('shuzipan'),
                list:[
                    {
                        
                        title: getWord('shuzipan'),
                        active:false,
                        id:'class1',
                    },
                ],
            },
            {
                active:false,
                childNav:false,
                type: getWord('liangmianpan'),
                list:[
                    {
                        title: getWord('liangmianpan'),
                        active:false,
                        id:'class2',
                    },
                ]
            },
            {   
                active:false,
                childNav:false,
                type: getWord(['first2','second4']),
                list:[
                    {
                        title: getWord('guanyahe'),
                        active:false,
                        id:'class3',
                    },
                    {
                        title: getWord('guanyazuhe'),
                        active:false,
                        id:'class4',
                    },
                ]
            },
            {
                active:false,
                childNav:false,
                type: getWord('ranking'),
                list:[
                    {
                        title:'1~5'+getWord('rank'),
                        active:false,
                        id:'class5',
                    },
                    {
                        title:'6~10'+getWord('rank'),
                        active:false,
                        id:'class6',
                    },
                ]
            }
        ],
        lhc:[
            {
                active:true,
                childNav:false,
                type: getWord('item_tema'),
                list:[
                    {
                        title: getWord('item_tema'),
                        id: 'class1',
                        active:false,
                    }
                ]
            },
            {
                active:false,
                childNav:false,
                type: getWord('zhengma'),
                list:[
                    {
                        title: getWord('zhengma'),
                        id: 'class2',
                        active:false,
                    },
                    {
                        title: getWord(['zhengte','yi']),
                        id: 'class3',
                        active:false,
                    },
                    {
                        title: getWord(['zhengte','er']),
                        id: 'class4',
                        active:false,
                    },
                    {
                        title: getWord(['zhengte','san']),
                        id: 'class5',
                        active:false,
                    },
                    {
                        title: getWord(['zhengte','si']),
                        id: 'class6',
                        active:false,
                    },
                    {
                        title: getWord(['zhengte','wu']),
                        id: 'class7',
                        active:false,
                    },
                    {
                        title: getWord(['zhengte','liu']),
                        id: 'class8',
                        active:false,
                    },
                    {
                        title: getWord(['zhengtewei']),
                        id: 'class9',
                        active:false,
                    },
                ]
            },
            {
                active:false,
                childNav:false,
                type: getWord('zodiac'),
                list:[
                    {
                        title: getWord('texiao'),
                        id: 'class10',
                        active:false,
                    },
                    {
                        title: getWord(['yi', 'xiao']),
                        id: 'class11',
                        active:false,
                    },
                    {
                        title: getWord('hexiao'),
                        id: 'class12',
                        parentId: '7',
                        active:false,
                    },
                    {
                        title: getWord('xiaolian'),
                        id: 'class13',
                        parentId: '8',
                        active:false,
                    },
                ]
            },
            {
                active:false,
                childNav:false,
                type: getWord('other'),
                list:[
                    {
                        title: getWord('banbo'),
                        id: 'class14',
                        active:false,
                    },
                    {
                        title: getWord('lianma'),
                        id: 'class15',
                        parentId: '10',
                        active:false,
                    },
                    {
                        title: getWord('weishulian'),
                        id: 'class16',
                        parentId: '11',
                        active:false,
                    },
                    {
                        title: getWord('quanbuzhong'),
                        id: 'class17',
                        parentId: '12',
                        active:false,
                    },
                ]
            },
        ],
        tencent:[
            {
                type: getWord('game_rules2'),
                active:false,
                list:[
                    {
                        title: getWord('zhenghepan'),
                        active:false,
                        id: 'class1',
                    },
                    {
                        title:getWord('longhudou'),
                        active:false,
                        id: 'class2',
                    }
                ]
            },
        ],
        fiveselect:[        
            {
                type: getWord('shuzipan'),
                list:[
                    {
                        title: getWord('shuzipan'),
                        active:true,
                        id:'class1',
                        parentId:'1',
                    },
                ]
            },
            {
                type: getWord('liangmianpan'),
                list:[
                    {
                        title: getWord('liangmianpan'),
                        active:false,
                        id:'class2',
                        parentId:'2',
                    },
                ]
            },
            {
                type: getWord('lianma'),
                list:[
                    {
                        title: getWord('lianma'),
                        active:false,
                        id:'class3',
                        parentId:'3',
                    },
                ]
            },
            {
                type: getWord('zhixuan'),
                list:[
                    {
                        title: getWord('zhixuan'),
                        active:false,
                        id:'class4',
                        parentId:'4',
                    },
                ]
            },
        ],
        klsf:[        
            {
                type: getWord('shuzipan'),
                list:[
                    {
                        title: getWord('shuzipan'),
                        active:true,
                        id: 'class1'
                    },
                ]
            },
            {
                type: getWord('liangmianpan'),
                list:[
                    {
                        title: getWord('liangmianpan'),
                        active:false,
                        id: 'class2'
                    },
                ]
            },
            {
                type: getWord(['number', 'yi', 'number_of_item2']),
                list:[
                    {
                        title: getWord(['number', 'yi', 'number_of_item2']),
                        active:false,
                        id: 'class3'
                    },
                    {
                        title: getWord(['number', 'er', 'number_of_item2']),
                        active:false,
                        id: 'class4'
                    },
                    {
                        title: getWord(['number', 'san', 'number_of_item2']),
                        active:false,
                        id: 'class5'
                    },
                    {
                        title: getWord(['number', 'si', 'number_of_item2']),
                        active:false,
                        id: 'class6'
                    },
                    {
                        title: getWord(['number', 'wu', 'number_of_item2']),
                        active:false,
                        id: 'class7'
                    },
                ]
            },
        ],
        klten:[
            {
                type: getWord('liangmianpan'),
                list:[
                    {
                        title: getWord('liangmianpan'),
                        active:true,
                        id: 'class1',
                        parentId: '1',
                    }, 
                ]
            },
            {
                type: getWord('danhao')+'1-8',
                list:[
                    {
                        title:getWord('danhao')+'1-8',
                        active:false,
                        id: 'class2',
                        parentId: '2',
                    }, 
                ]
            },
            {
                type: getWord('first_ball'),
                list:[
                    {
                        title: getWord('first_ball'),
                        active:false,
                        id: 'class3',
                        parentId: '3',
                    },
                    {
                        title: getWord('second_ball'),
                        active:false,
                        id: 'class4',
                        parentId: '3',
                    },
                    {
                        title: getWord('third_ball'),
                        active:false,
                        id: 'class5',
                        parentId: '3',
                    },
                    {
                        title: getWord('fourth_ball'),
                        active:false,
                        id: 'class6',
                        parentId: '3',
                    },
                    {
                        title: getWord('fifth_ball'),
                        active:false,
                        id: 'class7',
                        parentId: '3',
                    },
                    {
                        title: getWord('sixth_ball'),
                        active:false,
                        id: 'class8',
                        parentId: '3',
                    },
                    {
                        title: getWord('seventh_ball'),
                        active:false,
                        id: 'class9',
                        parentId: '3',
                    },
                    {
                        title: getWord('eighth_ball'),
                        active:false,
                        id: 'class10',
                        parentId: '3',
                    },
                ]
            },               
            {
                type: getWord('zhengma'),
                list:[        
                    {
                        title: getWord('zhengma'),
                        active:false,
                        id: 'class11',
                        parentId: '4',
                    },
                ]
            },
            {
                type: getWord('lianma'),
                list:[
                    {
                        title: getWord('lianma'),
                        active:false,
                        id: 'class12',
                        parentId: '5',
                    },
                ]
            },
        ],
        fc3d:[        
            {
                type: getWord('zhushipan'),
                list:[
                    {
                        title: getWord('zhushipan'),
                        id: 'class1',
                        parentId: '1',
                        active:true,
                    }, 
                ]
            },
            {
                type: getWord(['yi', 'zi', 'combine']),
                list:[
                    {
                        title: getWord(['yi', 'zi', 'combine']),
                        id: 'class2',
                        parentId: '2',
                        active:false,
                    },
                    {
                        title: getWord(['er', 'zi', 'combine']),
                        id: 'class3',
                        parentId: '2',
                        active:false,
                    },
                ]
            },
            {
                type: getWord(['yi', 'zi', 'positioning']),
                list:[
                    {
                        title: getWord(['yi', 'zi', 'positioning']),
                        id: 'class4',
                        parentId: '4',
                        active:false,
                    },
                    {
                        title: getWord(['er', 'zi', 'positioning']),
                        id: 'class4',
                        parentId: '5',
                        active:false,
                    },
                    {
                        title: getWord(['er', 'zi', 'heshu']),
                        id: 'class6',
                        parentId: '6',
                        active:false,
                    },
                ]
            },        
            {
                type: getWord(['zuxuan', 'san']),
                list:[
                    {
                        title: getWord(['zuxuan', 'san']),
                        id: 'class7',
                        parentId: '7',
                        active:false,
                    },
                    {
                        title: getWord(['zuxuan', 'liu']),
                        id: 'class8',
                        parentId: '8',
                        active:false,
                    },
                    {
                        title: getWord('kuadu'),
                        id: 'class9',
                        parentId: '9',
                        active:false,
                    },
                ]
            },
        ],
        xync:[
            {
                type: getWord('liangmianpan'),
                active:false,
                list:[
                    {
                        title: getWord('liangmianpan'),
                        id: 'class1',
                        parentId: '1',
                        active:true,
                    }, 
                ]
            },
            {
                type: getWord('danhao')+'1-8',
                active:false,
                list:[
                    {
                        title: getWord('danhao')+'1-8',
                        id: 'class2',
                        parentId: '2',
                        active:false,
                    },
                ],
            },
            {
                type: getWord('zhengma'),
                active:false,
                list:[
                    {
                        title: getWord('zhengma'),
                        id: 'class3',
                        parentId: '4',
                        active:false,
                    },
                ]
            },
            {
                type: getWord('lianma'),
                active:false,
                list:[
                    {
                        title: getWord('lianma'),
                        id: 'class4',
                        parentId: '5',
                        active:false,
                    },
                ],
            },
            {
                type: getWord('first_ball'),
                active:false,
                list:[
                    {
                        title:getWord('first_ball'),
                        id: 'class5',
                        parentId: '3',
                        active:false,
                    },
                    {
                        title:getWord('second_ball'),
                        id: 'class6',
                        parentId: '3',
                        active:false,
                    },
                    {
                        title:getWord('third_ball'),
                        id: 'class7',
                        parentId: '3',
                        active:false,
                    },
                    {
                        title:getWord('fourth_ball'),
                        id: 'class8',
                        parentId: '3',
                        active:false,
                    },
                    {
                        title:getWord('fifth_ball'),
                        id: 'class9',
                        parentId: '3',
                        active:false,
                    },
                    {
                        title:getWord('sixth_ball'),
                        id: 'class10',
                        parentId: '3',
                        active:false,
                    },
                    {
                        title:getWord('seventh_ball'),
                        id: 'class11',
                        parentId: '3',
                        active:false,
                    },
                    {
                        title:getWord('eighth_ball'),
                        id: 'class12',
                        parentId: '3',
                        active:false,
                    },
                ]
            },
        ],
        wanneng:[
            {            
                type: getWord('single_number_of_item'),
                active:false,
                list:[
                    {
                        id:'1',
                        title: getWord('single_number_of_item'),
                        active:true,
                        alias:'ONE',
                    }, 
                ]
            },
            {            
                type: getWord('type_text'),
                active:false,
                list:[
                    {
                        id:'2',
                        title: getWord('type_text'),
                        active:false,
                        alias:'DA',
                    },
                ],
            },
            {            
                type: getWord('type_baozi'),
                active:false,
                list:[
                    {
                        id:'3',
                        title: getWord('type_baozi'),
                        active:false,
                        alias:'BAO',
                    },
                ]
            },
            {            
                type: getWord('type_wanbao'),
                active:false,
                list:[
                    {
                        id:'4',
                        title: getWord('type_wanbao'),
                        active:false,
                        alias:'WAN',
                    },
                ],
            },
        ],
        damacai:[
            {            
                type: getWord('single_number_of_item2'),
                active:false,
                list:[
                    {
                        id:'1',
                        title: getWord('single_number_of_item2'),
                        active:true,
                        alias:'ONE',
                    }, 
                ]
            },
            {            
                type: getWord('type_text2'),
                active:false,
                list:[
                    {
                        id:'2',
                        title: getWord('type_text2'),
                        active:false,
                        alias:'DA',
                    },
                ],
            },
            {            
                type: getWord('type_baozi2'),
                active:false,
                list:[
                    {
                        id:'3',
                        title: getWord('type_baozi2'),
                        active:false,
                        alias:'BAO',
                    },
                ]
            },
            {            
                type: getWord('type_wanbao2'),
                active:false,
                list:[
                    {
                        id:'4',
                        title: getWord('type_wanbao2'),
                        active:false,
                        alias:'WAN',
                    },
                ],
            },
        ],        
        duoduo:[
            {            
                type: getWord('single_number_of_item3'),
                active:false,
                list:[
                    {
                        id:'1',
                        title: getWord('single_number_of_item3'),
                        active:true,
                        alias:'ONE',
                    }, 
                ]
            },
            {            
                type: getWord('type_text3'),
                active:false,
                list:[
                    {
                        id:'2',
                        title: getWord('type_text3'),
                        active:false,
                        alias:'DA',
                    },
                ],
            },
            {            
                type: getWord('type_baozi2'),
                active:false,
                list:[
                    {
                        id:'3',
                        title: getWord('type_baozi2'),
                        active:false,
                        alias:'BAO',
                    },
                ]
            },
            {            
                type: getWord('type_wanbao2'),
                active:false,
                list:[
                    {
                        id:'4',
                        title: getWord('type_wanbao2'),
                        active:false,
                        alias:'WAN',
                    },
                ],
            },
        ],
        yuedawan:[
            {            
                type: getWord('single_number_of_item3'),
                active:false,
                list:[
                    {
                        id:'1',
                        title: getWord('single_number_of_item3'),
                        active:true,
                        alias:'ONE',
                    }, 
                ]
            },
            {            
                type: getWord('type_text'),
                active:false,
                list:[
                    {
                        id:'2',
                        title: getWord('type_text'),
                        active:false,
                        alias:'DA',
                    },
                ],
            },
            {            
                type: getWord('type_baozi2'),
                active:false,
                list:[
                    {
                        id:'3',
                        title: getWord('type_baozi2'),
                        active:false,
                        alias:'BAO',
                    },
                ]
            },
            {            
                type: getWord('type_wanbao2'),
                active:false,
                list:[
                    {
                        id:'4',
                        title: getWord('type_wanbao2'),
                        active:false,
                        alias:'WAN',
                    },
                ],
            },
        ],
        shaba88:[
            {            
                type: getWord('single_number_of_item3'),
                active:false,
                list:[
                    {
                        id:'1',
                        title: getWord('single_number_of_item3'),
                        active:true,
                        alias:'ONE',
                    }, 
                ]
            },
            {            
                type: getWord('type_text'),
                active:false,
                list:[
                    {
                        id:'2',
                        title: getWord('type_text'),
                        active:false,
                        alias:'DA',
                    },
                ],
            },
            {            
                type: getWord('type_baozi2'),
                active:false,
                list:[
                    {
                        id:'3',
                        title: getWord('type_baozi2'),
                        active:false,
                        alias:'BAO',
                    },
                ]
            },
            {            
                type: getWord('type_wanbao2'),
                active:false,
                list:[
                    {
                        id:'4',
                        title: getWord('type_wanbao2'),
                        active:false,
                        alias:'WAN',
                    },
                ],
            },
        ],
        saimahui:[
            {            
                type: getWord('single_number_of_item3'),
                active:false,
                list:[
                    {
                        id:'1',
                        title: getWord('single_number_of_item3'),
                        active:true,
                        alias:'ONE',
                    }, 
                ]
            },
            {            
                type: getWord('type_text'),
                active:false,
                list:[
                    {
                        id:'2',
                        title: getWord('type_text'),
                        active:false,
                        alias:'DA',
                    },
                ],
            },
            {            
                type: getWord('type_baozi2'),
                active:false,
                list:[
                    {
                        id:'3',
                        title: getWord('type_baozi2'),
                        active:false,
                        alias:'BAO',
                    },
                ]
            },
            {            
                type: getWord('type_wanbao2'),
                active:false,
                list:[
                    {
                        id:'4',
                        title: getWord('type_wanbao2'),
                        active:false,
                        alias:'WAN',
                    },
                ],
            },
        ],
        xinjapowz:[
            {            
                type: getWord('single_number_of_item4'),
                active:false,
                list:[
                    {
                        id:'1',
                        title: getWord('single_number_of_item4'),
                        active:true,
                        alias:'ONE',
                    }, 
                ]
            },
            {            
                type: getWord('type_text4'),
                active:false,
                list:[
                    {
                        id:'2',
                        title: getWord('type_text4'),
                        active:false,
                        alias:'DA',
                    },
                ],
            },
            {            
                type: getWord('type_baozi3'),
                active:false,
                list:[
                    {
                        id:'3',
                        title: getWord('type_baozi3'),
                        active:false,
                        alias:'BAO',
                    },
                ]
            },
            {            
                type: getWord('type_wanbao3'),
                active:false,
                list:[
                    {
                        id:'4',
                        title: getWord('type_wanbao3'),
                        active:false,
                        alias:'WAN',
                    },
                ],
            },
        ],
    }
}
const TEMPLATE1_titleMenu = function(){
    return Object.assign(TEMPLATE2_titleMenu(), TEMPLATE3_titleMenu())
}

function currentColorRule(gameType) {
    if (app.$route.query.type||localStorage.getItem('game')||gameType) {
        if (_TEMPLATE==='template-1'||_TEMPLATE==='template-2') {
            switch (gameType||app.$route.query.type||JSON.parse(localStorage.getItem('game')).type) {
                case 'bj28':
                case 'jnd28':
                case 'twbg28':
                case 'mgelg28':
                case 'jndx28':
                case 'snfk28':
                case 'pc28one':
                    return pc28ColorRule;
                    break;
                case 'bjsc':
                case 'scone':
                case 'scthree':
                case 'scfive':
                case 'xyft':
                case 'azxy10':
                    return pk10ColorRule;
                    break;
                case 'lhc':
                case 'lhcaomen':
                case 'lhcone':
                case 'lhcthree':
                case 'lhcfive':
                case 'lhcten':
                    return lhcColorRule;
                    break;    
                case 'gdfiveselect':
                case 'sdfiveselect':
                case 'jsfiveselect':
                case 'jxfiveselect':
                case 'shhfiveselect':
                case 'anhfiveselect':
                case 'shxfiveselect':
                    return gdfiveselectColorRule;
                    break;
                case 'gxklsf':
                case 'cqklten':
                    return gxklsfColorRule;
                    break;    
                case 'gdklten':
                    return gdkltenColorRule;
                    break;
                case 'tiantiancai':
                    return tiantiancaiColorRule;
                    break;
                default:
                    return defaultColorRule;
                    break;
            }
        }
        if (_TEMPLATE==='template-3') {
            var type=app.$route.query.type || gameType || JSON.parse(localStorage.getItem('game')).type
            switch (type) {
                case 'bj28':
                case 'jnd28':
                case 'twbg28':
                case 'mgelg28':
                case 'jndx28':
                case 'snfk28':
                case 'pc28one':
                    return pc28ColorRule;
                    break;
                case 'bjsc':
                case 'scone':
                case 'scthree':
                case 'scfive':
                case 'xyft':
                case 'azxy10':
                    return pk10ColorRule;
                    break;
                case 'lhc':
                case 'lhcaomen':
                case 'lhcone':
                case 'lhcthree':
                case 'lhcfive':
                case 'lhcten':
                    return lhcColorRule;
                    break;    
                case 'gdfiveselect':
                case 'sdfiveselect':
                case 'jsfiveselect':
                case 'jxfiveselect':
                case 'shhfiveselect':
                case 'anhfiveselect':
                case 'shxfiveselect':
                    return TEMPLATE3_gdfiveselectColorRule;
                    break;
                case 'gxklsf':
                case 'cqklten':
                    return gxklsfColorRule;
                    break;    
                case 'gdklten':
                    return gdkltenColorRule;
                    break;
                default:
                    return TEMPLATE3_defaultColorRule;
                    break;
            }   
        }
    } else {
        return false;
    }
}

function lastDrawingFormat(dataSet, drawingRule, colorRule, zodiacRule, noStatus, gameType) {
    if ((dataSet&&dataSet.status === 1) || noStatus === true) {
        var lastDrawingValues = [];
        drawingRule.forEach(rule => {
            if (dataSet[rule] !== '' && dataSet[rule] !== undefined && dataSet[rule] !== '?') {
                lastDrawingValues.push({
                    value: dataSet[rule],
                    color: null,
                    bgColor: null,
                    zodiac: null,
                })
            }
        })

        if (lastDrawingValues && lastDrawingValues.length !== 0) {
            colorRule(gameType).forEach(item => {                
                var _name = item.name;
                var _color = item.color;
                var _bgColor = item.bgColor ? item.bgColor : null
                item.related.forEach(item => {
                    lastDrawingValues.filter(data => {
                        return parseInt(data.value) === parseInt(item)
                    }).forEach(item => {
                        item.name = _name;
                        item.color = _color
                        item.bgColor = _bgColor
                    })
                })
            })
        }

        if (zodiacRule) {
            Object.keys(zodiacRule).forEach(item=>{
                var _zodiac = item.zodiac;
                zodiacRule[item].numbers.forEach(_item=>{
                    lastDrawingValues.filter(data=>{
                        return parseInt(data.value) === parseInt(_item)
                    }).forEach(__item=>{
                        __item.zodiac = zodiacRule[item].name;
                    })
                })
            })
        }
    }

    return lastDrawingValues
}

function formatNumbers(numbers) {
    var _result=[];
    numbers.forEach(item=>{
        _result.push({
            title:item<10&&item.toString().length==1?'0'+item.toString():item.toString()
        })
    })
    lhcColorRule.forEach(item => {
        var _color = item.color;
        var _name = item.name;
        item.related.forEach(item => {
            var index = _result.filter(data => {                    
                return data.title === item
            }).forEach(item=>{
                item.colorName = _name;
                item.color = _color
            })
        })
    })
    return _result;
}

function createType(that, result, typeRule, colorRule, createNumber, zodiacRule) {
    var _arr = [];
    var _type = [];
    var _sliced = [];
    var _ordered = [];
    var _result = result;
    var _typeRule = typeRule.reduce((acc, value) => acc.concat(value), []);
    
    createTtcNumber();
    createGdfiveselectNumber();
    createGdfiveselectZhixuanNumber();
    
    function formatTitle(title) {
        return isNaN( title )
                ?title
                :title<10
                    ?'0'+title.toString()
                    :title.toString()
    }

    function convertTitle(item) {
        if (that.gameType.indexOf('lhc')===0) {
            
            if (item.name) {
                if (item.name.split('-')[1] !== undefined) {
                    return formatTitle( item.name.split('-')[1] )
                }
                if (item.name.split('(')[1] !== undefined) {
                    return formatTitle( item.name.substring(item.name.indexOf('(')).replace(/[()]/g, "") )
                }
                if (item.name.indexOf('特码') > -1) {
                    return item.name.split('特码')[1]
                }
                if (item.name.indexOf('正特一') > -1) {
                    return item.name.split('正特一')[1]
                }
                if (item.name.indexOf('正特二') > -1) {
                    return item.name.split('正特二')[1]
                }
                if (item.name.indexOf('正特三') > -1) {
                    return item.name.split('正特三')[1]
                }
                if (item.name.indexOf('正特四') > -1) {
                    return item.name.split('正特四')[1]
                }
                if (item.name.indexOf('正特五') > -1) {
                    return item.name.split('正特五')[1]
                }
                if (item.name.indexOf('正特六') > -1) {
                    return item.name.split('正特六')[1]
                }
                if (item.name.indexOf('正特') > -1) {
                    return item.name.split('正特')[1].split('')[0]
                }
                if (item.name.indexOf('特肖') > -1) {
                    return item.name.split('特肖')[1]
                }
                if (item.name.indexOf('连码') > -1) {
                    return item.name.split('连码')[1]
                }
                if (item.name.indexOf('半波') > -1) {
                    return item.name.split('半波')[1]
                }
                if (item.name.indexOf('正码总和') > -1) {
                    return item.name.split('正码')[1].split('')[0]+item.name.split('正码')[1].split('')[2]
                }else {
                    return item.name    
                }
            }else {
                return item.name
            }
        }
        if (that.gameType === 'gxklsf') {
            // if (item.keyword===('ZONGDAN')) {
            //     return '总单'
            // } else if (item.keyword===('ZONGDA')) {
            //     return '总大'
            // } else if (item.keyword===('ZONGXIAO')) {
            //     return '总小'
            // } else if (item.keyword===('ZONGSHUANG')) {
            //     return '总双'
            // } else if (item.keyword===('ZONGWEIDA')) {
            //     return '总尾大'
            // } else if (item.keyword===('ZONGWEIXIAO')) {
            //     return '总尾小'
            // } else 
            if (item.keyword===('LONG')) {
                return getWord('long')
            } else if (item.keyword===('HU')) {
                return getWord('hu')
            } else if (item.name.indexOf('尾-大')>-1) {
                return '尾大'
            } else if (item.name.indexOf('尾-小')>-1) {
                return '尾小'
            } else if (item.name.indexOf('合数-单')>-1) {
                return '合数单'
            } else if (item.name.indexOf('合数-双')>-1) {
                return '合数双'
            } else if (item.name.indexOf('球-大')>-1) {
                return '大'
            } else if (item.name.indexOf('球-小')>-1) {
                return '小'
            } else if (item.name.indexOf('球-单')>-1) {
                return '单'
            } else if (item.name.indexOf('球-双')>-1) {
                return '双'
            } else if (item.name.indexOf('球-红')>-1) {
                return '红'
            } else if (item.name.indexOf('球-绿')>-1) {
                return '绿'
            } else if (item.name.indexOf('球-蓝')>-1) {
                return '蓝'
            } else {
                return item.name;
            }
        }
        if (that.gameType === 'cqssc' || that.gameType === 'hljssc' || that.gameType === 'tjssc' || that.gameType === 'sscone' || that.gameType === 'sscthree' || that.gameType === 'sscfive' || that.gameType === 'azxy5') {
            if (item.name.split('-')[1] === undefined) {
                return item.name;
            } else if (item.name.indexOf('总和') > -1) {
                return item.name[0] + item.name[item.name.length - 1]
            } else {
                return item.name.split('-')[1]
            }
        }
        if (that.gameType === 'tencent' || that.gameType === 'jndxy') {
            if (item.name.split('-')[1] === undefined) {                
                if (item.keyword.indexOf('ONE_') > -1 || item.keyword.indexOf('TWO_') > -1 || item.keyword.indexOf('THREE_') > -1 || item.keyword.indexOf('FOUR_') > -1 || item.keyword.indexOf('FIVE_') > -1) {
                    return item.keyword.split('_')[1]
                }
                return item.name;
            } else if (item.name.indexOf('总和') > -1) {
                return item.name[0] + item.name[item.name.length - 1]
            } else if (item.keyword.indexOf('LONG_') > -1) {
                return getWord('long')
            } else if (item.keyword.indexOf('HU_') > -1) {
                return getWord('hu')
            } else if (item.keyword.indexOf('HE_') > -1) {
                return getWord('draw')
            } else {
                return item.name.split('-')[1]
            }
        }
        if (that.gameType === 'bjsc' || that.gameType === 'scone' || that.gameType === 'scthree' || that.gameType === 'scfive' || that.gameType === 'xyft' || that.gameType === 'azxy10') {
            if (item.name) {
                if (item.name.split('-')[1] === undefined) {
                    if (item.name.indexOf('龙') > -1 || item.name.indexOf('虎') > -1) {
                        return item.name.slice(-1)
                    } else if (item.name.indexOf('冠亚和') > -1) {
                        return item.name.slice(3)
                    } else {
                        return item.name;
                    }
                } else if (item.name.indexOf('冠亚军组合') > -1) {
                    return item.name.slice(5)
                } else {
                    return item.name.split('-')[1]
                }
            }
        }
        if (that.gameType.indexOf('fastthree' )>-1) {
            if (item.name.indexOf('点数') > -1) {
                return item.name.split('_')[1] + '点'
            } else if (item.keyword.indexOf('WEISHAI_') === 0) {
                return item.keyword.split('_')[1] + item.keyword.split('_')[1] + item.keyword.split('_')[1]
            } else if (item.keyword.indexOf('CHANG_') === 0) {
                return item.keyword.split('_')[1] + item.keyword.split('_')[2]
            } else if (item.keyword.indexOf('DUAN_') > -1) {
                return item.keyword.split('_')[1] + item.keyword.split('_')[1]
            } else if (item.keyword.indexOf('SANJUN_') > -1) {
                return item.keyword.split('_')[1]
            } else {
                return item.name
            }
        }
        if (that.gameType.indexOf('fiveselect')>-1) {
            if (item.name.split('-')[1] === undefined) {                
                return item.name;   
            } else if (item.name.indexOf('总和') > -1) {
                if (item.keyword==='ALLWEIDA') {                    
                    return '总尾大'
                }else if (item.keyword==='ALLWEIXIAO') {
                    return '总尾小'
                }else {
                    return item.name[0] + item.name[item.name.length - 1]
                }
            } else {                
                return item.name.split('-')[1]                
            }
        }
        if (that.gameType === 'fc3d') {
            if (item.name.split('二字组合')[1] !== undefined) {
                return item.name.slice(4);
            }else if ( (item.name.split('佰位')[1] !== undefined || item.name.split('拾位')[1] !== undefined || item.name.split('个位')[1] !== undefined) && item.name.length===3 ){
                return item.name.slice(2);
            }else if ( item.name.split('佰拾和尾数')[1] !== undefined || item.name.split('佰个和尾数')[1] !== undefined || item.name.split('拾个和尾数')[1] !== undefined || item.name.split('佰拾个和数')[1] !== undefined ){
                return item.name.slice(-1);
            }else if ( item.name.indexOf('二字和数')>-1 ) {
                return item.name.split('_')[1]
            }else if ( item.name.indexOf('跨度')>-1 ) {
                return item.name.split('_')[1]
            }else {
                return item.name
            }
        }
        if (that.gameType === 'gdklten') {

            if (window.language==='cn' && item.name.indexOf('第')===0) {
                return item.name.slice(3);
            } else if (window.language==='cn' && item.name.indexOf('正码')===0) {
                return item.name.slice(2)
            } else {
                return item.name
            }
        }
        if (that.gameType === 'cqklten') {

            if (window.language==='cn' && item.name.indexOf('第')===0) {
                return item.name.slice(3);
            } else if (window.language==='cn' && item.name.indexOf('正码')===0) {
                return item.name.slice(2)
            } else if (window.language!=='cn' && item.keyword.split('_').length===2) {
                return item.keyword.split('_')[1]
            } else if (window.language!=='cn' && item.keyword.split('_').length===3) {
                return item.keyword.split('_')[1]+item.keyword.split('_')[2]
            } else{
                return item.name
            }
        }
        if (that.gameType === 'tiantiancai') {
            if ( !isNaN(item.class) ) {
                return item.name.split('-')[1]>9
                            ?item.name.split('-')[1]
                            :'0'+item.name.split('-')[1]
            }else {
                return item.name
            }
        }
    }

    function bindClass(item) {
        return _typeRule.filter(_item => {
            return item.keyword === _item.id
        })[0].relatedClass
    }

    function changeOriginalTitle(item) {
        if (that.gameType === 'fc3d') {
            switch (true) {
                case (item==="二字定位百十位"):
                    return '二字定位佰拾位'
                    break;
                case (item==="二字定位百个位"):
                    return '二字定位佰个位'
                    break;
                case (item==="二字定位十个位"):
                    return '二字定位拾个位'
                    break;
                default:
                    return item
            }
        }else {
            return item;
        }
    }

    function convertOriginalTitle(item) {
        if (that.gameType === 'cqssc' || that.gameType === 'hljssc' || that.gameType === 'tjssc' || that.gameType === 'sscone' || that.gameType === 'sscthree' || that.gameType === 'sscfive' || that.gameType === 'tencent' || that.gameType.indexOf('fiveselect')>-1 || that.gameType === 'gxklsf' || that.gameType === 'jndxy' || that.gameType === 'azxy5') {
            switch (item) {
                case "ONE":
                    return getWord(['number', 'yi', 'ball'])
                    break;
                case "TWO":
                    return getWord(['number', 'er', 'ball'])
                    break;
                case "THREE":
                    return getWord(['number', 'san', 'ball'])
                    break;
                case "FOUR":
                    return getWord(['number', 'si', 'ball'])
                    break;
                case "FIVE":
                    return getWord(['number', 'wu', 'ball'])
                    break;
                case "QIANER":
                    return getWord(['qianer', 'zhixuan'])
                    break;
                case "QIANSAN":
                    return getWord(['qiansan', 'zhixuan'])
                    break;
                default:
                    return "NA"
            }
        }
        if (that.gameType === 'bjsc' || that.gameType === 'scone' || that.gameType === 'scthree' || that.gameType === 'scfive' || that.gameType === 'xyft' || that.gameType === 'azxy10') {
            switch (item) {
                case "ONE":
                    return getWord('first4')
                    break;
                case "TWO":
                    return getWord('second4')
                    break;
                case "THREE":
                    return getWord('third4')
                    break;
                case "FOUR":
                    return getWord('fourth_place')
                    break;
                case "FIVE":
                    return getWord('fifth_place')
                    break;
                case "SIX":
                    return getWord('sixth_place')
                    break;
                case "SEVEN":
                    return getWord('seventh_place')
                    break;
                case "EIGHT":
                    return getWord('eight_place')
                    break;
                case "NINE":
                    return getWord('ninth_place')
                    break;
                case "TEN":
                    return getWord('tenth_place')
                    break;
                default:
                    return "NA"
            }
        }
        if (that.gameType === 'fc3d') {
            switch (true) {
                case (item==="ONEMERGER"):
                    return getWord(['yi', 'zi', 'combine'])
                    break;
                case (item==="YIZIBAIWEI"):
                    return getWord(['yi', 'zi', 'combine', 'bai', 'place'])
                    break;
                case (item==="YIZISHIWEI"):
                    return getWord(['yi', 'zi', 'combine', 'shi', 'place'])
                    break;
                case (item==="YIZIGEWEI"):
                    return getWord(['yi', 'zi', 'combine', 'ge', 'place'])
                    break;
                case (item.indexOf("TWOMERGER_")>-1):
                    return getWord(['er', 'zi', 'combine'])
                    break;
                default:
                    return "NA"
            }
        }
    }

    function bindZodiacBall(data) {
        var _tmp=zodiacRule.filter(item=>{
            return data.class===item.id
        })        
        if (_tmp.length!==0){
            return formatNumbers(_tmp[0].numbers);
        }else {
            return null;
        }
    }

    function bindBanBoBall(data) {      
        for (var i=0;i<lhcBanBoRule.length;i++) {
            if (lhcBanBoRule[i].id===data.keyword){
                return {
                    numbers:lhcBanBoRule[i].related,
                    color:lhcBanBoRule[i].color
                };
                break;
            }
        }
    }

    function bindPermutation(data){
        if (data.keyword.indexOf('LIANMA_4_')===0) return 4
        if (data.keyword.indexOf('LIANMA_3_')===0) return 3
        if (data.keyword.indexOf('LIANMA_2_')===0) return 2
        if (data.keyword.indexOf('LIANMA_1_IN_TEMA')===0) return 2    
    }

    //处理数据 使之与UI一致
    if (that.gameType.indexOf('lhc')===0) {
        if (result.data.result.dingwei) {
            delete result.data.result.dingwei
        }

        _result = Object.values(result.data.result).reduce((acc, value) => acc.concat(value), []);

        _result.forEach(item => {
            _type.push({
                originalTitle: item.name,
                title: convertTitle(item),
                id: item.keyword,
                orderby:item.class,
                odds: item.odds,
                status: item.status,
                select: false,
                class: bindClass(item),
                zodiacBall: bindZodiacBall(item),
                banBoBall: item.parent_key==='COLOR'?bindBanBoBall(item):null,
                permutation: item.parent_key==='LIANMA'?bindPermutation(item):null,
                oldname: item.old_name,
            })
        });

        _type.findIndex(data => {
            var _item = _typeRule.filter(item => {
                return item.id === data.id
            })[0]
            if (_typeRule.includes(_item)) {
                _ordered[_typeRule.findIndex(item => item.id === data.id)] = data
            }
        })
        var _lhcZodiacList=lhcZodiacList()
        _lhcZodiacList.forEach(item=>{
            item.numbers=formatNumbers(zodiacRule.filter(_item=>{
                return _item.id===item.id
            })[0].numbers);
        })
        zodiacRule.forEach( (_item,index)=>{
            _lhcZodiacList[index].special=_item.isBenNian?_item.isBenNian:null
        })

        that.lhcAnimalRule=_lhcZodiacList;

        colorRule.forEach(item => {
            var _color = item.color;
            var _name = item.name;
            item.related.forEach(item => {
                var index = _ordered.filter(data => {                   
                    return data.title === item
                }).forEach(item=>{
                    item.color = _color;
                    item.colorName = _name;
                })
            })
        })
    }

    if (that.gameType === 'cqklten') {
        if (result.data.result.dingwei) {
            delete result.data.result.dingwei
        }
        if (result.data.result.tema) {
            delete result.data.result.tema
        }
        if (result.data.result.TEMA) {
            delete result.data.result.TEMA
        }

        _result = Object.values(result.data.result).reduce((acc, value) => acc.concat(value), []);
        _result.forEach((item, index) => {
            _arr[index] = Object.values(item).reduce((acc, value) => acc.concat(value), []);
        })
        _result = _arr.reduce((acc, value) => acc.concat(value), []);

        _result.forEach(item => {
            _type.push({
                originalTitle: item.name,
                title: convertTitle(item),
                orderby:item.class,
                id: item.keyword,
                odds: item.odds,
                status: item.status,
                select: false,
                class: bindClass(item),
                oldname: item.old_name,
            })
        });

        _type.findIndex(data => {
            var _item = _typeRule.filter(item => {
                return item.id === data.id
            })[0]
            if (_typeRule.includes(_item)) {
                _ordered[_typeRule.findIndex(item => item.id === data.id)] = data
            }
        })    

        // colorRule.forEach(item => {
        //     var _color = item.color;
        //     item.related.forEach(item => {
        //         var index = _ordered.findIndex(data => data.id.indexOf(item)>-1)
        //         _ordered[index].color = _color
        //     })
        // })
    }

    if (that.gameType.indexOf('28')>-1) {
        _result.data.result.TEMA.forEach(item => {
            _type.push({
                title: item.name,
                id: item.keyword,
                odds: item.odds,
                status: item.status,
                select: false,
                oldname: item.old_name,
                lang_key: item.lang_key,
            })
        });

        if(this._TEMPLATE!=='template-3') {

            _ordered = _type.slice(0)
            _ordered.sort(function(a, b) {
                return a.id < b.id ? -1 : a.id > b.id ? 1 : 0;
            })

            //POLYFILL ORIGIN
            // this.pc28TypeRule.reverse().forEach(item => {
            //     item.reverse().forEach(item => {
            //         _ordered.sort(function(x, y) { return x.id == item ? -1 : y.id == item ? 1 : 0; });
            //     })
            // })
            var _orderedChars = [];
            var _orderedNum = _ordered.slice(0, 28)

            _type.length = 0;
            _type = _ordered.slice(28)
            _type.findIndex(data => {
                if (_typeRule.includes(data.id)) {
                    _orderedChars[_typeRule.findIndex(item => item == data.id)] = data
                }
            })

            _sliced = _orderedChars.concat(_orderedNum)
        }else {
            _type.findIndex(data => {
                var _item = _typeRule.filter(item => {
                    return item.id === data.id
                })[0]
                if (_typeRule.includes(_item)) {
                    _ordered[_typeRule.findIndex(item => item.id === data.id)] = data
                }
            })
        }

        colorRule.forEach(item => {
            var _color = item.color;
            item.related.forEach(item => {
                var index = _ordered.findIndex(data => data.id === item)
                _ordered[index].color = _color
            })
        })
    }

    if (that.gameType.indexOf('fastthree')>-1){
        delete result.data.result.dingwei
        delete result.data.result.tema

        _result = Object.values(result.data.result).reduce((acc, value) => acc.concat(value), []);
        _result.forEach((item, index) => {
            _arr[index] = Object.values(item).reduce((acc, value) => acc.concat(value), []);
        })
        _result = _arr.reduce((acc, value) => acc.concat(value), []);

        _result.forEach(item => {
            _type.push({
                originalTitle: item.name,
                title: convertTitle(item),
                id: item.keyword,                
                odds: item.odds,
                status: item.status,
                select: false,
                class: bindClass(item),
                oldname:item.old_name,
            })
        });

        _type.findIndex(data => {
            var _item = _typeRule.filter(item => {
                return item.id === data.id
            })[0]
            if (_typeRule.includes(_item)) {
                _ordered[_typeRule.findIndex(item => item.id === data.id)] = data
            }
        })
    }

    if (that.gameType === 'tiantiancai') {
        delete result.data.result.dingwei;
        delete result.data.result.tema;
        delete result.data.result.TEMA;

        var _arr = []
        for (var i in result.data.result.TEMA1) {
            _arr.push( result.data.result.TEMA1[i] )
        }
        result.data.result.TEMA1=_arr;
        _arr = []
        for (var i in result.data.result.TEMA2) {
            _arr.push( result.data.result.TEMA2[i] )
        }
        result.data.result.TEMA2=_arr;
        _arr = []
        for (var i in result.data.result.LIANMA) {
            _arr.push( result.data.result.LIANMA[i] )
        }
        result.data.result.LIANMA=_arr;
        // _arr = []
        // for (var i in result.data.result.SEVEN_TEMA) {
        //     _arr.push( result.data.result.SEVEN_TEMA[i] )
        // }
        // result.data.result.SEVEN_TEMA=_arr;
        // _arr = []
        // for (var i in result.data.result.EIGHT_TEMA) {
        //     _arr.push( result.data.result.EIGHT_TEMA[i] )
        // }
        // result.data.result.EIGHT_TEMA=_arr;
        _result = Object.values(result.data.result).reduce((acc, value) => acc.concat(value), []);
        
        _result.forEach(item => {
            _type.push({
                originalTitle: changeOriginalTitle(item.name),
                title: convertTitle(item),
                id: item.keyword,
                orderby:item.class,
                parent:item.parent_key,
                odds: item.odds,
                status: item.status,
                select: false,
                class: bindClass(item),
                oldname: item.old_name,
            })
        });

        _type.findIndex(data => {
            var _item = _typeRule.filter(item => {
                return item.id === data.id
            })[0]
            if (_typeRule.includes(_item)) {
                _ordered[_typeRule.findIndex(item => item.id === data.id)] = data
            }
        })  

        colorRule.forEach(item => {
            var _color = item.color;
            var _name = item.name;
            item.related.forEach(item => {
                var index = _ordered.filter(data => {                   
                    return data.title === item
                }).forEach(item=>{
                    item.color = _color;
                    item.colorName = _name;
                })
            })
        })
    }

    if (that.gameType === 'tencent' || 
        that.gameType === 'cqssc' || 
        that.gameType === 'hljssc' || 
        that.gameType === 'tjssc' || 
        that.gameType === 'sscone' || 
        that.gameType === 'sscthree' || 
        that.gameType === 'sscfive' || 
        that.gameType === 'bjsc' || 
        that.gameType === 'scone' || 
        that.gameType === 'scthree' || 
        that.gameType === 'scfive' || 
        that.gameType === 'xyft' || 
        that.gameType === 'azxy10' || 
        that.gameType.indexOf('fiveselect')>-1 || 
        that.gameType === 'fc3d' || 
        that.gameType === 'gxklsf' || 
        that.gameType === 'gdklten' || 
        that.gameType === 'jndxy' ||
        that.gameType === 'azxy5') {

        var originalNumber;
        var startNumber;        
        var total;
        var endNumber;

        function init(){
            startNumber=0;
            if (that.gameType === 'bjsc' || that.gameType === 'scone' || that.gameType === 'scthree' || that.gameType === 'scfive' || that.gameType === 'xyft' || that.gameType === 'azxy10' || that.gameType.indexOf('fiveselect')>-1 || that.gameType === 'gxklsf') {
                startNumber=1;
            }
            total=(that.gameType.indexOf('fiveselect')>-1)
                    ?11
                    :that.gameType === 'gxklsf'
                        ?21
                        :10
            endNumber=startNumber+total
        }
        init();

        delete result.data.result.dingwei
        delete result.data.result.tema

        _result = Object.values(result.data.result).reduce((acc, value) => acc.concat(value), []);
        _result.forEach((item, index) => {
            _arr[index] = Object.values(item).reduce((acc, value) => acc.concat(value), []);
        })
        _result = _arr.reduce((acc, value) => acc.concat(value), []);

        _result.forEach(item => {
            _type.push({
                originalTitle: changeOriginalTitle(item.name),
                title: convertTitle(item),
                id: item.keyword,
                orderby:item.class,
                parent:item.parent_key,
                odds: item.odds,
                status: item.status,
                select: false,
                class: bindClass(item),
                oldname: item.old_name,
                lang_key: item.lang_key,
            })
        });

        _type.findIndex(data => {
            var _item = _typeRule.filter(item => {
                return item.id === data.id
            })[0]
            if (_typeRule.includes(_item)) {
                _ordered[_typeRule.findIndex(item => item.id === data.id)] = data
            }
        })     
        
        if (createNumber){
            createNumber.forEach(item=>{
                var _item=_ordered.filter(data=>{
                    return data.id===item
                })[0]                
                var _index=_ordered.findIndex(data=>{
                    return data.id===item
                })

                if(_item.id.indexOf('TWOMERGER')>-1){
                    originalNumber=parseInt(_item.id.substr(10,2))
                    startNumber=parseInt(_item.id.split('_')[1].split('-')[0])
                    endNumber=parseInt(_item.id.split('_')[1].split('-')[1])+1
                }else if (_item.id.indexOf('YIZIBAIWEI')>-1 || _item.id.indexOf('YIZISHIWEI')>-1 || _item.id.indexOf('YIZIGEWEI')>-1){
                    originalNumber=0
                    init();
                }else {                    
                    init();
                    originalNumber=startNumber
                }
                var _data=[];

                var max=_item.id==='QIANER'?2:_item.id==='QIANSAN'?3:1;                

                function _number(n,i){
                    if ( that.gameType === 'fc3d' ) {
                        return (originalNumber+n)
                    }else if ( that.gameType === 'gxklsf' ) {
                        return i>9?i:'0'+i
                    }else {
                        return i
                    }
                }

                function convertNumberTitlte(i){
                    if ( that.gameType === 'fc3d' ) {
                        if ( _item.id.indexOf('TWOMERGER')>-1 ) {                            
                            return i>9?i:'0'+i        
                        }else {
                            return i
                        }                        
                    }else if ( that.gameType === 'gxklsf' ) {
                        return i>9?i:'0'+i
                    }else {
                        return i
                    }
                }

                for (var j=0;j<max;j++){
                    for (var i=startNumber,n=0;i<endNumber;i++,n++){
                        _data.push({
                            id:_item.id,                            
                            odds:_item.odds,
                            select:_item.select,
                            status:_item.status,
                            originalTitle:convertOriginalTitle(_item.id)+'-'+_number(n,i),
                            title:convertNumberTitlte(i),
                            // color:'#3985E1',
                            class:_item.class,
                            oldname: _item.oldname+'-'+_number(n,i),
                            lang_key: _item.lang_key,
                        })
                    }
                }
                _ordered.splice(_index,1)
                _ordered.splice(_index, 0, ..._data);
            })
        }

        if (that.gameType==='gxklsf') {
            colorRule.forEach(item => {
                var _color = item.color;
                item.related.forEach(item => {        
                    var index = _ordered.filter(data => {
                        if( isNaN(item) ) {
                            return (data.id === item)
                        }else {
                            return (data.title.toString() === item)
                        }
                    }).forEach(item=>{
                        item.color = _color
                    })
                })
            })
        }else if ( (that.gameType==='azxy10'||that.gameType==='xyft'||that.gameType==='bjsc'||that.gameType==='scone'||that.gameType==='scthree'||that.gameType==='scfive'||that.gameType.indexOf('ssc')>-1||that.gameType.indexOf('fiveselect')>-1) && that._TEMPLATE==='template-3') {
            colorRule.forEach(item => {
                var _color = item.bgColor;
                item.related.forEach(item => {
                    var index = _ordered.filter(data => {
                        return (data.title === Number(item))
                    }).forEach(item=>{
                        item.color = _color
                    })
                })
            })
        }else {
            _ordered.forEach(item=>{
                if( isNaN(parseInt(item.title)) ) {

                }else {
                    item.color='#3985E1'
                }
            })
        }
    }

    //根据 TypeTitle 换行
    if (that.gameType.indexOf('lhc')===0) {
        _sliced = _ordered.reduce((acc, val, index) => {
            if (_TEMPLATE==='template-3') {
                if (!index || val.title === '0' || val.title === '01' || val.id === 'COLOR_RED_DA' || val.id === 'LIANMA_4_ALLIN' || val.id === 'LIANMA_3_ALLIN' || val.id === 'LIANMA_3_IN_2' || val.id === 'LIANMA_2_ALLIN' || val.id === 'LIANMA_2_IN_TEMA' || val.id === 'LIANMA_1_IN_TEMA' || val.id === 'ALLNOTIN_5' || val.id === 'ALLNOTIN_6' || val.id === 'ALLNOTIN_7' || val.id === 'ALLNOTIN_8' || val.id === 'ALLNOTIN_9' || val.id === 'ALLNOTIN_10' || val.id === 'ALLNOTIN_11' || val.id === 'ALLNOTIN_12' || val.title === '(1)' || val.id === 'TEMA_ANIMAL_SHU' || val.id === 'ANIMAL_ONE_SHU' || val.id === 'TEMA_HE_ANIMAL_1_IN' || val.id === 'ANIMAL_LIAN_2_IN' || val.id === 'ANIMAL_LIAN_3_IN' || val.id === 'ANIMAL_LIAN_4_IN' || val.id === 'ANIMAL_LIAN_5_IN' || val.id === 'WEI_LIAN_2_IN' || val.id === 'WEI_LIAN_3_IN' || val.id === 'WEI_LIAN_4_IN' || val.id === 'WEI_0' || val.id === 'NUMBER_1') acc.push([])
            }else {
            if (!index || val.title === '0' || val.title === '01' || val.id === 'COLOR_RED_DA' || val.id === 'LIANMA_4_ALLIN'  || val.id === 'ALLNOTIN_5' || val.title === '(1)' || val.id === 'TEMA_ANIMAL_SHU' || val.id === 'ANIMAL_ONE_SHU' || val.id === 'TEMA_HE_ANIMAL_1_IN' || val.id === 'ANIMAL_LIAN_2_IN' || val.id === 'WEI_LIAN_2_IN' || val.id === 'WEI_0' || val.id === 'NUMBER_1') acc.push([])
            }
            acc[acc.length - 1].push(val)
            return acc
        }, [])
        _sliced.forEach( (item,index) => {
            item.status = 1;
        })
    }

    if (that.gameType.indexOf('tiantiancai')>-1) {
        _sliced = _ordered.reduce((acc, val, index) => {
            if (!index || val.id==='TEMA2_1' || val.id === 'TEMA1_1' || val.id === 'LIANMA_5_5') acc.push([])
            acc[acc.length - 1].push(val)
            return acc
        }, [])
        _sliced.forEach(item => {
            item.status = 1;
        })
        console.log(_sliced)
    }

    if (that.gameType.indexOf('fastthree')>-1) {
        _sliced = _ordered.reduce((acc, val, index) => {
            if (!index || val.id==='SANJUN_1' || val.id === 'DA' || val.id === 'HE_4' || val.title === '111' || val.id==='WEISHAI_1' || val.id === 'CHANG_1_2' || val.id === 'DUAN_1_1') acc.push([])
            acc[acc.length - 1].push(val)
            return acc
        }, [])
        _sliced.forEach(item => {
            item.status = 1;
        })
    }

    if (that.gameType === 'cqssc' || that.gameType === 'hljssc' || that.gameType === 'tjssc' || that.gameType === 'sscone' || that.gameType === 'sscthree' || that.gameType === 'sscfive' || that.gameType === 'gxklsf' || that.gameType === 'jndxy' || that.gameType === 'azxy5') {
        _sliced = _ordered.reduce((acc, val, index) => {
            if (!index || (val.orderby === 'QIUDAXIAO'&&val.id.indexOf('DA')>-1) || val.id === 'LONG' || val.id.indexOf('BAOZI')>-1) acc.push([])
            acc[acc.length - 1].push(val)
            return acc
        }, [])
        _sliced.forEach(item => {
            item.status = 1;
        })
    }

    if (that.gameType === 'tencent') {
        _sliced = _ordered.reduce((acc, val, index) => {
            if (!index || (val.orderby === 'QIUDAXIAO'&&val.id.indexOf('DA')>-1) || val.id === 'ALLDA' || val.id.indexOf('BAOZI')>-1 || val.id === 'LONG_1_2' || val.id === 'LONG_1_3' || val.id === 'LONG_1_4' || val.id === 'LONG_1_5' || val.id === 'LONG_2_3' || val.id === 'LONG_2_4' || val.id === 'LONG_2_5' || val.id === 'LONG_3_4' || val.id === 'LONG_3_5' || val.id === 'LONG_4_5') acc.push([])
            acc[acc.length - 1].push(val)
            return acc
        }, [])
        _sliced.forEach(item => {
            item.status = 1;
        })
    }

    if (that.gameType === 'fc3d') {
        _sliced = _ordered.reduce((acc, val, index) => {
            if (!index || (val.id.indexOf('DA')>-1&&val.id.indexOf('DAN')===-1) || val.title === '00' || val.title === 0 || val.id === 'BAISHIDAN' || val.id === 'BAIGEDAN' || val.id === 'SHIGEDAN' || val.id === 'ERZIBAISHIWEI' || val.id === 'ERZIBAIGEWEI' || val.id === 'ERZISHIGEWEI' || val.id === 'ERZIBAISHIHE_0' || val.id==='ERZIBAISHIHEWEI_0' || val.id==='ERZIBAIGEHE_0' || val.id==='ERZIBAIGEHEWEI_0' || val.id==='ERZISHIGEHE_0' || val.id==='ERZISHIGEHEWEI_0' || val.id === 'ZUXUANSAN_5' || val.id === 'ZUXUANLIU_4' || val.id === 'KUADU_0' || val.id === 'TWOMERGER_00') acc.push([])
            acc[acc.length - 1].push(val)
            return acc
        }, [])
        _sliced.forEach(item => {
            item.status = 1;
        })
    }

    if (that.gameType === 'gxklsf') {
        _sliced = _ordered.reduce((acc, val, index) => {
            if (!index || val.title === '01' || (val.id.indexOf('DA')>-1&&val.id.indexOf('DAN')===-1&&val.id.indexOf('WEIDA')===-1) || val.id === 'ZONGDA' || (val.orderby==='QIUCE'&&val.id.indexOf('RED')>-1) ) acc.push([])
            acc[acc.length - 1].push(val)
            return acc
        }, [])
        _sliced.forEach(item => {
            item.status = 1;
        })
    }

    if (that.gameType === 'bjsc' || that.gameType === 'scone' || that.gameType === 'scthree' || that.gameType === 'scfive' || that.gameType === 'xyft' || that.gameType === 'azxy10') {
        _sliced = _ordered.reduce((acc, val, index) => {
            if (!index || (val.orderby === 'QIUDAXIAO'&&val.id.indexOf('DA')>-1) || val.id === 'GUANYAHEDA') acc.push([])
            acc[acc.length - 1].push(val)
            return acc
        }, [])
        _sliced.forEach(item => {
            item.status = 1;
        })
    }

    if (that.gameType.indexOf('fiveselect')>-1) {
        _sliced = _ordered.reduce((acc, val, index) => {
            if (_TEMPLATE==='template-3') {
                if (!index || val.title === 1 || val.id === 'LIANMAONE' || val.id === 'LIANMATWO' || val.id === 'LIANMATHREE' || val.id === 'LIANMAFOUR' || val.id === 'LIANMAFIVE' || val.id === 'LIANMASIX' || val.id === 'LIANMASEVEN' || val.id === 'LIANMAEIGHT' || val.id === 'ZUER' || val.id === 'ZUSAN' || (val.parent==='LIANGMIAN'&&val.id.indexOf('DA')>-1&&val.id.indexOf('DAN')===-1&&val.id!=='ALLDA'&&val.id!=='ALLWEIDA') || val.id === 'ALLDA' || val.id === 'ALLWEIDA' || val.id === 'QIANER' || val.id === 'QIANSAN') acc.push([])
            }else {
            if (!index || val.title === 1 || val.id === 'LIANMAONE' || (val.orderby==='QIUDAXIAO'&&val.id.indexOf('DA')>-1) || val.id === 'ALLDA' || val.id === 'ALLWEIDA' || val.id === 'QIANER') acc.push([])
            }            
            acc[acc.length - 1].push(val)
            return acc
        }, [])
        _sliced.forEach(item => {
            item.status = 1;
        })
    }

    if (that.gameType === 'gdklten') {
        _sliced = _ordered.reduce((acc, val, index) => {
            if (_TEMPLATE==='template-3') {
                if (!index || val.orderby==='DA' || val.orderby === '01' || val.orderby==='DONG' || val.id === 'LIAN_REN_2' || val.id === 'LIAN_REN_ZU_2' || val.id === 'LIAN_REN_3' || val.id === 'LIAN_REN_ZU_3' || val.id === 'LIAN_REN_4' || val.id === 'LIAN_REN_5' || val.id === 'TOTAL_DA' || val.id==='ZHENG_01') acc.push([])
            }else {
                if (!index || val.orderby==='DA' || val.orderby==='01' || val.orderby==='DONG' || val.id === 'LIAN_REN_2' || val.id === 'TOTAL_DA' || val.id==='ZHENG_01') acc.push([])
            }
            acc[acc.length - 1].push(val)
            return acc
        }, [])
        _sliced.forEach(item => {
            item.status = 1;
        })
    }

    if (this._TEMPLATE==='template-3' && that.gameType.indexOf('28')>-1) {
        _sliced = _ordered.reduce((acc, val, index) => {
            if (!index || val.id === 'RED' || val.id === '00') acc.push([])
            acc[acc.length - 1].push(val)
            return acc
        }, [])
        _sliced.forEach(item => {
            item.status = 1;
        })
    }

    if (that.gameType === 'cqklten') {
        _sliced = _ordered.reduce((acc, val, index) => {
            if (_TEMPLATE==='template-3') {
                if (!index || val.orderby === 'DA' || val.orderby === '01' || val.orderby === 'DONG' || val.id === 'LIAN_REN_2' || val.id === 'LIAN_REN_ZU_2' || val.id === 'LIAN_REN_3' || val.id === 'LIAN_REN_ZU_3' || val.id === 'LIAN_REN_4' || val.id === 'LIAN_REN_5' || val.id === 'TOTAL_DA' || val.id==='ZHENG_01') acc.push([])
            }else {
                if (!index || val.orderby===('DA') || val.orderby===('01') || val.orderby==='DONG' || val.id === 'ZHENG_01' || val.id === 'LIAN_REN_2') acc.push([])
            }            
            acc[acc.length - 1].push(val)
            return acc
        }, [])
        _sliced.forEach(item => {
            item.status = 1;
        })
    }

    return _sliced
}

function getClientTime() {
    //todo -> 客户端时间不适用于聊天室
    //取客户端小时补0
    if (new Date().getHours().toString().length == 1) {
        var date_hours = '0' + '' + new Date().getHours();
    } else {
        var date_hours = new Date().getHours();
    }

    //取客户端分钟补0
    if (new Date().getMinutes().toString().length == 1) {
        var date_minutes = '0' + '' + new Date().getMinutes();
    } else {
        var date_minutes = new Date().getMinutes();
    }

    return date_hours + ':' + date_minutes
}
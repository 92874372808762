import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import {
	getDict
} from '@/api/home'
import {
	roomLogin,
	roomLogout,
	simpleconfig
} from '@/api/beijing28'
import {
	getRoomConfig,
	GET_hongbaos,
	GET_onlineUserCount
} from '@/api/beijing28'
import {
	getInfo
} from "@/api/my";
import {
	caizhong
} from "@/api/home"

const store = new Vuex.Store({
	state: {
		currentDrawing: null,
		lastDrawing: null,
		userBalance: null,
		tradingFloorMessages: [],
		currentRoomNumber: null,
		currentGameType: null,
		chatRoomMessages: [],
		chatRoomNotices: [],
		chatRoomHongbaos: [],
		chatRoomRepeatHongbaos: [],

		chatroomSettings: null,
		chatroomNickname: (localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).chatroomNickname) || null,
		userInfo: null,
		yulibaoInfo: null,
		thirdMenu: null,
		onlineUsers: 1,
		tuhaoAnimationTimer: null,
		yulibaoConfig: null,
		gameList: null,

		language: null,
		dict: null,
	},
	mutations: {
		UPDATE_language(state, payload) {
			state.language = payload
		},
		UPDATE_dict(state, payload) {
			state.dict = payload
		},
		UPDATE_tuhaoAnimationTimer(state, payload) {
			state.tuhaoAnimationTimer = new Date().getTime();
			setTimeout(function () {
				state.tuhaoAnimationTimer = null
			}, 1000 * 60 * 5)
		},
		UPDATE_thirdMenu(state, payload) {
			state.thirdMenu = payload
		},
		UPDATE_currentDrawing(state, payload) {
			state.currentDrawing = payload
		},
		RESET_currentDrawing(state, payload) {
			state.currentDrawing = null;
			console.log('VUEX: currentDrawing RESET.')
		},
		UPDATE_lastDrawing(state, payload) {
			state.lastDrawing = payload
		},
		UPDATE_userBalance(state, payload) {
			state.userBalance = payload
		},
		UPDATE_tradingFloorMessages(state, payload) {
			state.tradingFloorMessages.push(payload)
		},
		RESET_tradingFloorMessages(state, payload) {
			state.tradingFloorMessages = [];
			console.log('VUEX: tradingFloorMessages RESET.')
		},
		UPDATE_chatRoomMessages(state, payload) {
			state.chatRoomMessages.push(payload)
		},
		RESET_chatRoomMessages(state, payload) {
			state.chatRoomMessages = [];
		},
		CLEAN_chatRoomMessageByConditions(state, payload) {
			var related = state.chatRoomMessages.filter((item, index) => {
				return (item.type === 'self' || item.type === 'self-SHORTCUT_IMAGE' || item.type === 'self-SHORTCUT_TEXT') && item.nickname === state.userInfo.talkroom_nick_name && index === state.chatRoomMessages.length - 1
			})
			if (related.length > 0) {
				state.chatRoomMessages = state.chatRoomMessages.slice(0, -1)
			}
		},
		UPDATE_chatRoomNotices(state, payload) {
			state.chatRoomNotices.push(payload)
		},
		UPDATE_chatRoomNoticeState(state, payload) {
			var related = state.chatRoomNotices.filter(function (item) {
				return payload.id === item.id
			})
			if (related.length > 0) {
				related[0].state = payload.state;
			}
		},
		UPDATE_chatRoomHongbaos(state, payload) {
			state.chatRoomHongbaos = payload
		},
		RESET_chatRoomHongbaos(state, payload) {
			state.chatRoomHongbaos = [];
		},
		UPDATE_chatRoomRepeatHongbaos(state, payload) {
			state.chatRoomRepeatHongbaos.push(payload)
		},
		UPDATE_hongbaoState(state, payload) {
			var related = state.chatRoomMessages.filter(function (item) {
				return payload.id === item.id
			})
			if (related.length > 0) {
				related[0].state = payload.state;
				related[0].value = payload.value;
				related[0].error = payload.error;
			}
			var relatedRepeat = state.chatRoomRepeatHongbaos.filter(function (item) {
				return payload.id === item.id
			})
			if (relatedRepeat.length > 0) {
				relatedRepeat[0].state = payload.state;
				relatedRepeat[0].value = payload.value;
				relatedRepeat[0].error = payload.error;
			}
		},
		UPDATE_hongbaoOpenList(state, payload) {
			var related = state.chatRoomMessages.filter(function (item) {
				return Number(payload.gift_money_id) === item.id
			})
			if (related.length > 0) {
				if (!related[0].openList) {
					related[0].openList = []
				}
				related[0].openList.push(payload);
			}
		},
		UPDATE_currentRoomNumber(state, payload) {
			state.currentRoomNumber = payload
		},
		UPDATE_currentGameType(state, payload) {
			state.currentGameType = payload
		},
		UPDATE_chatroomSettings(state, payload) {
			state.chatroomSettings = payload
		},
		UPDATE_userInfo(state, payload) {
			state.userInfo = payload
		},
		RESET_userInfo(state, payload) {
			state.userInfo = null;
			console.log('VUEX: userInfo RESET.')
		},
		UPDATE_onlineUsers(state, payload) {
			state.onlineUsers = payload
		},
		UPDATE_chatRoomNickname(state, payload) {
			state.chatroomNickname = payload
		},
		UPDATE_yulibaoInfo(state, payload) {
			state.yulibaoInfo = payload
		},
		UPDATE_yulibaoConfig(state, payload) {
			state.yulibaoConfig = payload
		},
		UPDATE_gameList(state, payload) {
			state.gameList = payload
		},
	},
	actions: {
		GET_gameList(context, payload) {
			caizhong().then(result => {
				if (result.data.code == "SUCCESS") {
					context.commit('UPDATE_gameList', data);
				} else {
					// app.$store.dispatch('GET_chatroomSettings',{});
				}
			});
		},
		GET_dict(context, payload) {
			getDict({
				sign: context.state.language
			}).then(result => {
				if (result.data.code == "SUCCESS") {
					context.commit('UPDATE_dict', result.data.result);
				} else {

				}
			})
		},
		LOGIN_tradingFloor(context, payload) {
			function start() {
				context.commit('RESET_tradingFloorMessages');
				// context.commit('RESET_chatRoomMessages');

				context.commit('UPDATE_currentRoomNumber', payload.room);
				context.commit('UPDATE_currentGameType', payload.type);

				typeof payload.callback === 'function' && payload.callback();
			}
			if (payload.room) {
				 
				roomLogin({
					game_room: payload.room,
					game_type: payload.type,
					language: window.language,
				}).then(result => {
					if (result.data.code == "SUCCESS") {
						console.log('ws room request sent.')
						start();
						roomLogout = false;
					} else {
						roomLogout = true;
						app.Mint.Toast('登录失败');
						setTimeout(() => {
							window.location.reload();
						}, 1000);
					}
				})
			} else {
				start();
			}
		},
		GET_chatroomSettings(context, payload) {
			getRoomConfig().then(function (result) {
				if (result.data.code == "SUCCESS") {
					var [data] = result.data.result;
					if (data && data.talk_conditions && (typeof data.talk_conditions === 'string')) {
						data.talk_conditions = JSON.parse(data.talk_conditions);
					}
					context.commit('UPDATE_chatroomSettings', data);
				} else {
					// app.$store.dispatch('GET_chatroomSettings',{});
				}
			})
		},
		GET_userInfo(context, payload) {
			return new Promise(function (resolve, reject) {
				getInfo().then(result => {
					if (result.data.code == "SUCCESS") {
						context.commit('UPDATE_userInfo', result.data.result);
						resolve(result.data.result)
					}
				});
			})
		},
		GET_onlineUsers(context, payload) {
			GET_onlineUserCount().then(result => {
				if (result.data.code == "SUCCESS") {
					context.commit('UPDATE_onlineUsers', result.data.result);
				}
			});
		},
		GET_chatRoomHongbaos(context, payload) {
			GET_hongbaos().then(result => {
				if (result.data.code == "SUCCESS") {
					context.commit('UPDATE_chatRoomHongbaos', result.data.result.list.reverse());
				}
			})
		},
		GET_yulibaoConfig(context, payload) {
			return new Promise(function (resolve, reject) {
				simpleconfig().then(result => {
					if (result.data.code === 'SUCCESS') {
						context.commit('UPDATE_yulibaoConfig', result.data.result);
						resolve(result.data.result)
					}
				})
			})
		},
	}
})

export default store
var CustomScroll = {};
CustomScroll.install = function (Vue, options) {
    Vue.prototype.CustomScroll = (element) => {
        
        $.easing.jswing = $.easing.swing;

        var pow = Math.pow,
            sqrt = Math.sqrt,
            sin = Math.sin,
            cos = Math.cos,
            PI = Math.PI,
            c1 = 1.70158,
            c2 = c1 * 1.525,
            c3 = c1 + 1,
            c4 = ( 2 * PI ) / 3,
            c5 = ( 2 * PI ) / 4.5;

        $.extend( $.easing,
        {
            def: 'easeOutQuad',
            swing: function (x) {
                return $.easing[$.easing.def](x);
            },
            easeInQuad: function (x) {
                return x * x;
            },
            easeOutQuad: function (x) {
                return 1 - ( 1 - x ) * ( 1 - x );
            },
            easeInOutQuad: function (x) {
                return x < 0.5 ?
                    2 * x * x :
                    1 - pow( -2 * x + 2, 2 ) / 2;
            },
            easeInCubic: function (x) {
                return x * x * x;
            },
            easeOutCubic: function (x) {
                return 1 - pow( 1 - x, 3 );
            },
            easeInOutCubic: function (x) {
                return x < 0.5 ?
                    4 * x * x * x :
                    1 - pow( -2 * x + 2, 3 ) / 2;
            },
            easeInQuart: function (x) {
                return x * x * x * x;
            },
            easeOutQuart: function (x) {
                return 1 - pow( 1 - x, 4 );
            },
        });

        function customScroll(element) {
            var app=document.getElementById(element);
            var $app=$('#'+element)

            const moveInertia=1;
            const boundary=.1;
            const incrementThreshold=4;

            var inertiaDifference=0;
            var accerlerationQueueTimerDuration=1000;
            var scrollInstructionQueue=0;
            var scrollInstructionQueueStartTime=null;
            var scrollInstructionQueueEndTime=null;
            var scrollInstructionQueueTimer=null;

            var appHeight=app.scrollHeight;
            var viewHeight=window.innerHeight;
            var startTop=0;
            var startLeft=0;
            var startPos={};
            var movePos={};
            var endPos={};
            var posDifference={};

            var _startTime=null;
            var endTime=null;
            var inertiaThreshold=200;
            var inertiaDuration=500;
            var timeDifference=null;

            var actualMoveBehavior=false;
            var currentDirectionIsVertical=null;

            function checkEnvironment(e,touchStart){
                if (e.touches.length>1) {
                    return false
                }else if (app.clientHeight===app.scrollHeight && app.clientWidth===app.scrollWidth ) {
                    if ( touchStart ) console.log('no scrollbar detected.')
                    return false
                }else {
                    return true
                }
            }

            function _start(e){
                e.stopPropagation();
                _startTime=new Date().getTime();
                if (!checkEnvironment(e,true)) {
                    return false;
                }

                if ( $app.is(':animated') ) {
                    e.preventDefault();
                    $app.stop();
                    console.log( 'stop scroll in touchstart' )
                }

                startTop=app.scrollTop //update position of scrollTop whenever touch again.
                startLeft=app.scrollLeft

                // if (accerlerationQueueTimer) {
                //  clearTimeout(accerlerationQueueTimer)
                // }
                if (scrollInstructionQueue===0) {
                    scrollInstructionQueueStartTime=new Date().getTime();

                    scrollInstructionQueueTimer=setTimeout(function(){
                        console.log( 'scrollInstructionQueue clear at timeout' )
                        scrollInstructionQueue=0
                    },accerlerationQueueTimerDuration)
                }
                
                // e.preventDefault();
                

                // document.getElementById('driftwood').innerHTML=e.touches.length

                var x=e.touches[0].clientX;
                var y=e.touches[0].clientY;

                startPos.x=x;
                startPos.y=y;

                console.log('start:', startPos)
            }

            function _move(e){
                
                e.preventDefault();
                e.stopPropagation();
                if (!checkEnvironment(e)) {
                    return false;
                }

                var x=e.touches[0].clientX;
                var y=e.touches[0].clientY;

                movePos.x=x;
                movePos.y=y;

                if ( movePos.y!==startPos.y || movePos.x!==startPos.x ) {
                    actualMoveBehavior=true;
                }
                // document.getElementById('driftwood').innerHTML=e.target
                // console.log('move:', movePos)

                if ( Math.abs(movePos.y-startPos.y) > Math.abs(movePos.x-startPos.x) ) {
                    currentDirectionIsVertical=true;
                    _scroll(movePos,true)
                    // _.debounce(_scroll(movePos,true),1000);
                }else {
                    currentDirectionIsVertical=false;
                    _scroll(movePos,true)
                }
            }

            function _end(e){
                e.stopPropagation();
                if (!checkEnvironment(e)) {
                    return false;
                }
                if (actualMoveBehavior===false) {
                    return false
                }
                endTime=new Date().getTime();
                timeDifference=(endTime-_startTime)
                
                

                var x=e.changedTouches[0].clientX;
                var y=e.changedTouches[0].clientY;

                endPos.x=x;
                endPos.y=y;

                console.log('end:', endPos)
                posDifference.vertical=Math.abs( startPos.y - endPos.y ) 
                posDifference.horizontal=Math.abs( startPos.x - endPos.x ) 

                inertiaDifference=inertiaThreshold/timeDifference > incrementThreshold ? incrementThreshold : inertiaThreshold/timeDifference 

                // _scroll(endPos,false)

                startTop=app.scrollTop //refresh position of scrollTop
                // startTop=parseInt( app.style.transform.match(/\((.+?)\)/g)[0].slice(1,-1) )
                startLeft=app.scrollLeft
                if ( currentDirectionIsVertical===true ) { 
                    console.log('no inertia vertical movement', startTop)
                }else {
                    console.log('no inertia horizontal movement', startLeft)
                }

                if ( startPos.y === endPos.y ) {
                    if ( $app.is(':animated') ) {
                        e.preventDefault();
                        $app.stop();
                        console.log( 'stop scroll in touchend' )
                        return false
                    }else {
                        console.log( 'just tap' )
                    }  
                }else if ( timeDifference<inertiaThreshold ) {
                    e.preventDefault();
                    // console.log( (endTime - scrollInstructionQueueStartTime) < accerlerationQueueTimerDuration )
                    if ( (endTime - scrollInstructionQueueStartTime) < accerlerationQueueTimerDuration ) {
                        console.log( 'scrollInstructionQueue added' )
                        scrollInstructionQueue+=1;
                    }else {
                        console.log( 'scrollInstructionQueue clear at touchEnd' )
                        scrollInstructionQueue=0;
                    }

                    if ( currentDirectionIsVertical===true ) {
                        console.log( 'inertia vertical move start', (_startTime-endTime), inertiaThreshold, posDifference.vertical )
                        $app.stop().animate(
                            {
                            scrollTop: startPos.y > endPos.y ? startTop + (moveInertia+scrollInstructionQueue)*inertiaDifference*posDifference.vertical : startTop - (moveInertia+scrollInstructionQueue)*inertiaDifference*posDifference.vertical
                            },
                            {
                                duration:inertiaThreshold*(inertiaDifference+scrollInstructionQueue),
                                easeing:'easeOutQuart'
                            },
                            function(){
                                startTop=app.scrollTop
                                console.log( 'inertia finished', startTop )
                            }
                        )
                    }else {
                        console.log( 'inertia horizontal move start', (_startTime-endTime), inertiaThreshold, posDifference.horizontal )
                        $app.stop().animate(
                            {
                            scrollLeft: startPos.x > endPos.x ? startLeft + (moveInertia+scrollInstructionQueue)*inertiaDifference*posDifference.horizontal : startLeft - (moveInertia+scrollInstructionQueue)*inertiaDifference*posDifference.horizontal
                            },
                            {
                                duration:inertiaThreshold*(inertiaDifference+scrollInstructionQueue),
                                easeing:'easeOutQuart'
                            },
                            function(){
                                startLeft=app.scrollLeft
                                console.log( 'inertia finished', startLeft )
                            }
                        )
                    }
                }else {
                    console.log( timeDifference )
                    e.preventDefault();
                    console.log(' hold too long ')
                }
            }

            function _scroll(offset,move){

                var length=0;

                if ( move ) {//scroll move
                    if ( currentDirectionIsVertical===true ) {
                        if ( startPos.y < offset.y ) {
                            app.scrollTop=startTop - Math.abs(startPos.y-offset.y)
                            // app.style.transform = 'translateY('+startTop - 0 + Math.abs(startPos.y-offset.y) + 'px)'
                        }else {
                            app.scrollTop=startTop + Math.abs(startPos.y-offset.y)
                            // app.style.transform = 'translateY('+-(startTop + Math.abs(startPos.y-offset.y)) + 'px)'
                        }
                    }else {
                        if ( startPos.x < offset.x ) {
                            app.scrollLeft=startLeft - Math.abs(startPos.x-offset.x)
                        }else {
                            app.scrollLeft=startLeft + Math.abs(startPos.x-offset.x)
                        }
                    }
                }else {//scroll end
                    if ( startPos.y < offset.y ) {
                        console.log('down')                     

                        // if ( startTop >= 0 ) {                           
                        //  length= startTop + (startTop - Math.abs(startPos.y-offset.y));
                            
                        // }else {
                            console.log('again', startTop , Math.abs(startPos.y-offset.y) )
                            length= (startTop + Math.abs(startPos.y-offset.y));
                            
                        // }

                        // if ( length>0 ) {
                        //  length=0;
                        // }else if ( Math.abs(length)>=appHeight ) {
                        //  length=-(appHeight-viewHeight);
                        // }

                        // app.scrollTop=startTop - Math.abs(startPos.y-offset.y)
                        app.style.transform =  'translateY('+ length + 'px)'                        
                    }else if ( startPos.y === offset.y ) {
                        console.log('stop at', parseInt( app.style.transform.match(/\((.+?)\)/g)[0].slice(1,-1) ) )
                        app.style.transform =  'translateY('+ parseInt( app.style.transform.match(/\((.+?)\)/g)[0].slice(1,-1) ) + 'px)'
                        return false;
                    }else {
                        console.log('up')

                        if ( startTop >= 0 ) {                          
                            length= -(startTop + Math.abs(startPos.y-offset.y));
                        }else {
                            console.log('again', startTop , Math.abs(startPos.y-offset.y) )
                            length= startTop + (startTop - Math.abs(startPos.y-offset.y));
                            
                        }

                        // if ( length>0 ) {
                        //  length=0;
                        // }else if ( Math.abs(length)>=appHeight ) {
                        //  length=-(appHeight-viewHeight);
                        // }

                        // app.scrollTop+=Math.abs(startPos.y-offset)
                        app.style.transform = 'translateY('+ length + 'px)'                     
                    }
                }

            }
            
            function init(){
                app.style.overflow='auto'
                // app.style.transform='translateY(0px)'                
            }
            init();

            app.addEventListener('touchstart',_start,false);
            app.addEventListener('touchmove',_move,false);
            app.addEventListener('touchend',_end,false);
        }

        console.log('CustomScroll mount to '+ element +' successful')

        var _style=
                '::-webkit-scrollbar {width: 3px;height:3px;}'+
                '::-webkit-scrollbar-track { -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1); border-radius: 10px;}'+
                '::-webkit-scrollbar-thumb { border-radius: 10px; -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);}'

        if ( window.navigator.userAgent.toLowerCase().indexOf('iphone')>-1 ) {
            // document.body.style.color='#5473ff';

            // $('<style type="text/css">'+_style+'</style>').appendTo(document.head)
            return customScroll(element)
        }
        
    }
}
// module.exports = CustomScroll;
export default CustomScroll;
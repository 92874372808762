import { service } from '../CONF.js'

//字典
export function getDict(data) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get(
                'v1.0/LanguageTypes', {
                params: {
                    ...data
                }
            })
        }))
    })
}

//首页领红包
export function getGold() {
    const data = {}
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/main/account/getGold', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//首页领红包
export function registerGold() {
    const data = {}
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                'main/account/registerGold', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//第三方平台跳转地址请求
export function casinoLogin(casino_type,client_type='Mobile') {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get(
                '/v1.0/Casino/login', {
                    params: {
                        casino_type,
                        client_type
                    }   
                })
        }))
    })
}

//第三方平台跳转地址请求
export function gamesLink() {
    const data = {}
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/main/WmCasino/gamesLink', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//奥创登录[返回跳转地址]
export function sexyCasinoLogin() {
    const data = {}
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/Main/SexyCasino/login', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//AS登录
export function asCasinoLogin() {
    const data = {}
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/main/AsCasino/login', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//DG登录
export function dgCasinoLogin() {
    const data = {}
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/main/DgCasino/login', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//BG登录
export function bgCasinoLogin() {
    const data = {
        url:window.location.href
    }
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/main/BgCasino/login', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//GJ登录
export function gjCasinoLogin() {
    const data = {}
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/main/CsCasino/login', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//KY登录
export function kyCasinoLogin() {
    const data = {}
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/main/KyCasino/login', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//DS登录
export function dsCasinoLogin() {
    const data = {}
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/main/DsCasino/login', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//LC登录
export function lcCasinoLogin() {
    const data = {}
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/main/LcCasino/login', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//XA登录
export function xaCasinoLogin() {
    const data = {}
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/main/XaCasino/gamesLink', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//KG登录
export function kgCasinoLogin() {
    const data = {}
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/main/KgCasino/login', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//OG登录
export function ogCasinoLogin() {
    const data = {}
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/main/OgCasino/login', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//AG登录
export function agCasinoLogin() {
    const data = {}
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/main/AgCasino/login', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//AEC登录
export function aecCasinoLogin() {
    const data = {}
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/main/SexyAecCasino/login', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//BBIN登录
export function bbinCasinoLogin() {
    const data = {}
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/main/BbinCasino/login', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//SB登录
export function sbCasinoLogin() {
    const data = {}
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/main/SbCasino/login', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//GG登录
export function ggCasinoLogin() {
    const data = {}
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/main/GgCasino/login', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//AGS登录
export function agsCasinoLogin() {
    const data = {}
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/main/agsCasino/login', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//玩法说明
export function explainList(type) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('main/Home/explainList',{
                params: { 
                    type: type 
                },
            })
        }))
    })
}

//赔率说明
export function oddsExplain(game_type, room_type) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('main/Home/oddsExplain',{
                params: {
                    game_type: game_type,
                    room_type: room_type
                },
            })
        }))
    })
}

// 走马灯公告
export function notice() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/home/notice',{
                params: {
                    authFree: true
                },
            })
        }))
    })
}

//弹窗公告
export function bulletin() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/home/bulletin',{
                params: {
                    authFree: true
                },
            })
        }))
    })
}

//首页轮播图
export function getLunbotu() {
    return new Promise(function(resolve, reject) {
        resolve(service()({
                url: '/main/home/banner',
                method: 'get',
                params: {
                    authFree: true
                },
            })
        )
    })
}

export function caizhong() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/home/lottery',{
                params: {
                    authFree: true
                },
            })
        }))
    })
}

export function getLotteries() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/home/haslottery',{
                params: {
                    authFree: true
                },
            })
        }))
    })
}

//第三方列表
export function getThirdPartyList(type) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/home/casino',{
                params: {
                    type:type
                },
            })
        }))
    })
}

//公告信息
export function gonggao() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/home/notice',{
                params: {
                    authFree: true
                },
            })
        }))
    })
}
//房间列表
export function room_list(type) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/home/room',{
                params: {
                    type: type,
                    authFree: true
                },
            })
        }))
    })
}
//最近封盘信息
export function issue(type) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/lottery/issue',{
                params: {
                    type: type,
                    authFree: true
                },
            })
        }))
    })
}

//开奖走势
export function lottery() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('Main/Lottery/allNewIssues',{
                params: {
                    authFree: true
                },
            })
        }))
    })
}

//历史开奖
export function history_lottery(type, date, page, pageSize,startTime,endTime) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('main/lottery/openHistory',{
                params: {
                    type: type,
                    date: date,
                    page: page,
                    pageSize: pageSize,     
                    startTime,
                    endTime,       
                },
            })
        }))
    })
}

//app下载
export function appDown(type) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/account/downloadUrl',{
                params: {
                    type: type,
                },
            })
        }))
    })
}

export function iosDownloadPath(type) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('v1.0/sslFiles/down',{
                params: {
                    type: 1,
                },
            })
        }))
    })
}

//抽奖--获取奖项和当前用户积分情况
export function drawPrepare() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/active/drawPrepare',{
                params: {

                }
            })
        }))
    })
}

//执行抽奖并获取抽奖结果
export function getMoneyResult() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/active/draw',{
                params: {

                },
            })
        }))
    })
}


//获得奖励的记录
export function drawLog(page, pageSize) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/active/drawLog',{
                params: {
                    page: page,
                    pageSize: pageSize,
                },
            })
        }))
    })
}

//提取全部奖励
export function drawGet() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/active/drawGet',{
                params: {

                },
            })
        }))
    })
}

//微信绑定用户名
export function userBinding(username, password) {
    const data = {
        username: username,
        password: password
    }
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/Main/Account/userBinding', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//获取cq9列表
export function electronicsDisplay(gameCompany, page=1,pageSize=20) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/home/electronicsDisplay',{
                params: {
                    casino_id:gameCompany,
                    page,
                    pageSize,
                },
            })
        }))
    })
}

//进入电子游艺大厅对应游戏
export function dianziyouyiLogin(thirdparty, params ) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/'+thirdparty+'/login',{
                params: params
            })
        }))
    })
}

//进入电子游艺大厅对应游戏V1.0
export function dianziyouyiLoginV1(casino_type, clint_type='mobile', game_type, game_code ) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/v1.0/Casino/login',{
                params: {
                    casino_type,
                    clint_type,
                    game_type,
                    game_code,
                }
            })
        }))
    })
}
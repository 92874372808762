<script>
import { getUnreadCount } from "@/api/my";

export default {
    data() {
        return {
            close:true,
            currentUrl:'',
            unreadMsg:null,
        };
    },
    props: [
        'bottomTabs'
    ],
    inject:[
        'switchTab'
    ],
    computed: {

    },
    methods:{
        
    },
    mounted(){
        if (window.localStorage.getItem('user')&&JSON.parse(window.localStorage.getItem('user')).token) {
        getUnreadCount().then(result=>{
                if (result.data.code == "SUCCESS") {
                    this.unreadMsg=result.data.result && result.data.result.unread
                }
            })
        }
        var related=this.bottomTabs.filter(item=>{
            return item.url===this.$route.path
        })
        if (related.length>0) {
            this.bottomTabs.forEach(item=>{
                item.active=false;
            })
            related[0].active=true;
        }
    },
    created(){
        // console.log(this.simpleVuex)
        // const that=this;
        // this.simpleVuex.$on('closeBottomTab', (data) => {
        //     if(data===true) {
        //         that.close=true
        //     }
        // })
        this.$root.$on('closeBottomTab',(data)=>{
            this.close=data
        })
        this.$root.$on('hardwareBackTriggered',(data)=>{
            this.currentUrl=data
        })
    },
    watch:{
        currentUrl(_new,_old){
            this.switchTab(_new)
        }
    }
};
</script>

<template>
    <nav v-if="!close" id="bottom-tab" :class="['clearfix',_TEMPLATE]" :style="{ '--data-length':bottomTabs.length }">
        <span v-if="unreadMsg && unreadMsg>0" class="sup">{{unreadMsg}}</span>
        <router-link v-for="(item,index) in bottomTabs" :key="index" :to="item.url" @click.native="switchTab(item.url)" class='ripple' :class="{ highlight:item.active }">
            <template v-if="_TEMPLATE==='template-1'||_TEMPLATE==='template-2'">
                <i class="iconfont" :class="item.icon" v-show='!item.active'></i>
                <i class="iconfont" :class="item.iconActive" v-show='item.active'></i>
            </template>
            <template v-if="_TEMPLATE==='template-3'">
                <i class="iconfont" :class="item.icon"></i>
            </template>
            <span>{{item.title}}</span>
        </router-link>        
    </nav>
</template>

<style lang='scss' type="text/css">
    #bottom-tab {        
        position: relative;
        box-shadow: 0px -1px 10px 0px rgba(0,0,0,.2);

        &.template-3 {

            a {
                color: #FFFFFF;
                background-color: #171719;

                &.highlight {
                    color: #CDBF0D;

                    .iconfont {
                        color: #CDBF0D;
                    }
                }
            }
        }

        .sup {
            position: absolute;
            right: .3rem;
            background: red;
            color: white;
            border-radius: 100%;
            width: .35rem;
            text-align: center;
            height: .35rem;
            line-height: .35rem;
            top: .05rem;
            z-index: 1;
        }

        a {
            text-align: center;
            color: #a8a8a8;
            background-color: #ffffff;
            height: 1rem;
            display: block;
            float:left;
            width: calc(100vw/var(--data-length));
            padding-top: .125rem;
            -webkit-tap-highlight-color: transparent;

            i {
                display: block;
                font-size: .45rem;
            }

            span {
                vertical-align: -2px;
            }

            &.highlight {
                color: #3985E1;

                .iconfont {
                    color: #82B7F8;
                }
            }
        }
    }
</style>